import React, { } from "react";
import { Link } from "react-router-dom";

import {
    Typography, Row, Col, Divider, Image, Button
} from 'antd';
import { MENU_ITEMS } from "../../util/options";

const HowToPlay = () => {
    const renderLotto = (name, link, imageName) => {
        return <>
            <Divider orientation="left" id={name}></Divider>
            <Link to={link} target="_blank">
                <Image
                    preview={false}
                    src={`/images/howtoplay/${imageName}`}
                    fallback={`/images/lotto/fallback.png`}
                    alt=""
                    className="content-image"
                />
            </Link>
        </>
    }

    return (
        <div className="common-page-group howtoplay-page">
            <div className='page-title'>
                <Typography className='title'>{MENU_ITEMS.howToPlay.label}</Typography>
            </div>
            <Row>
                <Col xs={24}>
                    <div className="my-2">
                        <Row gutter={[8, 8]}>
                            <Col><Button type="primary" href={`${window.location.pathname}#หวยไทย`} >หวยไทย</Button></Col>
                            <Col><Button type="primary" href={`${window.location.pathname}#หวยลาว`} >หวยลาว</Button></Col>
                            <Col><Button type="primary" href={`${window.location.pathname}#หวยเวียดนาม`} >หวยเวียดนาม</Button></Col>
                            <Col><Button type="primary" href={`${window.location.pathname}#หวยออมสิน`} >หวยออมสิน</Button></Col>
                            <Col><Button type="primary" href={`${window.location.pathname}#หวย ธ.ก.ส.`} >หวย ธ.ก.ส.</Button></Col>
                            <Col><Link to={`https://dowjonespowerball.com`} target="_blank" ><Button type="primary">หวยดาวโจนส์ VIP</Button></Link></Col>
                            <Col><Button type="primary" href={`${window.location.pathname}#หวยลาว`} >หวยลาวทีวี</Button></Col>
                            <Col><Button type="primary" href={`${window.location.pathname}#หวยเวียดนาม`} >หวยฮานอยสตาร์</Button></Col>
                            <Col><Button type="primary" href={`${window.location.pathname}#หวยเวียดนาม`} >หวยฮานอยทีวี</Button></Col>
                            <Col><Button type="primary" href={`${window.location.pathname}#หวยเวียดนาม`} >หวยลาวสตาร์</Button></Col>
                            <Col><Button type="primary" href={`${window.location.pathname}#หวยเวียดนาม`} >หวยฮานอยกาชาด</Button></Col>
                            <Col><Button type="primary" href={`${window.location.pathname}#หวยลาว`} >หวยลาวสามัคคี</Button></Col>
                            <Col><Button type="primary" href={`${window.location.pathname}#หวยลาว`} >หวยลาวกาชาด</Button></Col>
                            <Col><Button type="primary" href={`${window.location.pathname}#นิเคอิ-VIP`} >นิเคอิ VIP</Button></Col>
                            <Col><Button type="primary" href={`${window.location.pathname}#เกาหลี-VIP`} >เกาหลี VIP</Button></Col>
                            <Col><Button type="primary" href={`${window.location.pathname}#ฮั่งเส็ง-VIP`} >ฮั่งเส็ง VIP</Button></Col>
                            <Col><Button type="primary" href={`${window.location.pathname}#ใต้หวัน-VIP`} >ใต้หวัน VIP</Button></Col>
                            <Col><Button type="primary" href={`${window.location.pathname}#จีน-VIP`} >จีน VIP</Button></Col>
                        </Row>

                    </div>
                </Col>
                <Col xs={24}>
                    <div className="text-center">
                        {renderLotto("หวยไทย", "http://www.glo.or.th", "1.jpg")}
                        {renderLotto("หวยลาว", "https://www.laodl.com", "2.jpg")}
                        {renderLotto("หวยเวียดนาม", "https://xskt.com.vn", "3.jpg")}
                        {renderLotto("หวยออมสิน", "https://www.gsb.or.th/lotto/lottery/list-lite/psc.aspx", "4.png")}
                        {renderLotto("หวย ธ.ก.ส.", "https://www.baac.or.th/salak/content-lotto.php", "5.jpg")}
                        {renderLotto("หวยลาวสตาร์", "https://laostars.com", "6.png")}
                        {renderLotto("นิเคอิ-VIP", "https://stocks-vip.com", "7.jpeg")}
                        {renderLotto("เกาหลี-VIP", "https://stocks-vip.com", "8.jpeg")}
                        {renderLotto("ฮั่งเส็ง-VIP", "https://stocks-vip.com", "9.jpeg")}
                        {renderLotto("ใต้หวัน-VIP", "https://stocks-vip.com", "10.jpeg")}
                        {renderLotto("จีน-VIP", "https://stocks-vip.com", "11.jpeg")}
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default HowToPlay;
