import { get, post } from "../auth.service"
import { authHeader } from "../auth-header";
import { API_URL } from "../../util/constant"

const URL = API_URL + 'admin/';

const getRegisterData = async () => {
    return await get(URL + 'register/data', { headers: await authHeader() });
}

const getRegisterDataByAgent = async (userId) => {
    return await post(URL + 'register/data/by-agent', { userId }, { headers: await authHeader() });
}

const isUsernameTaken = async (username) => {
    return await post(URL + 'username-check', { username }, { headers: await authHeader() },);
}

const addAgent = async (values) => {
    return await post(URL + 'add-agent', values, { headers: await authHeader() },);
}

const listRecentAddAgent = async (userId) => {
    return await post(URL + 'agent/recent-add', { userId }, { headers: await authHeader() });
}

const listUser = async (values) => {
    return await post(URL + 'user/list', values, { headers: await authHeader() });
}

const depositToUser = async (values) => {
    return await post(URL + 'user/deposit', values, { headers: await authHeader() });
}

const withdrawFromUser = async (values) => {
    return await post(URL + 'user/withdraw', values, { headers: await authHeader() });
}


const changeUserPassword = async (values) => {
    return await post(URL + 'user/change-password', values, { headers: await authHeader() });
}

const updateUser = async (values) => {
    return await post(URL + 'user/update', values, { headers: await authHeader() });
}

const updateUserStatus = async (values) => {
    return await post(URL + 'user/status/update', values, { headers: await authHeader() });
}

const getMarkets = async () => {
    return get(URL + 'markets', { headers: await authHeader() });
}

//lotto result group + lotto date group
const prepareLottoResultAdd = async (lotto_dates_fk) => {
    return post(URL + 'lotto-result/add/data', { lotto_dates_fk }, { headers: await authHeader() });
}

const lottoResultAdd = async (values) => {
    return post(URL + 'lotto-result/add', values, { headers: await authHeader() });
}

const lottoResultUpdate = async (values) => {
    return post(URL + 'lotto-result/update', values, { headers: await authHeader() });
}

const lottoDateRefund = async (values) => {
    return post(URL + 'lotto-date/refund', values, { headers: await authHeader() });
}

const lottoDateReCalculate = async (values) => {
    return post(URL + 'lotto-date/re-calculate', values, { headers: await authHeader() });
}

const listLottoDate = async (values) => {
    return post(URL + 'lotto-date/list', values, { headers: await authHeader() });
}

const updateLottoDate = async (values) => {
    return post(URL + 'lotto-date/update', values, { headers: await authHeader() });
}

const lottoDateToggleCancel = async (values) => {
    return post(URL + 'lotto-date/toggle-cancel', values, { headers: await authHeader() });
}

const lottoDateDelete = async (values) => {
    return post(URL + 'lotto-date/delete', values, { headers: await authHeader() });
}

const lottoDateAdd = async (values) => {
    return post(URL + 'lotto-date/add', values, { headers: await authHeader() });
}

const lottoDateAddBulk = async (values) => {
    return post(URL + 'lotto-date/add-bulk', values, { headers: await authHeader() });
}

// end group

// lotto close number group
const prepareLottoClosedNumberManage = async (lotto_dates_fk) => {
    return post(URL + 'lotto-closed-number/data', { lotto_dates_fk }, { headers: await authHeader() });
}

const lottoClosedNumberUpdate = async (values) => {
    return post(URL + 'lotto-closed-number/update', values, { headers: await authHeader() });
}

// end group

// bet result group
const getDownlineBetResult = async (values) => {
    return await post(URL + 'bet-result', values, { headers: await authHeader() });
}

const getDownlineMemberBetResult = async (values) => {
    return await post(URL + 'member/bet-result', values, { headers: await authHeader() });
}

const getDownlineBetResultByDate = async (values) => {
    return await post(URL + 'bet-result/by-date', values, { headers: await authHeader() });
}

const getDownlineMemberBetResultByDate = async (values) => {
    return await post(URL + 'member/bet-result/by-date', values, { headers: await authHeader() });
}
// end group

const getDownlineWaitReward = async (values) => {
    return await post(URL + 'wait-reward', values, { headers: await authHeader() });
}

const getDownlineMemberWaitReward = async (values) => {
    return await post(URL + 'member/wait-reward', values, { headers: await authHeader() });
}


const AdminService = {
    getRegisterData,
    getRegisterDataByAgent,
    isUsernameTaken,
    addAgent,
    listRecentAddAgent,
    listUser,
    depositToUser,
    withdrawFromUser,
    changeUserPassword,
    updateUser,
    getMarkets,
    prepareLottoResultAdd,
    lottoResultAdd,
    lottoResultUpdate,
    lottoDateRefund,
    lottoDateReCalculate,
    listLottoDate,
    updateLottoDate,
    lottoDateToggleCancel,
    lottoDateDelete,
    lottoDateAdd,
    lottoDateAddBulk,
    prepareLottoClosedNumberManage,
    lottoClosedNumberUpdate,
    getDownlineBetResult,
    getDownlineMemberBetResult,
    getDownlineBetResultByDate,
    getDownlineMemberBetResultByDate,
    getDownlineWaitReward,
    getDownlineMemberWaitReward,
    updateUserStatus
}

export default AdminService