import React, { useState, useEffect } from 'react';
import { Spin, Typography, Row, Col, Table, Checkbox, Breadcrumb, Segmented, Empty, Alert } from 'antd';
import { ELM_STANDARD, MENU_ITEMS, ROLE_RENDER } from '../../util/options';
import { DATE_FORMAT, DB, NUMBER_FORMAT, openNotification, TABLE_COL_ATTRIBUTE } from '../../util/constant';
import AgentService from '../../services/user/agent.service';
import { useUserContext } from '../../contexts/UserContext';
import { UserOutlined } from '@ant-design/icons';
import { groupBy } from 'lodash';

import moment from 'moment';
import 'moment/locale/th';
import OrderDetail from '../common/modal/OrderDetail';

moment.updateLocale('th', {
    week: {
        dow: 1
    }
})

const textClassNumber = (value) => {
    return value >= 0 ? 'text-green' : 'text-red';
}

const WaitReward = () => {
    const { user, userAction } = useUserContext();
    const [loading, setLoading] = useState(true);
    const [loadingResult, setLoadingResult] = useState(false)

    const [data, setData] = useState([])
    const [agentData, setAgentData] = useState([])
    const [memberData, setMemberData] = useState({})
    const [byLottoData, setByLottoData] = useState([])

    const [nameIsShow, setNameIsShow] = useState(false)

    const [isModalOrderDetailVisible, setIsModalOrderDetailVisible] = useState(false);
    const [modalOrderDetailData, setModalOrderDetailData] = useState({})

    const [segmentValue, setSegmentValue] = useState('ทั้งหมด');
    const [breadcrumbs, setBreadcrumbs] = useState([])

    const [displayMode, setDisplayMode] = useState('agent')


    useEffect(() => {
        setBreadcrumbs([{ id: user.id, username: user.username, role: user.role }])
    }, [user.id, user.role, user.username])


    const segmentChange = (value) => {
        setSegmentValue(value)
        if (value === 'ทั้งหมด') {
            setMemberData(groupBy(data, 'lottos_fk'))
        } else if (value === 'บิลได้') {
            setMemberData(groupBy(data.filter(item => Number(item.wonAmount) > 0), 'lottos_fk'))
        } else if (value === 'บิลเสีย') {
            setMemberData(groupBy(data.filter(item => Number(item.wonAmount) <= 0), 'lottos_fk'))
        }
    }

    useEffect(() => {
        setLoading(false)
        getData()
    }, [])

    const getData = async () => {
        try {
            setLoadingResult(true)
            const res = await AgentService.getDownlineWaitReward()
            setData(res.resultByMember)
            setAgentData(res.resultByMember)
            setByLottoData(res.resultByLotto)
            setLoadingResult(false)
        }
        catch (error) {
            setLoadingResult(false)
            openNotification('error', 'เกิดข้อผิดพลาด', error.message)
        }
    }


    const changeUser = async (param) => {
        if (param.id === breadcrumbs[breadcrumbs.length - 1].id) {
            openNotification('info', `ระบบได้แสดงผลสมาชิก ${param.username} อยู่แล้ว`, '', 'bottomLeft')
            return
        }

        try {
            setLoadingResult(true)
            let data = []
            if (param.role === DB.USER.ROLE.AGENT) {
                setDisplayMode('agent')
                data = await AgentService.getDownlineWaitReward({ userId: param.id })
                setData(data.resultByMember)
                setAgentData(data.resultByMember)
                setByLottoData(data.resultByLotto)
            } else {
                setDisplayMode('member')
                data = await AgentService.getDownlineMemberWaitReward({ userId: param.id })
                setData(data)
                setMemberData(groupBy(data, 'lottos_fk'))
            }

            let index = breadcrumbs.findIndex(b => b.id === param.id)

            if (index === -1) {
                const bc = [...breadcrumbs]
                bc.push(param)
                setBreadcrumbs([...bc])
            } else {
                let newArr = JSON.parse(JSON.stringify(breadcrumbs));
                newArr.splice(index + 1)
                setBreadcrumbs(newArr)
            }
            setLoadingResult(false)
        } catch (err) {
            setLoadingResult(false)
        }
    }

    const renderMemberTables = () => {
        if (Object.entries(memberData)?.length > 0) {
            return Object.entries(memberData)?.map(([key, value]) => {
                return <div key={key} className="table-wrapper">
                    <div className='text-bold'>< span >[{(DB.SYSTEM.LOTTO.GROUP.find((g) => g.id === value[0]?.groupId))?.name}] {value[0]?.name}</span></div>
                    <Table
                        size='small'
                        rowKey={record => record.id}
                        dataSource={value}
                        scroll={{ x: 'max-content' }}
                        columns={[
                            {
                                title: 'เวลาแทง',
                                dataIndex: 'createdAt',
                                key: 'createdAt',
                                align: 'center',
                                render: value => (
                                    DATE_FORMAT.default(value)
                                )
                            }, {
                                title: 'ชนิดหวย',
                                dataIndex: 'name',
                                key: 'name',
                                align: 'center',
                                render: (value, record, index) => {
                                    return `[${(DB.SYSTEM.LOTTO.GROUP.find((g) => g.id === record.groupId))?.name}] - ${value}`
                                }
                            },
                            {
                                title: 'งวด',
                                dataIndex: 'date',
                                key: 'date',
                                align: 'center',
                                render: (value) => {
                                    return `${DATE_FORMAT.dateonly(value)}`
                                }
                            },
                            {
                                title: 'รายการ',
                                dataIndex: 'count',
                                key: 'count',
                                align: 'center',
                                render: value => (
                                    value
                                )
                            },
                            {
                                title: 'ยอด',
                                dataIndex: 'sum',
                                key: 'sum',
                                align: 'right',
                                render: value => (
                                    <div className='text-green'>{NUMBER_FORMAT.default(value)}</div>
                                )
                            },
                            {
                                title: 'ส่วนลด',
                                dataIndex: 'discount',
                                key: 'discount',
                                align: 'right',
                                render: value => (
                                    <div className='text-red'>{NUMBER_FORMAT.default(value)}</div>
                                )
                            },
                            {
                                title: 'รวม',
                                dataIndex: 'discount',
                                key: 'discount',
                                align: 'right',
                                render: (value, record) => (
                                    NUMBER_FORMAT.default(Number(record.sum) - Number(record.discount))
                                )
                            },
                            {
                                title: 'แพ้/ชนะ',
                                dataIndex: 'status',
                                key: 'status',
                                align: 'right',
                                render: (value, record) => {
                                    if (value === DB.SYSTEM.ORDER.STATUS.COMPLETED) {
                                        return Number(record.wonAmount) > 0 ?
                                            <span className='text-blue text-bold'>{NUMBER_FORMAT.default(record.wonAmount)}</span>
                                            : <span className='text-red'>ไม่ถูกรางวัล</span>
                                    }
                                    return ''
                                }
                            },
                            {
                                title: 'หมายเหตุ',
                                dataIndex: 'remark',
                                key: 'remark',
                                align: 'left',
                            },
                            {
                                title: '#',
                                dataIndex: 'actions',
                                key: 'actions',
                                align: 'center',
                                render: (value, record) => {


                                    return <Row gutter={[16, 16]}>
                                        <Col xs={12}><Typography.Link onClick={() => { setModalOrderDetailData(record); setIsModalOrderDetailVisible(true) }}>ดูรายละเอียด</Typography.Link></Col>
                                    </Row>

                                }
                            },

                        ]}
                        className="table-elm shadowed"
                        pagination={false}
                        rowClassName={(record) => {
                            if (record.status === DB.SYSTEM.ORDER.STATUS.CANCELED) return 'text-secondary opacity-50'
                            if (record.status === DB.SYSTEM.ORDER.STATUS.COMPLETED && Number(record.wonAmount) > 0) return 'highlighted-won-order'
                            return ''
                        }}
                        summary={pageData => {
                            let sum1 = 0;
                            let sum2 = 0;
                            let sum3 = 0;
                            let sum4 = 0;
                            let sumCount = 0
                            pageData.forEach(({ sum, discount, wonAmount, count }) => {
                                sum1 += Number(sum)
                                sum2 += Number(discount)
                                sum3 += Number(sum) - Number(discount)
                                sum4 += Number(wonAmount) ?? 0
                                sumCount += count
                            });
                            return (
                                <Table.Summary.Row style={{ fontWeight: 'bold' }}>
                                    <Table.Summary.Cell className='text-left' colSpan={3}><div >รวม</div></Table.Summary.Cell>
                                    <Table.Summary.Cell className='text-center'><div>{sumCount}</div> </Table.Summary.Cell>
                                    <Table.Summary.Cell className='text-right text-green'><div>{NUMBER_FORMAT.default(sum1)}</div> </Table.Summary.Cell>
                                    <Table.Summary.Cell className='text-right text-red'><div>{NUMBER_FORMAT.default(sum2)}</div> </Table.Summary.Cell>
                                    <Table.Summary.Cell className='text-right text-blue'><div>{NUMBER_FORMAT.default(sum3)}</div> </Table.Summary.Cell>
                                    <Table.Summary.Cell className='text-right text-blue'><div>{NUMBER_FORMAT.default(sum4)}</div> </Table.Summary.Cell>
                                    <Table.Summary.Cell colSpan={2}></Table.Summary.Cell>
                                </Table.Summary.Row>
                            );
                        }}
                    />
                </div>
            })
        } else {
            return <Empty />
        }
    }


    const renderExpandRow = (expandData) => {
        return <Table
            loading={loadingResult}
            bordered
            size='small'
            rowKey={record => record.id}
            dataSource={expandData.users}
            scroll={{ x: 'max-content' }}
            columns={[
                {
                    title: <div>
                        <div>ชื่อผู้ใช้</div>
                        <div><Checkbox checked={nameIsShow} onChange={() => setNameIsShow(!nameIsShow)}>แสดงชื่อ</Checkbox></div>
                    </div>,
                    dataIndex: 'username',
                    key: 'username',
                    width: '10%',
                    onCell: (record) => { return { onClick: () => changeUser({ id: record.id, username: record.username, role: record.role }) } },
                    render (value, record) {
                        const name = nameIsShow && <span className='text-gray'>({record.name})</span>
                        return <Typography.Link>{value} {name}</Typography.Link>
                    },
                },
                {
                    title: 'ระดับ',
                    dataIndex: 'role',
                    key: 'role',
                    align: 'center',
                    render (value) {
                        return ROLE_RENDER[value]
                    },
                    sorter: ((a, b) => a.role.localeCompare(b.role))
                },
                {
                    title: 'ยอดทั้งหมด',
                    dataIndex: 'sum',
                    key: 'sum',
                    align: 'right',
                    render: value => (
                        <div className='text-green'>{NUMBER_FORMAT.default(value)}</div>
                    )
                },
                {
                    title: <span><UserOutlined /> สมาชิก</span>,
                    ...TABLE_COL_ATTRIBUTE.colGroup,
                    children: [
                        {
                            title: 'ส่งออก',
                            dataIndex: 'memberExport',
                            key: 'memberExport',
                            align: 'right',
                            ...TABLE_COL_ATTRIBUTE.memberExport,
                            render: (value, record) => (
                                <div className='text-red'>{NUMBER_FORMAT.default(record.member.export)}</div>
                            )
                        },
                        {
                            title: 'ถูกรางวัล',
                            dataIndex: 'memberWonAmount',
                            key: 'memberWonAmount',
                            align: 'right',
                            render: (value, record) => (
                                <div className={textClassNumber(record.member.wonAmount)}>{NUMBER_FORMAT.default(record.member.wonAmount)}</div>
                            )
                        },
                        {
                            title: 'คอม',
                            dataIndex: 'memberDiscount',
                            key: 'memberDiscount',
                            align: 'right',
                            render: (value, record) => (
                                <div className={textClassNumber(record.member.discount)}>{NUMBER_FORMAT.default(record.member.discount)}</div>
                            )
                        },
                        {
                            title: 'รวม',
                            dataIndex: 'memberSummary',
                            key: 'memberSummary',
                            align: 'right',
                            render: (value, record) => {
                                let summary = record.member.export + record.member.wonAmount + record.member.discount
                                return <div className={textClassNumber(summary)}> {NUMBER_FORMAT.default(summary)}</div>
                            }
                        },
                    ]
                },
                {
                    title: <span><UserOutlined /> {breadcrumbs[breadcrumbs.length - 1]?.username}</span>,
                    ...TABLE_COL_ATTRIBUTE.colGroup,
                    children: [
                        {
                            title: 'ถือหุ้น',
                            dataIndex: 'agentHold',
                            key: 'agentHold',
                            align: 'right',
                            ...TABLE_COL_ATTRIBUTE.agentHold,
                            render: (value, record) => (
                                <div className={textClassNumber(record.agent.hold)}>{NUMBER_FORMAT.default(record.agent.hold)}</div>
                            )
                        },
                        {
                            title: 'ถูกรางวัล',
                            dataIndex: 'agentWonAmount',
                            key: 'agentWonAmount',
                            align: 'right',
                            render: (value, record) => (
                                <div className={textClassNumber(record.agent.wonAmount)}>{NUMBER_FORMAT.default(record.agent.wonAmount)}</div>
                            )
                        },
                        {
                            title: 'คอม',
                            dataIndex: 'agentDiscount',
                            key: 'agentDiscount',
                            align: 'right',
                            render: (value, record) => (
                                <div className={textClassNumber(record.agent.discount)}>{NUMBER_FORMAT.default(record.agent.discount)}</div>
                            )
                        },
                        {
                            title: 'รวม',
                            dataIndex: 'agentSummary',
                            key: 'agentSummary',
                            align: 'right',
                            render: (value, record) => {
                                let summary = record.agent.hold + record.agent.wonAmount + record.agent.discount
                                return <div className={textClassNumber(summary)}> {NUMBER_FORMAT.default(summary)}</div>
                            }
                        },
                    ]
                },
                {
                    title: <span>บริษัท</span>,
                    ...TABLE_COL_ATTRIBUTE.colGroup,
                    children: [
                        {
                            title: 'ถือหุ้น',
                            dataIndex: 'companyHold',
                            key: 'companyHold',
                            align: 'right',
                            ...TABLE_COL_ATTRIBUTE.companyHold,
                            render: (value, record) => {
                                let displayValue = -1 * (record.member.export + record.agent.hold)
                                return <div className={textClassNumber(displayValue)}>{NUMBER_FORMAT.default(displayValue)}</div>
                            }
                        },
                        {
                            title: 'ถูกรางวัล',
                            dataIndex: 'companyWonAmount',
                            key: 'companyWonAmount',
                            align: 'right',
                            render: (value, record) => {
                                let displayValue = -1 * (record.member.wonAmount + record.agent.wonAmount)
                                return <div className={textClassNumber(displayValue)}>{NUMBER_FORMAT.default(displayValue)}</div>
                            }
                        },
                        {
                            title: 'คอม',
                            dataIndex: 'companyDiscount',
                            key: 'companyDiscount',
                            align: 'right',
                            render: (value, record) => {
                                let displayValue = -1 * (record.member.discount + record.agent.discount)
                                return <div className={textClassNumber(displayValue)}>{NUMBER_FORMAT.default(displayValue)}</div>
                            }
                        },
                        {
                            title: 'รวม',
                            dataIndex: 'companySummary',
                            key: 'companySummary',
                            align: 'right',
                            render: (value, record) => {
                                let displayValue = (-1 * (record.member.export + record.agent.hold)) + (-1 * (record.member.wonAmount + record.agent.wonAmount)) + (-1 * (record.member.discount + record.agent.discount))
                                return <div className={textClassNumber(displayValue)}>{NUMBER_FORMAT.default(displayValue)}</div>
                            }
                        },
                    ]
                },
            ]}
            summary={pageData => {
                let results = {
                    sum: 0,
                    member: {
                        export: 0,
                        wonAmount: 0,
                        discount: 0,
                        summary: 0,
                    },
                    agent: {
                        hold: 0,
                        wonAmount: 0,
                        discount: 0,
                        summary: 0,
                    },
                    company: {
                        hold: 0,
                        wonAmount: 0,
                        discount: 0,
                        summary: 0,
                    }

                }
                pageData.forEach(({ sum, member, agent }) => {
                    results.sum += sum
                    results.member.export += member.export
                    results.member.wonAmount += member.wonAmount
                    results.member.discount += member.discount
                    results.member.summary += member.export + member.wonAmount + member.discount

                    results.agent.hold += agent.hold
                    results.agent.wonAmount += agent.wonAmount
                    results.agent.discount += agent.discount
                    results.agent.summary += agent.hold + agent.wonAmount + agent.discount

                    results.company.hold += -1 * (member.export + agent.hold)
                    results.company.wonAmount += -1 * (member.wonAmount + agent.wonAmount)
                    results.company.discount += -1 * (member.discount + agent.discount)
                    results.company.summary += (-1 * (member.export + agent.hold)) + (-1 * (member.wonAmount + agent.wonAmount)) + (-1 * (member.discount + agent.discount))
                });
                return (
                    <Table.Summary>
                        <Table.Summary.Row style={{ fontWeight: 'bold' }}>
                            <Table.Summary.Cell className='text-left' colSpan={2}><div>รวม</div></Table.Summary.Cell>
                            <Table.Summary.Cell className={`text-right ${textClassNumber(results.sum)}`}><div>{NUMBER_FORMAT.default(results.sum)}</div> </Table.Summary.Cell>
                            <Table.Summary.Cell className={`text-right ${textClassNumber(results.member.export)}`}><div>{NUMBER_FORMAT.default(results.member.export)}</div> </Table.Summary.Cell>
                            <Table.Summary.Cell className={`text-right ${textClassNumber(results.member.wonAmount)}`}><div>{NUMBER_FORMAT.default(results.member.wonAmount)}</div> </Table.Summary.Cell>
                            <Table.Summary.Cell className={`text-right ${textClassNumber(results.member.discount)}`}><div>{NUMBER_FORMAT.default(results.member.discount)}</div> </Table.Summary.Cell>
                            <Table.Summary.Cell className={`text-right ${textClassNumber(results.member.summary)}`}><div>{NUMBER_FORMAT.default(results.member.summary)}</div> </Table.Summary.Cell>
                            <Table.Summary.Cell className={`text-right ${textClassNumber(results.agent.hold)}`}><div>{NUMBER_FORMAT.default(results.agent.hold)}</div> </Table.Summary.Cell>
                            <Table.Summary.Cell className={`text-right ${textClassNumber(results.agent.wonAmount)}`}><div>{NUMBER_FORMAT.default(results.agent.wonAmount)}</div> </Table.Summary.Cell>
                            <Table.Summary.Cell className={`text-right ${textClassNumber(results.agent.discount)}`}><div>{NUMBER_FORMAT.default(results.agent.discount)}</div> </Table.Summary.Cell>
                            <Table.Summary.Cell className={`text-right ${textClassNumber(results.agent.summary)}`}><div>{NUMBER_FORMAT.default(results.agent.summary)}</div> </Table.Summary.Cell>
                            <Table.Summary.Cell className={`text-right ${textClassNumber(results.company.hold)}`}><div>{NUMBER_FORMAT.default(results.company.hold)}</div> </Table.Summary.Cell>
                            <Table.Summary.Cell className={`text-right ${textClassNumber(results.company.wonAmount)}`}><div>{NUMBER_FORMAT.default(results.company.wonAmount)}</div> </Table.Summary.Cell>
                            <Table.Summary.Cell className={`text-right ${textClassNumber(results.company.discount)}`}><div>{NUMBER_FORMAT.default(results.company.discount)}</div> </Table.Summary.Cell>
                            <Table.Summary.Cell className={`text-right ${textClassNumber(results.company.summary)}`}><div>{NUMBER_FORMAT.default(results.company.summary)}</div> </Table.Summary.Cell>
                        </Table.Summary.Row>
                    </Table.Summary>
                );
            }}
            className="table-elm shadowed"
            pagination={false}
        />
    }

    return (
        <div className='agent-page-group bet-result-by-date-page'>
            <Spin {...ELM_STANDARD.spin} spinning={loading}>
                <div className='page-title'>
                    <Typography className='title'>{MENU_ITEMS.waitReward.label}</Typography>
                </div>

                <div className='page-content-wrapper'>
                    <div className='page-content'>

                        <Alert
                            style={{ marginBottom: "1rem" }}
                            description={<div>แสดงข้อมูลงวดที่ <strong>ยังไม่ออกรางวัล</strong></div>}
                            type="info"
                        />

                        <div className='breadcrumb-styled'>
                            <Breadcrumb separator=">" className='unselectable'>
                                {breadcrumbs.map((item) =>
                                    <Breadcrumb.Item key={item.id}
                                        onClick={() => changeUser(item)}
                                    >{item.username}</Breadcrumb.Item>
                                )}
                            </Breadcrumb>
                        </div>

                        {displayMode === 'member' && (
                            <>
                                <div className='header-wrapper'>
                                    <div className='text-right'>
                                        <Segmented size="large" options={['ทั้งหมด', 'บิลได้', 'บิลเสีย']} value={segmentValue} onChange={segmentChange} />
                                    </div>
                                </div>

                                {renderMemberTables()}
                            </>
                        )}
                        {displayMode === 'agent' && (
                            <>
                                <div className="table-wrapper my-2">
                                    <Table
                                        loading={loadingResult}
                                        bordered
                                        size='small'
                                        rowKey={record => record.lottos_fk}
                                        dataSource={byLottoData}
                                        scroll={{ x: 'max-content' }}
                                        expandable={{
                                            expandedRowRender: record => renderExpandRow(record),
                                            expandRowByClick: true,
                                            showExpandColumn: false,
                                        }}
                                        columns={[
                                            {
                                                title: '',
                                                dataIndex: 'lottos_fk',
                                                key: 'lottos_fk',
                                                align: 'center',
                                                render: value => (
                                                    <img className='market-image' src={`../images/lotto/${value}.png`} alt="" width="45" />
                                                )
                                            },
                                            {
                                                title: 'ชนิดหวย',
                                                dataIndex: 'lottoName',
                                                key: 'lottoName',
                                                render: (value, record) => {
                                                    return `[${(DB.SYSTEM.LOTTO.GROUP.find((g) => g.id === record.groupId))?.name}] - ${value}`
                                                }
                                            },
                                            {
                                                title: 'ยอดทั้งหมด',
                                                dataIndex: 'sum',
                                                key: 'sum',
                                                align: 'right',
                                                render: value => (
                                                    <div className='text-green'>{NUMBER_FORMAT.default(value)}</div>
                                                )
                                            },
                                            {
                                                title: <span><UserOutlined /> สมาชิก</span>,
                                                ...TABLE_COL_ATTRIBUTE.colGroup,
                                                children: [
                                                    {
                                                        title: 'ส่งออก',
                                                        dataIndex: 'memberExport',
                                                        key: 'memberExport',
                                                        align: 'right',
                                                        ...TABLE_COL_ATTRIBUTE.memberExport,
                                                        render: (value, record) => (
                                                            <div className='text-red'>{NUMBER_FORMAT.default(record.member.export)}</div>
                                                        )
                                                    },
                                                    {
                                                        title: 'ถูกรางวัล',
                                                        dataIndex: 'memberWonAmount',
                                                        key: 'memberWonAmount',
                                                        align: 'right',
                                                        render: (value, record) => (
                                                            <div className={textClassNumber(record.member.wonAmount)}>{NUMBER_FORMAT.default(record.member.wonAmount)}</div>
                                                        )
                                                    },
                                                    {
                                                        title: 'คอม',
                                                        dataIndex: 'memberDiscount',
                                                        key: 'memberDiscount',
                                                        align: 'right',
                                                        render: (value, record) => (
                                                            <div className={textClassNumber(record.member.discount)}>{NUMBER_FORMAT.default(record.member.discount)}</div>
                                                        )
                                                    },
                                                    {
                                                        title: 'รวม',
                                                        dataIndex: 'memberSummary',
                                                        key: 'memberSummary',
                                                        align: 'right',
                                                        render: (value, record) => {
                                                            let summary = record.member.export + record.member.wonAmount + record.member.discount
                                                            return <div className={textClassNumber(summary)}> {NUMBER_FORMAT.default(summary)}</div>
                                                        }
                                                    },
                                                ]
                                            },
                                            {
                                                title: <span><UserOutlined /> {breadcrumbs[breadcrumbs.length - 1]?.username}</span>,
                                                ...TABLE_COL_ATTRIBUTE.colGroup,
                                                children: [
                                                    {
                                                        title: 'ถือหุ้น',
                                                        dataIndex: 'agentHold',
                                                        key: 'agentHold',
                                                        align: 'right',
                                                        ...TABLE_COL_ATTRIBUTE.agentHold,
                                                        render: (value, record) => (
                                                            <div className={textClassNumber(record.agent.hold)}>{NUMBER_FORMAT.default(record.agent.hold)}</div>
                                                        )
                                                    },
                                                    {
                                                        title: 'ถูกรางวัล',
                                                        dataIndex: 'agentWonAmount',
                                                        key: 'agentWonAmount',
                                                        align: 'right',
                                                        render: (value, record) => (
                                                            <div className={textClassNumber(record.agent.wonAmount)}>{NUMBER_FORMAT.default(record.agent.wonAmount)}</div>
                                                        )
                                                    },
                                                    {
                                                        title: 'คอม',
                                                        dataIndex: 'agentDiscount',
                                                        key: 'agentDiscount',
                                                        align: 'right',
                                                        render: (value, record) => (
                                                            <div className={textClassNumber(record.agent.discount)}>{NUMBER_FORMAT.default(record.agent.discount)}</div>
                                                        )
                                                    },
                                                    {
                                                        title: 'รวม',
                                                        dataIndex: 'agentSummary',
                                                        key: 'agentSummary',
                                                        align: 'right',
                                                        render: (value, record) => {
                                                            let summary = record.agent.hold + record.agent.wonAmount + record.agent.discount
                                                            return <div className={textClassNumber(summary)}> {NUMBER_FORMAT.default(summary)}</div>
                                                        }
                                                    },
                                                ]
                                            },
                                            {
                                                title: <span>บริษัท</span>,
                                                ...TABLE_COL_ATTRIBUTE.colGroup,
                                                children: [
                                                    {
                                                        title: 'ถือหุ้น',
                                                        dataIndex: 'companyHold',
                                                        key: 'companyHold',
                                                        align: 'right',
                                                        ...TABLE_COL_ATTRIBUTE.companyHold,
                                                        render: (value, record) => {
                                                            let displayValue = -1 * (record.member.export + record.agent.hold)
                                                            return <div className={textClassNumber(displayValue)}>{NUMBER_FORMAT.default(displayValue)}</div>
                                                        }
                                                    },
                                                    {
                                                        title: 'ถูกรางวัล',
                                                        dataIndex: 'companyWonAmount',
                                                        key: 'companyWonAmount',
                                                        align: 'right',
                                                        render: (value, record) => {
                                                            let displayValue = -1 * (record.member.wonAmount + record.agent.wonAmount)
                                                            return <div className={textClassNumber(displayValue)}>{NUMBER_FORMAT.default(displayValue)}</div>
                                                        }
                                                    },
                                                    {
                                                        title: 'คอม',
                                                        dataIndex: 'companyDiscount',
                                                        key: 'companyDiscount',
                                                        align: 'right',
                                                        render: (value, record) => {
                                                            let displayValue = -1 * (record.member.discount + record.agent.discount)
                                                            return <div className={textClassNumber(displayValue)}>{NUMBER_FORMAT.default(displayValue)}</div>
                                                        }
                                                    },
                                                    {
                                                        title: 'รวม',
                                                        dataIndex: 'companySummary',
                                                        key: 'companySummary',
                                                        align: 'right',
                                                        render: (value, record) => {
                                                            let displayValue = (-1 * (record.member.export + record.agent.hold)) + (-1 * (record.member.wonAmount + record.agent.wonAmount)) + (-1 * (record.member.discount + record.agent.discount))
                                                            return <div className={textClassNumber(displayValue)}>{NUMBER_FORMAT.default(displayValue)}</div>
                                                        }
                                                    },
                                                ]
                                            },
                                        ]}
                                        className="table-elm shadowed"
                                        pagination={false}
                                    />
                                </div>
                                <div className="table-wrapper my-2">
                                    <Typography.Title level={5}>รวมทั้งหมด</Typography.Title>
                                    <Table
                                        loading={loadingResult}
                                        bordered
                                        size='small'
                                        rowKey={record => record.id}
                                        dataSource={agentData}
                                        scroll={{ x: 'max-content' }}
                                        columns={[
                                            {
                                                title: <div>
                                                    <div>ชื่อผู้ใช้</div>
                                                    <div><Checkbox checked={nameIsShow} onChange={() => setNameIsShow(!nameIsShow)}>แสดงชื่อ</Checkbox></div>
                                                </div>,
                                                dataIndex: 'username',
                                                key: 'username',
                                                width: '10%',
                                                onCell: (record) => { return { onClick: () => changeUser({ id: record.id, username: record.username, role: record.role }) } },
                                                render (value, record) {
                                                    const name = nameIsShow && <span className='text-gray'>({record.name})</span>
                                                    return <Typography.Link>{value} {name}</Typography.Link>
                                                },
                                            },
                                            {
                                                title: 'ระดับ',
                                                dataIndex: 'role',
                                                key: 'role',
                                                align: 'center',
                                                render (value) {
                                                    return ROLE_RENDER[value]
                                                },
                                                sorter: ((a, b) => a.role.localeCompare(b.role))
                                            },
                                            {
                                                title: 'ยอดทั้งหมด',
                                                dataIndex: 'sum',
                                                key: 'sum',
                                                align: 'right',
                                                render: value => (
                                                    <div className='text-green'>{NUMBER_FORMAT.default(value)}</div>
                                                )
                                            },
                                            {
                                                title: <span><UserOutlined /> สมาชิก</span>,
                                                ...TABLE_COL_ATTRIBUTE.colGroup,
                                                children: [
                                                    {
                                                        title: 'ส่งออก',
                                                        dataIndex: 'memberExport',
                                                        key: 'memberExport',
                                                        align: 'right',
                                                        ...TABLE_COL_ATTRIBUTE.memberExport,
                                                        render: (value, record) => (
                                                            <div className='text-red'>{NUMBER_FORMAT.default(record.member.export)}</div>
                                                        )
                                                    },
                                                    {
                                                        title: 'ถูกรางวัล',
                                                        dataIndex: 'memberWonAmount',
                                                        key: 'memberWonAmount',
                                                        align: 'right',
                                                        render: (value, record) => (
                                                            <div className={textClassNumber(record.member.wonAmount)}>{NUMBER_FORMAT.default(record.member.wonAmount)}</div>
                                                        )
                                                    },
                                                    {
                                                        title: 'คอม',
                                                        dataIndex: 'memberDiscount',
                                                        key: 'memberDiscount',
                                                        align: 'right',
                                                        render: (value, record) => (
                                                            <div className={textClassNumber(record.member.discount)}>{NUMBER_FORMAT.default(record.member.discount)}</div>
                                                        )
                                                    },
                                                    {
                                                        title: 'รวม',
                                                        dataIndex: 'memberSummary',
                                                        key: 'memberSummary',
                                                        align: 'right',
                                                        render: (value, record) => {
                                                            let summary = record.member.export + record.member.wonAmount + record.member.discount
                                                            return <div className={textClassNumber(summary)}> {NUMBER_FORMAT.default(summary)}</div>
                                                        }
                                                    },
                                                ]
                                            },
                                            {
                                                title: <span><UserOutlined /> {breadcrumbs[breadcrumbs.length - 1]?.username}</span>,
                                                ...TABLE_COL_ATTRIBUTE.colGroup,
                                                children: [
                                                    {
                                                        title: 'ถือหุ้น',
                                                        dataIndex: 'agentHold',
                                                        key: 'agentHold',
                                                        align: 'right',
                                                        ...TABLE_COL_ATTRIBUTE.agentHold,
                                                        render: (value, record) => (
                                                            <div className={textClassNumber(record.agent.hold)}>{NUMBER_FORMAT.default(record.agent.hold)}</div>
                                                        )
                                                    },
                                                    {
                                                        title: 'ถูกรางวัล',
                                                        dataIndex: 'agentWonAmount',
                                                        key: 'agentWonAmount',
                                                        align: 'right',
                                                        render: (value, record) => (
                                                            <div className={textClassNumber(record.agent.wonAmount)}>{NUMBER_FORMAT.default(record.agent.wonAmount)}</div>
                                                        )
                                                    },
                                                    {
                                                        title: 'คอม',
                                                        dataIndex: 'agentDiscount',
                                                        key: 'agentDiscount',
                                                        align: 'right',
                                                        render: (value, record) => (
                                                            <div className={textClassNumber(record.agent.discount)}>{NUMBER_FORMAT.default(record.agent.discount)}</div>
                                                        )
                                                    },
                                                    {
                                                        title: 'รวม',
                                                        dataIndex: 'agentSummary',
                                                        key: 'agentSummary',
                                                        align: 'right',
                                                        render: (value, record) => {
                                                            let summary = record.agent.hold + record.agent.wonAmount + record.agent.discount
                                                            return <div className={textClassNumber(summary)}> {NUMBER_FORMAT.default(summary)}</div>
                                                        }
                                                    },
                                                ]
                                            },
                                            {
                                                title: <span>บริษัท</span>,
                                                ...TABLE_COL_ATTRIBUTE.colGroup,
                                                children: [
                                                    {
                                                        title: 'ถือหุ้น',
                                                        dataIndex: 'companyHold',
                                                        key: 'companyHold',
                                                        align: 'right',
                                                        ...TABLE_COL_ATTRIBUTE.companyHold,
                                                        render: (value, record) => {
                                                            let displayValue = -1 * (record.member.export + record.agent.hold)
                                                            return <div className={textClassNumber(displayValue)}>{NUMBER_FORMAT.default(displayValue)}</div>
                                                        }
                                                    },
                                                    {
                                                        title: 'ถูกรางวัล',
                                                        dataIndex: 'companyWonAmount',
                                                        key: 'companyWonAmount',
                                                        align: 'right',
                                                        render: (value, record) => {
                                                            let displayValue = -1 * (record.member.wonAmount + record.agent.wonAmount)
                                                            return <div className={textClassNumber(displayValue)}>{NUMBER_FORMAT.default(displayValue)}</div>
                                                        }
                                                    },
                                                    {
                                                        title: 'คอม',
                                                        dataIndex: 'companyDiscount',
                                                        key: 'companyDiscount',
                                                        align: 'right',
                                                        render: (value, record) => {
                                                            let displayValue = -1 * (record.member.discount + record.agent.discount)
                                                            return <div className={textClassNumber(displayValue)}>{NUMBER_FORMAT.default(displayValue)}</div>
                                                        }
                                                    },
                                                    {
                                                        title: 'รวม',
                                                        dataIndex: 'companySummary',
                                                        key: 'companySummary',
                                                        align: 'right',
                                                        render: (value, record) => {
                                                            let displayValue = (-1 * (record.member.export + record.agent.hold)) + (-1 * (record.member.wonAmount + record.agent.wonAmount)) + (-1 * (record.member.discount + record.agent.discount))
                                                            return <div className={textClassNumber(displayValue)}>{NUMBER_FORMAT.default(displayValue)}</div>
                                                        }
                                                    },
                                                ]
                                            },
                                        ]}
                                        summary={pageData => {
                                            let results = {
                                                sum: 0,
                                                member: {
                                                    export: 0,
                                                    wonAmount: 0,
                                                    discount: 0,
                                                    summary: 0,
                                                },
                                                agent: {
                                                    hold: 0,
                                                    wonAmount: 0,
                                                    discount: 0,
                                                    summary: 0,
                                                },
                                                company: {
                                                    hold: 0,
                                                    wonAmount: 0,
                                                    discount: 0,
                                                    summary: 0,
                                                }

                                            }
                                            pageData.forEach(({ sum, member, agent }) => {
                                                results.sum += sum
                                                results.member.export += member.export
                                                results.member.wonAmount += member.wonAmount
                                                results.member.discount += member.discount
                                                results.member.summary += member.export + member.wonAmount + member.discount

                                                results.agent.hold += agent.hold
                                                results.agent.wonAmount += agent.wonAmount
                                                results.agent.discount += agent.discount
                                                results.agent.summary += agent.hold + agent.wonAmount + agent.discount

                                                results.company.hold += -1 * (member.export + agent.hold)
                                                results.company.wonAmount += -1 * (member.wonAmount + agent.wonAmount)
                                                results.company.discount += -1 * (member.discount + agent.discount)
                                                results.company.summary += (-1 * (member.export + agent.hold)) + (-1 * (member.wonAmount + agent.wonAmount)) + (-1 * (member.discount + agent.discount))
                                            });
                                            return (
                                                <Table.Summary>
                                                    <Table.Summary.Row style={{ fontWeight: 'bold' }}>
                                                        <Table.Summary.Cell className='text-left' colSpan={2}><div>รวม</div></Table.Summary.Cell>
                                                        <Table.Summary.Cell className={`text-right ${textClassNumber(results.sum)}`}><div>{NUMBER_FORMAT.default(results.sum)}</div> </Table.Summary.Cell>
                                                        <Table.Summary.Cell className={`text-right ${textClassNumber(results.member.export)}`}><div>{NUMBER_FORMAT.default(results.member.export)}</div> </Table.Summary.Cell>
                                                        <Table.Summary.Cell className={`text-right ${textClassNumber(results.member.wonAmount)}`}><div>{NUMBER_FORMAT.default(results.member.wonAmount)}</div> </Table.Summary.Cell>
                                                        <Table.Summary.Cell className={`text-right ${textClassNumber(results.member.discount)}`}><div>{NUMBER_FORMAT.default(results.member.discount)}</div> </Table.Summary.Cell>
                                                        <Table.Summary.Cell className={`text-right ${textClassNumber(results.member.summary)}`}><div>{NUMBER_FORMAT.default(results.member.summary)}</div> </Table.Summary.Cell>
                                                        <Table.Summary.Cell className={`text-right ${textClassNumber(results.agent.hold)}`}><div>{NUMBER_FORMAT.default(results.agent.hold)}</div> </Table.Summary.Cell>
                                                        <Table.Summary.Cell className={`text-right ${textClassNumber(results.agent.wonAmount)}`}><div>{NUMBER_FORMAT.default(results.agent.wonAmount)}</div> </Table.Summary.Cell>
                                                        <Table.Summary.Cell className={`text-right ${textClassNumber(results.agent.discount)}`}><div>{NUMBER_FORMAT.default(results.agent.discount)}</div> </Table.Summary.Cell>
                                                        <Table.Summary.Cell className={`text-right ${textClassNumber(results.agent.summary)}`}><div>{NUMBER_FORMAT.default(results.agent.summary)}</div> </Table.Summary.Cell>
                                                        <Table.Summary.Cell className={`text-right ${textClassNumber(results.company.hold)}`}><div>{NUMBER_FORMAT.default(results.company.hold)}</div> </Table.Summary.Cell>
                                                        <Table.Summary.Cell className={`text-right ${textClassNumber(results.company.wonAmount)}`}><div>{NUMBER_FORMAT.default(results.company.wonAmount)}</div> </Table.Summary.Cell>
                                                        <Table.Summary.Cell className={`text-right ${textClassNumber(results.company.discount)}`}><div>{NUMBER_FORMAT.default(results.company.discount)}</div> </Table.Summary.Cell>
                                                        <Table.Summary.Cell className={`text-right ${textClassNumber(results.company.summary)}`}><div>{NUMBER_FORMAT.default(results.company.summary)}</div> </Table.Summary.Cell>
                                                    </Table.Summary.Row>
                                                </Table.Summary>
                                            );
                                        }}
                                        className="table-elm shadowed"
                                        pagination={false}
                                    />
                                </div>
                            </>
                        )}

                        <OrderDetail
                            visible={isModalOrderDetailVisible}
                            onClose={() => setIsModalOrderDetailVisible(false)}
                            data={modalOrderDetailData}
                        />
                    </div>
                </div>
            </Spin >
        </div >

    )
}

export default WaitReward;