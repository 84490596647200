import React, { useState, useEffect } from 'react';
import { Typography, message, Table, Empty, Space } from 'antd';
import { MENU_ITEMS } from '../../../util/options';
import { DATE_FORMAT, MSG } from '../../../util/constant';

import LottoService from '../../../services/system/lotto.service';
import { groupBy } from 'lodash';
import { useLottoParamContext } from '../../../contexts/LottoParamContext';


const RewardHistoryByLotto = () => {
    const [loadingResult, setLoadingResult] = useState(false)
    const { lottoParam, lottoParamAction } = useLottoParamContext();
    const [data, setData] = useState([])

    useEffect(() => {
        try {
            setLoadingResult(true)
            LottoService.getReportRewardByLotto({ lottos_fk: lottoParam.lotto.selected.id })
                .then(async res => {
                    setData(await processData(res.results))
                    setLoadingResult(false)
                })
        }
        catch (err) {
            message.error(MSG.COMMON.SMT_WRONG)
        }
    }, [lottoParam.lotto.selected.id, lottoParam.lottoDate.selected.id])


    const processData = async (data) => {
        const groupedData = groupBy(data, 'groupId')
        return groupedData
    }

    const renderTables = () => {
        if (Object.entries(data).length > 0) {
            return Object.entries(data).map(([key, value]) => {
                return <div key={key} className="table-wrapper">
                    <Table
                        size='small'
                        rowKey={record => record.id}
                        dataSource={value}
                        scroll={{ x: 'max-content' }}
                        columns={[
                            {
                                title: '#',
                                dataIndex: 'index',
                                key: 'index',
                                align: 'center',
                                render: (value, record, i) => (
                                    i + 1
                                )
                            },
                            {
                                title: 'งวด',
                                dataIndex: 'date',
                                key: 'date',
                                align: 'center',
                                render: (value) => {
                                    return `${DATE_FORMAT.dateonly(value)}`
                                }
                            },
                            {
                                title: 'เวลาเปิด',
                                dataIndex: 'openAt',
                                key: 'openAt',
                                align: 'center',
                                render: (value) => {
                                    return `${DATE_FORMAT.default(value)}`
                                }
                            },
                            {
                                title: 'เวลาปิด',
                                dataIndex: 'closeAt',
                                key: 'closeAt',
                                align: 'center',
                                render: (value) => {
                                    return `${DATE_FORMAT.default(value)}`
                                }
                            },
                            {
                                title: '3 ตัวบน',
                                dataIndex: 'threeUp',
                                key: 'threeUp',
                                align: 'center',
                                render: (text, record, index) => (
                                    record.result?.["3 ตัวบน"] ?? '-'
                                )
                            },
                            {
                                title: '2 ตัวบน',
                                dataIndex: 'twoUp',
                                key: 'twoUp',
                                align: 'center',
                                render: (text, record, index) => (
                                    record.result?.["2 ตัวบน"] ?? '-'
                                )
                            },
                            {
                                title: '2 ตัวล่าง',
                                dataIndex: 'twoBelow',
                                key: 'twoBelow',
                                align: 'center',
                                render: (text, record, index) => (
                                    record.result?.["2 ตัวล่าง"] ?? '-'
                                )
                            },
                        ]}
                        className="table-elm shadowed"
                        pagination={false}
                        loading={loadingResult}
                    />
                </div>
            })
        } else {
            return <Empty />
        }
    }



    return (
        <div className='agent-page-group reward-history-by-lotto-page'>
            <div className='page-title'>
                <Typography className='title'>{MENU_ITEMS.lottoHistoryByLotto.label}</Typography>
            </div>


            <div className='page-content-wrapper'>
                <div className='page-content'>
                    <div>
                        {lottoParam.lotto.selected.id ?
                            <Space className='text-big'>
                                <span>แสดงเฉพาะงวดหวย</span>
                                <span className='text-blue'>{lottoParam.lotto.selected.groupName}</span>
                                <span>, </span>
                                <span className='text-red'>{lottoParam.lotto.selected.name}</span>
                                <span className='text-secondary'>(เปลี่ยนได้ที่แถบเมนูด้านบน)</span>
                            </Space>
                            : '*เลือกกลุ่มหวย'}
                    </div>
                    <div className='tables-wrapper my-2'>
                        {renderTables()}
                    </div>
                </div>
            </div>
        </div >

    )
}

export default RewardHistoryByLotto;