import axios from "axios";
import { API_URL } from "../util/constant.js"
import { notification } from 'antd';
import { authHeader } from "./auth-header.js";

const URL = API_URL + "auth/";

export const req = axios.create();

export const get = async (url, options) => {
  try {
    const response = await req.get(url, options);
    return response.data;
  } catch (err) {
    return console.error(err);
  }
}

export const post = async (url, data, options) => {
  try {
    const response = await req.post(url, data, options);
    return response.data;
  } catch (err) {
    return console.error(err);
  }
}

const openNotification = (type, message, desc) => {
  notification[type]({
    message: message,
    description: desc,
  });
};

req.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response.status === 401) {
    openNotification('info', 'เซสชันหมดอายุ', 'เซสชันหมดอายุ กรุณาทำการเข้าสู่ระบบใหม่อีกครั้ง')
    logout()
    window.location = '/';
  }
  return error;
});


const login = async (username, password) => {
  const response = await req.post(URL + "signin", {
    username,
    password
  });
  if (response.data.info?.accessToken) {
    if ('localStorage' in window && window.localStorage !== null) {
      window.localStorage.setItem("user", JSON.stringify(response.data.info));
    } else {
      alert('อุปกรณ์ไม่รองรับ!');
    }
  }
  return response.data;
};

const isUserExists = async (data) => {
  try {
    const response = await req.post(URL + "username-check", data, { headers: await authHeader() });
    return response.data;
  } catch (err) {
    return console.error(err);
  }
};

const logout = () => {
  window.localStorage.removeItem("user");
};

const AuthService = {
  login,
  logout,
  isUserExists,
}

export default AuthService
