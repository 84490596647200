import React, { useState, useEffect } from 'react';
import { Button, Spin, Typography, Row, Col, message, DatePicker, Input, Table, Popconfirm, Card } from 'antd';
import { ELM_STANDARD, MENU_ITEMS, order_tag } from '../../util/options';
import { dateFormats, DATE_FORMAT, DB, MSG, NUMBER_FORMAT, ranges } from '../../util/constant';
import OrderService from '../../services/system/order.service';
import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useUserContext } from '../../contexts/UserContext';

import dayjs from 'dayjs'
import 'dayjs/locale/th'

import moment from 'moment';
import 'moment/locale/th';
import OrderDetail from '../common/modal/OrderDetail';
moment.updateLocale('th', {
    week: {
        dow: 1
    }
})

const { RangePicker } = DatePicker;

const Orders = () => {
    const { user, userAction } = useUserContext();

    const [loading, setLoading] = useState(true);
    const [loadingResult, setLoadingResult] = useState(false)

    const [data, setData] = useState([])
    const [total, setTotal] = useState(undefined)
    const [status, setStatus] = useState([])
    const params = { keyword: '', status: [], dateRange: { from: moment().format('YYYY-MM-DD'), to: moment().format('YYYY-MM-DD') } }


    const [keyword, setKeyword] = useState('')
    const [dateRange, setDateRange] = useState({ ...params.dateRange })
    const [dateValues, setDateValues] = useState([moment(), moment()])

    const [isModalOrderDetailVisible, setIsModalOrderDetailVisible] = useState(false);
    const [modalOrderDetailData, setModalOrderDetailData] = useState({})

    const [displayInfo, setDisplayInfo] = useState({
        diff: dayjs().diff(dayjs(), 'day') + 1,
        from: DATE_FORMAT.dateonly(),
        to: DATE_FORMAT.dateonly(),
    })

    const onRangeChange = (dates, dateStrings) => {
        if (!dates) return
        let dateObj = {
            "from": dateStrings[0],
            "to": dateStrings[1],
        }
        setDateValues(dates)
        setDateRange(dateObj)

        setLoadingResult(true)
        OrderService.getOrders({ keyword: keyword, status: status, dateRange: dateObj })
            .then(async res => {
                setDisplayInfo({
                    diff: dayjs(dateStrings[1]).diff(dateStrings[0], 'day') + 1,
                    from: DATE_FORMAT.dateonly(dateStrings[0]),
                    to: DATE_FORMAT.dateonly(dateStrings[1]),
                })
                setData(res.results);
                setTotal(res.total)
                setLoadingResult(false)
            })
    }

    const search = (value) => {
        setLoadingResult(true)
        OrderService.getOrders({ keyword: value, status: status, dateRange: dateRange })
            .then(async res => {
                setKeyword(value)
                setData(res.results);
                setTotal(res.total)
                setLoadingResult(false)
            })
    }




    const prepareData = async () => {
        try {
            const res = await OrderService.getOrders(params)
            setData(res.results)
            setTotal(res.total)

            setLoading(false)
        } catch (err) {
            message.error(MSG.COMMON.SMT_WRONG)
            setLoading(false)
        }
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setLoadingResult(true)
        setStatus(filters.status)
        setLoadingResult(false)
    };

    useEffect(() => {
        prepareData()
    }, [])

    const cancelOrder = async (id) => {
        setLoading(true)
        try {
            const res = await OrderService.cancelOrder(id)
            if (res === `OK`) {
                message.success({ content: 'ยกเลิกบิลสำเร็จ', key: 'cancelOrderSuccess', duration: 1 });
                userAction.updateBalance()
                // rendering update..
                let baseData = [...data]
                const index = baseData.findIndex(x => x.id === id)
                const obj = { ...baseData[index], updatedAt: new Date() }
                obj.status = DB.SYSTEM.ORDER.STATUS.CANCELED
                baseData[index] = { ...obj }
                setData([...baseData])
                setLoading(false)
            } else {
                message.error({ content: "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง", key: "cancelOrder", duration: 1 })
                setLoading(false)
            }
        } catch (err) {
            message.error({ content: "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง", key: "cancelOrder", duration: 1 })
            setLoading(false)
        }
    }


    return (
        <div className='member-page-group orders-page'>
            <Spin {...ELM_STANDARD.spin} spinning={loading}>
                <div className='page-title'>
                    <Typography className='title'>{MENU_ITEMS.orders.label}</Typography>
                </div>


                <div className='page-content-wrapper'>
                    <div className='page-content'>

                        <div className="search-section">
                            <Card title="ตัวเลือกการค้นหา" size="small">
                                <Row gutter={[16, 16]} justify='start'>
                                    <Col xs={24}>
                                        <RangePicker
                                            ranges={{ ...ranges }}
                                            onChange={onRangeChange}
                                            value={dateValues}
                                            allowEmpty={[false, false]}
                                            allowClear={false}
                                        />
                                    </Col>
                                    <Row gutter={[16, 16]}>
                                        {Object.entries(ranges)?.map(([key, value]) => {
                                            return <Col key={key}><Button onClick={() => {
                                                onRangeChange([...value], [moment(value[0]).format(dateFormats.only_date), moment(value[1]).format(dateFormats.only_date)])
                                            }
                                            }>{key}</Button></Col>
                                        })}
                                    </Row>
                                    <Col>
                                        <div className='search'>
                                            <Input.Search
                                                allowClear
                                                onSearch={search}
                                                value={keyword}
                                                onChange={(e) => setKeyword(e.target.value)}
                                                placeholder="ค้นหา: หมายเหตุ.." />
                                        </div>
                                    </Col>
                                </Row>
                            </Card>
                        </div>

                        <div className='displayInfo-section'>
                            <div>ข้อมูลรวม <span className='text-blue'>{displayInfo.diff}</span> วัน
                                ตั้งแต่วันที่ <span className='text-blue'>{displayInfo.from}</span> ถึง <span className='text-blue'>{displayInfo.to}</span></div>
                            <div>{<span className='text-secondary'>จำนวน {total} รายการ   </span>}</div>
                        </div>

                        <div className="table-wrapper">
                            <Table
                                size='small'
                                rowKey={record => record.id}
                                dataSource={data}
                                scroll={{ x: 'max-content' }}
                                onChange={handleTableChange}
                                columns={[
                                    {
                                        title: 'เวลาแทง',
                                        dataIndex: 'createdAt',
                                        key: 'createdAt',
                                        align: 'center',
                                        render: value => (
                                            DATE_FORMAT.default(value)
                                        )
                                    },
                                    {
                                        title: 'สถานะ',
                                        dataIndex: 'status',
                                        key: 'status',
                                        align: 'center',
                                        render: (value, record) => {
                                            return order_tag[value]
                                        },
                                        filters: [...DB.SYSTEM.ORDER.STATUS_FILTER].map(o => ({ text: o.title, value: o.key })),
                                        filteredValue: status || null,
                                        onFilter: (value, record) => record.status.includes(value),
                                    },
                                    {
                                        title: 'ชนิดหวย',
                                        dataIndex: 'name',
                                        key: 'name',
                                        align: 'center',
                                        render: (value, record, index) => {
                                            return `[${(DB.SYSTEM.LOTTO.GROUP.find((g) => g.id === record.groupId))?.name}] - ${value}`
                                        }
                                    },
                                    {
                                        title: 'งวด',
                                        dataIndex: 'date',
                                        key: 'date',
                                        align: 'center',
                                        render: (value) => {
                                            return `${DATE_FORMAT.dateonly(value)}`
                                        }
                                    },
                                    {
                                        title: 'รายการ',
                                        dataIndex: 'count',
                                        key: 'count',
                                        align: 'center',
                                        render: value => (
                                            value
                                        )
                                    },
                                    {
                                        title: 'ยอด',
                                        dataIndex: 'sum',
                                        key: 'sum',
                                        align: 'right',
                                        render: value => (
                                            <div className='text-green'>{NUMBER_FORMAT.default(value)}</div>
                                        )
                                    },
                                    {
                                        title: 'ส่วนลด',
                                        dataIndex: 'discount',
                                        key: 'discount',
                                        align: 'right',
                                        render: value => (
                                            <div className='text-red'>{NUMBER_FORMAT.default(value)}</div>
                                        )
                                    },
                                    {
                                        title: 'รวม',
                                        dataIndex: 'discount',
                                        key: 'discount',
                                        align: 'right',
                                        render: (value, record) => (
                                            NUMBER_FORMAT.default(Number(record.sum) - Number(record.discount))
                                        )
                                    },
                                    {
                                        title: 'แพ้/ชนะ',
                                        dataIndex: 'status',
                                        key: 'status',
                                        align: 'right',
                                        render: (value, record) => {
                                            if (value === DB.SYSTEM.ORDER.STATUS.COMPLETED) {
                                                return Number(record.wonAmount) > 0 ?
                                                    <span className='text-blue text-bold'>{NUMBER_FORMAT.default(record.wonAmount)}</span>
                                                    : <span className='text-red'>ไม่ถูกรางวัล</span>
                                            }
                                            return ''
                                        }
                                    },
                                    {
                                        title: 'หมายเหตุ',
                                        dataIndex: 'remark',
                                        key: 'remark',
                                        align: 'left',
                                    },
                                    {
                                        title: '#',
                                        dataIndex: 'actions',
                                        key: 'actions',
                                        align: 'center',
                                        render: (value, record) => {
                                            let cancelOrderElm = () => {
                                                if (record.status === DB.SYSTEM.ORDER.STATUS.PENDING)
                                                    return <Popconfirm
                                                        title={`ยืนยันการยกเลิกบิลนี้?`}
                                                        key={record.id}
                                                        onConfirm={() => cancelOrder(record.id)}
                                                        okText="ยืนยัน"
                                                        okType="danger"
                                                        cancelText="ยกเลิก"
                                                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}>
                                                        <div className="expanded-table-btn"><Button danger type="link"><DeleteOutlined /></Button></div>
                                                    </Popconfirm>
                                                else if (record.status === DB.SYSTEM.ORDER.STATUS.CANCELED)
                                                    return <div className='text-secondary'>โพยถูกยกเลิก</div>
                                                return <></>
                                            }

                                            return <Row gutter={[16, 16]}>
                                                <Col xs={12}><Typography.Link onClick={() => { setModalOrderDetailData(record); setIsModalOrderDetailVisible(true) }}>ดูรายละเอียด</Typography.Link></Col>
                                                <Col xs={12}>{cancelOrderElm()}</Col>
                                            </Row>

                                        }
                                    },

                                ]}
                                className="table-elm shadowed"
                                pagination={false}
                                loading={loadingResult}
                                rowClassName={(record) => {
                                    if (record.status === DB.SYSTEM.ORDER.STATUS.CANCELED) return 'text-secondary opacity-50'
                                    if (record.status === DB.SYSTEM.ORDER.STATUS.COMPLETED && Number(record.wonAmount) > 0) return 'highlighted-won-order'
                                    return ''
                                }}
                                summary={pageData => {
                                    let sum1 = 0;
                                    let sum2 = 0;
                                    let sum3 = 0;
                                    let sum4 = 0;
                                    let sumCount = 0
                                    pageData.forEach(({ sum, discount, wonAmount, count }) => {
                                        sum1 += Number(sum)
                                        sum2 += Number(discount)
                                        sum3 += Number(sum) - Number(discount)
                                        sum4 += Number(wonAmount) ?? 0
                                        sumCount += count
                                    });
                                    return (
                                        <Table.Summary.Row style={{ fontWeight: 'bold' }}>
                                            <Table.Summary.Cell className='text-left' colSpan={4}><div >รวม</div></Table.Summary.Cell>
                                            <Table.Summary.Cell className='text-center'><div>{sumCount}</div> </Table.Summary.Cell>
                                            <Table.Summary.Cell className='text-right text-green'><div>{NUMBER_FORMAT.default(sum1)}</div> </Table.Summary.Cell>
                                            <Table.Summary.Cell className='text-right text-red'><div>{NUMBER_FORMAT.default(sum2)}</div> </Table.Summary.Cell>
                                            <Table.Summary.Cell className='text-right text-blue'><div>{NUMBER_FORMAT.default(sum3)}</div> </Table.Summary.Cell>
                                            <Table.Summary.Cell className='text-right text-blue'><div>{NUMBER_FORMAT.default(sum4)}</div> </Table.Summary.Cell>
                                            <Table.Summary.Cell colSpan={3}></Table.Summary.Cell>
                                        </Table.Summary.Row>
                                    );
                                }}
                            />

                            <OrderDetail
                                visible={isModalOrderDetailVisible}
                                onClose={() => setIsModalOrderDetailVisible(false)}
                                data={modalOrderDetailData}
                            />
                        </div>
                    </div>
                </div>
            </Spin >
        </div >

    )
}

export default Orders;