import React, { useState, useEffect } from 'react';
import { Button, Spin, Typography, Row, Col, Card, Descriptions, Space, Table, Modal, Form, Input, message } from 'antd';
import { ELM_STANDARD, MENU_ITEMS } from '../../util/options';
import UserService from '../../services/user/user.service';
import { CloseOutlined, EditOutlined, KeyOutlined, SaveOutlined, UserOutlined } from '@ant-design/icons';
import { useUserContext } from '../../contexts/UserContext';
import { DATE_FORMAT, DB, MSG, NUMBER_FORMAT } from '../../util/constant';
const Profile = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({})
    const {  userAction } = useUserContext();

    const [modalData, setModalData] = useState(null)
    const [modalVisible, setModalVisible] = useState({ edit: false, changePassword: false });
    const [isModalLoading, setIsModalLoading] = useState(false);

    const [editForm] = Form.useForm();
    const [changePasswordForm] = Form.useForm();

    const forms = { edit: editForm, changePassword: changePasswordForm };

    const UserActionsService = {
        edit: UserService.updateSelf,
        changePassword: UserService.changePassword,
    }

    useEffect(() => {
        prepareData()
    }, [])

    const prepareData = async () => {
        const res = await UserService.getProfile()
        setData(res)
        setLoading(false)
    }

    const submit = async (param) => {
        try {
            await forms[param].validateFields()

            setIsModalLoading(true)
            const send = await UserActionsService[param]({ ...forms[param].getFieldsValue() })
            setIsModalLoading(false)
            if (send === `OK`) {
                forms[param].resetFields()
                setModalVisible({ ...modalVisible, [param]: false })
                message.success(MSG.COMMON.UPDATE.SUCCEED)
                setLoading(true)
                prepareData();
            } else {
                message.error(MSG.COMMON.VALIDATE.FAILED)
            }


            setLoading(false);
        } catch (err) {
            message.error(MSG.COMMON.VALIDATE.FAILED)
            setLoading(false);
        }
    }


    const closeModal = (param) => {
        setModalVisible({ ...modalVisible, [param]: false });
        setModalData(null);
        forms[param].resetFields()
    }
    const modalProps = (param) => {
        return {
            visible: modalVisible[param],
            footer: [
                <Button key="back" onClick={() => closeModal(param)} icon={<CloseOutlined />}>ยกเลิก</Button>,
                <Button type="primary" key="submit" onClick={() => submit(param)} icon={<SaveOutlined />}>ยืนยัน</Button>
            ],
            onOk: () => submit(param),
            onCancel: () => closeModal(param),
            forceRender: true,
        }
    }

    const formProps = (param) => {
        return {
            form: forms[param],
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
            autoComplete: "new-password",
            initialValues: modalData
        }
    }

    useEffect(() => {
        forms.edit.setFieldsValue(modalData)
    }, [forms.edit, modalData])

    return (
        <div className='common-page-group profile-page'>
            <Spin {...ELM_STANDARD.spin} spinning={loading}>
                <div className='page-title'>
                    <Typography className='title'>{MENU_ITEMS.profile.label}</Typography>
                </div>

                <div className='page-content-wrapper'>
                    <div className='page-content'>
                        <Row gutter={[16, 16]}>
                            <Col xs={12}>
                                <div className='content-wrapper'>
                                    <Card title="ข้อมูลส่วนตัว" >
                                        {data.user && (
                                            <Descriptions column={2}>
                                                <Descriptions.Item label="ชื่อ">{data.user.name ?? '-'}</Descriptions.Item>
                                                <Descriptions.Item label="เบอร์">{data.user.phone ?? '-'}</Descriptions.Item>
                                                <Descriptions.Item label="หมายเหตุ">{data.user.remark ?? '-'}</Descriptions.Item>
                                                <Descriptions.Item label="สมัครเมื่อ">{DATE_FORMAT.default(data.user.createdAt)}</Descriptions.Item>
                                                <Descriptions.Item label="เข้าใช้งานล่าสุดเมื่อ">{DATE_FORMAT.relativeTime(data.user.latestLogin)}</Descriptions.Item>
                                            </Descriptions>
                                        )}
                                    </Card>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className='user-action-wrapper'>
                                    <Card title="ตั้งค่าบัญชี">
                                        <Space direction='vertical'>
                                            <div><Button type="primary" shape="round" icon={<EditOutlined />} onClick={() => { setModalVisible({ ...modalVisible, edit: true }); setModalData(data.user) }}>แก้ไข</Button></div>
                                            <div><Button type="primary" shape="round" icon={<KeyOutlined />} onClick={() => { setModalVisible({ ...modalVisible, changePassword: true }) }}>เปลี่ยนรหัสผ่าน</Button></div>
                                        </Space>

                                    </Card>
                                </div>
                            </Col>
                            {userAction.isMember() && (
                                <Col xs={24}>
                                    <div className='user-rate-wrapper'>
                                        <Card title="ข้อมูลการตั้งค่า">
                                            <Row gutter={[16, 16]}>
                                                <Col xs={24}>
                                                    <Space direction='vertical'>
                                                        {data.newRate?.map((item, index) => (
                                                            <Space direction='vertical' key={item.id}>
                                                                <div className='text-bold'>{item.name}</div>
                                                                <Table
                                                                     scroll={{ x: 'max-content' }}
                                                                    bordered
                                                                    pagination={false}
                                                                    rowKey={(record) => record.id}
                                                                    dataSource={item?.rate}
                                                                    columns={[
                                                                        {
                                                                            title: ``,
                                                                            dataIndex: 'id',
                                                                            key: 'id',
                                                                            render: (value,) => {
                                                                                return DB.SYSTEM.DESC.RATE.find((d) => d.id === value)?.name
                                                                            }
                                                                        },
                                                                        {
                                                                            title: 'จ่าย',
                                                                            dataIndex: 'rate',
                                                                            key: 'rate',
                                                                            align: 'center',
                                                                        },
                                                                        {
                                                                            title: 'ลด',
                                                                            dataIndex: 'discount',
                                                                            key: 'discount',
                                                                            align: 'center',
                                                                        },
                                                                        {
                                                                            title: 'ขั้นต่ำ',
                                                                            dataIndex: 'min',
                                                                            key: 'min',
                                                                            align: 'center',
                                                                            render: (value, record) => {
                                                                                return <div>{NUMBER_FORMAT.compact(value)} - {NUMBER_FORMAT.compact(record.max)}</div>
                                                                            }
                                                                        },
                                                                    ]}
                                                                />
                                                            </Space>
                                                        ))}

                                                    </Space>
                                                </Col>
                                            </Row>

                                        </Card>
                                    </div>
                                </Col>
                            )}

                        </Row>
                    </div>
                </div>

                <Modal title={<span><UserOutlined /> แก้ไขข้อมูลสมาชิก</span>}
                    {...modalProps('edit')}>
                    <Spin  {...ELM_STANDARD.spin} spinning={isModalLoading}>
                        <Form
                            {...formProps('edit')}>
                            <Form.Item
                                name="name"
                                label="ชื่อ"
                                rules={[
                                    {
                                        required: true,
                                        message: 'กรุณาระบุชื่อ!',
                                        whitespace: true,
                                    },
                                    {
                                        max: 100,
                                        message: 'ความยาวไม่เกิน 100 ตัวอักษร!',
                                    },
                                ]}
                            >
                                <Input placeholder="Name"  autoComplete="new-password" />
                            </Form.Item>

                            <Form.Item
                                name="phone"
                                label="โทรศัพท์"
                                rules={[
                                    {
                                        pattern: new RegExp(/^[0-9]{10,12}$/), message: 'กรุณาระบุเบอร์โทรให้ถูกต้อง!',
                                        warningOnly: true,
                                    }
                                ]}
                            >
                                <Input placeholder="Phone"  autoComplete="new-password"/>
                            </Form.Item>

                        </Form>
                    </Spin>
                </Modal>


                <Modal title={<span><KeyOutlined /> เปลี่ยนรหัสผ่าน</span>}
                    {...modalProps('changePassword')}>
                    <Spin  {...ELM_STANDARD.spin} spinning={isModalLoading}>
                        <Form
                            {...formProps('changePassword')}>
                            <Form.Item
                                name="oldpass"
                                label=" รหัสผ่านปัจจุบัน"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'กรุณาระบุรหัสผ่าน!',
                                    },
                                ]}
                            >
                                <Input.Password placeholder="Current Password" />
                            </Form.Item>
                            <Form.Item
                                name="newpass"
                                label=" รหัสผ่านใหม่"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'กรุณาระบุรหัสผ่าน!',
                                    },
                                    {
                                        min: 6,
                                        message: 'ความยาว 6 ตัวอักษรขึ้นไป!',
                                    },
                                    {
                                        max: 255,
                                        message: 'ความยาวไม่เกิน 255 ตัวอักษร!',
                                    },
                                ]}
                            >
                                <Input.Password placeholder="Password" />
                            </Form.Item>
                            <Form.Item
                                name="confirmPassword"
                                label="ยืนยันรหัสผ่านใหม่"
                                hasFeedback
                                dependencies={['newpass']}
                                rules={[
                                    {
                                        required: true,
                                        message: 'กรุณายืนยันรหัสผ่าน!',

                                    },
                                    {
                                        min: 6,
                                        message: 'ความยาว 6 ตัวอักษรขึ้นไป!',
                                    },
                                    {
                                        max: 255,
                                        message: 'ความยาวไม่เกิน 255 ตัวอักษร!',
                                    },
                                    {
                                        validator: async (rule, value) => {
                                            if (!value || forms.changePassword.getFieldValue('newpass') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('รหัสผ่านไม่ตรงกัน!'));
                                        }
                                    }
                                ]}
                            >
                                <Input.Password placeholder="Confirm password" />
                            </Form.Item>

                        </Form>
                    </Spin>
                </Modal>
            </Spin>
        </div>
    )
}

export default Profile;