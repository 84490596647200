import React, { useState, useEffect } from 'react';
import { Typography, message, Table, Empty, Space, DatePicker, Card, Row, Col } from 'antd';
import { MENU_ITEMS } from '../../../util/options';
import { DATE_FORMAT, DB, MSG } from '../../../util/constant';

import LottoService from '../../../services/system/lotto.service';
import { groupBy } from 'lodash';

import moment from 'moment';
import 'moment/locale/th';
moment.updateLocale('th', {
    week: {
        dow: 1
    }
})

const dateFormat = 'DD MMMM YYYY';
const defaultDateFormat = 'YYYY-MM-DD';

const RewardHistoryByDate = () => {
    const [loadingResult, setLoadingResult] = useState(false)
    const [data, setData] = useState([])

    const [dateParam, setDateParam] = useState(moment().format(dateFormat))

    const [dateValue, setDateValue] = useState(moment())

    const onDateChange = (date, dateString) => {
        if (!date) return

        setDateValue(date)
        setDateParam(dateString)
        setLoadingResult(true)
    }

    useEffect(() => {
        try {
            setLoadingResult(true)
            LottoService.getReportRewardByDate({ date: dateValue.format(defaultDateFormat) })
                .then(async res => {
                    setData(await processData(res.results))
                    setLoadingResult(false)
                })
        }
        catch (err) {
            message.error(MSG.COMMON.SMT_WRONG)
        }
    }, [dateValue])

    const processData = async (data) => {
        const groupedData = groupBy(data, 'groupId')
        return groupedData
    }

    const renderTables = () => {
        if (Object.entries(data).length > 0) {
            return Object.entries(data).map(([key, value]) => {
                return <div key={key} className="table-wrapper">
                    <div className='text-bold text-big'>< span >{(DB.SYSTEM.LOTTO.GROUP.find((g) => g.id === value[0].groupId))?.name} - {value[0].name}</span></div>
                    <Table
                        size='small'
                        rowKey={record => record.id}
                        dataSource={value}
                        scroll={{ x: 'max-content' }}
                        columns={[
                            {
                                title: '#',
                                dataIndex: 'index',
                                key: 'index',
                                align: 'center',
                                render: (value, record, i) => (
                                    i + 1
                                )
                            },
                            {
                                title: 'งวด',
                                dataIndex: 'date',
                                key: 'date',
                                align: 'center',
                                render: (value) => {
                                    return `${DATE_FORMAT.dateonly(value)}`
                                }
                            },
                            {
                                title: 'เวลาเปิด',
                                dataIndex: 'openAt',
                                key: 'openAt',
                                align: 'center',
                                render: (value) => {
                                    return `${DATE_FORMAT.default(value)}`
                                }
                            },
                            {
                                title: 'เวลาปิด',
                                dataIndex: 'closeAt',
                                key: 'closeAt',
                                align: 'center',
                                render: (value) => {
                                    return `${DATE_FORMAT.default(value)}`
                                }
                            },
                            {
                                title: '3 ตัวบน',
                                dataIndex: 'threeUp',
                                key: 'threeUp',
                                align: 'center',
                                render: (text, record, index) => (
                                    record.result?.["3 ตัวบน"] ?? '-'
                                )
                            },
                            {
                                title: '2 ตัวบน',
                                dataIndex: 'twoUp',
                                key: 'twoUp',
                                align: 'center',
                                render: (text, record, index) => (
                                    record.result?.["2 ตัวบน"] ?? '-'
                                )
                            },
                            {
                                title: '2 ตัวล่าง',
                                dataIndex: 'twoBelow',
                                key: 'twoBelow',
                                align: 'center',
                                render: (text, record, index) => (
                                    record.result?.["2 ตัวล่าง"] ?? '-'
                                )
                            },
                        ]}
                        className="table-elm shadowed"
                        pagination={false}
                        loading={loadingResult}
                    />
                </div>
            })
        } else {
            return <Empty />
        }
    }



    return (
        <div className='agent-page-group reward-history-by-lotto-page'>
            <div className='page-title'>
                <Typography className='title'>{MENU_ITEMS.lottoHistoryByDate.label}</Typography>
            </div>


            <div className='page-content-wrapper'>
                <div className='page-content'>
                    <div className="search-section">
                        <Card title="ตัวเลือกการค้นหา" size="small">
                            <Row gutter={[16, 16]} justify='start'>
                                <Col xs={24}>
                                    <DatePicker value={dateValue} onChange={onDateChange} format={dateFormat} />
                                </Col>
                            </Row>
                        </Card>
                    </div>
                    <div className='tables-wrapper my-2'>
                        {renderTables()}
                    </div>
                </div>
            </div>
        </div >

    )
}

export default RewardHistoryByDate;