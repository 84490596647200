import React from 'react';
import { Result, Button } from 'antd';
import {
    Link,
} from "react-router-dom";
const NotFound = () => {
    return (
        <div className="page-404">
            <Result
                status="404"
                title="404"
                subTitle="ขออภัย ไม่พบหน้าที่คุณต้องการ"
                extra={<Button type="primary"><Link to='/'>กลับหน้าหลัก</Link></Button>}
            />
        </div>
    )
}

export default NotFound;