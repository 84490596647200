import { get, post } from "../auth.service"
import { authHeader } from "../auth-header";
import { API_URL } from "../../util/constant"

const URL = API_URL + 'user/';

const getUser = async () => {
    return await get(URL + 'get', { headers: await authHeader() });
}

const updateSelf = async (data) => {
    return post(URL + 'update-self', { ...data }, { headers: await authHeader() });
}

const changePassword = async (data) => {
    return post(URL + 'change-password', { ...data }, { headers: await authHeader() });
}

const getUserBalance = async () => {
    return get(URL + 'balance', { headers: await authHeader() });
}

const getProfile = async () => {
    return get(URL + 'profile', { headers: await authHeader() });
}


const UserService = {
    getUser,
    changePassword,
    getUserBalance,
    getProfile,
    updateSelf,
}

export default UserService