export const SHARED_CONSTANT = {
  USER: {
    ROLE: {
      ADMIN: Number(process.env.REACT_APP_ROLE_ADMIN),
      AGENT: Number(process.env.REACT_APP_ROLE_AGENT),
      MEMBER: Number(process.env.REACT_APP_ROLE_MEMBER),
    },
    STATUS: {
      ACTIVE: 'ACTIVE',
      INACTIVE: 'INACTIVE',
      LOCKED: 'LOCKED',
      UPLINELOCKED: 'UPLINELOCKED',
    },
    TRANSACTION: {
      TYPE: {
        DEPOSIT: 'DEPOSIT',
        WITHDRAW: 'WITHDRAW',
        BET: 'BET',
        WIN: 'WIN',
        CANCELBET: 'CANCELBET',
      },
      TYPE_FILTER: [
        { key: 'DEPOSIT', title: 'ฝากเข้า' },
        { key: 'WITHDRAW', title: 'ถอนออก' },
        { key: 'BET', title: 'แทง' },
        { key: 'WIN', title: 'ถูกรางวัล' },
        { key: 'CANCELBET', title: 'ยกเลิกโพย' },
      ],
      AGENT_TYPE_FILTER: [
        { key: 'DEPOSIT', title: 'ฝากเข้า' },
        { key: 'WITHDRAW', title: 'ถอนออก' },
      ],
    },
    RATE: {
      STATUS: {
        ACTIVE: 1,
        INACTIVE: 0,
      },
    },
  },
  SYSTEM: {
    LOTTO: {
      GROUP: [
        {
          id: 1,
          name: 'หวยรายวัน',
        },
        {
          id: 2,
          name: 'หวยต่างประเทศ',
        },
        {
          id: 3,
          name: 'หวยหุ้น',
        },
        {
          id: 4,
          name: 'หวยหุ้น VIP',
        },
        {
          id: 5,
          name: 'หวยอื่น ๆ',
        },
        {
          id: 6,
          name: 'หวยลาว',
        },
        {
          id: 7,
          name: 'หวยต่าง ๆ',
        },
      ],
      STATUS: {
        ACTIVE: 1,
        INACTIVE: 0,
      },
      BET_MODE: {
        6: { name: '3 ตัวบน' },
        4: { name: '3 ตัวโต๊ด' },
        2: { name: '3 ตัวล่าง' },
        5: { name: '2 ตัวบน' },
        1: { name: '2 ตัวล่าง' },
        7: { name: 'วิ่งบน' },
        3: { name: 'วิ่งล่าง' },
      },
      BET_MODE_TO_ID: {
        '3 ตัวบน': 6,
        '3 ตัวโต๊ด': 4,
        '3 ตัวล่าง': 2,
        '2 ตัวบน': 5,
        '2 ตัวล่าง': 1,
        วิ่งบน: 7,
        วิ่งล่าง: 3,
      },
      DEFAULT_REGISTER: [
        {
          id: 1,
          name: 'หวยรายวัน',
          status: 1,
          rate: [
            {
              id: 1,
              name: '2 ตัวล่าง',
              rate: 92,
              discount: 8,
              min: 1,
              max: 100000,
              holdAmount: 0,
            },
            {
              id: 2,
              name: '3 ตัวล่าง',
              rate: 0,
              discount: 0,
              min: 1,
              max: 100000,
              holdAmount: 0,
            },
            {
              id: 3,
              name: 'วิ่งล่าง',
              rate: 4,
              discount: 12,
              min: 1,
              max: 100000,
              holdAmount: 0,
            },
            {
              id: 4,
              name: '3 ตัวโต๊ด',
              rate: 125,
              discount: 15,
              min: 1,
              max: 100000,
              holdAmount: 0,
            },
            {
              id: 5,
              name: '2 ตัวบน',
              rate: 92,
              discount: 8,
              min: 1,
              max: 100000,
              holdAmount: 0,
            },
            {
              id: 6,
              name: '3 ตัวบน',
              rate: 800,
              discount: 15,
              min: 1,
              max: 100000,
              holdAmount: 0,
            },
            {
              id: 7,
              name: 'วิ่งบน',
              rate: 3,
              discount: 12,
              min: 1,
              max: 100000,
              holdAmount: 0,
            },
          ],
        },
        {
          id: 2,
          name: 'หวยต่างประเทศ',
          status: 1,
          rate: [
            {
              id: 1,
              name: '2 ตัวล่าง',
              rate: 92,
              discount: 8,
              min: 1,
              max: 100000,
              holdAmount: 0,
            },
            {
              id: 2,
              name: '3 ตัวล่าง',
              rate: 0,
              discount: 0,
              min: 1,
              max: 100000,
              holdAmount: 0,
            },
            {
              id: 3,
              name: 'วิ่งล่าง',
              rate: 4,
              discount: 12,
              min: 1,
              max: 100000,
              holdAmount: 0,
            },
            {
              id: 4,
              name: '3 ตัวโต๊ด',
              rate: 125,
              discount: 15,
              min: 1,
              max: 100000,
              holdAmount: 0,
            },
            {
              id: 5,
              name: '2 ตัวบน',
              rate: 92,
              discount: 8,
              min: 1,
              max: 100000,
              holdAmount: 0,
            },
            {
              id: 6,
              name: '3 ตัวบน',
              rate: 800,
              discount: 15,
              min: 1,
              max: 100000,
              holdAmount: 0,
            },
            {
              id: 7,
              name: 'วิ่งบน',
              rate: 3,
              discount: 12,
              min: 1,
              max: 100000,
              holdAmount: 0,
            },
          ],
        },
        {
          id: 3,
          name: 'หวยหุ้น',
          status: 1,
          rate: [
            {
              id: 1,
              name: '2 ตัวล่าง',
              rate: 92,
              discount: 8,
              min: 1,
              max: 100000,
              holdAmount: 0,
            },
            {
              id: 2,
              name: '3 ตัวล่าง',
              rate: 0,
              discount: 0,
              min: 1,
              max: 100000,
              holdAmount: 0,
            },
            {
              id: 3,
              name: 'วิ่งล่าง',
              rate: 4,
              discount: 12,
              min: 1,
              max: 100000,
              holdAmount: 0,
            },
            {
              id: 4,
              name: '3 ตัวโต๊ด',
              rate: 125,
              discount: 15,
              min: 1,
              max: 100000,
              holdAmount: 0,
            },
            {
              id: 5,
              name: '2 ตัวบน',
              rate: 92,
              discount: 8,
              min: 1,
              max: 100000,
              holdAmount: 0,
            },
            {
              id: 6,
              name: '3 ตัวบน',
              rate: 800,
              discount: 15,
              min: 1,
              max: 100000,
              holdAmount: 0,
            },
            {
              id: 7,
              name: 'วิ่งบน',
              rate: 3,
              discount: 12,
              min: 1,
              max: 100000,
              holdAmount: 0,
            },
          ],
        },
        {
          id: 4,
          name: 'หวยหุ้น VIP',
          status: 1,
          rate: [
            {
              id: 1,
              name: '2 ตัวล่าง',
              rate: 92,
              discount: 8,
              min: 1,
              max: 100000,
              holdAmount: 0,
            },
            {
              id: 2,
              name: '3 ตัวล่าง',
              rate: 0,
              discount: 0,
              min: 1,
              max: 100000,
              holdAmount: 0,
            },
            {
              id: 3,
              name: 'วิ่งล่าง',
              rate: 4,
              discount: 12,
              min: 1,
              max: 100000,
              holdAmount: 0,
            },
            {
              id: 4,
              name: '3 ตัวโต๊ด',
              rate: 125,
              discount: 15,
              min: 1,
              max: 100000,
              holdAmount: 0,
            },
            {
              id: 5,
              name: '2 ตัวบน',
              rate: 92,
              discount: 8,
              min: 1,
              max: 100000,
              holdAmount: 0,
            },
            {
              id: 6,
              name: '3 ตัวบน',
              rate: 800,
              discount: 15,
              min: 1,
              max: 100000,
              holdAmount: 0,
            },
            {
              id: 7,
              name: 'วิ่งบน',
              rate: 3,
              discount: 12,
              min: 1,
              max: 100000,
              holdAmount: 0,
            },
          ],
        },
        {
          id: 5,
          name: 'หวยอื่น ๆ',
          status: 1,
          rate: [
            {
              id: 1,
              name: '2 ตัวล่าง',
              rate: 92,
              discount: 8,
              min: 1,
              max: 100000,
              holdAmount: 0,
            },
            {
              id: 2,
              name: '3 ตัวล่าง',
              rate: 0,
              discount: 0,
              min: 1,
              max: 100000,
              holdAmount: 0,
            },
            {
              id: 3,
              name: 'วิ่งล่าง',
              rate: 4,
              discount: 12,
              min: 1,
              max: 100000,
              holdAmount: 0,
            },
            {
              id: 4,
              name: '3 ตัวโต๊ด',
              rate: 125,
              discount: 15,
              min: 1,
              max: 100000,
              holdAmount: 0,
            },
            {
              id: 5,
              name: '2 ตัวบน',
              rate: 92,
              discount: 8,
              min: 1,
              max: 100000,
              holdAmount: 0,
            },
            {
              id: 6,
              name: '3 ตัวบน',
              rate: 800,
              discount: 15,
              min: 1,
              max: 100000,
              holdAmount: 0,
            },
            {
              id: 7,
              name: 'วิ่งบน',
              rate: 3,
              discount: 12,
              min: 1,
              max: 100000,
              holdAmount: 0,
            },
          ],
        },
        {
          id: 6,
          name: 'หวยลาว',
          status: 1,
          rate: [
            {
              id: 1,
              name: '2 ตัวล่าง',
              rate: 92,
              discount: 8,
              min: 1,
              max: 100000,
              holdAmount: 0,
            },
            {
              id: 2,
              name: '3 ตัวล่าง',
              rate: 0,
              discount: 0,
              min: 1,
              max: 100000,
              holdAmount: 0,
            },
            {
              id: 3,
              name: 'วิ่งล่าง',
              rate: 4,
              discount: 12,
              min: 1,
              max: 100000,
              holdAmount: 0,
            },
            {
              id: 4,
              name: '3 ตัวโต๊ด',
              rate: 125,
              discount: 15,
              min: 1,
              max: 100000,
              holdAmount: 0,
            },
            {
              id: 5,
              name: '2 ตัวบน',
              rate: 92,
              discount: 8,
              min: 1,
              max: 100000,
              holdAmount: 0,
            },
            {
              id: 6,
              name: '3 ตัวบน',
              rate: 800,
              discount: 15,
              min: 1,
              max: 100000,
              holdAmount: 0,
            },
            {
              id: 7,
              name: 'วิ่งบน',
              rate: 3,
              discount: 12,
              min: 1,
              max: 100000,
              holdAmount: 0,
            },
          ],
        },
        {
          id: 7,
          name: 'หวยต่าง ๆ',
          status: 1,
          rate: [
            {
              id: 1,
              name: '2 ตัวล่าง',
              rate: 92,
              discount: 8,
              min: 1,
              max: 100000,
              holdAmount: 0,
            },
            {
              id: 2,
              name: '3 ตัวล่าง',
              rate: 0,
              discount: 0,
              min: 1,
              max: 100000,
              holdAmount: 0,
            },
            {
              id: 3,
              name: 'วิ่งล่าง',
              rate: 4,
              discount: 12,
              min: 1,
              max: 100000,
              holdAmount: 0,
            },
            {
              id: 4,
              name: '3 ตัวโต๊ด',
              rate: 125,
              discount: 15,
              min: 1,
              max: 100000,
              holdAmount: 0,
            },
            {
              id: 5,
              name: '2 ตัวบน',
              rate: 92,
              discount: 8,
              min: 1,
              max: 100000,
              holdAmount: 0,
            },
            {
              id: 6,
              name: '3 ตัวบน',
              rate: 800,
              discount: 15,
              min: 1,
              max: 100000,
              holdAmount: 0,
            },
            {
              id: 7,
              name: 'วิ่งบน',
              rate: 3,
              discount: 12,
              min: 1,
              max: 100000,
              holdAmount: 0,
            },
          ],
        },
      ],
    },
    LOTTO_DATE: {
      STATUS: {
        PENDING: 'PENDING',
        CANCELED: 'CANCELED',
        CALCULATING: 'CALCULATING',
        COMPLETED: 'COMPLETED',
        REFUNDED: 'REFUNDED',
      },
    },
    ORDER: {
      STATUS: {
        PENDING: 'PENDING',
        CANCELED: 'CANCELED',
        CALCULATING: 'CALCULATING',
        COMPLETED: 'COMPLETED',
        REFUNDED: 'REFUNDED',
      },
      STATUS_FILTER: [
        { key: 'PENDING', title: 'รอผล' },
        { key: 'CANCELED', title: 'ถูกยกเลิก' },
        { key: 'CALCULATING', title: 'กำลังคำนวณ' },
        { key: 'COMPLETED', title: 'เสร็จสิ้น' },
      ],
    },
    DESC: {
      RATE: [
        {
          id: 1,
          name: '2 ตัวล่าง',
        },
        {
          id: 2,
          name: '3 ตัวล่าง',
        },
        {
          id: 3,
          name: 'วิ่งล่าง',
        },
        {
          id: 4,
          name: '3 ตัวโต๊ด',
        },
        {
          id: 5,
          name: '2 ตัวบน',
        },
        {
          id: 6,
          name: '3 ตัวบน',
        },
        {
          id: 7,
          name: 'วิ่งบน',
        },
      ],
    },
  },
};
