import { get, post } from "../auth.service"
import { authHeader } from "../auth-header";
import { API_URL } from "../../util/constant"

const URL = API_URL + 'lottos/';

const getLottoParamData = async () => {
    return await get(URL + 'param-data', { headers: await authHeader() });
}

const getLottoParamDateData = async (lottos_fk) => {
    return await post(URL + 'param-date-data', { lottos_fk }, { headers: await authHeader() });
}

const getLottoParamDateLatest = async (lottos_fk) => {
    return await post(URL + 'param-date-data/latest', { lottos_fk }, { headers: await authHeader() });
}

const getLottoParamDateDataAdmin = async (lottos_fk) => {
    return await post(URL + 'admin/param-date-data', { lottos_fk }, { headers: await authHeader() });
}

const getLottoParamDateLatestAdmin = async (lottos_fk) => {
    return await post(URL + 'admin/param-date-data/latest', { lottos_fk }, { headers: await authHeader() });
}
const getReportReward = async (values) => {
    return await post(URL + 'report/reward', values, { headers: await authHeader() });
}

const getReportRewardByLotto = async (values) => {
    return await post(URL + 'report/reward-by-lotto', values, { headers: await authHeader() });
}


const getReportRewardByDate = async (values) => {
    return await post(URL + 'report/reward-by-date', values, { headers: await authHeader() });
}

const getLottoClosedNumbers = async (lotto_dates_fk) => {
    return await post(URL + 'closed-numbers', { lotto_dates_fk }, { headers: await authHeader() });
}

const LottoService = {
    getLottoParamData,
    getReportReward,
    getLottoParamDateData,
    getLottoParamDateLatest,
    getReportRewardByLotto,
    getReportRewardByDate,
    getLottoParamDateDataAdmin,
    getLottoParamDateLatestAdmin,
    getLottoClosedNumbers
}

export default LottoService