import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Spin, Alert, Row, Col, Statistic, message, Divider, Empty, Tooltip } from 'antd';
import {
    Link,
} from "react-router-dom";
import { ELM_STANDARD, MENU_ITEMS } from '../../util/options';
import MemberService from '../../services/user/member.service';
import { groupBy } from 'lodash'
import { DATE_FORMAT, DB, MEMBER_INTERVAL_UPDATE } from '../../util/constant';
import dayjs from 'dayjs'
import 'dayjs/locale/th'
import isBetween from 'dayjs/plugin/isBetween'
import { ClockCircleOutlined, SyncOutlined } from '@ant-design/icons';
dayjs.extend(isBetween)

const HomeMember = () => {
    const [loading, setLoading] = useState(true)
    const [markets, setMarkets] = useState([])
    const [updatedAt, setUpdatedAt] = useState(null)

    useEffect(() => {
        prepareData()
    }, [])

    const prepareData = async () => {
        const data = await MemberService.getMarkets()
        const groupedData = groupBy(data, 'groupId')

        // assign group name
        Object.keys(groupedData).forEach((key) => {
            groupedData[key].groupName = DB.SYSTEM.LOTTO.GROUP.find(x => x.id == key)?.name
        })

        setMarkets(Object.values(groupedData))
        setUpdatedAt(dayjs().add(MEMBER_INTERVAL_UPDATE, 'minute'))
        setLoading(false)
    }


    const updateMarketStatus = () => {
        message.info({ key: 'updateMarketStatus', content: 'กำลังรีเฟรชสถานะตลาด..' })
        prepareData()
    }

    const onVisibilityChange = () => {
        if (document.visibilityState === 'visible' && dayjs().isAfter(updatedAt)) {
            console.log("Tab reopened, refetch the data!");
            prepareData()
        }
    };

    useLayoutEffect(() => {
        document.addEventListener("visibilitychange", onVisibilityChange);
        return () => document.removeEventListener("visibilitychange", onVisibilityChange);
    }, [updatedAt]);

    const renderMarkets = () => {
        return markets.map((market, i) => {
            return <div key={`market-${i}`} className='market-wrapper'>
                <div className='market-title'>{market.groupName}</div>
                <Row className='market-content' gutter={[8, 16]}>{renderMarketContent(market)}</Row>
                <Divider />
            </div>

        })
    }

    const renderMarketContent = (market) => {
        return market.map((m) => {
            let status = {}
            if (m.status === DB.SYSTEM.LOTTO_DATE.STATUS.PENDING) {
                if (dayjs().isBefore(m.openAt)) {
                    status.key = 'willActive';
                    status.isDisabledLink = true
                }
                else if (dayjs().isBetween(m.openAt, m.closeAt)) {
                    status.key = 'active';
                    status.isDisabledLink = false
                } else {
                    status.key = 'inactive'
                    status.isDisabledLink = false
                }
            } else if (m.status === DB.SYSTEM.LOTTO_DATE.STATUS.CANCELED) {
                status.key = 'canceled'
                status.isDisabledLink = true
            } else {
                status.key = 'inactive'
                status.isDisabledLink = false
            }

            return <Col xs={6} key={`${m.lottos_fk}${m.date}`}>
                <Link style={status.isDisabledLink ? { pointerEvents: 'none' } : {}}
                    to={status.key === 'active' ? `${MENU_ITEMS.bet.rawPath}/${m.id}` : MENU_ITEMS.reportReward.path}>
                    <div className={`market-item market-${status.key}`}>
                        <Row wrap={false}>
                            <Col xs={6} className="col-flex-vertical-center">
                                <div className='market-image-wrapper'>
                                    <img className='market-image' src={`../images/lotto/${m.lottos_fk}.png`} alt="" />
                                </div>
                            </Col>
                            <Col flex={1}>
                                <Row justify='space-between'>
                                    <Col xs={24} className="name">{m.name}</Col>
                                    <Col xs={24} className="date">{DATE_FORMAT.dateonly(m.date)}</Col>
                                    {status.key === 'willActive' && (
                                        <>
                                            <Col xs={12}>เปิด</Col><Col className='text-right' xs={12}>{DATE_FORMAT.default(m.openAt)}</Col>
                                            <Col xs={12}>สถานะ</Col><Col className='text-right' xs={12}><Statistic.Countdown prefix="เปิดรับใน" title="" value={m.openAt} onFinish={() => updateMarketStatus()} /></Col>
                                        </>
                                    )}
                                    {status.key === 'active' && (
                                        <>
                                            <Col xs={12}>ปิด</Col><Col className='text-right' xs={12}>{DATE_FORMAT.default(m.closeAt)}</Col>
                                            <Col xs={12}>สถานะ</Col><Col className='text-right' xs={12}><Statistic.Countdown prefix="ปิดรับใน" title="" value={m.closeAt} onFinish={() => updateMarketStatus(m.lottos_fk)} /></Col>
                                        </>
                                    )}
                                    {status.key === 'inactive' && (
                                        <><Col xs={12}>สถานะ</Col><Col className='text-right' xs={12}>ปิดรับ</Col></>

                                    )}
                                    {status.key === 'canceled' && (
                                        <><Col xs={12}>สถานะ</Col><Col className='text-right' xs={12}>ถูกยกเลิก</Col></>
                                    )}

                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Link>
            </Col>
        })
    }

    return (
        <div className='member-page-group home-member-page'>
            <Spin {...ELM_STANDARD.spin} spinning={loading}>

                <Alert
                    message={`สำคัญมาก!!!`}
                    description={`*** กรุณาอ่านและทำความเข้าใจ "กฎและกติกา" และ "วิธีเล่นหวย" ที่เมนู***
                    **บริษัทฯแนะนำลูกค้าทุกท่านให้ตรวจสอบรายการแทงทุกครั้งก่อนยืนยันการแทง บริษัทฯจะไม่สามารถยกเลิกบิลที่กดบันทึกแล้วทุกกรณี***`}
                    type="warning"
                    className='my-2'
                    closable
                />

                <div className='text-secondary text-center font-sarabun cursor-pointer'>
                    <Tooltip placement='top' title={<div className='text-center'>คลิกเพื่ออัปเดต</div>}>
                        <div>
                            {loading ? <SyncOutlined spin />
                                : <span onClick={prepareData}>อัปเดตล่าสุด: <ClockCircleOutlined /> {DATE_FORMAT.timeonly(dayjs(updatedAt).subtract(MEMBER_INTERVAL_UPDATE, 'minute'))}</span>}
                        </div>
                    </Tooltip>
                </div>

                <div className='page-content-wrapper'>
                    <div className='page-content'>
                        {markets.length > 0 ? (
                            renderMarkets()
                        ) : <Empty description="ไม่มีหวยเปิดในขณะนี้" />}
                    </div>
                </div>
            </Spin>
        </div>

    )
}

export default HomeMember;