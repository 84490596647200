import React, { createContext, useContext, useState, useMemo, useEffect } from "react"
import MemberService from "../services/user/member.service"


const UserRateContext = createContext({})

export const useUserRateContext = () => {
    return useContext(UserRateContext)
}

const initialUserRate = {
    id: null,
    groupId: null,
    name : null,
    status: null,
    rate: [],
    hold: null,
    uplineKeep: null,
}


export const UserRateProvider = ({ children }) => {
    const [userRate, setUserRate] = useState(initialUserRate)

    const getUserRateData = async (lottoDateId) => {
        const data = await MemberService.getRateByDateId(lottoDateId)
        if(data){
            setUserRate(data)
        }
    }

    const userRateStore = useMemo(() => ({
        userRate,
        userRateAction: {
            setUserRate,
            getUserRateData
        }
    }), [userRate])


    return (
        <UserRateContext.Provider value={userRateStore}>
            {children}
        </UserRateContext.Provider>
    )
}

export default UserRateProvider