import React, { useState, useEffect } from "react";
import { DATE_FORMAT } from "../../util/constant";

const Clock = () => {
    const [date, setDate] = useState(DATE_FORMAT.thaiHalf());

    useEffect(() => {
        const timerID = setInterval(() => tick(), 1000);
        return () => {
            clearInterval(timerID);
        };
    }, []);

    const tick = () => {
        setDate(DATE_FORMAT.thaiHalf());
    };

    return <div style={{color: '#1890ff'}}>{date}</div>;
};

export default Clock;
