import { Button, Divider, Form, Input, InputNumber, Table, Select, Spin, Tabs, Typography, Switch, Space, message } from "antd";
import React, { useState, useEffect } from "react";
import AgentService from "../../services/user/agent.service";
import { MSG } from "../../util/constant";
import { ELM_STANDARD, MENU_ITEMS } from "../../util/options";

const { TabPane } = Tabs;
const CreateUserConfig = () => {
    const [formData, setFormData] = useState([])
    const [loading, setLoading] = useState(true);
    const [form] = Form.useForm()
    const [initialValues, setInitialValues] = useState({})

    const [customLimitSelected, setCustomLimitSelected] = useState([])
    const [customRateSelected, setCustomRateSelected] = useState([])


    const customLimitAll = () => {
        setCustomLimitSelected(formData.map(item => item.id))
    }

    const cancelLimitAll = () => {
        setCustomLimitSelected([])
    }

    const customLimitAllAtOnce = () => {
        setCustomLimitSelected([])
    }

    const customRateAll = () => {
        setCustomRateSelected(formData.map(item => item.id))
    }

    const cancelRateAll = () => {
        setCustomRateSelected([])
    }

    const customRateAllAtOnce = () => {
        setCustomRateSelected([])
    }

    const submit = async (values) => {
        setLoading(true)
        try {
            const send = await AgentService.updateRegisterData(values)
            if (send === `OK`) {
                message.success(MSG.COMMON.UPDATE.SUCCEED)
            } else {
                message.error(MSG.COMMON.VALIDATE.FAILED)
            }
            setLoading(false);
        } catch (err) {
            message.error(MSG.COMMON.VALIDATE.FAILED)
            setLoading(false);
        }

    }

    useEffect(() => {
        prepareData();
    }, [])


    const prepareData = async () => {
        const data = await AgentService.getRegisterData();
        setFormData(data)
        const initial = {
            userRate: {
                ...data.map((value) => {
                    return {
                        lotto_groups_fk: value.id,
                        status: value.status,
                        rate: value.rate.map((item) => {
                            return {
                                id: item.id,
                                min: item.min,
                                max: item.max,
                                rate: item.rate,
                                discount: item.discount,
                            }
                        })
                    }
                })
            }
        }
        setInitialValues(initial)
        setLoading(false)
    }

    useEffect(() => {
        form.setFieldsValue(initialValues)
    }, [form, initialValues])

    return (
        <div className='agent-page-group add-member-page'>
            <Spin {...ELM_STANDARD.spin} spinning={loading}>
                <div className='page-title'>
                    <Typography className='title'>{MENU_ITEMS.createUserConfig.label}</Typography>
                </div>

                <div className='page-content-wrapper'>
                    <div className='page-content'>
                        <Form form={form} layout="vertical" initialValues={initialValues} onFinish={submit}>
                            {/* tabs */}
                            <Tabs defaultActiveKey="1">
                                <TabPane forceRender tab="เปิด/ปิดหวย แบ่งหุ้น/เก็บของ" key="1">
                                    <Table
                                         scroll={{ x: 'max-content' }}
                                        rowKey={(record) => record.id}
                                        pagination={false}
                                        dataSource={formData}
                                        columns={[{
                                            title: 'กลุ่ม',
                                            dataIndex: 'name',
                                            key: 'name',
                                            render: (value, record) => {
                                                return <div>{record.id}. {record.name}</div>
                                            }
                                        },
                                        {
                                            title: 'เปิด/ปิดหวย',
                                            dataIndex: 'status',
                                            key: 'status',
                                            align: 'center',
                                            render: (value, record, i) => {
                                                return <>
                                                    <Form.Item name={['userRate', i, 'lotto_groups_fk']} style={{ display: 'none' }}>
                                                        <Input type="hidden" />
                                                    </Form.Item>
                                                    <Form.Item name={['userRate', i, 'status',]} valuePropName="checked" style={{ marginBottom: 0 }}>
                                                        <Switch checkedChildren="เปิด" unCheckedChildren="ปิด" />
                                                    </Form.Item>
                                                </>
                                            }
                                        },
                                        ]}
                                    />
                                </TabPane>
                                <TabPane forceRender tab="ข้อมูลการแทง" key="2">
                                    <Space direction="vertical" className="w-100" size={'large'}>
                                        <Space className="w-100">
                                            <Button type="primary" onClick={customLimitAll}>เลือกทั้งหมด</Button>
                                            <Button type="primary" onClick={cancelLimitAll}>ยกเลิกทั้งหมด</Button>
                                            {/* TODO: ux */}
                                            <Button type="danger" onClick={customLimitAllAtOnce} disabled>ตั้งค่าทั้งหมด</Button>
                                        </Space>
                                        <Select
                                            showSearch
                                            mode="multiple"
                                            allowClear
                                            className="w-100"
                                            placeholder="เลือกหวย"
                                            value={customLimitSelected}
                                            onChange={(value) => setCustomLimitSelected(value)}
                                        >
                                            {formData.map((item, i) => {
                                                return <Select.Option key={i} value={item.id}>{item.id}. {item.name}</Select.Option>
                                            })}
                                        </Select>

                                        {formData.map((item, index) => {
                                            const isDisplaying = customLimitSelected.includes(item.id)
                                            return <div key={item.id} className={isDisplaying ? '' : 'display-none'}>
                                                <Typography.Title level={5}>{item.id}. {item.name}</Typography.Title>
                                                <Table
                                                     scroll={{ x: 'max-content' }}
                                                    size={'small'}
                                                    key={`customLimitSelected-${item.id}`}
                                                    rowKey={(record) => record.id}
                                                    pagination={false}
                                                    dataSource={item?.rate}
                                                    columns={[
                                                        {
                                                            title: 'ประเภท',
                                                            dataIndex: 'name',
                                                            key: 'name',
                                                            render: (value,) => {
                                                                return <div>{value}</div>
                                                            }
                                                        },
                                                        {
                                                            title: 'ขั้นต่ำ',
                                                            dataIndex: 'min',
                                                            key: 'min',
                                                            align: 'center',
                                                            render: (value, record, i) => {
                                                                return <>
                                                                    <Form.Item name={['userRate', index, 'rate', i, 'id']} style={{ display: 'none' }}>
                                                                        <Input type="hidden" />
                                                                    </Form.Item>

                                                                    <Form.Item name={['userRate', index, 'rate', i, 'min']}>
                                                                        <InputNumber min={1} max={100000} />
                                                                    </Form.Item></>
                                                            }
                                                        },
                                                        {
                                                            title: 'สูงสุด',
                                                            dataIndex: 'max',
                                                            key: 'max',
                                                            align: 'center',
                                                            render: (value, record, i) => {
                                                                return <Form.Item name={['userRate', index, 'rate', i, 'max']}>
                                                                    <InputNumber min={1} max={100000} />
                                                                </Form.Item>
                                                            }
                                                        },
                                                    ]}
                                                />
                                            </div>
                                        })

                                        }
                                    </Space>
                                </TabPane>
                                <TabPane forceRender tab="อัตราจ่าย" key="3">
                                    <Space direction="vertical" className="w-100" size={'large'}>
                                        <Space className="w-100">
                                            <Button type="primary" onClick={customRateAll}>เลือกทั้งหมด</Button>
                                            <Button type="primary" onClick={cancelRateAll}>ยกเลิกทั้งหมด</Button>
                                            {/* TODO: ux */}
                                            <Button type="danger" onClick={customRateAllAtOnce} disabled>ตั้งค่าทั้งหมด</Button>
                                        </Space>
                                        <Select
                                            mode="multiple"
                                            allowClear
                                            className="w-100"
                                            placeholder="เลือกกลุ่มหวย"
                                            value={customRateSelected}
                                            onChange={(value) => setCustomRateSelected(value)}
                                        >
                                            {formData.map((item, i) => {
                                                return <Select.Option key={i} value={item.id}>{item.id}. {item.name}</Select.Option>
                                            })}
                                        </Select>

                                        {formData.map((item, index) => {
                                            const isDisplaying = customRateSelected.includes(item.id)
                                            return <div key={item.id} className={isDisplaying ? '' : 'display-none'}>
                                                <Typography.Title level={5}>{item.id}. {item.name}</Typography.Title>
                                                <Table
                                                     scroll={{ x: 'max-content' }}
                                                    size={'small'}
                                                    key={`customRateSelected-${item.id}`}
                                                    rowKey={(record) => record.id}
                                                    pagination={false}
                                                    dataSource={item?.rate}
                                                    columns={[
                                                        {
                                                            title: 'ประเภท',
                                                            dataIndex: 'name',
                                                            key: 'name',
                                                            render: (value,) => {
                                                                return <div>{value}</div>
                                                            }
                                                        },
                                                        {
                                                            title: 'จ่าย',
                                                            dataIndex: 'rate',
                                                            key: 'rate',
                                                            align: 'center',
                                                            render: (value, record, i) => {
                                                                return <Form.Item name={['userRate', index, 'rate', i, 'rate']} style={{ marginBottom: 0 }}  >
                                                                    <InputNumber min={0} size={'small'} />
                                                                </Form.Item>
                                                            }
                                                        },
                                                        {
                                                            title: 'คอม',
                                                            dataIndex: 'discount',
                                                            key: 'discount',
                                                            align: 'center',
                                                            render: (value, record, i) => {
                                                                return <Form.Item name={['userRate', index, 'rate', i, 'discount']} style={{ marginBottom: 0 }}>
                                                                    <InputNumber min={0} max={100} size={'small'} />
                                                                </Form.Item>
                                                            }
                                                        },
                                                    ]}
                                                />
                                            </div>
                                        })

                                        }
                                    </Space>
                                </TabPane>
                            </Tabs>
                            <Form.Item>

                                <Divider />
                                <Form.Item className="text-right" >
                                    <Button size={'large'} htmlType='submit' type="primary" key="submit">
                                        บันทึก
                                    </Button>
                                </Form.Item>
                            </Form.Item>
                        </Form>
                    </div>
                </div >
            </Spin >
        </div >
    );
};

export default CreateUserConfig;
