import { get, post } from "../auth.service"
import { authHeader } from "../auth-header";
import { API_URL } from "../../util/constant"

const URL = API_URL + 'agent/';

const getRegisterData = async () => {
    return await get(URL + 'register/data', { headers: await authHeader() });
}

const getRegisterDataByMember = async (userId) => {
    return await post(URL + 'register/data/by-member', { userId }, { headers: await authHeader() });
}

const getRegisterDataByAgent = async (userId) => {
    return await post(URL + 'register/data/by-agent', { userId }, { headers: await authHeader() });
}

const updateRegisterData = async (values) => {
    return await post(URL + 'register/update', values, { headers: await authHeader() });
}

const isUsernameTaken = async (username) => {
    return await post(URL + 'username-check', { username }, { headers: await authHeader() },);
}

const addMember = async (values) => {
    return await post(URL + 'add-member', values, { headers: await authHeader() },);
}


const addAgent = async (values) => {
    return await post(URL + 'add-agent', values, { headers: await authHeader() },);
}

const listUser = async (userId) => {
    return await post(URL + 'user/list', { userId }, { headers: await authHeader() });
}

const listLockedUser = async (userId) => {
    return await post(URL + 'user/list/locked', { userId }, { headers: await authHeader() });
}

const listUserAll = async () => {
    return await get(URL + 'user/list/all', { headers: await authHeader() });
}

const updateUser = async (values) => {
    return await post(URL + 'user/update', values, { headers: await authHeader() });
}

const changeUserPassword = async (values) => {
    return await post(URL + 'user/change-password', values, { headers: await authHeader() });
}

const updateUserStatus = async (values) => {
    return await post(URL + 'user/status/update', values, { headers: await authHeader() });
}

const depositToUser = async (values) => {
    return await post(URL + 'user/deposit', values, { headers: await authHeader() });
}

const withdrawFromUser = async (values) => {
    return await post(URL + 'user/withdraw', values, { headers: await authHeader() });
}


const getOwnRate = async () => {
    return await get(URL + 'own-rate', { headers: await authHeader() });
}

const getDownlineRate = async (userId) => {
    return await post(URL + 'downline-rate', { userId }, { headers: await authHeader() });
}

const updateDownlineHold = async (values) => {
    return await post(URL + 'downline-rate/hold/update', values, { headers: await authHeader() })
}

const bulkUpdateDownlineHold = async (values) => {
    return await post(URL + 'downline-rate/hold/bulk-update', values, { headers: await authHeader() });
}

const bulkUpdateDownlineBoundary = async (values) => {
    return await post(URL + 'downline-rate/boundary/bulk-update', values, { headers: await authHeader() });
}

const bulkUpdateDownlineRate = async (values) => {
    return await post(URL + 'downline-rate/rate/bulk-update', values, { headers: await authHeader() });
}

const updateDownlineRate = async (values) => {
    return await post(URL + 'downline-rate/update', values, { headers: await authHeader() })
}

const getDownlineBetResultOrders = async (values) => {
    return await post(URL + 'downline/bet-result-orders', values, { headers: await authHeader() });
}

const getDownlineBetResult = async (values) => {
    return await post(URL + 'downline/bet-result', values, { headers: await authHeader() });
}

const getDownlineMemberBetResult = async (values) => {
    return await post(URL + 'downline/member/bet-result', values, { headers: await authHeader() });
}

const getDownlineBetResultByDate = async (values) => {
    return await post(URL + 'downline/bet-result/by-date', values, { headers: await authHeader() });
}

const getDownlineMemberBetResultByDate = async (values) => {
    return await post(URL + 'downline/member/bet-result/by-date', values, { headers: await authHeader() });
}

const updateSelfRate = async (values) => {
    return await post(URL + 'self/rate/update', values, { headers: await authHeader() });
}

const bulkUpdateSelfRate = async (values) => {
    return await post(URL + 'self/rate/bulk-update', values, { headers: await authHeader() });
}

const downlineReceiveSettingHistory = async (values) => {
    return await post(URL + 'downline/receive-setting/history', values, { headers: await authHeader() });
}

const downlineTransferHistory = async (values) => {
    return await post(URL + 'downline/transfer/history', values, { headers: await authHeader() });
}

const downlineLoginHistory = async (values) => {
    return await post(URL + 'downline/login/history', values, { headers: await authHeader() });
}


const getDownlineWaitReward = async (values) => {
    return await post(URL + 'downline/wait-reward', values, { headers: await authHeader() });
}

const getDownlineMemberWaitReward = async (values) => {
    return await post(URL + 'downline/member/wait-reward', values, { headers: await authHeader() });
}

const AgentService = {
    getRegisterData,
    getRegisterDataByMember,
    getRegisterDataByAgent,
    updateRegisterData,
    isUsernameTaken,
    addMember,
    addAgent,
    listUser,
    listLockedUser,
    listUserAll,
    updateUser,
    changeUserPassword,
    updateUserStatus,
    depositToUser,
    withdrawFromUser,
    getOwnRate,
    getDownlineRate,
    updateDownlineHold,
    updateDownlineRate,
    getDownlineBetResultOrders,
    getDownlineBetResult,
    getDownlineMemberBetResult,
    getDownlineBetResultByDate,
    getDownlineMemberBetResultByDate,
    updateSelfRate,
    bulkUpdateSelfRate,
    downlineReceiveSettingHistory,
    downlineTransferHistory,
    downlineLoginHistory,
    getDownlineWaitReward,
    getDownlineMemberWaitReward,
    bulkUpdateDownlineHold,
    bulkUpdateDownlineBoundary,
    bulkUpdateDownlineRate
}

export default AgentService