import {
  Col,
  Divider,
  Menu,
  message,
  Row,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { DB, DATE_FORMAT, MSG } from '../../../util/constant';
import { MENU_ITEMS, ROLE_RENDER } from '../../../util/options';

import { useUserContext } from '../../../contexts/UserContext';
import { ClockCircleOutlined, SyncOutlined } from '@ant-design/icons';
import Clock from '../Clock';

const Sider = (props) => {
  const { user, userAction } = useUserContext();
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState([]);
  const [balanceLoading, setBalanceLoading] = useState(false);
  const navigate = useNavigate();

  const setMenu = (path) => {
    const keys = Object.keys(MENU_ITEMS);
    if (userAction.isMember()) {
      keys.forEach((key, index) => {
        if (MENU_ITEMS[key].path === path) {
          if (MENU_ITEMS[key].hasOwnProperty('roleFlag')) {
            if (MENU_ITEMS[key].roleFlag === DB.USER.ROLE.MEMBER) {
              setSelectedKey(key);
            }
          } else {
            setSelectedKey(key);
          }
        }
      });
    } else {
      // not member not roleFlag
      keys.forEach((key, index) => {
        if (
          MENU_ITEMS[key].path === path &&
          !MENU_ITEMS[key].hasOwnProperty('roleFlag')
        ) {
          setSelectedKey(key);
        }
      });
    }
  };

  const logout = () => {
    userAction.logout();
    message.success(MSG.COMMON.LOGOUT);
  };

  const updateBalance = async () => {
    setBalanceLoading(true);
    const update = await userAction.updateBalance();
    if (update) {
      message.success(MSG.COMMON.UPDATE.SUCCEED);
      setBalanceLoading(false);
    } else {
      message.success(MSG.COMMON.UPDATE.FAILED);
      setBalanceLoading(false);
    }
  };

  const clickableMenu = (menuObj) => {
    const style = props.isCollapsed ? { display: 'inline-block' } : {};
    return {
      key: menuObj.key,
      label: (
        <div
          style={style}
          onClick={() => {
            setSelectedKey(menuObj.key);
            navigate(menuObj.path);
          }}
        >
          {menuObj.label}
        </div>
      ),
      icon: (
        <div
          style={style}
          onClick={() => {
            setSelectedKey(menuObj.key);
            navigate(menuObj.path);
          }}
        >
          {menuObj.icon}
        </div>
      ),
    };
  };

  const unclickableMenu = (menuObj) => {
    return {
      key: menuObj.key,
      label: menuObj.label,
      icon: menuObj.icon,
    };
  };

  const MENU_ELM = {
    divider: { key: 'divider', type: 'divider' },
    // agent
    home: {
      ...clickableMenu(MENU_ITEMS.home),
    },
    logout: {
      key: MENU_ITEMS.logout.key,
      label: (
        <div
          onClick={() => {
            logout();
          }}
        >
          {MENU_ITEMS.logout.label}
        </div>
      ),
      icon: (
        <div
          onClick={() => {
            logout();
          }}
        >
          {MENU_ITEMS.logout.icon}
        </div>
      ),
    },
    agent: {
      betReport: {
        ...unclickableMenu(MENU_ITEMS.betReport),
        children: [
          clickableMenu(MENU_ITEMS.waitReward),
          clickableMenu(MENU_ITEMS.lockedNumber),
          clickableMenu(MENU_ITEMS.shareByType),
        ],
      },
      betResultReport: {
        ...unclickableMenu(MENU_ITEMS.betResultReport),
        children: [
          clickableMenu(MENU_ITEMS.betResult),
          clickableMenu(MENU_ITEMS.betResultByDate),
          clickableMenu(MENU_ITEMS.betResultOrders),
          clickableMenu(MENU_ITEMS.betResultGameResult),
        ],
      },
      lottoReport: {
        ...unclickableMenu(MENU_ITEMS.lottoReport),
        children: [
          clickableMenu(MENU_ITEMS.receiveSettingHistory),
          clickableMenu(MENU_ITEMS.orderGraph),
        ],
      },
      manageUser: {
        ...unclickableMenu(MENU_ITEMS.manageUser),
        children: [
          clickableMenu(MENU_ITEMS.addMember),
          clickableMenu(MENU_ITEMS.addAgent),
          clickableMenu(MENU_ITEMS.userList),
          clickableMenu(MENU_ITEMS.lockedUser),
          clickableMenu(MENU_ITEMS.createUserConfig),
        ],
      },
      userSetting: {
        ...unclickableMenu(MENU_ITEMS.userSetting),
        children: [
          clickableMenu(MENU_ITEMS.mySetting),
          clickableMenu(MENU_ITEMS.receiveSetting),
          clickableMenu(MENU_ITEMS.boundarySetting),
          clickableMenu(MENU_ITEMS.rate),
          clickableMenu(MENU_ITEMS.manageLotto),
          clickableMenu(MENU_ITEMS.userReceive),
        ],
      },
      report: {
        ...unclickableMenu(MENU_ITEMS.report),
        children: [
          clickableMenu(MENU_ITEMS.memberTransfer),
          clickableMenu(MENU_ITEMS.loginHistory),
        ],
      },
      lottoHistory: {
        ...unclickableMenu(MENU_ITEMS.lottoHistory),
        children: [
          clickableMenu(MENU_ITEMS.lottoHistoryByLotto),
          clickableMenu(MENU_ITEMS.lottoHistoryByDate),
        ],
      },
    },
  };

  useEffect(() => {
    setMenu(location.pathname);
  }, [location]);

  const menu = () => {
    if (userAction.isMember()) {
      return [
        { ...clickableMenu(MENU_ITEMS.homeMember) },
        { ...clickableMenu(MENU_ITEMS.orders) },
        { ...clickableMenu(MENU_ITEMS.ordersByType) },
        {
          ...unclickableMenu(MENU_ITEMS.memberAccount),
          children: [
            clickableMenu(MENU_ITEMS.memberTransactions),
            clickableMenu(MENU_ITEMS.memberWaitReport),
            clickableMenu(MENU_ITEMS.memberTransactionsReport),
          ],
        },
        { ...clickableMenu(MENU_ITEMS.reportReward) },
        { ...clickableMenu(MENU_ITEMS.rules) },
        { ...clickableMenu(MENU_ITEMS.howToPlay) },
        { ...clickableMenu(MENU_ITEMS.resultLink) },
      ];
    } else if (userAction.isAgent()) {
      return [
        MENU_ELM.home,
        ...Object.values(MENU_ELM.agent),
        MENU_ELM.divider,
        MENU_ELM.logout,
      ];
    } else if (userAction.isAdmin()) {
      return [
        clickableMenu(MENU_ITEMS.lottoResult),
        clickableMenu(MENU_ITEMS.lottoDate),
        clickableMenu(MENU_ITEMS.lottoClosedNumber),
        clickableMenu(MENU_ITEMS.adminWaitReward),
        {
          ...unclickableMenu(MENU_ITEMS.adminReportResult),
          children: [
            clickableMenu(MENU_ITEMS.adminReportResultByType),
            clickableMenu(MENU_ITEMS.adminReportResultByDate),
            clickableMenu(MENU_ITEMS.betResultGameResult),
          ],
        },
        {
          ...unclickableMenu(MENU_ITEMS.adminManageUser),
          children: [
            clickableMenu(MENU_ITEMS.adminAddAgent),
            clickableMenu(MENU_ITEMS.adminUserList),
          ],
        },
        { ...clickableMenu(MENU_ITEMS.rules) },
        { ...clickableMenu(MENU_ITEMS.howToPlay) },
        { ...clickableMenu(MENU_ITEMS.resultLink) },
        MENU_ELM.divider,
        MENU_ELM.logout,
      ];
    }
    return [];
  };

  return (
    <div className="sider-wrapper">
      {user.isPermit && (
        <>
          <Typography.Title
            className="logo"
            style={{ color: '#fff', textShadow: '6px 6px 0px rgba(0,0,0,0.2)' }}
          >
            DUNCHESS LOTTO
          </Typography.Title>
          <div
            className={`unselectable ${
              props.isCollapsed ? 'display-none' : ''
            }`}
          >
            <Typography.Text className="text-center" style={{ color: '#fff' }}>
              {' '}
              <Clock />
            </Typography.Text>
            <div className="user-info" style={{ color: '#fff' }}>
              <Row gutter={[8, 8]} justify="space-between">
                <Col>Username</Col>
                <Col>{user.username}</Col>
              </Row>
              <Row gutter={[8, 8]} justify="space-between">
                <Col>Role</Col>
                <Col>{ROLE_RENDER[user.role]}</Col>
              </Row>
              <Row gutter={[8, 8]} justify="space-between">
                <Col>Balance</Col>
                <Col>
                  <Tooltip
                    title={
                      <Space direction="vertical">
                        <div className="text-center">
                          คลิกเพื่ออัปเดต <SyncOutlined />
                        </div>
                        <div>
                          {' '}
                          อัปเดตล่าสุด <ClockCircleOutlined />{' '}
                          {DATE_FORMAT.timeonly(user.lastUpdate)}
                        </div>
                      </Space>
                    }
                  >
                    <div className="cursor-pointer">
                      {balanceLoading ? (
                        <SyncOutlined spin />
                      ) : (
                        <span onClick={updateBalance}>
                          {userAction.getBalance()}
                        </span>
                      )}
                    </div>
                  </Tooltip>
                </Col>
              </Row>
              {/* if agent */}
              {userAction.isAgent() && (
                <Row gutter={[8, 8]} justify="space-between">
                  <Col>Member</Col>
                  <Col>{user.downline ?? 0}</Col>
                </Row>
              )}

              <Divider style={{ backgroundColor: '#0d263b' }} />
              <Row justify="space-between">
                <Col>
                  <Link to={MENU_ITEMS.profile.path}>
                    {MENU_ITEMS.profile.icon} {MENU_ITEMS.profile.label}
                  </Link>
                </Col>
                <Col>
                  <Typography.Link
                    onClick={() => {
                      logout();
                    }}
                  >
                    {MENU_ITEMS.logout.icon} {MENU_ITEMS.logout.label}
                  </Typography.Link>
                </Col>
              </Row>
            </div>
          </div>

          <Divider style={{ backgroundColor: '#0d263b' }} />

          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={selectedKey}
            items={menu()}
          ></Menu>
        </>
      )}
    </div>
  );
};

export default Sider;
