import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import { BrowserRouter } from "react-router-dom";

import { ConfigProvider } from 'antd';
import th_TH from 'antd/es/locale/th_TH'
import { UserProvider } from './contexts/UserContext.js'


import dayjs from 'dayjs';
import 'dayjs/locale/th'
dayjs.locale('th')

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <ConfigProvider locale={th_TH}>
    <BrowserRouter>
      <UserProvider>
          <App />
      </UserProvider>
    </BrowserRouter>
  </ConfigProvider>,
);