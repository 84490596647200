import React, { useState, useEffect } from 'react';
import { Spin, Typography, Table, Tabs, Breadcrumb, Checkbox, Empty } from 'antd';
import { ELM_STANDARD, MENU_ITEMS, ROLE_RENDER } from '../../../util/options';
import AgentService from '../../../services/user/agent.service';
import { useUserContext } from '../../../contexts/UserContext';
import { DB, openNotification } from '../../../util/constant';
import { groupBy } from 'lodash';


const ReceiveList = () => {
    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([])
    const [breadcrumbs, setBreadcrumbs] = useState([])
    const { user, userAction } = useUserContext();
    const [nameIsShow, setNameIsShow] = useState(false)

    const changeUser = async (param) => {
        if (param.id === breadcrumbs[breadcrumbs.length - 1].id) {
            openNotification('info', `ระบบได้แสดงผลสมาชิก ${param.username} อยู่แล้ว`, '', 'bottomLeft')
            return
        }

        try {
            setLoading(true)
            const res = await AgentService.getDownlineRate(param.id)
            setList(await processData(res.list))
            let index = breadcrumbs.findIndex(b => b.id === param.id)

            if (index === -1) {
                const bc = [...breadcrumbs]
                bc.push(param)
                setBreadcrumbs([...bc])
            } else {
                let newArr = JSON.parse(JSON.stringify(breadcrumbs));
                newArr.splice(index + 1)
                setBreadcrumbs(newArr)
            }
            setLoading(false)
        } catch (err) {
            setLoading(false)
        }
    }

    useEffect(() => {
        prepareData();
    }, [])

    const prepareData = async () => {
        const res = await AgentService.getDownlineRate()
        setList(await processData(res.list))
        setBreadcrumbs([{ id: user.id, username: user.username }])
        setLoading(false)
    }


    const processData = async (data) => {
        const groupedData = groupBy(data, 'lotto_groups_fk')
        return groupedData
    }


    return (
        <div className='agent-page-group receive-list-page'>
            <Spin {...ELM_STANDARD.spin} spinning={loading}>
                <div className='page-title'>
                    <Typography className='title'>{MENU_ITEMS.userReceive.label}</Typography>
                </div>

                <div className='page-content-wrapper'>
                    <div className='page-content'>
                        <div className='breadcrumb-styled'>
                            <Breadcrumb separator=">" className='unselectable'>
                                {breadcrumbs.map((item) =>
                                    <Breadcrumb.Item key={item.id}
                                        onClick={() => changeUser({ id: item.id, username: item.username })}>{item.username}</Breadcrumb.Item>
                                )}
                            </Breadcrumb>
                        </div>


                        <Tabs defaultActiveKey={Object.keys(list)?.[0]}>
                            {Object.entries(list)?.map(([key, item]) => {
                                return <Tabs.TabPane tab={DB.SYSTEM.LOTTO.GROUP.find((g) => g.id == key)?.name} key={key}>

                                    <Table
                                        scroll={{ x: 'max-content' }}
                                        rowKey={(record) => record.id}
                                        pagination={ELM_STANDARD.pagination}
                                        dataSource={item}
                                        columns={[
                                            {
                                                title: 'ลำดับ',
                                                dataIndex: 'i',
                                                key: 'i',
                                                align: 'center',
                                                render: (text, record, index) => {
                                                    return index + 1
                                                },
                                            },
                                            {
                                                title: <div>
                                                    <div>ชื่อผู้ใช้</div>
                                                    <div><Checkbox checked={nameIsShow} onChange={() => setNameIsShow(!nameIsShow)}>แสดงชื่อ</Checkbox></div>
                                                </div>,
                                                dataIndex: 'username',
                                                key: 'username',
                                                render (value, record) {
                                                    const name = nameIsShow && <span className='text-gray'>({record.name})</span>
                                                    return record.role === DB.USER.ROLE.MEMBER ?
                                                        <Typography>{value} {name}</Typography>
                                                        :
                                                        <Typography.Link onClick={() => changeUser({ id: record.users_fk, username: record.username })}>{value} {name}</Typography.Link>
                                                },
                                            },
                                            {
                                                title: 'ระดับ',
                                                dataIndex: 'role',
                                                key: 'role',
                                                align: 'center',
                                                render (value) {
                                                    return ROLE_RENDER[value]
                                                },
                                            },
                                            {
                                                title: '3 ตัวบน',
                                                dataIndex: 'threeUp',
                                                key: 'threeUp',
                                                align: 'center',
                                                render: (text, record, index) => {
                                                    const rate = record.rate.find((r) => r.id === DB.SYSTEM.LOTTO.BET_MODE_TO_ID['3 ตัวบน'])
                                                    return rate?.holdAmount ?? 0
                                                }
                                            },
                                            {
                                                title: '3 ตัวล่าง',
                                                dataIndex: 'threeDown',
                                                key: 'threeDown',
                                                align: 'center',
                                                render: (text, record, index) => {
                                                    const rate = record.rate.find((r) => r.id === DB.SYSTEM.LOTTO.BET_MODE_TO_ID['3 ตัวล่าง'])
                                                    return rate?.holdAmount ?? 0
                                                }
                                            },
                                            {
                                                title: '3 ตัวโต๊ด',
                                                dataIndex: 'threeOdd',
                                                key: 'threeOdd',
                                                align: 'center',
                                                render: (text, record, index) => {
                                                    const rate = record.rate.find((r) => r.id === DB.SYSTEM.LOTTO.BET_MODE_TO_ID['3 ตัวโต๊ด'])
                                                    return rate?.holdAmount ?? 0
                                                }
                                            },
                                            {
                                                title: '2 ตัวบน',
                                                dataIndex: 'twoUp',
                                                key: 'twoUp',
                                                align: 'center',
                                                render: (text, record, index) => {
                                                    const rate = record.rate.find((r) => r.id === DB.SYSTEM.LOTTO.BET_MODE_TO_ID['2 ตัวบน'])
                                                    return rate?.holdAmount ?? 0
                                                }
                                            },
                                            {
                                                title: '2 ตัวล่าง',
                                                dataIndex: 'twoDown',
                                                key: 'twoDown',
                                                align: 'center',
                                                render: (text, record, index) => {
                                                    const rate = record.rate.find((r) => r.id === DB.SYSTEM.LOTTO.BET_MODE_TO_ID['2 ตัวล่าง'])
                                                    return rate?.holdAmount ?? 0
                                                }
                                            },
                                            {
                                                title: 'วิ่งบน',
                                                dataIndex: 'runUp',
                                                key: 'runUp',
                                                align: 'center',
                                                render: (text, record, index) => {
                                                    const rate = record.rate.find((r) => r.id === DB.SYSTEM.LOTTO.BET_MODE_TO_ID['วิ่งบน'])
                                                    return rate?.holdAmount ?? 0
                                                }
                                            },
                                            {
                                                title: 'วิ่งล่าง',
                                                dataIndex: 'runDown',
                                                key: 'runDown',
                                                align: 'center',
                                                render: (text, record, index) => {
                                                    const rate = record.rate.find((r) => r.id === DB.SYSTEM.LOTTO.BET_MODE_TO_ID['วิ่งล่าง'])
                                                    return rate?.holdAmount ?? 0
                                                }
                                            },
                                        ]}
                                    />
                                </Tabs.TabPane>
                            })}
                        </Tabs>

                        {Object.entries(list)?.length === 0 && <Empty />}
                    </div>
                </div>


            </Spin >
        </div >
    )
}

export default ReceiveList;
