import React, { createContext, useContext, useState, useMemo, useEffect } from "react"
import { useNavigate } from 'react-router-dom'

import AuthService from "../services/auth.service.js"
import UserService from "../services/user/user.service.js"

import numeral from 'numeral'
import { DB } from "../util/constant.js"

import dayjs from 'dayjs'
import 'dayjs/locale/th'

const UserContext = createContext({})

export const useUserContext = () => {
    return useContext(UserContext)
}

const initialUser = {
    isPermit: false,
    id: "",
    username: "",
    role: null,
    createdAt: "",
    status: "",
    accessToken: "",
    balance: 0,
    lastUpdate: dayjs(),
    downline: 0,
}

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(initialUser)
    const navigate = useNavigate();

    const updateBalance = async () => {
        const balance = await UserService.getUserBalance()
        setUser(prev => ({ ...prev, balance: balance?.amount, lastUpdate: dayjs() }))

        let user = JSON.parse(window.localStorage.getItem('user'));
        user.balance = balance?.amount
        window.localStorage.setItem('user', JSON.stringify(user));

        return true
    }

    const updateUser = async () => {
        const res = await UserService.getUser()
        setUser({ ...res, accessToken: user.accessToken, lastUpdate: dayjs() })

        window.localStorage.setItem('user', JSON.stringify({ accessToken: user.accessToken, ...res }));
    }

    const isAdmin = () => {
        return user.role === DB.USER.ROLE.ADMIN
    }

    const isAgent = () => {
        return user.role === DB.USER.ROLE.AGENT
    }

    const isMember = () => {
        return user.role === DB.USER.ROLE.MEMBER
    }

    const logout = () => {
        AuthService.logout()
        setUser(initialUser)
        navigate(`/`)
    }


    const getBalance = () => {
        return numeral(user?.balance).format('0,0.00')
    }

    const getUserData = async () => {
        const user = await JSON.parse(window.localStorage.getItem("user"));
        if (user) {
            setUser(user)
            if (user.role != DB.USER.ROLE.ADMIN) {
                updateBalance()
            }
        }
    }

    useEffect(() => {
        getUserData()
    }, [])

    const userStore = useMemo(() => ({
        user,
        userAction: {
            setUser,

            isAdmin,
            isAgent,
            isMember,
            logout,

            updateBalance,
            updateUser,

            getBalance,
        }
    }), [user])


    return (
        <UserContext.Provider value={userStore}>
            {children}
        </UserContext.Provider>
    )
}

export default UserProvider