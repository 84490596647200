import React, { useState, useEffect } from 'react';
import { Spin, Typography, Table, Tabs, Form, message, Button, Breadcrumb, Checkbox, Modal, Input, InputNumber, Empty, Tag, Select } from 'antd';
import { ELM_STANDARD, MENU_ITEMS, ROLE_RENDER } from '../../../util/options';
import AgentService from '../../../services/user/agent.service';
import { useUserContext } from '../../../contexts/UserContext';
import { DB, MSG, openNotification, replaceRateByDefault } from '../../../util/constant';
import { CloseOutlined, EditOutlined, SaveOutlined, SettingOutlined } from '@ant-design/icons';
import { groupBy } from 'lodash';


const BoundarySetting = () => {
    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([])
    const [ownRate, setOwnRate] = useState([])
    const [breadcrumbs, setBreadcrumbs] = useState([])

    const { user } = useUserContext();

    const [modalData, setModalData] = useState(null)
    const [modalVisible, setModalVisible] = useState({ edit: false });
    const [isModalLoading, setIsModalLoading] = useState(false);
    const [editForm] = Form.useForm();

    const [nameIsShow, setNameIsShow] = useState(false)
    const [activeMode, setActiveMode] = useState('min')

    const initialBulkEditValues = {
        [DB.SYSTEM.LOTTO.BET_MODE_TO_ID['3 ตัวบน']]: null,
        [DB.SYSTEM.LOTTO.BET_MODE_TO_ID['3 ตัวล่าง']]: null,
        [DB.SYSTEM.LOTTO.BET_MODE_TO_ID['3 ตัวโต๊ด']]: null,
        [DB.SYSTEM.LOTTO.BET_MODE_TO_ID['2 ตัวบน']]: null,
        [DB.SYSTEM.LOTTO.BET_MODE_TO_ID['2 ตัวล่าง']]: null,
        [DB.SYSTEM.LOTTO.BET_MODE_TO_ID['วิ่งบน']]: null,
        [DB.SYSTEM.LOTTO.BET_MODE_TO_ID['วิ่งล่าง']]: null,
    }

    const [bulkEditValues, setBulkEditValues] = useState({ ...initialBulkEditValues })
    const [bulkEditSelectedLotto, setBulkEditSelectedLotto] = useState([])
    const [checkedList, setCheckedList] = useState([])
    const handleCheckboxChange = id => (e) => {
        setCheckedList(e.target.checked ? [...checkedList, id] : checkedList.filter(item => item !== id))
    }
    const resetSelection = () => {
        setCheckedList([])
        setBulkEditSelectedLotto([])
        setBulkEditValues({ ...initialBulkEditValues })
    }

    const submitBulkEdit = async (type) => {
        if (breadcrumbs.length > 1) {
            message.error(MSG.COMMON.VALIDATE.DIRECT_DOWNLINE_ONLY)
            return
        }
        try {
            setLoading(true)
            const send = await ActionsService['bulkEdit']({ type, values: bulkEditValues, lotto_groups_fk: bulkEditSelectedLotto, userId: checkedList })
            setLoading(false)
            if (send === `OK`) {
                resetSelection()
                message.success(MSG.COMMON.UPDATE.SUCCEED)
                setLoading(true)
                prepareData();
            } else {
                message.error(MSG.COMMON.VALIDATE.FAILED)
            }
            setLoading(false);
        } catch (err) {
            message.error(MSG.COMMON.VALIDATE.FAILED)
            setLoading(false);
        }
    }

    const forms = { edit: editForm };
    const ActionsService = {
        edit: AgentService.updateDownlineRate,
        bulkEdit: AgentService.bulkUpdateDownlineBoundary
    }

    const modalProps = (param) => {
        return {
            visible: modalVisible[param],
            footer: [
                <Button key="back" onClick={() => closeModal(param)} icon={<CloseOutlined />}>ยกเลิก</Button>,
                <Button type="primary" key="submit" onClick={() => submit(param)} icon={<SaveOutlined />}>บันทึก</Button>
            ],
            onOk: () => submit(param),
            onCancel: () => closeModal(param),
            forceRender: true,
        }
    }

    const formProps = (param) => {
        return {
            form: forms[param],
            autoComplete: "new-password",
            initialValues: modalData
        }
    }

    const openEditModal = async (record) => {
        setModalData(await replaceRateByDefault(record))
        setModalVisible({ edit: true })
    }

    const submit = async (param) => {
        try {

            setIsModalLoading(true)
            const send = await ActionsService[param]({ ...forms[param].getFieldsValue(), userId: modalData.users_fk, lotto_groups_fk: modalData.lotto_groups_fk })
            setIsModalLoading(false)
            if (send === `OK`) {
                forms[param].resetFields()
                setModalVisible({ ...modalVisible, [param]: false })
                message.success(MSG.COMMON.UPDATE.SUCCEED)
                setLoading(true)
                prepareData();
            } else if (send === `HAS_DOWNLINE_RATE_HIGHER_THAN_THIS`) {
                message.error(`อัปเดตข้อมูลไม่สำเร็จ มีลูกสายที่มีอัตราการแบ่งหุ้นมากกว่าที่คุณกำหนด`)
            } else {
                message.error(MSG.COMMON.VALIDATE.FAILED)
            }


            setLoading(false);
        } catch (err) {
            message.error(MSG.COMMON.VALIDATE.FAILED)
            setLoading(false);
        }
    }


    const closeModal = (param) => {
        setModalVisible({ ...modalVisible, [param]: false });
        setModalData(null);
        forms[param].resetFields()
    }

    const changeUser = async (param) => {
        if (param.id === breadcrumbs[breadcrumbs.length - 1].id) {
            openNotification('info', `ระบบได้แสดงผลสมาชิก ${param.username} อยู่แล้ว`, '', 'bottomLeft')
            return
        }

        try {
            resetSelection()
            setLoading(true)
            const res = await AgentService.getDownlineRate(param.id)
            setList(await processData(res.list))
            setOwnRate(res.ownRate)
            let index = breadcrumbs.findIndex(b => b.id === param.id)

            if (index === -1) {
                const bc = [...breadcrumbs]
                bc.push(param)
                setBreadcrumbs([...bc])
            } else {
                let newArr = JSON.parse(JSON.stringify(breadcrumbs));
                newArr.splice(index + 1)
                setBreadcrumbs(newArr)
            }
            setLoading(false)
        } catch (err) {
            setLoading(false)
        }
    }

    useEffect(() => {
        prepareData();
    }, [])

    const prepareData = async () => {
        const res = await AgentService.getDownlineRate()
        setList(await processData(res.list))
        setOwnRate(res.ownRate)
        setBreadcrumbs([{ id: user.id, username: user.username }])
        setLoading(false)
    }

    useEffect(() => {
        forms.edit.setFieldsValue(modalData)
    }, [forms.edit, modalData])

    const processData = async (data) => {
        const groupedData = groupBy(data, 'lotto_groups_fk')
        return groupedData
    }


    const renderTable = (type, tableData) => {
        const defaultValue = type === 'min' ? 1 : 100000
        return <Table
            scroll={{ x: 'max-content' }}
            rowKey={(record) => record.id}
            pagination={ELM_STANDARD.pagination}
            dataSource={tableData}
            columns={[
                {
                    title: 'ลำดับ',
                    dataIndex: 'i',
                    key: 'i',
                    align: 'center',
                    render: (text, record, index) => {
                        return index + 1
                    },
                },
                {
                    title: <div>
                        <div>ชื่อผู้ใช้</div>
                        <div><Checkbox checked={nameIsShow} onChange={() => setNameIsShow(!nameIsShow)}>แสดงชื่อ</Checkbox></div>
                    </div>,
                    dataIndex: 'username',
                    key: 'username',
                    render (value, record) {
                        const name = nameIsShow && <span className='text-gray'>({record.name})</span>
                        return record.role === DB.USER.ROLE.MEMBER ?
                            <Typography>{value} {name}</Typography>
                            :
                            <Typography.Link onClick={() => changeUser({ id: record.users_fk, username: record.username })}>{value} {name}</Typography.Link>
                    },
                },
                {
                    title: 'ระดับ',
                    dataIndex: 'role',
                    key: 'role',
                    align: 'center',
                    render (value) {
                        return ROLE_RENDER[value]
                    },
                },
                {
                    title: 'ตัวเลือกการตั้งค่า',
                    dataIndex: 'users_fk',
                    key: 'users_fk',
                    align: 'center',
                    render: (value, record) => {
                        return <Checkbox disabled={breadcrumbs?.length > 1} checked={checkedList.findIndex(item => item === record.users_fk) !== -1} onChange={handleCheckboxChange(record.users_fk)}></Checkbox>
                    }
                },
                {
                    title: '3 ตัวบน',
                    dataIndex: 'threeUp',
                    key: 'threeUp',
                    align: 'center',
                    render: (text, record, index) => {
                        const rate = record.rate.find((r) => r.id === DB.SYSTEM.LOTTO.BET_MODE_TO_ID['3 ตัวบน'])
                        return rate ? `${rate[type]}` : defaultValue
                    }
                },
                {
                    title: '3 ตัวล่าง',
                    dataIndex: 'threeDown',
                    key: 'threeDown',
                    align: 'center',
                    render: (text, record, index) => {
                        const rate = record.rate.find((r) => r.id === DB.SYSTEM.LOTTO.BET_MODE_TO_ID['3 ตัวล่าง'])
                        return rate ? `${rate[type]}` : defaultValue
                    }
                },
                {
                    title: '3 ตัวโต๊ด',
                    dataIndex: 'threeOdd',
                    key: 'threeOdd',
                    align: 'center',
                    render: (text, record, index) => {
                        const rate = record.rate.find((r) => r.id === DB.SYSTEM.LOTTO.BET_MODE_TO_ID['3 ตัวโต๊ด'])
                        return rate ? `${rate[type]}` : defaultValue
                    }
                },
                {
                    title: '2 ตัวบน',
                    dataIndex: 'twoUp',
                    key: 'twoUp',
                    align: 'center',
                    render: (text, record, index) => {
                        const rate = record.rate.find((r) => r.id === DB.SYSTEM.LOTTO.BET_MODE_TO_ID['2 ตัวบน'])
                        return rate ? `${rate[type]}` : defaultValue
                    }
                },
                {
                    title: '2 ตัวล่าง',
                    dataIndex: 'twoDown',
                    key: 'twoDown',
                    align: 'center',
                    render: (text, record, index) => {
                        const rate = record.rate.find((r) => r.id === DB.SYSTEM.LOTTO.BET_MODE_TO_ID['2 ตัวล่าง'])
                        return rate ? `${rate[type]}` : defaultValue
                    }
                },
                {
                    title: 'วิ่งบน',
                    dataIndex: 'runUp',
                    key: 'runUp',
                    align: 'center',
                    render: (text, record, index) => {
                        const rate = record.rate.find((r) => r.id === DB.SYSTEM.LOTTO.BET_MODE_TO_ID['วิ่งบน'])
                        return rate ? `${rate[type]}` : defaultValue
                    }
                },
                {
                    title: 'วิ่งล่าง',
                    dataIndex: 'runDown',
                    key: 'runDown',
                    align: 'center',
                    render: (text, record, index) => {
                        const rate = record.rate.find((r) => r.id === DB.SYSTEM.LOTTO.BET_MODE_TO_ID['วิ่งล่าง'])
                        return rate ? `${rate[type]}` : defaultValue
                    }
                },
                {
                    title: 'Actions',
                    dataIndex: 'name',
                    key: 'name',
                    align: 'center',
                    render (value, record) {
                        return <Button disabled={breadcrumbs.length > 1} icon={<EditOutlined />} onClick={() => openEditModal(record)}>แก้ไข</Button>
                    }
                },
            ]}
            sticky
            summary={() => {
                return (
                    <Table.Summary fixed={'top'}>
                        <Table.Summary.Row className='bg-blue' style={{ fontWeight: 'bold' }}>
                            <Table.Summary.Cell colSpan={3}>
                                <Select
                                    mode="multiple"
                                    allowClear
                                    className="w-100"
                                    placeholder="เลือกหวย"
                                    value={bulkEditSelectedLotto}
                                    onChange={(value) => setBulkEditSelectedLotto(value)}
                                    placement="topLeft"
                                >
                                    {ownRate.map((item, i) => {
                                        return <Select.Option key={item.id} value={item.id}>{item.id}. {item.name}</Select.Option>
                                    })}
                                </Select>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell className='text-center'>
                                <Checkbox disabled={breadcrumbs?.length > 1}
                                    onChange={(e) => e.target.checked ? setCheckedList(tableData.map(i => i.users_fk)) : setCheckedList([])}></Checkbox>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell className='text-center'><InputNumber min={1} size={'small'} value={bulkEditValues[DB.SYSTEM.LOTTO.BET_MODE_TO_ID['3 ตัวบน']]} onChange={(value) => setBulkEditValues({ ...bulkEditValues, [DB.SYSTEM.LOTTO.BET_MODE_TO_ID['3 ตัวบน']]: value })} /></Table.Summary.Cell>
                            <Table.Summary.Cell className='text-center'><InputNumber min={1} size={'small'} value={bulkEditValues[DB.SYSTEM.LOTTO.BET_MODE_TO_ID['3 ตัวล่าง']]} onChange={(value) => setBulkEditValues({ ...bulkEditValues, [DB.SYSTEM.LOTTO.BET_MODE_TO_ID['3 ตัวล่าง']]: value })} /></Table.Summary.Cell>
                            <Table.Summary.Cell className='text-center'><InputNumber min={1} size={'small'} value={bulkEditValues[DB.SYSTEM.LOTTO.BET_MODE_TO_ID['3 ตัวโต๊ด']]} onChange={(value) => setBulkEditValues({ ...bulkEditValues, [DB.SYSTEM.LOTTO.BET_MODE_TO_ID['3 ตัวโต๊ด']]: value })} /></Table.Summary.Cell>
                            <Table.Summary.Cell className='text-center'><InputNumber min={1} size={'small'} value={bulkEditValues[DB.SYSTEM.LOTTO.BET_MODE_TO_ID['2 ตัวบน']]} onChange={(value) => setBulkEditValues({ ...bulkEditValues, [DB.SYSTEM.LOTTO.BET_MODE_TO_ID['2 ตัวบน']]: value })} /></Table.Summary.Cell>
                            <Table.Summary.Cell className='text-center'><InputNumber min={1} size={'small'} value={bulkEditValues[DB.SYSTEM.LOTTO.BET_MODE_TO_ID['2 ตัวล่าง']]} onChange={(value) => setBulkEditValues({ ...bulkEditValues, [DB.SYSTEM.LOTTO.BET_MODE_TO_ID['2 ตัวล่าง']]: value })} /></Table.Summary.Cell>
                            <Table.Summary.Cell className='text-center'><InputNumber min={1} size={'small'} value={bulkEditValues[DB.SYSTEM.LOTTO.BET_MODE_TO_ID['วิ่งบน']]} onChange={(value) => setBulkEditValues({ ...bulkEditValues, [DB.SYSTEM.LOTTO.BET_MODE_TO_ID['วิ่งบน']]: value })} /></Table.Summary.Cell>
                            <Table.Summary.Cell className='text-center'><InputNumber min={1} size={'small'} value={bulkEditValues[DB.SYSTEM.LOTTO.BET_MODE_TO_ID['วิ่งล่าง']]} onChange={(value) => setBulkEditValues({ ...bulkEditValues, [DB.SYSTEM.LOTTO.BET_MODE_TO_ID['วิ่งล่าง']]: value })} /></Table.Summary.Cell>
                            <Table.Summary.Cell className='text-center'><Button type='primary' icon={<EditOutlined />}
                                onClick={() => submitBulkEdit(type)}
                                disabled={!checkedList.length || !bulkEditSelectedLotto.length}
                            >บันทึก</Button></Table.Summary.Cell>
                        </Table.Summary.Row>
                    </Table.Summary>
                );
            }}
        />
    }


    return (
        <div className='agent-page-group my-setting-page'>
            <Spin {...ELM_STANDARD.spin} spinning={loading}>
                <div className='page-title'>
                    <Typography className='title'>{MENU_ITEMS.boundarySetting.label}</Typography>
                </div>

                <div className='page-content-wrapper'>
                    <div className='page-content'>
                        <div className='breadcrumb-styled'>
                            <Breadcrumb separator=">" className='unselectable'>
                                {breadcrumbs.map((item) =>
                                    <Breadcrumb.Item key={item.id}
                                        onClick={() => changeUser({ id: item.id, username: item.username })}>{item.username}</Breadcrumb.Item>
                                )}
                            </Breadcrumb>
                        </div>


                        <Tabs defaultActiveKey={Object.keys(list)?.[0]} onChange={() => resetSelection()}>
                            {Object.entries(list)?.map(([key, item]) => {
                                return <Tabs.TabPane tab={DB.SYSTEM.LOTTO.GROUP.find((g) => g.id == key)?.name} key={key}>
                                    <Tabs centered defaultActiveKey='min' activeKey={activeMode} onChange={(key) => { resetSelection(); setActiveMode(key) }} type="card" >
                                        <Tabs.TabPane tab={`ขั้นต่ำ`} key={`min`}>
                                            {renderTable('min', item)}
                                        </Tabs.TabPane>
                                        <Tabs.TabPane tab={`สูงสุด`} key={`max`}>
                                            {renderTable('max', item)}
                                        </Tabs.TabPane>
                                    </Tabs>

                                </Tabs.TabPane>
                            })}
                        </Tabs>

                        {Object.entries(list)?.length === 0 && <Empty />}


                    </div>
                </div>

                <Modal title={<span><SettingOutlined /> ตั้งค่า{MENU_ITEMS.boundarySetting.label}</span>}
                    {...modalProps('edit')}>
                    <Spin  {...ELM_STANDARD.spin} spinning={isModalLoading}>
                        <Tag className='my-1'>ตั้งค่าให้ผู้ใช้ {modalData?.username}</Tag>
                        <Form
                            {...formProps('edit')}>
                            <Table
                                scroll={{ x: 'max-content' }}
                                size={'small'}
                                rowKey={(record) => record.id}
                                pagination={false}
                                dataSource={modalData?.rate}
                                columns={[
                                    {
                                        title: 'ประเภท',
                                        dataIndex: 'name',
                                        key: 'name',
                                    },
                                    {
                                        title: 'ขั้นต่ำ',
                                        dataIndex: 'min',
                                        key: 'min',
                                        align: 'center',
                                        render: (value, record, i) => {
                                            return <Form.Item name={['rate', i, 'min']} style={{ marginBottom: 0 }}>
                                                <InputNumber min={1} max={100000} />
                                            </Form.Item>

                                        }
                                    },
                                    {
                                        title: 'สูงสุด',
                                        dataIndex: 'max',
                                        key: 'max',
                                        align: 'center',
                                        render: (value, record, i) => {
                                            return <> <Form.Item name={['rate', i, 'max']} style={{ marginBottom: 0 }}>
                                                <InputNumber min={1} max={100000} />
                                            </Form.Item>
                                                <Form.Item name={['rate', i, 'discount']} style={{ display: 'none' }}>
                                                    <InputNumber min={0} max={100} size={'small'} hidden />
                                                </Form.Item>
                                                <Form.Item name={['rate', i, 'rate']} style={{ display: 'none' }}  >
                                                    <InputNumber min={0} size={'small'} hidden />
                                                </Form.Item>
                                                <Form.Item name={['rate', i, 'id']} style={{ display: 'none' }}>
                                                    <Input type="hidden" />
                                                </Form.Item>
                                                <Form.Item name={['rate', i, 'holdAmount']} style={{ display: 'none' }}>
                                                    <InputNumber />
                                                </Form.Item>
                                            </>
                                        }
                                    },

                                ]}
                            />
                        </Form>
                    </Spin>
                </Modal>
            </Spin >
        </div>
    )
}

export default BoundarySetting;
