import { get, post } from "../auth.service"
import { authHeader } from "../auth-header";
import { API_URL } from "../../util/constant"

const URL = API_URL + 'order/';

const submitOrder = async (values) => {
    return await post(URL + 'add', values, { headers: await authHeader() });
}

const cancelOrder = async (id) => {
    return await post(URL + 'cancel', { id }, { headers: await authHeader() });
}

const getRecentOrders = async () => {
    return await get(URL + 'recent', { headers: await authHeader() });
}

const getOrders = async (values) => {
    return await post(URL + '', values, { headers: await authHeader() });
}

const OrderService = {
    submitOrder,
    cancelOrder,
    getRecentOrders,
    getOrders
}

export default OrderService