import React, { useState, useEffect } from 'react';
import { Button, Typography, Row, Col, Card, Table, DatePicker, Select, Checkbox } from 'antd';
import { MENU_ITEMS } from '../../../util/options';
import { SearchOutlined } from '@ant-design/icons';
import { DATE_FORMAT, DB, ranges } from '../../../util/constant';

import AgentService from '../../../services/user/agent.service';
import moment from 'moment';
import 'moment/locale/th';
import dayjs from 'dayjs';

moment.updateLocale('th', {
    week: {
        dow: 1
    }
})

const { RangePicker } = DatePicker;
const ReceiveSettingHistory = () => {
    const [loadingResult, setLoadingResult] = useState(false)
    const [data, setData] = useState([])
    const [users, setUsers] = useState([])
    const [dateRange, setDateRange] = useState({ from: moment().format('YYYY-MM-DD'), to: moment().format('YYYY-MM-DD') })
    const [dateValues, setDateValues] = useState([moment(), moment()])
    const [displayInfo, setDisplayInfo] = useState({
        diff: dayjs().diff(dayjs(), 'day') + 1,
        from: DATE_FORMAT.dateonly(),
        to: DATE_FORMAT.dateonly(),
    })


    const [nameIsShow, setNameIsShow] = useState(false)

    const [selectedUser, setSelectedUser] = useState(null)


    const onRangeChange = (dates, dateStrings) => {
        if (!dates) return
        let dateObj = {
            "from": dateStrings[0],
            "to": dateStrings[1],
        }
        setDateValues(dates)
        setDateRange(dateObj)
    }

    const search = async () => {
        setLoadingResult(true)
        setDisplayInfo({
            diff: dayjs(dateRange.to).diff(dateRange.from, 'day') + 1,
            from: DATE_FORMAT.dateonly(dateRange.from),
            to: DATE_FORMAT.dateonly(dateRange.to),
        })
        const data = await AgentService.downlineReceiveSettingHistory({ userId: selectedUser, dateRange: dateRange })
        setData(data)
        setLoadingResult(false)
    }

    useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        setLoadingResult(true);
        const res = await AgentService.downlineReceiveSettingHistory({ userId: null, dateRange: dateRange })
        const users = await AgentService.listUserAll();
        setData(res)
        setUsers(users)
        setLoadingResult(false);
    }



    return (
        <div className='agent-page-group receive-setting-history-page'>
            <div className='page-title'>
                <Typography className='title'>{MENU_ITEMS.receiveSettingHistory.label}</Typography>
            </div>

            <div className='page-content-wrapper'>
                <div className='page-content'>
                    <div className="search-section">
                        <Card title="ตัวเลือกการค้นหา" size="small">
                            <Row gutter={[16, 16]} justify='start'>
                                <Col xs={12}>
                                    <Row gutter={[16, 16]} justify="left">
                                        <Col>
                                            <RangePicker
                                                ranges={{ ...ranges }}
                                                onChange={onRangeChange}
                                                value={dateValues}
                                                allowEmpty={[false, false]}
                                                allowClear={false}
                                            />
                                        </Col>
                                        <Col>
                                            <Select
                                                allowClear
                                                showSearch
                                                style={{ width: 200, maxWidth: '30vw' }}
                                                placeholder="ค้นหาสมาชิก"
                                                optionFilterProp="children"
                                                onChange={setSelectedUser}
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                            >
                                                {users.map((user) =>
                                                    <Select.Option key={user.id} value={user.id}>{`${user.username} - [${user.name}]`}</Select.Option>
                                                )}
                                            </Select>
                                        </Col>
                                        <Col><Button loading={loadingResult} type="primary" onClick={search}><SearchOutlined /> ค้นหา</Button></Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card>
                    </div>

                    <div className='displayInfo-section'>
                        <div>ข้อมูลรวม <span className='text-blue'>{displayInfo.diff}</span> วัน
                            ตั้งแต่วันที่ <span className='text-blue'>{displayInfo.from}</span> ถึง <span className='text-blue'>{displayInfo.to}</span></div>
                    </div>

                    <div className="table-wrapper my-2">
                        <Typography.Title level={5}>รวมทั้งหมด</Typography.Title>
                        <Table
                            loading={loadingResult}
                            bordered
                            size='small'
                            rowKey={record => record.id}
                            dataSource={data}
                            scroll={{ x: 'max-content' }}
                            columns={[
                                {
                                    title: '#',
                                    dataIndex: 'index',
                                    key: 'index',
                                    align: 'center',
                                    render (value, record, i) {
                                        return i + 1
                                    },
                                },
                                {
                                    title: 'เวลา',
                                    dataIndex: 'createdAt',
                                    key: 'createdAt',
                                    align: 'center',
                                    render (value) {
                                        return DATE_FORMAT.default(value)
                                    },
                                },
                                {
                                    title: <div><Checkbox checked={nameIsShow} onChange={() => setNameIsShow(!nameIsShow)}>แสดงชื่อ</Checkbox></div>,
                                    children: [
                                        {
                                            title: "ผู้ตั้งค่า",
                                            dataIndex: 'username',
                                            key: 'username',
                                            render (value, record) {
                                                const name = nameIsShow && <span className='text-gray'>({record.name})</span>
                                                return <Typography>{value} {name}</Typography>
                                            },
                                        },
                                        {
                                            title: "ผู้ถูกตั้งค่า",
                                            dataIndex: 'targetUsername',
                                            key: 'targetUsername',
                                            render (value, record) {
                                                const name = nameIsShow && <span className='text-gray'>({record.targetName})</span>
                                                return <Typography>{value} {name}</Typography>
                                            },
                                        },
                                    ]
                                },
                                {
                                    title: 'ชื่อฟังก์ชัน',
                                    dataIndex: 'remark',
                                    key: 'remark',
                                    align: 'center',
                                },
                                {
                                    title: 'กลุ่มหวย',
                                    dataIndex: 'lotto_groups_fk',
                                    key: 'lotto_groups_fk',
                                    align: 'center',
                                    render: value => {
                                        let value2 = JSON.parse(value)
                                        if (Array.isArray(value2)) {
                                            return value2.map(v => (DB.SYSTEM.LOTTO.GROUP.find((g) => g.id == v))?.name).join(', ')
                                        }
                                        return `${(DB.SYSTEM.LOTTO.GROUP.find((g) => g.id == value))?.name}`
                                    }
                                },
                                {
                                    title: 'สถานะ',
                                    dataIndex: 'type',
                                    key: 'type',
                                    align: 'center',
                                },
                                {
                                    title: 'ip address',
                                    dataIndex: 'ip_address',
                                    key: 'ip_address',
                                    align: 'center',
                                },
                                {
                                    title: 'รายละเอียด',
                                    dataIndex: 'details',
                                    key: 'details',
                                    ellipsis: {
                                        showTitle: false,
                                    },
                                    render: value => (
                                        <Typography.Paragraph
                                            style={{ width: '200px', whiteSpace: 'normal' }}
                                            copyable={{ text: JSON.stringify(value.body) }}
                                            ellipsis={{ rows: 1, expandable: true, symbol: 'ดูรายละเอียด' }} >
                                            <div><pre>{JSON.stringify(value.body, null, 2)}</pre></div>
                                        </Typography.Paragraph >

                                    ),
                                },
                            ]}
                            className="table-elm shadowed"
                            pagination={false}
                        />
                    </div>
                </div>
            </div>


        </div>
    )
}

export default ReceiveSettingHistory;