import { get, post } from "../auth.service"
import { authHeader } from "../auth-header";
import { API_URL } from "../../util/constant"

const URL = API_URL + 'member/';

const getMarkets = async () => {
    return get(URL + 'markets', { headers: await authHeader() });
}

const getLottoToBet = async (lottoDateId) => {
    return post(URL + 'lotto/bet', { lottoDateId }, { headers: await authHeader() });
}

const getRateByDateId = async (lottoDateId) => {
    return post(URL + 'rate/by-date-id', { lottoDateId }, { headers: await authHeader() });
}

const getTransactions = async (values) => {
    return post(URL + 'transactions/get', values, { headers: await authHeader() });
}

const getWaitReport = async () => {
    return get(URL + 'transactions/wait-report', { headers: await authHeader() });
}

const getWaitReportByLottoDatesFk = async (lotto_dates_fk) => {
    return post(URL + 'transactions/wait-report/by-date', { lotto_dates_fk }, { headers: await authHeader() });
}

const getTransactionsReport = async (values) => {
    return post(URL + 'transactions/report', values, { headers: await authHeader() });
}

const getTransactionsReportByLottoDatesFk = async (values) => {
    return post(URL + 'transactions/report/by-date', values, { headers: await authHeader() });
}


const MemberService = {
    getMarkets,
    getLottoToBet,
    getRateByDateId,
    getTransactions,
    getWaitReport,
    getWaitReportByLottoDatesFk,
    getTransactionsReport,
    getTransactionsReportByLottoDatesFk
}

export default MemberService