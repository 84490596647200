import React, { useState, useEffect } from 'react';
import { Button, Typography, Row, Col, Card, Table, DatePicker, Select, Checkbox, Pagination } from 'antd';
import { MENU_ITEMS } from '../../../util/options';
import { SearchOutlined } from '@ant-design/icons';
import { DATE_FORMAT, DB, NUMBER_FORMAT, ranges } from '../../../util/constant';

import AgentService from '../../../services/user/agent.service';
import moment from 'moment';
import 'moment/locale/th';
import dayjs from 'dayjs';

moment.updateLocale('th', {
    week: {
        dow: 1
    }
})

const { RangePicker } = DatePicker;
const TransferHistory = () => {
    const params = {
        current: 1,
        pageSize: 50,
        pageSizeOptions: ['50', '100', '250', '500', '1000', '2500'],
        dateRange: { from: moment().format('YYYY-MM-DD'), to: moment().format('YYYY-MM-DD') }
    }

    const [loadingResult, setLoadingResult] = useState(false)

    const [data, setData] = useState([])
    const [total, setTotal] = useState(undefined)
    const [users, setUsers] = useState([])

    const [dateRange, setDateRange] = useState({ ...params.dateRange })
    const [dateValues, setDateValues] = useState([moment(), moment()])
    const [displayInfo, setDisplayInfo] = useState({
        diff: dayjs().diff(dayjs(), 'day') + 1,
        from: DATE_FORMAT.dateonly(),
        to: DATE_FORMAT.dateonly(),
    })
    const [pagination, setPagination] = useState({ ...params })


    const [nameIsShow, setNameIsShow] = useState(false)

    const [selectedUser, setSelectedUser] = useState(null)
    const [selectedType, setSelectedType] = useState('all')


    const onRangeChange = (dates, dateStrings) => {
        if (!dates) return
        let dateObj = {
            "from": dateStrings[0],
            "to": dateStrings[1],
        }
        setDateValues(dates)
        setDateRange(dateObj)
    }

    const search = async () => {
        setLoadingResult(true)
        setDisplayInfo({
            diff: dayjs(dateRange.to).diff(dateRange.from, 'day') + 1,
            from: DATE_FORMAT.dateonly(dateRange.from),
            to: DATE_FORMAT.dateonly(dateRange.to),
        })
        const res = await AgentService.downlineTransferHistory({ userId: selectedUser, dateRange: dateRange, type: selectedType, pageSize: pagination.pageSize, current: 1 })
        setPagination({ ...pagination, current: 1 })
        setData(res.results);
        setTotal(res.total)
        setLoadingResult(false)
    }

    const pageChange = (pagination) => {
        setLoadingResult(true)
        AgentService.downlineTransferHistory({ userId: selectedUser, dateRange: dateRange, type: selectedType, ...pagination })
            .then(async res => {
                setPagination({ ...pagination, total: res.total })
                setData(res.results);
                setTotal(res.total)
                setLoadingResult(false)
            })
    }

    useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        setLoadingResult(true);
        const res = await AgentService.downlineTransferHistory({ userId: null, dateRange: dateRange, current: params.current, pageSize: params.pageSize })
        const users = await AgentService.listUserAll();
        setData(res.results);
        setTotal(res.total)
        setUsers(users)
        setLoadingResult(false);
    }



    return (
        <div className='agent-page-group report-transfer-history-page'>
            <div className='page-title'>
                <Typography className='title'>{MENU_ITEMS.memberTransfer.label}</Typography>
            </div>

            <div className='page-content-wrapper'>
                <div className='page-content'>
                    <div className="search-section">
                        <Card title="ตัวเลือกการค้นหา" size="small">
                            <Row gutter={[16, 16]} justify='start'>
                                <Col xs={12}>
                                    <Row gutter={[16, 16]} justify="left">
                                        <Col>
                                            <RangePicker
                                                ranges={{ ...ranges }}
                                                onChange={onRangeChange}
                                                value={dateValues}
                                                allowEmpty={[false, false]}
                                                allowClear={false}
                                            />
                                        </Col>
                                        <Col>
                                            <Select
                                                allowClear
                                                showSearch
                                                style={{ width: 200, maxWidth: '30vw' }}
                                                placeholder="ค้นหาสมาชิก"
                                                optionFilterProp="children"
                                                onChange={setSelectedUser}
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                            >
                                                {users.map((user) =>
                                                    <Select.Option key={user.id} value={user.id}>{`${user.username} - [${user.name}]`}</Select.Option>
                                                )}
                                            </Select>
                                        </Col>
                                        <Col>
                                            <Select
                                                style={{ width: 200, maxWidth: '30vw' }}
                                                placeholder="ประเภท"
                                                onChange={setSelectedType}
                                                value={selectedType}
                                            >
                                                <Select.Option value={'all'}>ทั้งหมด</Select.Option>
                                                {DB.USER.TRANSACTION.AGENT_TYPE_FILTER.map((type) =>
                                                    <Select.Option key={type.key} value={type.key}>{type.title}</Select.Option>
                                                )}
                                            </Select>
                                        </Col>
                                        <Col xs={24}>
                                            <Pagination
                                                disabled={loadingResult}
                                                defaultPageSize={params.pageSizeOptions[0]}
                                                current={pagination.current}
                                                onChange={(page, size) => {
                                                    pageChange({ current: page, pageSize: size })
                                                }} defaultCurrent={1} total={total} showSizeChanger pageSizeOptions={params.pageSizeOptions} />
                                        </Col>
                                        <Col><Button loading={loadingResult} type="primary" onClick={search}><SearchOutlined /> ค้นหา</Button></Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card>
                    </div>

                    <div className='displayInfo-section'>
                        <div>ข้อมูลรวม <span className='text-blue'>{displayInfo.diff}</span> วัน
                            ตั้งแต่วันที่ <span className='text-blue'>{displayInfo.from}</span> ถึง <span className='text-blue'>{displayInfo.to}</span></div>
                        <div>{<span className='text-secondary'>จำนวน {total} รายการ   </span>}</div>
                    </div>

                    <div className="table-wrapper my-2">
                        <Table
                            size='small'
                            rowKey={record => record.id}
                            dataSource={data}
                            scroll={{ x: 'max-content' }}
                            columns={[
                                {
                                    title: '#',
                                    dataIndex: 'index',
                                    key: 'index',
                                    align: 'center',
                                    render (value, record, i) {
                                        return i + 1
                                    },
                                },
                                {
                                    title: <div><Checkbox checked={nameIsShow} onChange={() => setNameIsShow(!nameIsShow)}>แสดงชื่อ</Checkbox></div>,
                                    children: [
                                        {
                                            title: "บัญชีฝาก",
                                            dataIndex: 'username',
                                            key: 'username',
                                            render (value, record) {
                                                const name = nameIsShow && <span className='text-gray'>({record.name})</span>
                                                return <Typography>{value} {name}</Typography>
                                            },
                                        },
                                        {
                                            title: "บัญชีรับ",
                                            dataIndex: 'targetUsername',
                                            key: 'targetUsername',
                                            render (value, record) {
                                                const name = nameIsShow && <span className='text-gray'>({record.targetName})</span>
                                                return <Typography>{value} {name}</Typography>
                                            },
                                        },
                                    ]
                                },
                                {
                                    title: 'คำอธิบาย',
                                    dataIndex: 'details',
                                    key: 'details',
                                    align: 'left',
                                    render: (value, record) => {
                                        const type = DB.USER.TRANSACTION.TYPE_FILTER.find((t) => t.key === record.type)?.title
                                        return `${type} ${record.username} โดย ${record.targetUsername}`
                                    },
                                    filteredValue: (selectedType === "all" ? [DB.USER.TRANSACTION.TYPE.DEPOSIT, DB.USER.TRANSACTION.TYPE.WITHDRAW] : [selectedType]) || null,
                                    onFilter: (value, record) => record.type === value,
                                },
                                {
                                    title: 'วันที่/เวลา',
                                    dataIndex: 'createdAt',
                                    key: 'createdAt',
                                    align: 'center',
                                    render: value => (
                                        DATE_FORMAT.default(value)
                                    )
                                },
                                {
                                    title: 'เงินออก',
                                    dataIndex: 'amount',
                                    key: 'amount',
                                    align: 'right',
                                    render: (value, record) => {
                                        if ([DB.USER.TRANSACTION.TYPE.BET, DB.USER.TRANSACTION.TYPE.WITHDRAW].includes(record.type)) {
                                            return <span className='text-red'>{NUMBER_FORMAT.default(value)}</span>
                                        }
                                        return '-'
                                    }
                                },
                                {
                                    title: 'เงินเข้า',
                                    dataIndex: 'amount',
                                    key: 'amount',
                                    align: 'right',
                                    render: (value, record) => {
                                        if ([DB.USER.TRANSACTION.TYPE.WIN, DB.USER.TRANSACTION.TYPE.DEPOSIT, DB.USER.TRANSACTION.TYPE.CANCELBET].includes(record.type)) {
                                            return <span className='text-green'>{NUMBER_FORMAT.default(value)}</span>
                                        }
                                        return '-'
                                    }
                                },
                                {
                                    title: 'คงเหลือ',
                                    dataIndex: 'balance',
                                    key: 'balance',
                                    align: 'right',
                                    render: (value) => {
                                        return <span className='text-blue'>{NUMBER_FORMAT.default(value)}</span>
                                    }
                                },
                            ]}
                            className="table-elm shadowed"
                            pagination={false}
                            loading={loadingResult}
                        />
                    </div>
                </div>
            </div>


        </div>
    )
}

export default TransferHistory;