import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Row, Col, Card, Empty, Tooltip, Alert, Statistic } from 'antd';
import { ClockCircleOutlined, InfoCircleOutlined, LockOutlined, ScissorOutlined, SyncOutlined } from '@ant-design/icons';
import { ADMIN_INTERVAL_UPDATE, DATE_FORMAT, DB } from '../../util/constant';
import { useLottoParamContext } from '../../contexts/LottoParamContext';

import dayjs from 'dayjs'
import 'dayjs/locale/th'
import isBetween from 'dayjs/plugin/isBetween'
import LottoService from '../../services/system/lotto.service';
import { lotto_date_tag } from '../../util/options';
dayjs.extend(isBetween)


const CommonClosedNumbers = () => {
    const [updatedAt, setUpdatedAt] = useState(null)
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({})

    const { lottoParam, lottoParamAction } = useLottoParamContext();

    useEffect(() => {
        prepareData();
    }, [lottoParam.lottoDate.selected.id])


    const prepareData = async () => {
        if (lottoParam.lottoDate.selected.id) {
            setLoading(true);
            const res = await LottoService.getLottoClosedNumbers(lottoParam.lottoDate.selected.id)
            setData(res)
            setUpdatedAt(dayjs().add(ADMIN_INTERVAL_UPDATE, 'minute'))
            setLoading(false);
        }
    }

    const onVisibilityChange = () => {
        if (document.visibilityState === 'visible' && dayjs().isAfter(updatedAt)) {
            console.log("Tab reopened, refetch the data!");
            prepareData()
        }
    };

    useLayoutEffect(() => {
        document.addEventListener("visibilitychange", onVisibilityChange);
        return () => document.removeEventListener("visibilitychange", onVisibilityChange);
    }, [updatedAt]);

    const renderNumbers = (numbers) => {
        return <Row gutter={[8, 8]}>
            {Object.entries(numbers).map(([key, value]) => {
                return (
                    [<Col xs={12} key={`${key}1`}>{key}</Col>,
                    <Col xs={12} key={`${key}2`}>{Array.isArray(value) ? value.join(', ') : value}</Col>]
                )
            })}
        </Row>
    }

    const renderMarketContent = (m) => {
        let status = {}
        if (m.status === DB.SYSTEM.LOTTO_DATE.STATUS.PENDING) {
            if (dayjs().isBefore(m.openAt)) {
                status.key = 'willActive';
            } else if (dayjs().isBetween(m.openAt, m.closeAt)) {
                status.key = 'active';
            } else if (dayjs().isBetween(m.closeAt, m.postAt)) {
                status.key = 'pending';
            } else {
                status.key = 'late'
            }
        } else if (m.status === DB.SYSTEM.LOTTO_DATE.STATUS.CANCELED) {
            status.key = 'canceled'
        } else {
            status.key = 'inactive'
        }

        return <div className='h-100' >
            <div className={`market-item market-${status.key}`} >
                <Row wrap={false}>
                    <Col flex={1}>
                        <Row justify='space-between' gutter={[4, 4]}>
                            <Col xs={12}><div className="name">{m.name}</div><div className="date">{DATE_FORMAT.dateonly(m.date)}</div></Col>
                            <Col className='text-right' xs={12}>
                                <div className='market-image-wrapper'>
                                    <img className='market-image' src={`../images/lotto/${m.lottos_fk}.png`} alt="" />
                                </div>
                            </Col>
                            <Col xs={12}>สถานะ</Col><Col className='text-right' xs={12}>{lotto_date_tag[m.status]}</Col>
                            <Col xs={12}>เปิด</Col><Col className='text-right' xs={12}>{DATE_FORMAT.thaiHalf(m.openAt)}</Col>
                            <Col xs={12}>ปิด</Col><Col className='text-right' xs={12}>{DATE_FORMAT.thaiHalf(m.closeAt)}</Col>
                            <Col xs={12}>ออกผล</Col><Col className='text-right' xs={12}>{DATE_FORMAT.thaiHalf(m.postAt)}</Col>
                            {status.key === 'willActive' && (
                                <Col xs={24} className="text-right"><Statistic.Countdown prefix="เปิดรับใน" title="" value={m.openAt} /></Col>
                            )}
                            {status.key === 'active' && (
                                <Col xs={24} className="text-right"><Statistic.Countdown prefix="ปิดรับใน" title="" value={m.closeAt} /></Col>
                            )}
                            {status.key === 'pending' && (
                                <Col xs={24} className="text-center"><Alert message="ตลาดปิดรับ กรุณาออกผล" type="warning" showIcon /></Col>
                            )}
                            {status.key === 'late' && (
                                <Col xs={24} className="text-center"><Alert message="เลยเวลาออกผล กรุณาออกผลทันที" type="error" showIcon /></Col>
                            )}
                               <Col xs={12}>แก้ไขเลขอั้นล่าสุด</Col><Col className='text-right' xs={12}>{DATE_FORMAT.relativeTime(m.updatedAt)}</Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div >
    }

    return (
        <div className='common-page-group closed-numbers-page'>
            {lottoParam.lotto.selected.id && (
                <div className='text-secondary text-center font-sarabun cursor-pointer'>
                    <Tooltip placement='top' title={<div className='text-center'>คลิกเพื่ออัปเดต</div>}>
                        <div>
                            {loading ? <SyncOutlined spin />
                                : <span onClick={prepareData}>อัปเดตล่าสุด: <ClockCircleOutlined /> {DATE_FORMAT.timeonly(dayjs(updatedAt).subtract(ADMIN_INTERVAL_UPDATE, 'minute'))}</span>}
                        </div>
                    </Tooltip>
                </div>
            )}

            <div className='page-content-wrapper'>
                <div className='page-content'>
                    <Row gutter={[16, 16]}>
                        <Col xs={12}>
                            <div className='market-wrapper'>
                                <Card title={<><InfoCircleOutlined /> ข้อมูลงวด</>} size='small'>
                                    {lottoParam.lotto.selected.id ? renderMarketContent(data)
                                        : <Alert
                                            style={{ margin: "1rem" }}
                                            description={<div>ยังไม่เลือกกลุ่มหวย</div>}
                                            type="info"
                                            showIcon
                                        />}
                                </Card>
                            </div>
                        </Col>
                        <Col xs={0} xs={12}>
                        </Col>
                        <Col xs={12}>
                            <div className='content-wrapper'>
                                <Card title={
                                    <Row justify='space-between'>
                                        <Col><LockOutlined /> เลขอั้น</Col>
                                    </Row>} size='small'>
                                    {data?.closed ? renderNumbers(data.closed) :
                                        <>
                                            <Empty description="ไม่มีเลขอั้น" />
                                        </>
                                    }
                                </Card>
                            </div>
                        </Col>

                        <Col xs={12}>
                            <div className='content-wrapper'>
                                <Card title={
                                    <Row justify='space-between'>
                                        <Col><ScissorOutlined /> จ่ายครึ่ง</Col>
                                    </Row>} size='small'>
                                    {data?.half ? renderNumbers(data.half) :
                                        <>
                                            <Empty description="ไม่มีเลขจ่ายครึ่ง" />
                                        </>
                                    }
                                </Card>
                            </div>
                        </Col>

                    </Row>
                </div >
            </div >






        </div >
    )
}

export default CommonClosedNumbers;