const buttonFontColor = '#fafafa'

const LOTTO_STYLE_FNC = (color_1, color_2) => ({
    get color () { return color_1 },
    get backgroundColor () { return color_2 },
    get borderColor () { return color_1 },
    get button () {
        return {
            color: buttonFontColor,
            get backgroundColor () { return color_1 },
            get borderColor () { return color_2 },
        }
    }
})

export const LOTTO_STYLE = {
    '8': {
        color_1: 'hsl(282deg 90% 51%)',
        color_2: 'hsl(281deg 92% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
    '19': {
        color_1: 'hsl(282deg 90% 51%)',
        color_2: 'hsl(281deg 92% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
    '12': {
        color_1: 'hsl(282deg 90% 51%)',
        color_2: 'hsl(281deg 92% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
    '15': {
        color_1: 'hsl(217deg 90% 51%)',
        color_2: 'hsl(217deg 92% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
    '16': {
        color_1: 'hsl(217deg 90% 51%)',
        color_2: 'hsl(217deg 92% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
    '17': {
        color_1: 'hsl(217deg 90% 51%)',
        color_2: 'hsl(217deg 92% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
    '29': {
        color_1: 'hsl(290 90% 51%)',
        color_2: 'hsl(290 92% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
    '34': {
        color_1: 'hsl(290 90% 51%)',
        color_2: 'hsl(290 92% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
    '13': {
        color_1: 'hsl(283 90% 51%)',
        color_2: 'hsl(283 92% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
    '14': {
        color_1: 'hsl(283 90% 51%)',
        color_2: 'hsl(283 92% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
    '1': {
        color_1: 'hsl(232 90% 51%)',
        color_2: 'hsl(232 92% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
    '3': {
        color_1: 'hsl(232 90% 51%)',
        color_2: 'hsl(232 92% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
    '11': {
        color_1: 'hsl(232 90% 51%)',
        color_2: 'hsl(232 92% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
    '24': {
        color_1: 'hsl(26 9% 52%)',
        color_2: 'hsl(26 9% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
    '20': {
        color_1: 'hsl(26 9% 52%)',
        color_2: 'hsl(26 9% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
    '43': {
        color_1: 'hsl(309 43% 52%)',
        color_2: 'hsl(309 43% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
    '26': {
        color_1: 'hsl(15 63% 52%)',
        color_2: 'hsl(15 63% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
    '21': {
        color_1: 'hsl(15 63% 52%)',
        color_2: 'hsl(15 63% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
    '31': {
        color_1: 'hsl(29 57% 52%)',
        color_2: 'hsl(29 57% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
    '36': {
        color_1: 'hsl(29 57% 52%)',
        color_2: 'hsl(29 57% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
    '9': {
        color_1: 'hsl(29 57% 52%)',
        color_2: 'hsl(29 57% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
    '42': {
        color_1: 'hsl(19 100% 52%)',
        color_2: 'hsl(19 100% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
    '46': {
        color_1: 'hsl(19 100% 52%)',
        color_2: 'hsl(19 100% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
    '47': {
        color_1: 'hsl(19 100% 52%)',
        color_2: 'hsl(19 100% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
    '32': {
        color_1: 'hsl(148 17% 52%)',
        color_2: 'hsl(148 17% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
    '10': {
        color_1: 'hsl(148 17% 52%)',
        color_2: 'hsl(148 17% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
    '37': {
        color_1: 'hsl(128 46% 52%)',
        color_2: 'hsl(128 46% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
    '27': {
        color_1: 'hsl(128 46% 52%)',
        color_2: 'hsl(128 46% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
    '22': {
        color_1: 'hsl(128 46% 52%)',
        color_2: 'hsl(128 46% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
    '30': {
        color_1: 'hsl(198 50% 52%)',
        color_2: 'hsl(198 50% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
    '41': {
        color_1: 'hsl(198 50% 52%)',
        color_2: 'hsl(198 50% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
    '23': {
        color_1: 'hsl(0 0% 52%)',
        color_2: 'hsl(0 0% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
    '44': {
        color_1: 'hsl(172 46% 52%)',
        color_2: 'hsl(172 46% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
    '48': {
        color_1: 'hsl(172 46% 52%)',
        color_2: 'hsl(172 46% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
    '49': {
        color_1: 'hsl(202 100% 52%)',
        color_2: 'hsl(202 100% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
    '2': {
        color_1: 'hsl(0 87% 52%)',
        color_2: 'hsl(0 87% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
    '38': {
        color_1: 'hsl(0 87% 52%)',
        color_2: 'hsl(0 87% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
    '5': {
        color_1: 'hsl(0 87% 52%)',
        color_2: 'hsl(0 87% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
    '7': {
        color_1: 'hsl(0 87% 52%)',
        color_2: 'hsl(0 87% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
    '18': {
        color_1: 'hsl(0 87% 52%)',
        color_2: 'hsl(0 87% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
    '4': {
        color_1: 'hsl(0 87% 52%)',
        color_2: 'hsl(0 87% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
    '40': {
        color_1: 'hsl(0 87% 52%)',
        color_2: 'hsl(0 87% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
    '45': {
        color_1: 'hsl(0 100% 52%)',
        color_2: 'hsl(0 100% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
    '35': {
        color_1: 'hsl(0 100% 52%)',
        color_2: 'hsl(0 100% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
    '6': {
        color_1: 'hsl(249 100% 52%)',
        color_2: 'hsl(249 100% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
    '39': {
        color_1: 'hsl(249 100% 52%)',
        color_2: 'hsl(249 100% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
    '28': {
        color_1: 'hsl(224 38% 52%)',
        color_2: 'hsl(224 38% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
    '25': {
        color_1: 'hsl(29 32% 52%)',
        color_2: 'hsl(29 32% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
    '33': {
        color_1: 'hsl(312 32% 52%)',
        color_2: 'hsl(312 32% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
    '50': {
        color_1: 'hsl(282deg 90% 51%)',
        color_2: 'hsl(281deg 92% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
    '51': {
        color_1: 'hsl(282deg 90% 51%)',
        color_2: 'hsl(281deg 92% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
    '52': {
        color_1: 'hsl(282deg 90% 51%)',
        color_2: 'hsl(281deg 92% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
    '53': {
        color_1: 'hsl(282deg 90% 51%)',
        color_2: 'hsl(281deg 92% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
    '54': {
        color_1: 'hsl(282deg 90% 51%)',
        color_2: 'hsl(281deg 92% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
    '55': {
        color_1: 'hsl(282deg 90% 51%)',
        color_2: 'hsl(281deg 92% 90%)',
        get details () { return LOTTO_STYLE_FNC(this.color_1, this.color_2) }
    },
}