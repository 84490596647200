import React, { useState, useEffect } from 'react';
import { Spin, Typography, message, Space, Table, Checkbox, Segmented } from 'antd';
import { ELM_STANDARD, lotto_date_tag, MENU_ITEMS, order_tag } from '../../util/options';
import { DATE_FORMAT, DB, MSG, NUMBER_FORMAT } from '../../util/constant';
import AgentService from '../../services/user/agent.service';
import { useLottoParamContext } from '../../contexts/LottoParamContext';
import OrderDetail from '../common/modal/OrderDetail';

const BetResultOrders = () => {
    const { lottoParam } = useLottoParamContext();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([])
    const [tableData, setTableData] = useState([])
    const [nameIsShow, setNameIsShow] = useState(false)
    const [isModalOrderDetailVisible, setIsModalOrderDetailVisible] = useState(false);
    const [modalOrderDetailData, setModalOrderDetailData] = useState({})
    const [segmentValue, setSegmentValue] = useState('ทั้งหมด');

    useEffect(() => {
        setSegmentValue('ทั้งหมด')
        setLoading(true)
        if (lottoParam.lotto.selected.id) {
            if (lottoParam.lottoDate.selected.id) {
                AgentService.getDownlineBetResultOrders({ lottos_fk: lottoParam.lotto.selected.id, lotto_dates_fk: lottoParam.lottoDate.selected.id })
                    .then(res => {
                        setData(res)
                        setTableData(res)
                        setLoading(false)
                    }).catch(err => {
                        message.error(MSG.COMMON.SMT_WRONG)
                        setLoading(false)
                    })
            } else {
                setData([])
                setTableData([])
                setLoading(false)
            }
        }
        setLoading(false)
    }, [lottoParam.lotto.selected.id, lottoParam.lottoDate.selected.id])

    const segmentChange = (value) => {
        setSegmentValue(value)
        if (value === 'ทั้งหมด') {
            setTableData(data)
        } else if (value === 'บิลได้') {
            setTableData(data.filter(item => Number(item.wonAmount) > 0))
        } else if (value === 'บิลเสีย') {
            setTableData(data.filter(item => Number(item.wonAmount) <= 0))
        }
    }

    return (
        <div className='agent-page-group bet-result-orders-page'>
            <Spin {...ELM_STANDARD.spin} spinning={loading}>
                <div className='page-title'>
                    <Typography className='title'>{MENU_ITEMS.betResultOrders.label}</Typography>
                </div>

                <div className='page-content-wrapper'>
                    <div className='page-content'>
                        <div>
                            {lottoParam.lotto.selected.id ?
                                <Space className='text-big'>
                                    <span className='text-blue'>[{lottoParam.lotto.selected.groupName}] - {lottoParam.lotto.selected.name}</span>
                                    <span>, </span>
                                    <span className='text-red'>{DATE_FORMAT.dateonlyDashed(lottoParam.lottoDate.selected.date)}</span>
                                    <span>{lotto_date_tag[lottoParam.lottoDate.selected.status]}</span>
                                    <span className='text-secondary'>(เปลี่ยนได้ที่แถบเมนูด้านบน)</span>
                                </Space>
                                : '*เลือกกลุ่มหวย'}
                        </div>

                        <div className='header-wrapper'>
                            <div className='text-right'>
                                <Segmented size="large" options={['ทั้งหมด', 'บิลได้', 'บิลเสีย']} value={segmentValue} onChange={segmentChange} />
                            </div>

                        </div>

                        <div className="table-wrapper my-2">
                            <Table
                                bordered
                                size='small'
                                rowKey={record => record.id}
                                dataSource={tableData}
                                scroll={{ x: 'max-content' }}
                                onRow={(record) => {
                                    return {
                                        onClick: (e) => {
                                            setModalOrderDetailData(record); setIsModalOrderDetailVisible(true)
                                        }
                                    }
                                }}
                                columns={[
                                    {
                                        title: '#',
                                        dataIndex: 'index',
                                        key: 'index',
                                        align: 'center',
                                        render: (value, record, i) => (
                                            i + 1
                                        )
                                    },
                                    {
                                        title: <div>
                                            <div>ชื่อผู้ใช้</div>
                                            <div><Checkbox checked={nameIsShow} onChange={() => setNameIsShow(!nameIsShow)}>แสดงชื่อ</Checkbox></div>
                                        </div>,
                                        dataIndex: 'username',
                                        key: 'username',
                                        width: '10%',
                                        render (value, record) {
                                            const name = nameIsShow && <span className='text-gray'>({record.name})</span>
                                            return <Typography className='text-blue'>{value} {name}</Typography>
                                        },
                                    },
                                    {
                                        title: 'ชนิดหวย',
                                        dataIndex: 'lottoName',
                                        key: 'lottoName',
                                        align: 'center',
                                        render: (value, record, index) => {
                                            return `[${(DB.SYSTEM.LOTTO.GROUP.find((g) => g.id === record.groupId))?.name}] - ${value}`
                                        }
                                    },
                                    {
                                        title: 'งวด',
                                        dataIndex: 'date',
                                        key: 'date',
                                        align: 'center',
                                        render: (value) => {
                                            return `${DATE_FORMAT.dateonly(value)}`
                                        }
                                    },
                                    {
                                        title: 'เวลาแทง',
                                        dataIndex: 'createdAt',
                                        key: 'createdAt',
                                        align: 'center',
                                        render: value => (
                                            DATE_FORMAT.default(value)
                                        )
                                    },
                                    {
                                        title: 'ยอดบิล',
                                        dataIndex: 'sum',
                                        key: 'sum',
                                        align: 'right',
                                        render: value => (
                                            <div className='text-green'>{NUMBER_FORMAT.default(value)}</div>
                                        )
                                    },
                                    {
                                        title: 'ส่วนลด',
                                        dataIndex: 'discount',
                                        key: 'discount',
                                        align: 'right',
                                        render: value => (
                                            <div className='text-red'>{NUMBER_FORMAT.default(value)}</div>
                                        )
                                    },
                                    {
                                        title: 'สุทธิ',
                                        dataIndex: 'summary',
                                        key: 'summary',
                                        align: 'right',
                                        render: (value, record) => (
                                            <div className='text-blue'>{NUMBER_FORMAT.default(Number(record.sum) - Number(record.discount))}</div>
                                        )
                                    },
                                    {
                                        title: 'ยอดถูกรางวัล',
                                        dataIndex: 'status',
                                        key: 'status',
                                        align: 'right',
                                        width: '8%',
                                        className: 'bg-yellow',
                                        render: (value, record) => {
                                            if (value === DB.SYSTEM.ORDER.STATUS.COMPLETED) {
                                                return Number(record.wonAmount) > 0 ?
                                                    <span className='text-blue text-bold'>{NUMBER_FORMAT.default(record.wonAmount)}</span>
                                                    : <span className='text-gray'>{record.wonAmount}</span>
                                            }
                                            return '-'
                                        }
                                    },
                                    {
                                        title: 'ได้เสีย',
                                        dataIndex: 'endAmount',
                                        key: 'endAmount',
                                        align: 'right',
                                        render: (value, record) => {
                                            const endAmount = NUMBER_FORMAT.default(Number(record.sum) - Number(record.discount) - Number(record.wonAmount))
                                            return endAmount > 0 ? <span className='text-green'>{endAmount}</span> : <span className='text-red'>{endAmount}</span>
                                        }
                                    },
                                    {
                                        title: 'สถานะ',
                                        dataIndex: 'status',
                                        key: 'status',
                                        align: 'center',
                                        render: (value, record) => {
                                            return order_tag[value]
                                        },
                                    },
                                    {
                                        title: 'หมายเหตุ',
                                        dataIndex: 'remark',
                                        key: 'remark',
                                        align: 'left',
                                        width: '5%',
                                    },
                                ]}
                                sticky
                                summary={pageData => {
                                    let sum1 = 0;
                                    let sum2 = 0;
                                    let sum3 = 0;
                                    let sum4 = 0;
                                    let sum5 = 0;
                                    pageData.forEach(({ sum, discount, wonAmount }) => {
                                        sum1 += Number(sum)
                                        sum2 += Number(discount)
                                        sum3 += Number(sum) - Number(discount)
                                        sum4 += Number(wonAmount) ?? 0
                                        sum5 += Number(sum) - Number(discount) - (Number(wonAmount) ?? 0)
                                    });
                                    return (
                                        <Table.Summary fixed={'top'}>
                                            <Table.Summary.Row className='bg-yellow' style={{ fontWeight: 'bold' }}>
                                                <Table.Summary.Cell className='text-left' colSpan={5}><div>{lottoParam.lotto.selected.id ? `${lottoParam.lotto.selected.groupName} - ${lottoParam.lotto.selected.name}` : '*เลือกกลุ่มหวย'}</div></Table.Summary.Cell>
                                                <Table.Summary.Cell className='text-right text-green'><div>{NUMBER_FORMAT.default(sum1)}</div> </Table.Summary.Cell>
                                                <Table.Summary.Cell className='text-right text-red'><div>{NUMBER_FORMAT.default(sum2)}</div> </Table.Summary.Cell>
                                                <Table.Summary.Cell className='text-right text-blue'><div>{NUMBER_FORMAT.default(sum3)}</div> </Table.Summary.Cell>
                                                <Table.Summary.Cell className='text-right text-green'><div>{NUMBER_FORMAT.default(sum4)}</div> </Table.Summary.Cell>
                                                <Table.Summary.Cell className={`text-right  ${sum5 > 0 ? `text-green` : `text-red`}`}><div>{NUMBER_FORMAT.default(sum5)}</div> </Table.Summary.Cell>
                                                <Table.Summary.Cell colSpan={3}></Table.Summary.Cell>
                                            </Table.Summary.Row>
                                        </Table.Summary>
                                    );
                                }}
                                className="table-elm shadowed"
                                pagination={false}
                            />

                            <OrderDetail
                                visible={isModalOrderDetailVisible}
                                onClose={() => setIsModalOrderDetailVisible(false)}
                                data={modalOrderDetailData}
                            />
                        </div>
                    </div>
                </div>
            </Spin >
        </div >

    )
}

export default BetResultOrders;