import React, { useState, useEffect, useLayoutEffect } from 'react';
import {
    Link,
    useNavigate,
} from "react-router-dom";
import { Row, Col, Card, Statistic, Alert, Space, Empty, Button, Modal, Spin, Form, message, Input, Tooltip, Popconfirm, Table, Typography } from 'antd';
import { ELM_STANDARD, lotto_date_tag, MENU_ITEMS } from '../../util/options';
import { AppstoreOutlined, ClockCircleOutlined, CloseOutlined, EditOutlined, HistoryOutlined, InfoCircleOutlined, LeftOutlined, LockOutlined, MinusCircleOutlined, PlusOutlined, QuestionCircleOutlined, SaveOutlined, ScissorOutlined, SyncOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { ADMIN_INTERVAL_UPDATE, DATE_FORMAT, DB, MSG } from '../../util/constant';
import { useLottoParamContext } from '../../contexts/LottoParamContext';
import AdminService from '../../services/user/admin.service';

import dayjs from 'dayjs'
import 'dayjs/locale/th'
import isBetween from 'dayjs/plugin/isBetween'
import { cloneDeep, difference, uniq } from 'lodash';
dayjs.extend(isBetween)


const CloseNumber = () => {
    const [updatedAt, setUpdatedAt] = useState(null)
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({})
    const [history, setHistory] = useState([])

    const { lottoParam, lottoParamAction } = useLottoParamContext();
    const navigate = useNavigate();

    const [modalVisible, setModalVisible] = useState({ half: false, closed: false });
    const initialModalData = {
        half: {
            "3 ตัวบน": [""],
            "3 ตัวโต๊ด": [""],
            "3 ตัวล่าง": [""],
            "2 ตัวบน": [""],
            "2 ตัวล่าง": [""],
            "วิ่งบน": [""],
            "วิ่งล่าง": [""],
        },
        closed: {
            "3 ตัวบน": [""],
            "3 ตัวโต๊ด": [""],
            "3 ตัวล่าง": [""],
            "2 ตัวบน": [""],
            "2 ตัวล่าง": [""],
            "วิ่งบน": [""],
            "วิ่งล่าง": [""],
        },
    }
    const [modalData, setModalData] = useState(cloneDeep(initialModalData));
    const [modalLoading, setModalLoading] = useState(false);

    const [closedForm] = Form.useForm();
    const [halfForm] = Form.useForm();

    const forms = { closed: closedForm, half: halfForm };
    const ActionService = {
        closed: AdminService.lottoClosedNumberUpdate,
        half: AdminService.lottoClosedNumberUpdate,
    }

    const modalProps = (param) => {
        return {
            ...ELM_STANDARD.noTransitionModal,
            visible: modalVisible[param],
            footer: [
                <Button key="back" onClick={() => closeModal(param)} icon={<CloseOutlined />}>ยกเลิก</Button>,
                <Popconfirm
                    key="submit"
                    title={`กรุณายืนยัน?`}
                    onConfirm={() => submit(param)}
                    okText="ยืนยัน"
                    okType="danger"
                    cancelText="ยกเลิก"
                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}>
                    <Button type="primary" icon={<SaveOutlined />}>ยืนยัน</Button>
                </Popconfirm>
            ],
            onCancel: () => closeModal(param),
            forceRender: true,
        }
    }

    const formProps = (param) => {
        return {
            form: forms[param],
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 20 },
            },
            autoComplete: "new-password",
            initialValues: modalData[param],
        }
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 20 },
        },
    };
    const formItemLayoutWithOutLabel = {
        wrapperCol: {
            xs: { span: 24, offset: 0 },
            sm: { span: 20, offset: 4 },
        },
    };

    const closeModal = (param) => {
        setModalVisible({ ...modalVisible, [param]: false });
        setModalData(cloneDeep(initialModalData));
        forms[param].resetFields()
    }

    const submit = async (param) => {
        try {
            setModalLoading(true)
            await forms[param].validateFields()

            // remove all empty value for each array and uniqed it
            const anotherMode = param === 'closed' ? 'half' : 'closed'
            const newData = { ...forms[param].getFieldsValue() }

            const anotherModeData = { ...data[anotherMode] }
            for (let key in newData) {
                if (Array.isArray(newData[key])) {
                    newData[key] = uniq(newData[key].filter(item => item && item.trim() !== ""))
                    anotherModeData[key] = difference(anotherModeData[key], newData[key])
                }
            }

            const removeEmptyAttribute = (obj) => {
                for (let key in obj) {
                    if (Array.isArray(obj[key])) {
                        if (obj[key].length === 0) {
                            delete obj[key]
                        }
                    } else if (obj[key]?.trim() === "") {
                        delete obj[key]
                    }
                }
                return cloneDeep(obj)
            }


            const send = await ActionService[param]({
                [param]: cloneDeep(removeEmptyAttribute(newData)),
                [anotherMode]: cloneDeep(removeEmptyAttribute(anotherModeData)),
                lotto_dates_fk: lottoParam.lottoDate.selected.id
            })

            setModalLoading(false)
            if (send === `OK`) {
                forms[param].resetFields()
                setModalVisible({ ...modalVisible, [param]: false })
                message.success(MSG.COMMON.UPDATE.SUCCEED)
                prepareData();
            } else if (send === `LOTTO_DATE_NOT_PENDING`) {
                message.error(MSG.LOTTO_DATE.LOTTO_DATE_NOT_PENDING)
                prepareData();
            } else if (send === `NOT_FOUND`) {
                message.error(MSG.LOTTO_DATE.NOT_FOUND)
                prepareData();
            } else {
                message.error(MSG.COMMON.VALIDATE.FAILED)
            }
        } catch (err) {
            setModalLoading(false)
            message.error(MSG.COMMON.VALIDATE.FAILED)
        }
    }

    useEffect(() => {
        prepareData();
    }, [lottoParam.lottoDate.selected.id])

    useEffect(() => {
        forms.half.setFieldsValue(modalData.half)
    }, [forms.half, modalData.half])

    useEffect(() => {
        forms.closed.setFieldsValue(modalData.closed)
    }, [forms.closed, modalData.closed])

    const prepareData = async () => {
        if (lottoParam.lottoDate.selected.id) {
            setLoading(true);
            const res = await AdminService.prepareLottoClosedNumberManage(lottoParam.lottoDate.selected.id)
            setData(res.data)
            setHistory(res.history)
            setUpdatedAt(dayjs().add(ADMIN_INTERVAL_UPDATE, 'minute'))
            setLoading(false);
        }
    }

    const onVisibilityChange = () => {
        if (document.visibilityState === 'visible' && dayjs().isAfter(updatedAt)) {
            console.log("Tab reopened, refetch the data!");
            prepareData()
        }
    };

    useLayoutEffect(() => {
        document.addEventListener("visibilitychange", onVisibilityChange);
        return () => document.removeEventListener("visibilitychange", onVisibilityChange);
    }, [updatedAt]);

    const renderMarketContent = (m) => {
        let status = {}
        if (m.status === DB.SYSTEM.LOTTO_DATE.STATUS.PENDING) {
            if (dayjs().isBefore(m.openAt)) {
                status.key = 'willActive';
            } else if (dayjs().isBetween(m.openAt, m.closeAt)) {
                status.key = 'active';
            } else if (dayjs().isBetween(m.closeAt, m.postAt)) {
                status.key = 'pending';
            } else {
                status.key = 'late'
            }
        } else if (m.status === DB.SYSTEM.LOTTO_DATE.STATUS.CANCELED) {
            status.key = 'canceled'
        } else {
            status.key = 'inactive'
        }

        return <div className='h-100' >
            <div className={`market-item market-${status.key}`} >
                <Row wrap={false}>
                    <Col flex={1}>
                        <Row justify='space-between' gutter={[4, 4]}>
                            <Col xs={12}><div className="name">{m.name}</div><div className="date">{DATE_FORMAT.dateonly(m.date)}</div></Col>
                            <Col className='text-right' xs={12}>
                                <div className='market-image-wrapper'>
                                    <img className='market-image' src={`../images/lotto/${m.lottos_fk}.png`} alt="" />
                                </div>
                            </Col>
                            <Col xs={12}>สถานะ</Col><Col className='text-right' xs={12}>{lotto_date_tag[m.status]}</Col>
                            <Col xs={12}>เปิด</Col><Col className='text-right' xs={12}>{DATE_FORMAT.thaiHalf(m.openAt)}</Col>
                            <Col xs={12}>ปิด</Col><Col className='text-right' xs={12}>{DATE_FORMAT.thaiHalf(m.closeAt)}</Col>
                            <Col xs={12}>ออกผล</Col><Col className='text-right' xs={12}>{DATE_FORMAT.thaiHalf(m.postAt)}</Col>
                            {status.key === 'willActive' && (
                                <Col xs={24} className="text-right"><Statistic.Countdown prefix="เปิดรับใน" title="" value={m.openAt} /></Col>
                            )}
                            {status.key === 'active' && (
                                <Col xs={24} className="text-right"><Statistic.Countdown prefix="ปิดรับใน" title="" value={m.closeAt} /></Col>
                            )}
                            {status.key === 'pending' && (
                                <Col xs={24} className="text-center"><Alert message="ตลาดปิดรับ กรุณาออกผล" type="warning" showIcon /></Col>
                            )}
                            {status.key === 'late' && (
                                <Col xs={24} className="text-center"><Alert message="เลยเวลาออกผล กรุณาออกผลทันที" type="error" showIcon /></Col>
                            )}
                        </Row>
                    </Col>
                </Row>
            </div>
        </div >
    }

    const renderNumbers = (numbers) => {
        return <Row gutter={[8, 8]}>
            {Object.entries(numbers).map(([key, value]) => {
                return (
                    [<Col xs={12} key={`${key}1`}>{key}</Col>,
                    <Col xs={12} key={`${key}2`}>{Array.isArray(value) ? value.join(', ') : value}</Col>]
                )
            })}
        </Row>
    }

    const renderHistory = () => {
        return <Table
            size='small'
            rowKey={record => record.id}
            dataSource={history}
            scroll={{ x: 'max-content' }}
            columns={[
                {
                    title: '#',
                    dataIndex: 'index',
                    key: 'index',
                    align: 'center',
                    width: '10%',
                    render (value, record, i) {
                        return i + 1
                    },
                },
                {
                    title: 'วันที่/เวลา',
                    dataIndex: 'createdAt',
                    key: 'createdAt',
                    align: 'center',
                    width: '10%',
                    render: value => (
                        DATE_FORMAT.default(value)
                    )
                },
                {
                    title: <div>
                        <div>ชื่อผู้ใช้</div>
                    </div>,
                    dataIndex: 'username',
                    key: 'username',
                    align: 'center',
                    render: (value, record) => (
                        <div>
                            <div>{value} {record.name ?? ``}</div>
                        </div>
                    )
                },
                {
                    title: 'รายละเอียด',
                    dataIndex: 'details',
                    key: 'details',
                    ellipsis: {
                        showTitle: false,
                    },
                    render: value => {
                        const body = JSON.parse(value)
                        return <Typography.Paragraph
                            style={{ whiteSpace: 'normal' }}
                            copyable={{ text: JSON.stringify(body) }}
                            ellipsis={{ rows: 1, expandable: true, symbol: 'ดูรายละเอียด' }} >
                            <div><pre>{JSON.stringify(body, null, 2)}</pre></div>
                        </Typography.Paragraph >
                    },
                },
                {
                    title: 'ip address',
                    dataIndex: 'ip_address',
                    key: 'ip_address',
                    align: 'center',
                },
            ]}
            className="table-elm shadowed"
            pagination={false}
            loading={loading}
        />
    }

    const renderDynamicForm = (name, fields, add, remove, errors, limit, mode) => {
        return <>
            {fields.map((field, index) => (

                <Form.Item
                    {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                    label={index === 0 ? name : ''}
                    required={false}
                    key={field.key}
                >
                    <Form.Item
                        {...field}
                        noStyle
                        rules={[
                            {
                                len: limit,
                                message: 'รูปแบบไม่ถูกต้อง',
                            },
                            {
                                validator: (rule, value) => {
                                    const anotherMode = mode === 'closed' ? 'half' : 'closed'
                                    const anotherModeText = mode === 'closed' ? 'จ่ายครึ่ง' : 'เลขอั้น'
                                    if (value && data?.[anotherMode]?.[name]?.some(item => item == value)) {
                                        return Promise.reject(`คำเตือน: มีเลขนี้อยู่ที่ ${anotherModeText} จะทำการลบออกจาก ${anotherModeText} หากยืนยัน`)
                                    }

                                    return Promise.resolve()
                                },
                                warningOnly: true,
                            }
                        ]}
                    >
                        <Input placeholder={index === 0 ? '' : name} maxLength={limit} style={{ width: '60%' }} />
                    </Form.Item>
                    {fields.length > 1 ? (
                        <MinusCircleOutlined
                            style={{ margin: '0 0 0 8px' }}
                            className="dynamic-delete-button"
                            onClick={() => remove(field.name)}
                        />
                    ) : null}
                </Form.Item>
            ))}
            <Form.Item {...formItemLayoutWithOutLabel}>
                <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{ width: '60%' }}
                    icon={<PlusOutlined />}
                >
                    เพิ่มเลข {name}
                </Button>
                <Form.ErrorList errors={errors} />
            </Form.Item>
        </>
    }



    return (
        <div className='admin-page-group result-add-page'>
            <div>
                <Button onClick={() => {
                    // lotto param context select this
                    lottoParamAction.selectLottoAndLottoDate(
                        {
                            id: null,
                            date: null,
                            status: null,
                        },
                        {
                            id: null,
                            groupId: null,
                            name: null,
                            groupName: null,
                        }
                    )
                    // navigate to lotto result add page
                    navigate(MENU_ITEMS.lottoResult.path)
                }} type='link' icon={<LeftOutlined />} size="large">
                    เปลี่ยนตลาด
                </Button>
            </div>

            {lottoParam.lotto.selected.id && (
                <div className='text-secondary text-center font-sarabun cursor-pointer'>
                    <Tooltip placement='top' title={<div className='text-center'>คลิกเพื่ออัปเดต</div>}>
                        <div>
                            {loading ? <SyncOutlined spin />
                                : <span onClick={prepareData}>อัปเดตล่าสุด: <ClockCircleOutlined /> {DATE_FORMAT.timeonly(dayjs(updatedAt).subtract(ADMIN_INTERVAL_UPDATE, 'minute'))}</span>}
                        </div>
                    </Tooltip>
                </div>
            )}

            <Alert
                style={{ margin: "1rem" }}
                message={'การอั้นเลข'}
                description={<ul>
                    <li>การอั้นเลขจะมีผลกับ<strong>บิลที่เปิดมาหลังเพิ่มเลขอั้น</strong>เท่านั้น</li>
                    <li>อั้นได้เฉพาะงวดที่อยู่ในสถานะ {lotto_date_tag['PENDING']} เท่านั้น</li>
                </ul>}
                type="warning"
                showIcon
                closable
            />

            <div className='page-content-wrapper'>
                <div className='page-content'>
                    <Row gutter={[16, 16]}>
                        <Col xs={12}>
                            <div className='market-wrapper'>
                                <Card title={<><InfoCircleOutlined /> ข้อมูลงวด</>} size='small'>
                                    {lottoParam.lotto.selected.id ? renderMarketContent(data)
                                        : <Alert
                                            style={{ margin: "1rem" }}
                                            description={<div>ยังไม่เลือกกลุ่มหวย</div>}
                                            type="info"
                                            showIcon
                                        />}
                                </Card>
                            </div>
                        </Col>
                        <Col xs={12}>
                            <div>
                                <Card title={<><ThunderboltOutlined /> Actions</>} size='small' className='card-items-centered card-actions'>
                                    {lottoParam.lotto.selected.id ?
                                        <Row gutter={[8, 8]} justify='center'>
                                            <Col><Link to={MENU_ITEMS.lottoResultAdd.path} disabled={!lottoParam.lotto.selected.id}><Button type='primary' icon={<AppstoreOutlined />}>ออกผล</Button></Link></Col>
                                        </Row>
                                        : <Empty description="ไม่มีข้อมูล" />}
                                </Card>
                            </div>
                        </Col>

                        <Col xs={12}>
                            <div className='content-wrapper'>
                                <Card title={
                                    <Row justify='space-between'>
                                        <Col><LockOutlined /> เลขอั้น</Col>
                                        <Col>
                                            <Space className='text-right'>
                                                <Button disabled={!lottoParam.lottoDate.selected.id || data?.status !== DB.SYSTEM.LOTTO_DATE.STATUS.PENDING} type="primary" icon={<EditOutlined />}
                                                    onClick={() => { setModalVisible({ ...modalVisible, closed: true }); setModalData({ ...modalData, closed: cloneDeep(data.closed) }) }}>แก้ไข</Button>
                                            </Space>
                                        </Col>
                                    </Row>} size='small'>
                                    {data?.closed ? renderNumbers(data.closed) :
                                        <>
                                            <Empty description="ไม่มีเลขอั้น" />
                                        </>
                                    }
                                </Card>
                            </div>
                        </Col>

                        <Col xs={12}>
                            <div className='content-wrapper'>
                                <Card title={
                                    <Row justify='space-between'>
                                        <Col><ScissorOutlined /> จ่ายครึ่ง</Col>
                                        <Col>
                                            <Space className='text-right'>
                                                <Button disabled={!lottoParam.lottoDate.selected.id || data?.status !== DB.SYSTEM.LOTTO_DATE.STATUS.PENDING} type="primary" icon={<EditOutlined />}
                                                    onClick={() => { setModalVisible({ ...modalVisible, half: true }); setModalData({ ...modalData, half: cloneDeep(data.half) }) }}>แก้ไข</Button>
                                            </Space>
                                        </Col>
                                    </Row>} size='small'>
                                    {data?.half ? renderNumbers(data.half) :
                                        <>
                                            <Empty description="ไม่มีเลขจ่ายครึ่ง" />
                                        </>
                                    }
                                </Card>
                            </div>
                        </Col>

                        <Col xs={24}>
                            <div className='content-wrapper'>
                                <Card title={
                                    <Row justify='space-between'>
                                        <Col><HistoryOutlined /> ประวัติ</Col>
                                    </Row>} size='small'>
                                    {history.length > 0 ? renderHistory() :
                                        <>
                                            <Empty description="ไม่มีประวัติ" />
                                        </>
                                    }
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </div >
            </div >


            <Modal title={<span><LockOutlined /> แก้ไขเลขอั้น</span>}
                {...modalProps('closed')}>
                <Spin  {...ELM_STANDARD.spin} spinning={modalLoading}>
                    <Form
                        {...formProps('closed')}>
                        <Form.List
                            name="3 ตัวบน"
                        >
                            {(fields, { add, remove }, { errors }) => (
                                renderDynamicForm('3 ตัวบน', fields, add, remove, errors, 3, 'closed')
                            )}
                        </Form.List>
                        <Form.List
                            name="3 ตัวล่าง"
                        >
                            {(fields, { add, remove }, { errors }) => (
                                renderDynamicForm('3 ตัวล่าง', fields, add, remove, errors, 3, 'closed')
                            )}
                        </Form.List>

                        <Form.List
                            name="3 ตัวโต๊ด"
                        >
                            {(fields, { add, remove }, { errors }) => (
                                renderDynamicForm('3 ตัวโต๊ด', fields, add, remove, errors, 3, 'closed')
                            )}
                        </Form.List>

                        <Form.List
                            name="2 ตัวบน"
                        >
                            {(fields, { add, remove }, { errors }) => (
                                renderDynamicForm('2 ตัวบน', fields, add, remove, errors, 2, 'closed')
                            )}
                        </Form.List>

                        <Form.List
                            name="2 ตัวล่าง"
                        >
                            {(fields, { add, remove }, { errors }) => (
                                renderDynamicForm('2 ตัวล่าง', fields, add, remove, errors, 2, 'closed')
                            )}
                        </Form.List>

                        <Form.List
                            name="วิ่งบน"
                        >
                            {(fields, { add, remove }, { errors }) => (
                                renderDynamicForm('วิ่งบน', fields, add, remove, errors, 1, 'closed')
                            )}
                        </Form.List>
                        <Form.List
                            name="วิ่งล่าง"
                        >
                            {(fields, { add, remove }, { errors }) => (
                                renderDynamicForm('วิ่งล่าง', fields, add, remove, errors, 1, 'closed')
                            )}
                        </Form.List>

                    </Form>
                </Spin>
            </Modal>

            <Modal title={<span><ScissorOutlined />แก้ไขเลขจ่ายครึ่ง</span>}
                {...modalProps('half')}>
                <Spin  {...ELM_STANDARD.spin} spinning={modalLoading}>
                    <Form
                        {...formProps('half')}>
                        <Form.List
                            name="3 ตัวบน"
                        >
                            {(fields, { add, remove }, { errors }) => (
                                renderDynamicForm('3 ตัวบน', fields, add, remove, errors, 3, 'half')
                            )}
                        </Form.List>
                        <Form.List
                            name="3 ตัวล่าง"
                        >
                            {(fields, { add, remove }, { errors }) => (
                                renderDynamicForm('3 ตัวล่าง', fields, add, remove, errors, 3, 'half')
                            )}
                        </Form.List>

                        <Form.List
                            name="3 ตัวโต๊ด"
                        >
                            {(fields, { add, remove }, { errors }) => (
                                renderDynamicForm('3 ตัวโต๊ด', fields, add, remove, errors, 3, 'half')
                            )}
                        </Form.List>

                        <Form.List
                            name="2 ตัวบน"
                        >
                            {(fields, { add, remove }, { errors }) => (
                                renderDynamicForm('2 ตัวบน', fields, add, remove, errors, 2, 'half')
                            )}
                        </Form.List>

                        <Form.List
                            name="2 ตัวล่าง"
                        >
                            {(fields, { add, remove }, { errors }) => (
                                renderDynamicForm('2 ตัวล่าง', fields, add, remove, errors, 2, 'half')
                            )}
                        </Form.List>

                        <Form.List
                            name="วิ่งบน"
                        >
                            {(fields, { add, remove }, { errors }) => (
                                renderDynamicForm('วิ่งบน', fields, add, remove, errors, 1, 'half')
                            )}
                        </Form.List>
                        <Form.List
                            name="วิ่งล่าง"
                        >
                            {(fields, { add, remove }, { errors }) => (
                                renderDynamicForm('วิ่งล่าง', fields, add, remove, errors, 1, 'half')
                            )}
                        </Form.List>

                    </Form>
                </Spin>
            </Modal>




        </div >
    )
}

export default CloseNumber;