import React, { useState } from 'react';

import { Col, Empty, Modal, Row, Space, Spin, Tabs, Typography } from 'antd';
import { useLottoParamContext } from '../../../contexts/LottoParamContext';
import { BookOutlined, CalendarOutlined, CaretDownOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { DATE_FORMAT, DB } from '../../../util/constant';
import { groupBy } from 'lodash';
import { ELM_STANDARD, lotto_date_tag } from '../../../util/options';

const CustomHeader = () => {
    const { lottoParam, lottoParamAction } = useLottoParamContext();
    const [isModalVisible, setIsModalVisible] = useState({ lotto: false, lottoDate: false });
    const [isModalLoading, setIsModalLoading] = useState({ lotto: false, lottoDate: false });

    const openModalSelectLotto = () => {
        setIsModalVisible({ ...isModalVisible, lotto: true });
    }

    const openModalSelectLottoDate = async () => {
        setIsModalLoading({ ...isModalLoading, lottoDate: true });
        await lottoParamAction.updateLottoDateData();
        setIsModalVisible({ ...isModalVisible, lottoDate: true });
        setIsModalLoading({ ...isModalLoading, lottoDate: false });
    }

    const selectLotto = async (lotto) => {
        await lottoParamAction.selectLotto(lotto)
        setIsModalVisible({ ...isModalVisible, lotto: false });
    }

    const selectLottoDate = async (lottoDate) => {
        await lottoParamAction.selectLottoDate(lottoDate)
        setIsModalVisible({ ...isModalVisible, lottoDate: false });
    }

    return (
        <div className="custom-header">
            <Space size="large">
                <div className='cursor-pointer' onClick={() => openModalSelectLotto()}>
                    <BookOutlined className='text-gold' /> <Typography.Text>
                        {lottoParam.lotto.selected.id ? `${lottoParam.lotto.selected.groupName} - ${lottoParam.lotto.selected.name}` : '*เลือกกลุ่มหวย'}
                    </Typography.Text>
                    <CaretDownOutlined />
                </div>
                {lottoParam.lotto.selected.id && (
                    <div className='cursor-pointer' onClick={() => openModalSelectLottoDate()}>
                        <CalendarOutlined className='text-green' /> <Typography.Text>
                            {lottoParam.lottoDate.selected.id ? `${DATE_FORMAT.dateonlyDashed(lottoParam.lottoDate.selected.date)}` : '*เลือกงวดหวย'}
                        </Typography.Text>
                        <CaretDownOutlined />
                    </div>
                )}
            </Space>

            <Modal
                {...ELM_STANDARD.noTransitionModal}
                className='responsive-modal modal-min-height'
                title="เลือกหวย" footer={null} visible={isModalVisible.lotto} onCancel={() => setIsModalVisible({ ...isModalVisible, lotto: false })}>
                <Tabs tabPosition={'left'}>
                    {Object.entries(groupBy(lottoParam.lotto.list, 'groupId')).map(([groupId, arr]) => {
                        const groupName = DB.SYSTEM.LOTTO.GROUP.find((g) => g.id == groupId)?.name
                        return <Tabs.TabPane tab={`${groupName} (${arr?.length})`} key={groupId}>
                            {arr.map((lotto) => (
                                <div key={lotto.id} className={`cursor-pointer lotto-param ${lottoParam.lotto.selected.id == lotto.id ? `lotto-param-selected` : ``}`}
                                    onClick={() => selectLotto({
                                        id: lotto.id,
                                        groupId: lotto.groupId,
                                        name: lotto.name,
                                        groupName: groupName,
                                    })}>
                                    {lotto.name}
                                </div>
                            ))}
                        </Tabs.TabPane>
                    })}

                </Tabs>
            </Modal>

            <Modal          {...ELM_STANDARD.noTransitionModal}
                className='responsive-modal modal-min-height'
                title="งวดหวย" footer={null} visible={isModalVisible.lottoDate} onCancel={() => setIsModalVisible({ ...isModalVisible, lottoDate: false })}>
                <Spin {...ELM_STANDARD.spin} spinning={isModalLoading.lottoDate}>
                    <Row gutter={[16, 16]}>
                        {lottoParam.lottoDate.list.map((lottoDate) => (
                            <Col xs={8} key={lottoDate.id} className="cursor-pointer" onClick={() => selectLottoDate({ id: lottoDate.id, date: lottoDate.date, status: lottoDate.status })}>
                                <div className={`lotto-param lotto-param-radius ${lottoParam.lottoDate.selected.id == lottoDate.id ? `lotto-param-selected` : ``}`}>
                                    <Row justify='space-between'>
                                        <Col>{DATE_FORMAT.dateonlyDashed(lottoDate.date)}</Col>
                                        <Col>{lotto_date_tag[lottoDate.status]}</Col>
                                        <Col><ClockCircleOutlined /> {DATE_FORMAT.thaiHalfNoSec(lottoDate.postAt)}</Col>
                                    </Row>
                                </div>
                            </Col>
                        ))}
                    </Row>
                    {lottoParam.lottoDate.list.length === 0 && (
                        <Empty />
                    )}
                </Spin>
            </Modal>
        </div >



    );
}

export default CustomHeader;
