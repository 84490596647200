
import React from 'react';
import { Typography } from 'antd';
import { MENU_ITEMS } from '../../util/options';
import { COMPANY_NAME } from '../../util/constant';

const Rules = () => {
    return <div className='member-page-group orders-page'>
        <div className='page-title'>
            <Typography className='title'>{MENU_ITEMS.rules.label}</Typography>
        </div>


        <div className='page-content-wrapper'>
            <div className='page-content'>
                <div>
                    <div></div>
                    <div>
                        <div className="text-left">
                            <div>
                                {COMPANY_NAME} ยึดเอาข้อมูลที่ เอเย่นต์และ สมาชิกทุกคน
                                บนหน้าเว๊บไซต์เป็นหลัก กรุณาตรวจสอบรายการเล่นและ
                                พิมพ์เก็บไว้เป็นหลักฐานเสมอ
                            </div>
                            <div>
                                หากมีข้อสงสัยในการตั้งค่าต่างๆ และต้องการความช่วยเหลือ
                                โปรดติดต่อต้นสายของท่าน หากมีข้อผิดพลาดที่เกิดขึ้นกับระบบ
                            </div>
                            <div>โปรดแจ้งทางต้นสายของท่าน</div>
                            ก่อนออกผลรางวัล มิเช่นนั้นบริษัท
                            จะถือเอารายการแทงและข้อมูลที่เกิดขึ้นในเว็บไซต์เป็นหลักเสมอ
                            <br />
                            <div>
                                1. กติกา การเข้าเล่น แต่ละตลาดหวย สมาชิกสามารถเข้าเล่นที่ เมนู
                                แทงหวย&nbsp;
                            </div>
                            **สำคัญ**โปรดตรวจทานทุกครั้งก่อนกดยืนยันการแทง
                            <div className="text-small">เพราะแทงแล้วยกเลิกไม่ได้&nbsp;</div> <div className="text-small">
                                และทางบริษัทแนะนำให้แทงแล้ว เก็บของมูลการแทงไว้(save/print)
                                เพื่อตรวจสอบหลังผลรางวัลออก
                            </div> <div>
                                2. สามารถตรวจสอบรายการที่แทงแล้ว ที่ส่วนขวาของหน้าแทงหวย และ ที่เมนู
                                รายการแทง ด้านบนของหน้าเล่น
                            </div> <div>
                                3. สามารถตรวจสอบรายการที่ประกาศผลแล้ว ที่เมนู รายการการเงิน
                                ด้านบนของหน้าเล่น
                            </div> <div>
                                4. สามารถเช็คผลรางวัลแต่ละตลาดได้ที่ เมนู ตรวจผลรางวัล
                                ด้านบนของหน้าเล่น+หากไม่มีหลักฐานมายืนยัน(ที่บันทึก/ปริ้นไว้)
                                มายืนยัน บริษัทยึดตามข้อมูลที่บริษัทมีอยู่
                            </div> <div>
                                5. หากเอเย่นต์หรือสมาชิก ท่านใดพบปัญหาการแทง หรือระบบบัญชี
                                ในหน้าส่วนตัว โปรดติดต่อเอเย่นต์ ของท่าน ภายใน1ชม ก่อนหวยออก
                                มิเช่นนั้นบริษัทจะยึดข้อมูลตามเว๊บไซต์เป็นหลัก
                            </div> <div>
                                6. หากมีการเปลี่ยนแปลงในการออกผลรางวัลตลาดต่างๆทางบริษัท
                                จะแจ้งลูกค้าผ่านทางข้าวสาร ‘ตัวอักษรวิ่ง’ ด้านบน, หรือ
                                แจ้งผ่านทางเอเย่นต์ของท่าน
                            </div> <div>
                                7. บริษัทแนะนำให้ เอเย่นต์และสมาชิกทุกท่าน
                                ดูแลรับผิดชอบข้อมูลส่วนตัว และรหัสการเข้าสู่หน้าแทง
                                ของสมาชิกภายใต้การดูแลของสายงานเป็นอย่างดี เพื่อผลประโยชน์ของทุกท่าน
                            </div> <div>
                                8. บริษัทปฎิเสธการจ่ายเงินรางวัล ทั้งสายงาน และยกเลิกความเป็นสมาชิก
                                หากตรวจพบการโกงหรือทุจรจิตร ทุกรูปแบบที่เกิดขึ้น
                            </div> <div>
                                9. เอเยนต์ทุกสายงาน
                                ต้องรับผิดชอบกับยอดที่เกิดขึ้นภายใต้สายงานตัวเองทุกกรณี และ
                                บริษัทมีสิทธิในการยกเลิก
                                ทุกยอดที่มีการเล่นเข้ามาหลังหวยออกแล้วของทุกตลาดหวย
                            </div> <div><br /></div> <div>&nbsp; หวยไทย (หวยรัฐบาล)&nbsp;</div> <ul><li><div>รางวัล 3 ตัวบน จะใช้3ตัวสุดท้ายของรางวัลที่ 1&nbsp;</div></li> <li><div>รางวัล 2 ตัวบน จะใช้2ตัวสุดท้ายของรางวัลที่ 1</div></li> <li><div>
                                รางวัล 3 ตัวโต้ด จะใช้3ตัวสุดท้ายของรางวัลที่ 1 สลับตำแหน่งได้
                            </div></li> <li><div>
                                รางวัล 2 ตัวโต้ด จะใช้3ตัวสุดท้ายของรางวัลที่ 1
                                ถูก2ตัวสลับตำแหน่งได้
                            </div></li> <li><div>
                                รางวัล วิ่งบน จะใช้3ตัวสุดท้ายของรางวัลที่ 1
                                ถูก1ตัวสลับตำแหน่งได้&nbsp;
                            </div></li> <li><div>รางวัล 2 ตัวล่าง จะใช้รางวัลเลขท้าย 2 ตัว</div></li> <li><div>
                                รางวัล วิ่งล่าง จะใช้รางวัลเลขท้าย 2 ตัว
                                ถูก1ตัวสลับตำแหน่งได้&nbsp;
                            </div></li> <li><div>
                                รางวัล 3 ตัวล่าง มี 4 รางวัล ใช้รางวัลเลขท้าย 3 ตัว 2รางวัล และ
                                รางวัลเลข 3 ตัวหน้า 2รางวัล
                            </div></li> <li><div>
                                รางวัล คู่คี่บน จะใช้เลขหลักหน่วย ของ 2ตัวบน เลขคู่คือ 0,2,4,6,8
                                และคี่คือ 1,3,5,7,9
                            </div></li> <li><div>
                                รางวัล คู่คี่ล่าง จะใช้เลขหลักหน่วย ของ 2ตัวล่าง เลขคู่คือ
                                0,2,4,6,8 และคี่คือ 1,3,5,7,9
                            </div></li> <li><div>
                                รางวัล สูงต่ำบน จะใช้เลขหลักสิบ ของ 2ตัวบน ต่ำคือเลข 0,1,2,3,4
                                และสูงคือเลข 5,6,7,8,9
                            </div></li> <li><div>
                                รางวัล สูงต่ำล่าง จะใช้เลขหลักสิบ ของ 2ตัวล่าง ต่ำคือเลข
                                0,1,2,3,4 และสูงคือเลข 5,6,7,8,9
                            </div></li></ul> <div>&nbsp;หวยลาว&nbsp;</div> <ul><li><div>
                                รางวัล 3 ตัวบน จะใช้3ตัวสุดท้าย(จากเลข4หลักที่ออกรางวัล)&nbsp;
                            </div></li> <li><div>
                                รางวัล 2 ตัวบน จะใช้2ตัวสุดท้าย(จากเลข4หลักที่ออกรางวัล)&nbsp;
                            </div></li> <li><div>
                                รางวัล 3 ตัวโต้ด จะใช้3ตัวสุดท้าย(จากเลข4หลักที่ออกรางวัล)
                                สลับตำแหน่งได้
                            </div></li> <li><div>
                                รางวัล 2 ตัวโต้ด จะใช้3ตัวสุดท้าย(จากเลข4หลักที่ออกรางวัล)
                                ถูก2ตัวสลับตำแหน่งได้
                            </div></li> <li><div>
                                รางวัล วิ่งบน จะใช้3ตัวสุดท้าย(จากเลข4หลักที่ออกรางวัล)
                                ถูก1ตัวสลับตำแหน่งได้&nbsp;
                            </div></li> <li><div>
                                รางวัล 2 ตัวล่าง
                                จะใช้2ตัวเลขด้านหน้า(จากเลข4หลักที่ออกรางวัล)&nbsp;
                            </div></li> <li><div>
                                รางวัล คู่คี่บน จะใช้เลขหลักหน่วย ของ 2ตัวบน เลขคู่คือ 0,2,4,6,8
                                และคี่คือ 1,3,5,7,9
                            </div></li> <li><div>
                                รางวัล คู่คี่ล่าง จะใช้เลขหลักหน่วย ของ 2ตัวล่าง เลขคู่คือ
                                0,2,4,6,8 และคี่คือ 1,3,5,7,9
                            </div></li> <li><div>
                                รางวัล สูงต่ำบน จะใช้เลขหลักสิบ ของ 2ตัวบน ต่ำคือเลข 0,1,2,3,4
                                และสูงคือเลข 5,6,7,8,9
                            </div></li> <li><div>
                                รางวัล สูงต่ำล่าง จะใช้เลขหลักสิบ ของ 2ตัวล่าง ต่ำคือเลข
                                0,1,2,3,4 และสูงคือเลข 5,6,7,8,9
                            </div></li></ul> <div>หวยเวียดนาม (หวยฮานอย)&nbsp;</div> <ul><li><div>รางวัล 3 ตัวบน จะใช้3ตัวสุดท้ายของรางวัลที่ 1&nbsp;</div></li> <li><div>รางวัล 2 ตัวบน จะใช้2ตัวสุดท้ายของรางวัลที่ 1</div></li> <li><div>
                                รางวัล 3 ตัวโต้ด จะใช้3ตัวสุดท้ายของรางวัลที่ 1 สลับตำแหน่งได้
                            </div></li> <li><div>
                                รางวัล 2 ตัวโต้ด จะใช้3ตัวสุดท้ายของรางวัลที่ 1
                                ถูก2ตัวสลับตำแหน่งได้
                            </div></li> <li><div>
                                รางวัล วิ่งบน จะใช้3ตัวสุดท้ายของรางวัลที่ 1
                                ถูก1ตัวสลับตำแหน่งได้&nbsp;
                            </div></li> <li><div>รางวัล 2 ตัวล่าง จะใช้2ตัวสุดท้ายของรางวัลที่ 2</div></li> <li><div>
                                รางวัล วิ่งล่าง จะใช้รางวัลเลขท้าย 2 ตัวของรางวัลที่ 2
                                ถูก1ตัวสลับตำแหน่งได้&nbsp;
                            </div></li> <li><div>รางวัล 3 ตัวล่าง จะใช้3ตัวสุดท้ายของรางวัลที่ 2</div></li> <li><div>
                                รางวัล คู่คี่บน จะใช้เลขหลักหน่วย ของ 2ตัวบน เลขคู่คือ 0,2,4,6,8
                                และคี่คือ 1,3,5,7,9
                            </div></li> <li><div>
                                รางวัล คู่คี่ล่าง จะใช้เลขหลักหน่วย ของ 2ตัวล่าง เลขคู่คือ
                                0,2,4,6,8 และคี่คือ 1,3,5,7,9
                            </div></li> <li><div>
                                รางวัล สูงต่ำบน จะใช้เลขหลักสิบ ของ 2ตัวบน ต่ำคือเลข 0,1,2,3,4
                                และสูงคือเลข 5,6,7,8,9
                            </div></li> <li><div>
                                รางวัล สูงต่ำล่าง จะใช้เลขหลักสิบ ของ 2ตัวล่าง ต่ำคือเลข
                                0,1,2,3,4 และสูงคือเลข 5,6,7,8,9
                            </div></li></ul> <div>หวยมาเลเซีย&nbsp;</div> <ul><li><div>รางวัล 3 ตัวบน จะใช้3ตัวสุดท้ายของรางวัลที่ 1&nbsp;</div></li> <li><div>รางวัล 2 ตัวบน จะใช้2ตัวสุดท้ายของรางวัลที่ 1</div></li> <li><div>
                                รางวัล 3 ตัวโต้ด จะใช้3ตัวสุดท้ายของรางวัลที่ 1 สลับตำแหน่งได้
                            </div></li> <li><div>
                                รางวัล 2 ตัวโต้ด จะใช้3ตัวสุดท้ายของรางวัลที่ 1
                                ถูก2ตัวสลับตำแหน่งได้
                            </div></li> <li><div>
                                รางวัล วิ่งบน จะใช้3ตัวสุดท้ายของรางวัลที่ 1
                                ถูก1ตัวสลับตำแหน่งได้&nbsp;
                            </div></li> <li><div>รางวัล 2 ตัวล่าง จะใช้2ตัวสุดท้ายของรางวัลที่ 2</div></li> <li><div>
                                รางวัล วิ่งล่าง จะใช้รางวัลเลขท้าย 2 ตัวของรางวัลที่ 2
                                ถูก1ตัวสลับตำแหน่งได้&nbsp;
                            </div></li> <li><div>รางวัล 3 ตัวล่าง จะใช้3ตัวสุดท้ายของรางวัลที่ 2</div></li> <li><div>
                                รางวัล คู่คี่บน จะใช้เลขหลักหน่วย ของ 2ตัวบน เลขคู่คือ 0,2,4,6,8
                                และคี่คือ 1,3,5,7,9
                            </div></li> <li><div>
                                รางวัล คู่คี่ล่าง จะใช้เลขหลักหน่วย ของ 2ตัวล่าง เลขคู่คือ
                                0,2,4,6,8 และคี่คือ 1,3,5,7,9
                            </div></li> <li><div>
                                รางวัล สูงต่ำบน จะใช้เลขหลักสิบ ของ 2ตัวบน ต่ำคือเลข 0,1,2,3,4
                                และสูงคือเลข 5,6,7,8,9
                            </div></li> <li><div>
                                รางวัล สูงต่ำล่าง จะใช้เลขหลักสิบ ของ 2ตัวล่าง ต่ำคือเลข
                                0,1,2,3,4 และสูงคือเลข 5,6,7,8,9
                            </div></li></ul> <div><br /></div> <div><br /></div> <div>&nbsp;</div> <br /></div>
                    </div>
                </div>
            </div>
        </div>
    </div>



}

export default Rules