import React, { useState, useEffect } from 'react';
import { Button, Typography, Row, Col, Card, Table, DatePicker, Select, Checkbox, Pagination, Tag } from 'antd';
import { MENU_ITEMS, ROLE_RENDER } from '../../../util/options';
import { CheckCircleOutlined, CloseCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { DATE_FORMAT, DB, ranges } from '../../../util/constant';

import AgentService from '../../../services/user/agent.service';
import moment from 'moment';
import 'moment/locale/th';
import dayjs from 'dayjs';

moment.updateLocale('th', {
    week: {
        dow: 1
    }
})

const { RangePicker } = DatePicker;
const LoginHistory = () => {
    const params = {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '50', '100', '250', '500'],
        dateRange: { from: moment().format('YYYY-MM-DD'), to: moment().format('YYYY-MM-DD') }
    }

    const [loadingResult, setLoadingResult] = useState(false)

    const [data, setData] = useState([])
    const [total, setTotal] = useState(undefined)
    const [users, setUsers] = useState([])

    const [dateRange, setDateRange] = useState({ ...params.dateRange })
    const [dateValues, setDateValues] = useState([moment(), moment()])
    const [displayInfo, setDisplayInfo] = useState({
        diff: dayjs().diff(dayjs(), 'day') + 1,
        from: DATE_FORMAT.dateonly(),
        to: DATE_FORMAT.dateonly(),
    })
    const [pagination, setPagination] = useState({ ...params })


    const [nameIsShow, setNameIsShow] = useState(false)

    const [selectedUser, setSelectedUser] = useState(null)
    const [selectedType, setSelectedType] = useState('all')


    const onRangeChange = (dates, dateStrings) => {
        if (!dates) return
        let dateObj = {
            "from": dateStrings[0],
            "to": dateStrings[1],
        }
        setDateValues(dates)
        setDateRange(dateObj)
    }

    const search = async () => {
        setLoadingResult(true)
        setDisplayInfo({
            diff: dayjs(dateRange.to).diff(dateRange.from, 'day') + 1,
            from: DATE_FORMAT.dateonly(dateRange.from),
            to: DATE_FORMAT.dateonly(dateRange.to),
        })
        const res = await AgentService.downlineLoginHistory({ userId: selectedUser, dateRange: dateRange, type: selectedType, pageSize: pagination.pageSize, current: 1 })
        setPagination({ ...pagination, current: 1 })
        setData(res.results);
        setTotal(res.total)
        setLoadingResult(false)
    }

    const pageChange = (pagination) => {
        setLoadingResult(true)
        AgentService.downlineLoginHistory({ userId: selectedUser, dateRange: dateRange, type: selectedType, ...pagination })
            .then(async res => {
                setPagination({ ...pagination, total: res.total })
                setData(res.results);
                setTotal(res.total)
                setLoadingResult(false)
            })
    }

    useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        setLoadingResult(true);
        const res = await AgentService.downlineLoginHistory({ userId: null, dateRange: dateRange, current: params.current, pageSize: params.pageSize })
        const users = await AgentService.listUserAll();
        setData(res.results);
        setTotal(res.total)
        setUsers(users)
        setLoadingResult(false);
    }



    return (
        <div className='agent-page-group report-login-history-page'>
            <div className='page-title'>
                <Typography className='title'>{MENU_ITEMS.loginHistory.label}</Typography>
            </div>

            <div className='page-content-wrapper'>
                <div className='page-content'>
                    <div className="search-section">
                        <Card title="ตัวเลือกการค้นหา" size="small">
                            <Row gutter={[16, 16]} justify='start'>
                                <Col xs={12}>
                                    <Row gutter={[16, 16]} justify="left">
                                        <Col>
                                        <RangePicker
                                                    ranges={{ ...ranges }}
                                                    onChange={onRangeChange}
                                                    value={dateValues}
                                                    allowEmpty={[false, false]}
                                                    allowClear={false}
                                                />
                                        </Col>
                                        <Col>
                                            <Select
                                                allowClear
                                                showSearch
                                                style={{ width: 200, maxWidth: '30vw' }}
                                                placeholder="ค้นหาสมาชิก"
                                                optionFilterProp="children"
                                                onChange={setSelectedUser}
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                            >
                                                {users.map((user) =>
                                                    <Select.Option key={user.id} value={user.id}>{`${user.username} - [${user.name}]`}</Select.Option>
                                                )}
                                            </Select>
                                        </Col>
                                        <Col>
                                            <Select
                                                style={{ width: 200, maxWidth: '30vw' }}
                                                placeholder="ประเภทสมาชิก"
                                                onChange={setSelectedType}
                                                value={selectedType}
                                            >
                                                <Select.Option value={'all'}>ทั้งหมด</Select.Option>
                                                <Select.Option value={DB.USER.ROLE.AGENT}>{ROLE_RENDER[DB.USER.ROLE.AGENT]}</Select.Option>
                                                <Select.Option value={DB.USER.ROLE.MEMBER}>{ROLE_RENDER[DB.USER.ROLE.MEMBER]}</Select.Option>
                                            </Select>
                                        </Col>
                                        <Col xs={24}>
                                            <Pagination
                                                disabled={loadingResult}
                                                defaultPageSize={params.pageSizeOptions[0]}
                                                current={pagination.current}
                                                onChange={(page, size) => {
                                                    pageChange({ current: page, pageSize: size })
                                                }} defaultCurrent={1} total={total} showSizeChanger pageSizeOptions={params.pageSizeOptions} />
                                        </Col>
                                        <Col><Button loading={loadingResult} type="primary" onClick={search}><SearchOutlined /> ค้นหา</Button></Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card>
                    </div>

                    <div className='displayInfo-section'>
                        <div>ข้อมูลรวม <span className='text-blue'>{displayInfo.diff}</span> วัน
                            ตั้งแต่วันที่ <span className='text-blue'>{displayInfo.from}</span> ถึง <span className='text-blue'>{displayInfo.to}</span></div>
                        <div>{<span className='text-secondary'>จำนวน {total} รายการ   </span>}</div>
                    </div>

                    <div className="table-wrapper my-2">
                        <Table
                            size='small'
                            rowKey={record => record.id}
                            dataSource={data}
                            scroll={{ x: 'max-content' }}
                            columns={[
                                {
                                    title: '#',
                                    dataIndex: 'index',
                                    key: 'index',
                                    align: 'center',
                                    width: '10%',
                                    render (value, record, i) {
                                        return i + 1
                                    },
                                },
                                {
                                    title: 'วันที่/เวลา',
                                    dataIndex: 'createdAt',
                                    key: 'createdAt',
                                    align: 'center',
                                    width: '10%',
                                    render: value => (
                                        DATE_FORMAT.default(value)
                                    )
                                },
                                {
                                    title: <div>
                                        <div>ชื่อผู้ใช้</div>
                                        <div><Checkbox checked={nameIsShow} onChange={() => setNameIsShow(!nameIsShow)}>แสดงชื่อ</Checkbox></div>
                                    </div>,
                                    dataIndex: 'username',
                                    key: 'username',
                                    width: '15%',
                                    render (value, record) {
                                        const name = nameIsShow && <span className='text-gray'>({record.name})</span>
                                        return <Typography>{value} {name}</Typography>
                                    }
                                },
                                {
                                    title: 'ระดับ',
                                    dataIndex: 'role',
                                    key: 'role',
                                    align: 'center',
                                    width: '10%',
                                    render (value) {
                                        return ROLE_RENDER[value]
                                    },
                                    filteredValue: (selectedType === "all" ? [DB.USER.ROLE.AGENT, DB.USER.ROLE.MEMBER] : [selectedType]) || null,
                                    onFilter: (value, record) => record.role == value,
                                },
                                {
                                    title: 'สถานะ',
                                    dataIndex: 'remark',
                                    key: 'remark',
                                    align: 'center',
                                    render (value) {
                                        return value === 'login.success' ? <Tag icon={< CheckCircleOutlined />} color="success" >สำเร็จ </Tag >
                                            : <Tag icon={< CloseCircleOutlined />} color="error" >ล้มเหลว </Tag >
                                    },
                                },
                                {
                                    title: 'ip address',
                                    dataIndex: 'ip_address',
                                    key: 'ip_address',
                                    align: 'center',
                                },
                            ]}
                            className="table-elm shadowed"
                            pagination={false}
                            loading={loadingResult}
                        />
                    </div>
                </div>
            </div>


        </div>
    )
}

export default LoginHistory;