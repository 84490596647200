import React, { useState, useEffect } from 'react';
import { Typography, Row, Col, message, DatePicker, Input, Table, Card, Pagination, Select, Button } from 'antd';
import { MENU_ITEMS } from '../../util/options';
import { dateFormats, DATE_FORMAT, DB, MSG, NUMBER_FORMAT, ranges } from '../../util/constant';

import dayjs from 'dayjs'
import 'dayjs/locale/th'

import moment from 'moment';
import 'moment/locale/th';
import MemberService from '../../services/user/member.service';
import { SearchOutlined } from '@ant-design/icons';
moment.updateLocale('th', {
    week: {
        dow: 1
    }
})

const { RangePicker } = DatePicker;

const Transactions = () => {
    const [loadingResult, setLoadingResult] = useState(false)

    const [data, setData] = useState([])
    const [total, setTotal] = useState(undefined)
    const [type, setType] = useState("all")
    const params = {
        keyword: '',
        type: ["all"],
        current: 1,
        pageSize: 50,
        pageSizeOptions: ['50', '100', '250', '500', '1000', '2500'],
        dateRange: { from: moment().format('YYYY-MM-DD'), to: moment().format('YYYY-MM-DD') }
    }
    const [pagination, setPagination] = useState({ current: params.current, pageSize: params.pageSize })
    const [keyword, setKeyword] = useState('')
    const [stgKeyword, setStgKeyword] = useState('')
    const [dateRange, setDateRange] = useState({ ...params.dateRange })
    const [dateValues, setDateValues] = useState([moment(), moment()])

    const [displayInfo, setDisplayInfo] = useState({
        diff: dayjs().diff(dayjs(), 'day') + 1,
        from: DATE_FORMAT.dateonly(),
        to: DATE_FORMAT.dateonly(),
    })

    const onRangeChange = (dates, dateStrings) => {
        if (!dates) return
        let dateObj = {
            "from": dateStrings[0],
            "to": dateStrings[1],
        }
        setDateValues(dates)
        setDateRange(dateObj)
    }

    const search = async (_dateRange = dateRange) => {
        setLoadingResult(true)
        setDisplayInfo({
            diff: dayjs(_dateRange.to).diff(_dateRange.from, 'day') + 1,
            from: DATE_FORMAT.dateonly(_dateRange.from),
            to: DATE_FORMAT.dateonly(_dateRange.to),
        })
        const res = await MemberService.getTransactions({ keyword: keyword, dateRange: _dateRange, pageSize: pagination.pageSize, current: 1 })
        setPagination({ ...pagination, current: 1 })
        setData(res.results);
        setTotal(res.total)
        setLoadingResult(false)
    }

    const pageChange = (pagination) => {
        setLoadingResult(true)
        MemberService.getTransactions({ keyword: keyword, ...pagination, dateRange: dateRange })
            .then(async res => {
                setPagination({ ...pagination })
                setData(res.results);
                setTotal(res.total)
                setLoadingResult(false)
            })
    }

    useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        setLoadingResult(true);
        const res = await MemberService.getTransactions({ ...params })
        setData(res.results);
        setTotal(res.total)
        setLoadingResult(false)
    }

    return (
        <div className='member-page-group transactions-page'>
            <div className='page-title'>
                <Typography className='title'>{MENU_ITEMS.memberTransactions.label}</Typography>
            </div>


            <div className='page-content-wrapper'>
                <div className='page-content'>

                    <div className="search-section">
                        <Card title="ตัวเลือกการค้นหา" size="small">
                            <Row gutter={[16, 16]} justify='start'>
                                <Col xs={24}>
                                    <RangePicker
                                        ranges={{ ...ranges }}
                                        onChange={onRangeChange}
                                        value={dateValues}
                                        allowEmpty={[false, false]}
                                        allowClear={false}
                                    />
                                </Col>
                                <Col>
                                    <div className='search'>
                                        <Input.Search
                                            allowClear
                                            onSearch={(value) => setKeyword(value)}
                                            value={stgKeyword}
                                            onChange={(e) => setStgKeyword(e.target.value)}
                                            placeholder="ค้นหา: หมายเหตุ.." />
                                    </div>
                                </Col>
                                <Col>
                                    รายการ  <Select onChange={(v) => setType(v)} style={{ width: 100 }} value={type}>
                                        <Select.Option value={'all'}>ทั้งหมด</Select.Option>
                                        {[...DB.USER.TRANSACTION.TYPE_FILTER].map(t => (
                                            <Select.Option key={t.key} value={t.key}>{t.title}</Select.Option>
                                        ))}
                                    </Select>
                                </Col>
                                <Col xs={24}>
                                    <Pagination
                                        disabled={loadingResult}
                                        defaultPageSize={params.pageSizeOptions[0]}
                                        current={pagination.current}
                                        onChange={(page, size) => {
                                            pageChange({ current: page, pageSize: size })
                                        }} defaultCurrent={1} total={total} showSizeChanger pageSizeOptions={params.pageSizeOptions} />
                                </Col>
                                <Row gutter={[16, 16]}>
                                    {Object.entries(ranges)?.map(([key, value]) => {
                                        return <Col key={key}><Button onClick={() => {
                                            let dateObj = {
                                                "from": moment(value[0]).format(dateFormats.only_date),
                                                "to": moment(value[1]).format(dateFormats.only_date),
                                            }
                                            onRangeChange([...value], [dateObj.from, dateObj.to])
                                            search(dateObj)
                                        }
                                        }>{key}</Button></Col>
                                    })}
                                </Row>
                                <Col xs={24}><Button loading={loadingResult} type="primary" onClick={() => search()}><SearchOutlined /> ค้นหา</Button></Col>
                            </Row>
                        </Card>
                    </div>

                    <div className='displayInfo-section'>
                        <div>ข้อมูลรวม <span className='text-blue'>{displayInfo.diff}</span> วัน
                            ตั้งแต่วันที่ <span className='text-blue'>{displayInfo.from}</span> ถึง <span className='text-blue'>{displayInfo.to}</span></div>
                        <div>{<span className='text-secondary'>จำนวน {total} รายการ   </span>}</div>
                    </div>

                    <div className="table-wrapper">
                        <Table
                            size='small'
                            rowKey={record => record.id}
                            dataSource={data}
                            scroll={{ x: 'max-content' }}
                            columns={[
                                {
                                    title: 'วันที่/เวลา',
                                    dataIndex: 'createdAt',
                                    key: 'createdAt',
                                    align: 'center',
                                    render: value => (
                                        DATE_FORMAT.default(value)
                                    )
                                },
                                {
                                    title: 'รายละเอียด',
                                    dataIndex: 'details',
                                    key: 'details',
                                    align: 'left',
                                    render: (value, record) => {
                                        const type = DB.USER.TRANSACTION.TYPE_FILTER.find((t) => t.key === record.type)?.title

                                        if (record.type === DB.USER.TRANSACTION.TYPE.BET ||
                                            record.type === DB.USER.TRANSACTION.TYPE.CANCELBET ||
                                            record.type === DB.USER.TRANSACTION.TYPE.WIN) {
                                            const groupName = DB.SYSTEM.LOTTO.GROUP.find((g) => g.id === record.groupId)?.name
                                            return `${type} [${groupName}] - ${record.name} - ${DATE_FORMAT.dateonly(record.date)} ${record.orderRemark ? `#${record.orderRemark}` : ''}`
                                        }
                                        else {
                                            return `${type}`
                                        }
                                    },
                                    filteredValue: (type === "all" ? Object.keys(DB.USER.TRANSACTION.TYPE) : [type]) || null,
                                    onFilter: (value, record) => record.type === value,
                                },
                                {
                                    title: 'เพิ่มเติม',
                                    dataIndex: 'remark',
                                    key: 'remark',
                                    align: 'left',
                                },
                                {
                                    title: 'เงินออก',
                                    dataIndex: 'amount',
                                    key: 'amount',
                                    align: 'right',
                                    render: (value, record) => {
                                        if ([DB.USER.TRANSACTION.TYPE.BET, DB.USER.TRANSACTION.TYPE.WITHDRAW].includes(record.type)) {
                                            return <span className='text-red'>{NUMBER_FORMAT.default(value)}</span>
                                        }
                                        return '-'
                                    }
                                },
                                {
                                    title: 'เงินเข้า',
                                    dataIndex: 'amount',
                                    key: 'amount',
                                    align: 'right',
                                    render: (value, record) => {
                                        if ([DB.USER.TRANSACTION.TYPE.WIN, DB.USER.TRANSACTION.TYPE.DEPOSIT, DB.USER.TRANSACTION.TYPE.CANCELBET].includes(record.type)) {
                                            return <span className='text-green'>{NUMBER_FORMAT.default(value)}</span>
                                        }
                                        return '-'
                                    }
                                },
                                {
                                    title: 'คงเหลือ',
                                    dataIndex: 'balance',
                                    key: 'balance',
                                    align: 'right',
                                    render: (value) => {
                                        return <span className='text-blue'>{NUMBER_FORMAT.default(value)}</span>
                                    }
                                },
                            ]}
                            className="table-elm shadowed"
                            pagination={false}
                            loading={loadingResult}
                        />
                    </div>
                </div>
            </div>
        </div >

    )
}

export default Transactions;