import React from 'react';
import { Result, Button } from 'antd';
import {
    Link,
} from "react-router-dom";
const UnderConstruction = () => {
    return (
        <div className="page-404">
            <Result
                status="403"
                title="403"
                subTitle="ขออภัย หน้านี้อยู่ระหว่างการปรับปรุง"
                extra={<Button type="primary"><Link to='/'>กลับหน้าหลัก</Link></Button>}
            />
        </div>
    )
}

export default UnderConstruction;