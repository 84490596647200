import React, { useState, useEffect } from 'react';

import { Breadcrumb, Button, Col, Dropdown, Form, Input, InputNumber, Menu, message, Modal, Row, Space, Spin, Table, Typography } from 'antd';
import { default_tag, ELM_STANDARD, MENU_ITEMS, ROLE_RENDER } from '../../util/options';
import { useUserContext } from '../../contexts/UserContext';
import { DATE_FORMAT, DB, MSG, NUMBER_FORMAT, openNotification } from '../../util/constant';
import { CloseOutlined, DownOutlined, EditOutlined, KeyOutlined, LockOutlined, MinusOutlined, PlusOutlined, SaveOutlined, UserOutlined } from '@ant-design/icons';
import AdminService from '../../services/user/admin.service';

const AdminListUser = () => {
    const params = {
        keyword: '',
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '50', '100'],
        total: 0
    }

    const [pagination, setPagination] = useState({ ...params })

    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([])
    const [filterTable, setFilterTable] = useState([])
    const [breadcrumbs, setBreadcrumbs] = useState([])
    const { user } = useUserContext();
    const [keyword, setKeyword] = useState('')

    const [modalData, setModalData] = useState(null)
    const [modalVisible, setModalVisible] = useState({ edit: false, changePassword: false, deposit: false });
    const [isModalLoading, setIsModalLoading] = useState(false);

    const [editForm] = Form.useForm();
    const [changePasswordForm] = Form.useForm();
    const [depositForm] = Form.useForm();
    const [withdrawForm] = Form.useForm();

    const forms = { edit: editForm, changePassword: changePasswordForm, deposit: depositForm, withdraw: withdrawForm }
    const UserActionsService = {
        edit: AdminService.updateUser,
        changePassword: AdminService.changeUserPassword,
        deposit: AdminService.depositToUser,
        withdraw: AdminService.withdrawFromUser
    }

    const pageChange = (pagination) => {
        setLoading(true)
        AdminService.listUser({ userId: breadcrumbs[breadcrumbs.length - 1].id, ...pagination, keyword: keyword })
            .then(async res => {
                setPagination({ ...pagination, total: res.total })
                setList(res.results)
                setFilterTable(res.results)
                setLoading(false)
            })
    }

    const onSearch = async () => {
        setLoading(true)
        const data = await AdminService.listUser({ userId: breadcrumbs[breadcrumbs.length - 1].id, ...pagination, keyword: keyword })
        setList(data.results)
        setFilterTable(data.results)
        setPagination({ ...pagination, total: data.total })
        setLoading(false)
    }

    const submit = async (param) => {
        try {
            await forms[param].validateFields()

            setIsModalLoading(true)
            const send = await UserActionsService[param]({ ...forms[param].getFieldsValue(), userId: modalData.id })
            setIsModalLoading(false)
            if (send === `OK`) {
                forms[param].resetFields()
                setModalVisible({ ...modalVisible, [param]: false })
                message.success(MSG.COMMON.UPDATE.SUCCEED)
                setLoading(true)
                prepareData();
            } else if (send === `NOT_ENOUGH_BALANCE`) {
                message.error(MSG.COMMON.VALIDATE.NOT_ENOUGH_BALANCE)
            } else {
                message.error(MSG.COMMON.VALIDATE.FAILED)
            }


            setLoading(false);
        } catch (err) {
            message.error(MSG.COMMON.VALIDATE.FAILED)
            setLoading(false);
        }
    }


    const closeModal = (param) => {
        setModalVisible({ ...modalVisible, [param]: false });
        setModalData(null);
        forms[param].resetFields()
    }

    const changeUser = async (param) => {
        if (param.id === breadcrumbs[breadcrumbs.length - 1].id) {
            openNotification('info', `ระบบได้แสดงผลสมาชิก ${param.username} อยู่แล้ว`, '', 'bottomLeft')
            return
        }

        try {
            setLoading(true)
            const data = await AdminService.listUser({ userId: param.id, ...pagination, keyword: keyword })
            setList(data.results)
            setFilterTable(data.results)
            setPagination({ ...pagination, total: data.total })
            let index = breadcrumbs.findIndex(b => b.id === param.id)

            if (index === -1) {
                const bc = [...breadcrumbs]
                bc.push(param)
                setBreadcrumbs([...bc])
            } else {
                let newArr = JSON.parse(JSON.stringify(breadcrumbs));
                newArr.splice(index + 1)
                setBreadcrumbs(newArr)
            }
            setLoading(false)
        } catch (err) {
            setLoading(false)
        }
    }

    const resetUser = async () => {
        try {
            setLoading(true)
            const data = await AdminService.listUser({ ...pagination, keyword: keyword })
            setList(data.results)
            setFilterTable(data.results)
            setPagination({ ...pagination, total: data.total })
            setBreadcrumbs([{ id: user.id, username: user.username }])
            setLoading(false)
        } catch (err) {
            setLoading(false)
        }
    }

    const updateUserStatus = async (id) => {
        try {
            setLoading(true)
            const send = await AdminService.updateUserStatus({ userId: id })
            setIsModalLoading(false)
            if (send === `OK`) {
                message.success(MSG.COMMON.UPDATE.SUCCEED)
                prepareData();
            } else if (send === `UPLINELOCKED`) {
                message.success(MSG.COMMON.VALIDATE.UPLINE_LOCKED)
            } else {
                message.error(MSG.COMMON.VALIDATE.FAILED)
            }
            setLoading(false);
        } catch (err) {
            message.error(MSG.COMMON.VALIDATE.FAILED)
            setLoading(false);
        }
    }



    useEffect(() => {
        prepareData();
    }, [])

    useEffect(() => {
        forms.edit.setFieldsValue(modalData)
    }, [forms.edit, modalData])

    const prepareData = async () => {
        const data = await AdminService.listUser({ ...pagination })
        setList(data.results)
        setFilterTable(data.results)
        setPagination({ ...pagination, total: data.total })
        setBreadcrumbs([{ id: user.id, username: user.username }])
        setLoading(false)
    }


    const modalProps = (param) => {
        return {
            visible: modalVisible[param],
            footer: [
                <Button key="back" onClick={() => closeModal(param)} icon={<CloseOutlined />}>ยกเลิก</Button>,
                <Button type="primary" key="submit" onClick={() => submit(param)} icon={<SaveOutlined />}>ยืนยัน</Button>
            ],
            onOk: () => submit(param),
            onCancel: () => closeModal(param),
            forceRender: true,
        }
    }

    const formProps = (param) => {
        return {
            form: forms[param],
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
            autoComplete: "new-password",
            initialValues: modalData
        }
    }

    const menu = (record) => {
        return <Menu
            items={[
                {
                    label: 'แก้ไขข้อมูล',
                    key: 'edit',
                    icon: <EditOutlined />,
                    onClick: () => { setModalVisible({ ...modalVisible, edit: true }); setModalData(record) }
                },
                {
                    label: 'เปลี่ยนรหัสผ่าน',
                    key: 'changePassword',
                    icon: <KeyOutlined />,
                    onClick: () => { setModalVisible({ ...modalVisible, changePassword: true }); setModalData(record) }
                },
                {
                    label: 'ฝาก',
                    key: 'deposit',
                    icon: <PlusOutlined />,
                    onClick: () => { setModalVisible({ ...modalVisible, deposit: true }); setModalData(record) }
                },
                {
                    label: 'ถอน',
                    key: 'withdraw',
                    icon: <MinusOutlined />,
                    onClick: () => { setModalVisible({ ...modalVisible, withdraw: true }); setModalData(record) }
                },
                {
                    type: 'divider',
                },
                {
                    label: record.status !== DB.USER.STATUS.LOCKED ? 'ล็อคบัญชี' : 'ปลดล็อคบัญชี',
                    key: 'lock',
                    icon: <LockOutlined />,
                    disabled: record.status === DB.USER.STATUS.UPLINELOCKED,
                    onClick: () => { updateUserStatus(record.id) }
                },
            ]}
        />
    }
    return (
        <div className='admin-page-group list-user-page'>
            <Spin {...ELM_STANDARD.spin} spinning={loading}>
                <div className='page-title'>
                    <Typography className='title'>{MENU_ITEMS.adminUserList.label}</Typography>
                </div>

                <div className='page-content-wrapper'>
                    <div className='page-content'>
                        <div className='breadcrumb-styled'>
                            <Breadcrumb separator=">" className='unselectable'>
                                {breadcrumbs.map((item, i) =>
                                    <Breadcrumb.Item key={item.id}
                                        onClick={() => i === 0 ? resetUser() : changeUser({ id: item.id, username: item.username })}>{item.username}</Breadcrumb.Item>
                                )}
                            </Breadcrumb>
                        </div>

                        <div className="header-wrapper">
                            <Row gutter={[{ xs: 16, sm: 16, md: 48, lg: 64 }, 16]} align="middle" justify='space-between' >
                                <Col span={24} className="text-right">
                                    <Space size={"large"}>
                                        <div className='search'>
                                            <Input.Search
                                                value={keyword}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    setKeyword(value)
                                                }}
                                                enterButton
                                                onSearch={() => onSearch()}
                                                placeholder="ค้นหา" />
                                        </div>
                                    </Space>
                                </Col>
                            </Row>
                        </div>

                        <Table
                            scroll={{ x: 'max-content' }}
                            rowKey={(record) => record.id}
                            pagination={pagination}
                            onChange={pageChange}
                            dataSource={filterTable}
                            columns={[
                                {
                                    title: 'ลำดับ',
                                    dataIndex: 'id',
                                    key: 'id',
                                    align: 'center',
                                },
                                {
                                    title: 'ชื่อผู้ใช้',
                                    dataIndex: 'username',
                                    key: 'username',
                                    render (value, record) {
                                        return record.role === DB.USER.ROLE.MEMBER ? value : <Typography.Link onClick={() => changeUser({ id: record.id, username: record.username })}>{value}</Typography.Link>
                                    },
                                },
                                {
                                    title: 'ระดับ',
                                    dataIndex: 'role',
                                    key: 'role',
                                    align: 'center',
                                    render (value) {
                                        return ROLE_RENDER[value]
                                    },
                                },
                                {
                                    title: 'ชื่อ',
                                    dataIndex: 'name',
                                    key: 'name',
                                    align: 'center',
                                },
                                {
                                    title: 'โทรศัพท์',
                                    dataIndex: 'phone',
                                    key: 'phone',
                                    align: 'center',
                                },
                                {
                                    title: 'สมัครเมื่อ',
                                    dataIndex: 'createdAt',
                                    key: 'createdAt',
                                    align: 'center',
                                    render (value) {
                                        return DATE_FORMAT.thaiHalf(value)
                                    }
                                },
                                {
                                    title: 'เข้าใช้งานล่าสุด',
                                    dataIndex: 'latestLogin',
                                    key: 'latestLogin',
                                    align: 'center',
                                    render (value) {
                                        return value ? DATE_FORMAT.thaiHalf(value) : '-'
                                    },
                                },
                                {
                                    title: 'สถานะ',
                                    dataIndex: 'status',
                                    key: 'status',
                                    align: 'center',
                                    render (value) {
                                        return default_tag[value]
                                    },
                                },
                                {
                                    title: 'กระเป๋า',
                                    dataIndex: 'amount',
                                    key: 'amount',
                                    align: 'right',
                                    render (value) {
                                        return value ? <Typography.Text type="success">{NUMBER_FORMAT.default(value)}</Typography.Text> : "-"
                                    },
                                },
                                // {
                                //     title: 'เป็นลูกสายของ',
                                //     dataIndex: 'uplineUsername',
                                //     key: 'uplineUsername',
                                //     onCell: (record) => { return { onClick: () => changeUser({ id: record.upline_users_fk, username: record.uplineUsername }) } },
                                //     render (value, record) {
                                //         return value ? <Typography.Link>{value}</Typography.Link>
                                //             : '-'
                                //     },
                                // },
                                {
                                    title: 'Actions',
                                    dataIndex: 'name',
                                    key: 'name',
                                    align: 'center',
                                    render (value, record) {
                                        return <Dropdown overlay={() => menu(record)} trigger={['click']} disabled={breadcrumbs.length > 1}>
                                            <Button>
                                                <Space>
                                                    จัดการสมาชิก
                                                    <DownOutlined />
                                                </Space>
                                            </Button>
                                        </Dropdown>
                                    }
                                },
                            ]}
                        />
                    </div>
                </div >

                <Modal title={<span><UserOutlined /> แก้ไขข้อมูลสมาชิก</span>}
                    {...modalProps('edit')}>
                    <Spin  {...ELM_STANDARD.spin} spinning={isModalLoading}>
                        <Form
                            {...formProps('edit')}>
                            <Form.Item
                                name="name"
                                label="ชื่อ"
                                rules={[
                                    {
                                        required: true,
                                        message: 'กรุณาระบุชื่อ!',
                                        whitespace: true,
                                    },
                                    {
                                        max: 100,
                                        message: 'ความยาวไม่เกิน 100 ตัวอักษร!',
                                    },
                                ]}
                            >
                                <Input placeholder="Name" autoComplete="new-password" />
                            </Form.Item>

                            <Form.Item
                                name="phone"
                                label="โทรศัพท์"
                                rules={[
                                    {
                                        pattern: new RegExp(/^[0-9]{10,12}$/), message: 'กรุณาระบุเบอร์โทรให้ถูกต้อง!',
                                        warningOnly: true,
                                    }
                                ]}
                            >
                                <Input placeholder="Phone" autoComplete="new-password" />
                            </Form.Item>

                        </Form>
                    </Spin>
                </Modal>


                <Modal title={<span><KeyOutlined /> เปลี่ยนรหัสผ่าน</span>}
                    {...modalProps('changePassword')}>
                    <Spin  {...ELM_STANDARD.spin} spinning={isModalLoading}>
                        <Form
                            {...formProps('changePassword')}>
                            <Form.Item
                                name="password"
                                label=" รหัสผ่านใหม่"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'กรุณาระบุรหัสผ่าน!',
                                    },
                                    {
                                        min: 6,
                                        message: 'ความยาว 6 ตัวอักษรขึ้นไป!',
                                    },
                                    {
                                        max: 255,
                                        message: 'ความยาวไม่เกิน 255 ตัวอักษร!',
                                    },
                                ]}
                            >
                                <Input.Password placeholder="Password" />
                            </Form.Item>
                            <Form.Item
                                name="confirmPassword"
                                label="ยืนยันรหัสผ่านใหม่"
                                hasFeedback
                                dependencies={['password']}
                                rules={[
                                    {
                                        required: true,
                                        message: 'กรุณายืนยันรหัสผ่าน!',

                                    },
                                    {
                                        min: 6,
                                        message: 'ความยาว 6 ตัวอักษรขึ้นไป!',
                                    },
                                    {
                                        max: 255,
                                        message: 'ความยาวไม่เกิน 255 ตัวอักษร!',
                                    },
                                    {
                                        validator: async (rule, value) => {
                                            if (!value || forms.changePassword.getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('รหัสผ่านไม่ตรงกัน!'));
                                        }
                                    }
                                ]}
                            >
                                <Input.Password placeholder="Confirm password" />
                            </Form.Item>

                        </Form>
                    </Spin>
                </Modal>


                <Modal title={<span><PlusOutlined /> ฝาก</span>}
                    {...modalProps('deposit')}>
                    <Spin  {...ELM_STANDARD.spin} spinning={isModalLoading}>
                        <Form
                            {...formProps('deposit')}>
                            <Form.Item
                                label="จำนวน"
                                name="deposit_amount"
                                rules={[
                                    { required: true, message: 'กรุณาระบุ!' }
                                ]}
                            >
                                <InputNumber
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                    className='w-100'
                                    step={1}
                                    precision={2}
                                    min={0}
                                    placeholder={`ระบุจำนวนเงิน`} />
                            </Form.Item>

                        </Form>
                    </Spin>
                </Modal>

                <Modal title={<span><MinusOutlined /> ถอน</span>}
                    {...modalProps('withdraw')}>
                    <Spin  {...ELM_STANDARD.spin} spinning={isModalLoading}>
                        <Form
                            {...formProps('withdraw')}>
                            <Form.Item
                                label="จำนวน"
                                name="withdraw_amount"
                                rules={[
                                    { required: true, message: 'กรุณาระบุ!' }
                                ]}
                            >
                                <InputNumber
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                    className='w-100'
                                    step={1}
                                    precision={2}
                                    min={0}
                                    placeholder={`ระบุจำนวนเงิน`} />
                            </Form.Item>

                        </Form>
                    </Spin>
                </Modal>

            </Spin >
        </div >
    );
}

export default AdminListUser;
