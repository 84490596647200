import { Button, Col, Divider, Form, Input, InputNumber, Table, Row, Select, Spin, Tabs, Typography, Switch, Space, message } from "antd";
import React, { useState, useEffect } from "react";
import { MSG } from "../../util/constant";
import { ELM_STANDARD, MENU_ITEMS } from "../../util/options";
import AdminService from "../../services/user/admin.service";
import { NumberOutlined } from "@ant-design/icons";

const formStyled = {
    style: {
        marginBottom: 0
    }
}

const { TabPane } = Tabs;
const AdminAddAgent = () => {
    const [loading, setLoading] = useState(true);
    const [loadingRate, setLoadingRate] = useState(false);

    const [formData, setFormData] = useState([])
    const [users, setUsers] = useState([])
    const [selectedUser, setSelectedUser] = useState(null)

    const [form] = Form.useForm()
    const [initialValues, setInitialValues] = useState({})

    const [customLimitSelected, setCustomLimitSelected] = useState([])
    const [customRateSelected, setCustomRateSelected] = useState([])


    const customLimitAll = () => {
        setCustomLimitSelected(formData.map(item => item.id))
    }

    const cancelLimitAll = () => {
        setCustomLimitSelected([])
    }

    const customLimitAllAtOnce = () => {
        setCustomLimitSelected([])
    }

    const customRateAll = () => {
        setCustomRateSelected(formData.map(item => item.id))
    }

    const cancelRateAll = () => {
        setCustomRateSelected([])
    }

    const customRateAllAtOnce = () => {
        setCustomRateSelected([])
    }

    const submit = async (values) => {
        setLoading(true)
        try {
            const send = await AdminService.addAgent(values)
            if (send === `OK`) {
                form.resetFields()
                message.success(MSG.COMMON.ADD.SUCCEED)
            } else {
                message.error(MSG.COMMON.VALIDATE.FAILED)
            }
            setLoading(false);
        } catch (err) {
            message.error(MSG.COMMON.VALIDATE.FAILED)
            setLoading(false);
        }

    }

    useEffect(() => {
        prepareData();
    }, [])


    const prepareData = async () => {
        const data = await AdminService.getRegisterData();
        const users = await AdminService.listRecentAddAgent();
        setUsers(users)
        setFormData(data)
        const initial = {
            userRate: {
                ...data.map((value) => {
                    return {
                        id: value.id,
                        hold: value.userRate?.hold ?? 0,
                        uplineKeep: true,
                        status: value.status,
                        rate: value.rate.map((item) => {
                            return {
                                id: item.id,
                                min: item.min,
                                max: item.max,
                                rate: item.rate,
                                discount: item.discount,
                            }
                        })
                    }
                })
            }
        }
        setInitialValues(initial)
        setLoading(false)
    }


    useEffect(() => {
        prepareDataByUser(selectedUser)
    }, [selectedUser])

    const prepareDataByUser = async (user) => {
        setLoadingRate(true)
        let data;
        if (selectedUser) {
            data = await AdminService.getRegisterDataByAgent(user)
        } else {
            data = await AdminService.getRegisterData()
        }
        const initial = {
            userRate: {
                ...data.map((value) => {
                    return {
                        id: value.id,
                        hold: value.userRate?.hold ?? 0,
                        uplineKeep: true,
                        status: value.status,
                        rate: value.rate.map((item) => {
                            return {
                                id: item.id,
                                min: item.min,
                                max: item.max,
                                rate: item.rate,
                                discount: item.discount,
                            }
                        })
                    }
                })
            }
        }
        setInitialValues(initial)
        customLimitAll()
        customRateAll()
        setLoadingRate(false)
    }


    useEffect(() => {
        form.setFieldsValue(initialValues)
    }, [form, initialValues])

    return (
        <div className='admin-page-group add-agent-page'>
            <Spin {...ELM_STANDARD.spin} spinning={loading}>
                <div className='page-title'>
                    <Typography className='title'>{MENU_ITEMS.addAgent.label}</Typography>
                </div>

                <div className='page-content-wrapper'>
                    <div className='page-content'>
                        <Form form={form} layout="vertical" initialValues={initialValues} onFinish={submit}>
                            <Row gutter={[{ xs: 8, sm: 8, md: 16, lg: 16 }, 16]} >
                                <Col>
                                    <Form.Item label=" ">
                                        <Button icon={<NumberOutlined />}
                                            onClick={() => {
                                                form.setFieldsValue({ username: Math.random().toString(36).slice(9) })
                                                form.validateFields(['username'])
                                            }}>
                                            สุ่มชื่อผู้ใช้
                                        </Button>
                                    </Form.Item>
                                </Col>
                                <Col  xs={6} >
                                    <Form.Item
                                        name="username"
                                        label="ชื่อผู้ใช้"
                                        hasFeedback
                                        validateTrigger="onChange"
                                        rules={[
                                            { required: true, message: 'กรุณากรอกชื่อผู้ใช้!' },
                                            { max: 8, message: 'ความยาวไม่เกิน 8 ตัวอักษร!' },
                                            { min: 1, message: 'อย่างน้อย 1 ตัวอักษร!' },
                                            { pattern: new RegExp(/^[a-z0-9]+$/), message: "ตัวเลขและตัวอักษร a-z เท่านั้น" },
                                            {
                                                validator: async (rule, value) => {
                                                    if (value && value.length > 0) {
                                                        const isExists = await AdminService.isUsernameTaken(value)
                                                        if (isExists) {
                                                            return Promise.reject('ชื่อผู้ใช้นี้ถูกใช้ไปแล้ว')
                                                        }
                                                        else {
                                                            return Promise.resolve()
                                                        }
                                                    }
                                                }
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Username" autoComplete="new-password" />
                                    </Form.Item>
                                </Col>

                                <Col  xs={6} >
                                    <Form.Item
                                        name="password"
                                        label=" รหัสผ่าน"
                                        hasFeedback
                                        rules={[
                                            {
                                                required: true,
                                                message: 'กรุณาระบุรหัสผ่าน!',
                                            },
                                            {
                                                min: 6,
                                                message: 'ความยาว 6 ตัวอักษรขึ้นไป!',
                                            },
                                            {
                                                max: 255,
                                                message: 'ความยาวไม่เกิน 255 ตัวอักษร!',
                                            },
                                        ]}
                                    >
                                        <Input.Password placeholder="Password" />
                                    </Form.Item>
                                </Col>
                                <Col xs={6} >
                                    <Form.Item
                                        name="confirmPassword"
                                        label="ยืนยันรหัสผ่าน"
                                        hasFeedback
                                        dependencies={['password']}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'กรุณายืนยันรหัสผ่าน!',

                                            },
                                            {
                                                min: 6,
                                                message: 'ความยาว 6 ตัวอักษรขึ้นไป!',
                                            },
                                            {
                                                max: 255,
                                                message: 'ความยาวไม่เกิน 255 ตัวอักษร!',
                                            },
                                            {
                                                validator: async (rule, value) => {
                                                    if (!value || form.getFieldValue('password') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error('รหัสผ่านไม่ตรงกัน!'));
                                                }
                                            }
                                        ]}
                                    >
                                        <Input.Password placeholder="Confirm password" />
                                    </Form.Item>
                                </Col>
                                <Col  xs={6} >
                                    <Form.Item
                                        name="name"
                                        label="ชื่อ"
                                        hasFeedback
                                        rules={[
                                            {
                                                required: true,
                                                message: 'กรุณาระบุชื่อ!',
                                                whitespace: true,
                                            },
                                            {
                                                max: 100,
                                                message: 'ความยาวไม่เกิน 100 ตัวอักษร!',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Name" autoComplete="new-password" />
                                    </Form.Item>
                                </Col>
                                <Col   xs={6} >
                                    <Form.Item
                                        name="phone"
                                        label="โทรศัพท์"
                                        hasFeedback
                                        rules={[
                                            {
                                                pattern: new RegExp(/^[0-9]{10,12}$/), message: 'กรุณาระบุเบอร์โทรให้ถูกต้อง!',
                                                warningOnly: true,
                                            }
                                        ]}
                                    >
                                        <Input placeholder="Phone" autoComplete="new-password" />
                                    </Form.Item>
                                </Col>
                                <Col  xs={6} >
                                    <Form.Item
                                        name="remark"
                                        label="หมายเหตุ"
                                        hasFeedback
                                        rules={[
                                            {
                                                max: 255,
                                                message: 'ความยาวไม่เกิน 255 ตัวอักษร!',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Remark" autoComplete="new-password" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4} >
                                    <Form.Item
                                        label="เครดิตเริ่มต้น"
                                        name="credit"
                                        rules={[
                                            { required: true, message: 'กรุณาระบุ!' }
                                        ]}
                                    >
                                        <InputNumber
                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                            className='w-100'
                                            step={1}
                                            precision={2}
                                            min={0}
                                            placeholder={`ระบุจำนวนเงิน`} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Divider />

                            {/* tabs */}
                            <Row gutter={[8, 8]} justify="space-between">
                                <Col><Typography.Title level={4}>ตั้งค่าเริ่มต้น</Typography.Title></Col>
                                <Col>
                                    <Select
                                        allowClear
                                        showSearch
                                        style={{ width: 500, maxWidth: '30vw' }}
                                        placeholder="ค้นหาสมาชิก"
                                        optionFilterProp="children"
                                        onChange={setSelectedUser}
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    >
                                        {users.map((user) =>
                                            <Select.Option key={user.id} value={user.id}>{`${user.username} - [${user.name}]`}</Select.Option>
                                        )}
                                    </Select>
                                </Col>
                            </Row>

                            <Spin {...ELM_STANDARD.spin} spinning={loadingRate}>
                                <Tabs defaultActiveKey="1">
                                    <TabPane forceRender tab="เปิด/ปิดหวย แบ่งหุ้น/เก็บของ" key="1">
                                        <Table
                                            scroll={{ x: 'max-content' }}
                                            rowKey={(record) => record.id}
                                            pagination={false}
                                            dataSource={formData}
                                            columns={[{
                                                title: 'กลุ่ม',
                                                dataIndex: 'name',
                                                key: 'name',
                                                render: (value, record) => {
                                                    return <div>{record.id}. {record.name}</div>
                                                }
                                            },
                                            {
                                                title: 'เปิด/ปิดหวย',
                                                dataIndex: 'status',
                                                key: 'status',
                                                align: 'center',
                                                render: (value, record, i) => {
                                                    return <Form.Item name={['userRate', i, 'status',]} valuePropName="checked" {...formStyled}>
                                                        <Switch checkedChildren="เปิด" unCheckedChildren="ปิด" />
                                                    </Form.Item>
                                                }
                                            },
                                            {
                                                title: 'แบ่งหุ้น (ให้เอเจนต์)',
                                                dataIndex: 'hold',
                                                key: 'hold',
                                                render: (value, record, i) => {
                                                    let options = []
                                                    for (let j = Number(90); j >= 0; j -= 0.5) {
                                                        options.push(<Select.Option key={j} value={j}>{`${j}%`}</Select.Option>)
                                                    }

                                                    return <>
                                                        <Form.Item name={['userRate', i, 'id']} style={{ display: 'none' }}>
                                                            <Input type="hidden" />
                                                        </Form.Item>

                                                        <Form.Item name={['userRate', i, 'hold']} {...formStyled}>
                                                            <Select showSearch>
                                                                {options}
                                                            </Select>
                                                        </Form.Item>
                                                    </>
                                                }
                                            },
                                            {
                                                title: 'เข้าบริษัท',
                                                dataIndex: 'keep',
                                                key: 'keep',
                                                align: 'center',
                                                render: (value, record, i) => {
                                                    return <Form.Item shouldUpdate {...formStyled}>
                                                        {() => {
                                                            return `${100 - (form.getFieldValue(['userRate', i, 'hold']) ?? 0)}%`
                                                        }}
                                                    </Form.Item>
                                                }
                                            },
                                            {
                                                title: 'เก็บของเกิน',
                                                dataIndex: 'uplineKeep',
                                                key: 'uplineKeep',
                                                align: 'center',
                                                render: (value, record, i) => {
                                                    return <Form.Item name={['userRate', i, 'uplineKeep']} valuePropName="checked" {...formStyled}>
                                                        <Switch disabled checkedChildren="เก็บ" unCheckedChildren="ไม่เก็บ" />
                                                    </Form.Item>
                                                }
                                            },
                                            ]}
                                        />
                                    </TabPane>
                                    <TabPane forceRender tab="ข้อมูลการแทง" key="2">
                                        <Space direction="vertical" className="w-100" size={'large'}>
                                            <Space className="w-100">
                                                <Button type="primary" onClick={customLimitAll}>เลือกทั้งหมด</Button>
                                                <Button type="primary" onClick={cancelLimitAll}>ยกเลิกทั้งหมด</Button>
                                                {/* TODO: ux */}
                                                <Button type="danger" onClick={customLimitAllAtOnce} disabled>ตั้งค่าทั้งหมด</Button>
                                            </Space>
                                            <Select
                                                showSearch
                                                optionFilterProp="children"
                                                mode="multiple"
                                                allowClear
                                                className="w-100"
                                                placeholder="เลือกหวย"
                                                value={customLimitSelected}
                                                onChange={(value) => setCustomLimitSelected(value)}
                                                filterOption={(input, option) => option.children.includes(input.toLowerCase())}
                                            >
                                                {formData.map((item, i) => {
                                                    return <Select.Option key={i} value={item.id}>{`${item.id}. ${item.name}`}</Select.Option>
                                                })}
                                            </Select>

                                            {formData.map((item, index) => {
                                                const isDisplaying = customLimitSelected.includes(item.id)
                                                return <div key={item.id} className={isDisplaying ? '' : 'display-none'}>
                                                    <Typography.Title level={5}>{item.id}. {item.name}</Typography.Title>
                                                    <Table
                                                        scroll={{ x: 'max-content' }}
                                                        size={'small'}
                                                        key={`customLimitSelected-${item.id}`}
                                                        rowKey={(record) => record.id}
                                                        pagination={false}
                                                        dataSource={item?.rate}
                                                        columns={[
                                                            {
                                                                title: 'ประเภท',
                                                                dataIndex: 'name',
                                                                key: 'name',
                                                                render: (value,) => {
                                                                    return <div>{value}</div>
                                                                }
                                                            },
                                                            {
                                                                title: 'ขั้นต่ำ',
                                                                dataIndex: 'min',
                                                                key: 'min',
                                                                align: 'center',
                                                                render: (value, record, i) => {
                                                                    return <>  <Form.Item name={['userRate', index, 'rate', i, 'id']} style={{ display: 'none' }}>
                                                                        <Input type="hidden" />
                                                                    </Form.Item>
                                                                        <Form.Item name={['userRate', index, 'rate', i, 'min']}>
                                                                            <InputNumber min={1} max={100000} />
                                                                        </Form.Item>
                                                                    </>
                                                                }
                                                            },
                                                            {
                                                                title: 'สูงสุด',
                                                                dataIndex: 'max',
                                                                key: 'max',
                                                                align: 'center',
                                                                render: (value, record, i) => {
                                                                    return <Form.Item name={['userRate', index, 'rate', i, 'max']}>
                                                                        <InputNumber min={1} max={100000} />
                                                                    </Form.Item>
                                                                }
                                                            },
                                                        ]}
                                                    />
                                                </div>
                                            })

                                            }
                                        </Space>
                                    </TabPane>
                                    <TabPane forceRender tab="อัตราจ่าย" key="3">
                                        <Space direction="vertical" className="w-100" size={'large'}>
                                            <Space className="w-100">
                                                <Button type="primary" onClick={customRateAll}>เลือกทั้งหมด</Button>
                                                <Button type="primary" onClick={cancelRateAll}>ยกเลิกทั้งหมด</Button>
                                                {/* TODO: ux */}
                                                <Button type="danger" onClick={customRateAllAtOnce} disabled>ตั้งค่าทั้งหมด</Button>
                                            </Space>
                                            <Select
                                                showSearch
                                                optionFilterProp="children"
                                                mode="multiple"
                                                allowClear
                                                className="w-100"
                                                placeholder="เลือกหวย"
                                                value={customRateSelected}
                                                onChange={(value) => setCustomRateSelected(value)}
                                                filterOption={(input, option) => option.children.includes(input.toLowerCase())}
                                            >
                                                {formData.map((item, i) => {
                                                    return <Select.Option key={i} value={item.id}>{`${item.id}. ${item.name}`}</Select.Option>
                                                })}
                                            </Select>

                                            {formData.map((item, index) => {
                                                const isDisplaying = customRateSelected.includes(item.id)
                                                return <div key={item.id} className={isDisplaying ? '' : 'display-none'}>
                                                    <Typography.Title level={5}>{item.id}. {item.name}</Typography.Title>
                                                    <Table
                                                        scroll={{ x: 'max-content' }}
                                                        size={'small'}
                                                        key={`customRateSelected-${item.id}`}
                                                        rowKey={(record) => record.id}
                                                        pagination={false}
                                                        dataSource={item?.rate}
                                                        columns={[
                                                            {
                                                                title: 'ประเภท',
                                                                dataIndex: 'name',
                                                                key: 'name',
                                                                render: (value,) => {
                                                                    return <div>{value}</div>
                                                                }
                                                            },
                                                            {
                                                                title: 'จ่าย',
                                                                dataIndex: 'rate',
                                                                key: 'rate',
                                                                align: 'center',
                                                                render: (value, record, i) => {
                                                                    return <Form.Item name={['userRate', index, 'rate', i, 'rate']} {...formStyled}  >
                                                                        <InputNumber min={0} size={'small'} />
                                                                    </Form.Item>
                                                                }
                                                            },
                                                            {
                                                                title: 'คอม',
                                                                dataIndex: 'discount',
                                                                key: 'discount',
                                                                align: 'center',
                                                                render: (value, record, i) => {
                                                                    return <Form.Item name={['userRate', index, 'rate', i, 'discount']} {...formStyled}>
                                                                        <InputNumber min={0} max={100} size={'small'} />
                                                                    </Form.Item>
                                                                }
                                                            },
                                                        ]}
                                                    />
                                                </div>
                                            })

                                            }
                                        </Space>
                                    </TabPane>
                                </Tabs>
                            </Spin>
                            <Form.Item>

                                <Divider />
                                <Form.Item className="text-right" >
                                    <Button size={'large'} htmlType='submit' type="primary" key="submit">
                                        สมัครสมาชิก
                                    </Button>
                                </Form.Item>
                            </Form.Item>
                        </Form>
                    </div>
                </div >
            </Spin >
        </div >
    );
};

export default AdminAddAgent;
