import React, { useState, useEffect } from 'react';
import { Typography, Row, Col, Card, Table, Empty } from 'antd';
import { MENU_ITEMS } from '../../util/options';
import { DollarOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { useUserContext } from '../../contexts/UserContext';
import { DATE_FORMAT, DB, NUMBER_FORMAT } from '../../util/constant';
import LottoService from '../../services/system/lotto.service';

import moment from 'moment';
import 'moment/locale/th';
import { groupBy } from 'lodash';
moment.updateLocale('th', {
    week: {
        dow: 1
    }
})

const Landing = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({})
    const { user } = useUserContext();

    useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        setLoading(true);
        const res = await LottoService.getReportReward({ dateRange: { from: moment().format('YYYY-MM-DD'), to: moment().format('YYYY-MM-DD') } })
        setData(await processData(res.results))
        setLoading(false);
    }

    const processData = async (data) => {
        const groupedData = groupBy(data, 'groupId')
        return groupedData
    }

    const renderTables = () => {
        if (Object.entries(data).length > 0) {
            return Object.entries(data).map(([key, value]) => {
                return <div key={key} className="table-wrapper">
                    <div className='text-bold text-big'>< span >{(DB.SYSTEM.LOTTO.GROUP.find((g) => g.id === value[0].groupId))?.name}</span></div>
                    <Table
                         scroll={{ x: 'max-content' }}
                        size='small'
                        rowKey={record => record.id}
                        dataSource={value}
                        columns={[
                            {
                                title: '',
                                dataIndex: 'lottos_fk',
                                key: 'lottos_fk',
                                align: 'center',
                                width: '100px',
                                render: (value) => {
                                    return <img className='market-image-in-td' src={`/images/lotto/${value}.png`} alt="" />
                                }
                            },
                            {
                                title: 'ประเภทหวย',
                                dataIndex: 'name',
                                key: 'name',
                                align: 'center',
                                width: '200px',
                            },
                            {
                                title: 'งวด',
                                dataIndex: 'date',
                                key: 'date',
                                align: 'center',
                                width: '200px',
                                render: (value) => {
                                    return `${DATE_FORMAT.dateonly(value)}`
                                }
                            },
                            {
                                title: '3 ตัวบน',
                                dataIndex: 'threeUp',
                                key: 'threeUp',
                                align: 'center',
                                render: (text, record, index) => (
                                    record.result?.["3 ตัวบน"] ?? '-'
                                )
                            },
                            {
                                title: '2 ตัวบน',
                                dataIndex: 'twoUp',
                                key: 'twoUp',
                                align: 'center',
                                render: (text, record, index) => (
                                    record.result?.["2 ตัวบน"] ?? '-'
                                )
                            },
                            {
                                title: '2 ตัวล่าง',
                                dataIndex: 'twoBelow',
                                key: 'twoBelow',
                                align: 'center',
                                render: (text, record, index) => (
                                    record.result?.["2 ตัวล่าง"] ?? '-'
                                )
                            },
                        ]}
                        className="table-elm shadowed"
                        pagination={false}
                        loading={loading}
                    />
                </div>
            })
        } else {
            return <Empty />
        }
    }

    return (
        <div className='agent-page-group landing-page'>
            <div className='page-title'>
                <Typography className='title'>{MENU_ITEMS.home.label}</Typography>
            </div>

            <div className='page-content-wrapper'>
                <div className='page-content'>
                    <Row gutter={[16, 16]}>
                        <Col xs={12}>
                            <div className='content-wrapper'>
                                <Card title="จำนวนเงิน" size='small'>
                                    <Row justify='space-between' >
                                        <Col className='text-big'><DollarOutlined /></Col>
                                        <Col className='text-green text-bold text-big'>{NUMBER_FORMAT.default(user.balance)}</Col>
                                    </Row>
                                </Card>
                            </div>
                        </Col>
                        <Col xs={12}>
                            <div className='content-wrapper'>
                                <Card title="สมาชิก" size='small'>
                                    <Row justify='space-between' >
                                        <Col className='text-big'><UsergroupAddOutlined /></Col>
                                        <Col className='text-blue text-bold text-big'>{user.downline}</Col>
                                    </Row>
                                </Card>
                            </div>
                        </Col>
                        <Col xs={24}>
                            <div className='lotto-result-wrapper'>
                                <Card title="ผลหวยวันนี้">
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24}>
                                            <div className='tables-wrapper'>
                                                {renderTables()}
                                            </div>
                                        </Col>
                                    </Row>

                                </Card>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>


        </div>
    )
}

export default Landing;