import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AuthService from '../../services/auth.service';
import { useUserContext } from '../../contexts/UserContext';

import { Button, message, Form, Input, Typography, Row, Col } from 'antd';
import { LoginOutlined, KeyOutlined, UserOutlined } from '@ant-design/icons';

import '../../dist/css/login.less';

import { MSG } from '../../util/constant';

const Login = (props) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const { user, userAction } = useUserContext();
  const navigate = useNavigate();

  const handleLogin = () => {
    setLoading(true);
    AuthService.login(username, password).then(
      (res) => {
        if (res.status === 'OK') {
          userAction.setUser(res.info);
          message.success(MSG.COMMON.LOGIN.SUCCEED + res.info.username);
          setLoading(false);
          navigate('/');
        } else {
          if (res) {
            message.error(res.message);
          } else {
            message.error(MSG.COMMON.SMT_WRONG);
          }
          setLoading(false);
        }
      },
      (error) => {
        const resresponse =
          error.response?.data?.response || error.response || error.toString();
        if (resresponse?.data) {
          message.error(resresponse.data.message);
        } else {
          message.error(MSG.COMMON.SMT_WRONG);
        }
        setLoading(false);
      }
    );
  };

  return (
    <div className="login-wrapper w-100">
      <div className="login-container">
        <Row>
          <Col xs={24} className="login-panel-right">
            <Typography.Title
              className="login-logo"
              style={{
                color: '#fff',
                textShadow: '6px 6px 0px rgba(0,0,0,0.2)',
              }}
            >
              DUNCHESS
            </Typography.Title>
            <Form onFinish={handleLogin}>
              <Form.Item
                name="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                rules={[{ required: true, message: 'กรุณากรอกชื่อผู้ใช้!' }]}
              >
                <Input
                  placeholder="Username"
                  size="large"
                  prefix={<UserOutlined />}
                />
              </Form.Item>

              <Form.Item
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                rules={[{ required: true, message: 'กรุณากรอกรหัสผ่าน!' }]}
              >
                <Input.Password
                  placeholder="Password"
                  size="large"
                  prefix={<KeyOutlined />}
                />
              </Form.Item>

              <Form.Item style={{ float: 'right' }}>
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  เข้าสู่ระบบ
                  <LoginOutlined />
                </Button>
              </Form.Item>
            </Form>
            <Typography className="login-footer">
              Copyright © 2022 DUNCHESS LOTTO. All rights reserved.
            </Typography>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Login;
