import React, { useState, useEffect, useLayoutEffect } from 'react';
import {
    Link,
    useNavigate,
} from "react-router-dom";
import { Row, Col, Card, Statistic, Alert, Space, Empty, Button, Modal, Spin, Form, message, Input, Tooltip, Popconfirm } from 'antd';
import { ELM_STANDARD, lotto_date_tag, MENU_ITEMS } from '../../../util/options';
import { ClockCircleOutlined, CloseOutlined, EditOutlined, FileDoneOutlined, InfoCircleOutlined, LeftOutlined, LockOutlined, MehOutlined, MinusCircleOutlined, PlusOutlined, QuestionCircleOutlined, ReloadOutlined, SaveOutlined, SyncOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { ADMIN_INTERVAL_UPDATE, DATE_FORMAT, DB, MSG } from '../../../util/constant';
import { useLottoParamContext } from '../../../contexts/LottoParamContext';
import AdminService from '../../../services/user/admin.service';

import dayjs from 'dayjs'
import 'dayjs/locale/th'
import isBetween from 'dayjs/plugin/isBetween'
import { cloneDeep, uniq } from 'lodash';
dayjs.extend(isBetween)


const ResultAdd = () => {
    const [updatedAt, setUpdatedAt] = useState(null)
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({})
    const { lottoParam, lottoParamAction } = useLottoParamContext();
    const navigate = useNavigate();

    const [modalVisible, setModalVisible] = useState({ add: false, edit: false });
    const initialModalData = {
        add: {
            "3 ตัวโต๊ด": [""],
            "วิ่งบน": ["", "", ""],
            "วิ่งล่าง": ["", ""]
        },
        edit: {}
    }
    const [modalData, setModalData] = useState(cloneDeep(initialModalData));
    const [modalLoading, setModalLoading] = useState(false);


    const [addForm] = Form.useForm();
    const [editForm] = Form.useForm();

    const forms = { edit: editForm, add: addForm };
    const ActionService = {
        add: AdminService.lottoResultAdd,
        edit: AdminService.lottoResultUpdate,
        refund: AdminService.lottoDateRefund,
        reCalculate: AdminService.lottoDateReCalculate
    }

    const modalProps = (param) => {
        return {
            ...ELM_STANDARD.noTransitionModal,
            visible: modalVisible[param],
            footer: [
                <Button key="back" onClick={() => closeModal(param)} icon={<CloseOutlined />}>ยกเลิก</Button>,
                <Popconfirm
                    key="submit"
                    title={`กรุณายืนยัน?`}
                    onConfirm={() => submit(param)}
                    okText="ยืนยัน"
                    okType="danger"
                    cancelText="ยกเลิก"
                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}>
                    <Button type="primary" icon={<SaveOutlined />}>ยืนยัน</Button>
                </Popconfirm>
            ],
            onCancel: () => closeModal(param),
            forceRender: true,
        }
    }

    const formProps = (param) => {
        return {
            form: forms[param],
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 20 },
            },
            autoComplete: "new-password",
            initialValues: modalData[param],
        }
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 20 },
        },
    };
    const formItemLayoutWithOutLabel = {
        wrapperCol: {
            xs: { span: 24, offset: 0 },
            sm: { span: 20, offset: 4 },
        },
    };

    const numberInputHandler3 = (e, mode) => {
        if (e.target.validity.valid) {
            let value = e.target.value;
            if (value.length === 3) {
                updateThreeOdd(value, mode);
            }
        }
    }

    const updateThreeOdd = (n, mode) => {
        let result = [n.charAt(0) + n.charAt(1) + n.charAt(2)
            , n.charAt(0) + n.charAt(2) + n.charAt(1)
            , n.charAt(1) + n.charAt(0) + n.charAt(2)
            , n.charAt(1) + n.charAt(2) + n.charAt(0)
            , n.charAt(2) + n.charAt(0) + n.charAt(1)
            , n.charAt(2) + n.charAt(1) + n.charAt(0)]
        forms[mode].setFieldsValue({ '3 ตัวโต๊ด': uniq(result, [n]) })
    }


    const closeModal = (param) => {
        setModalVisible({ ...modalVisible, [param]: false });
        setModalData(cloneDeep(initialModalData));
        forms[param].resetFields()
    }

    const submit = async (param) => {
        try {
            setModalLoading(true)
            await forms[param].validateFields()

            // remove all empty value for each array
            const data = { ...forms[param].getFieldsValue() }
            for (let key in data) {
                if (Array.isArray(data[key])) {
                    data[key] = uniq(data[key].filter(item => item && item.trim() !== ""))
                }
            }

            // remove empty attribute that has empty array
            for (let key in data) {
                if (Array.isArray(data[key])) {
                    if (data[key].length === 0) {
                        delete data[key]
                    }
                } else if (data[key]?.trim() === "") {
                    delete data[key]
                }
            }

            const send = await ActionService[param]({ result: cloneDeep(data), lotto_dates_fk: lottoParam.lottoDate.selected.id })
            setModalLoading(false)
            if (send === `OK`) {
                forms[param].resetFields()
                setModalVisible({ ...modalVisible, [param]: false })
                message.success(MSG.COMMON.UPDATE.SUCCEED)
                prepareData();
            } else if (send === `LOTTO_DATE_NOT_PENDING`) {
                message.error(MSG.LOTTO_DATE.LOTTO_DATE_NOT_PENDING)
                prepareData();
            } else if (send === `LOTTO_DATE_CALCULATING`) {
                message.error(MSG.LOTTO_DATE.LOTTO_DATE_CALCULATING)
                prepareData();
            } else {
                message.error(MSG.COMMON.VALIDATE.FAILED)
            }
        } catch (err) {
            setModalLoading(false)
            message.error(MSG.COMMON.VALIDATE.FAILED)
        }
    }

    const refund = async () => {
        try {
            setLoading(true)
            const send = await ActionService.refund({ lotto_dates_fk: lottoParam.lottoDate.selected.id })
            setLoading(false)
            if (send === `OK`) {
                message.success(MSG.COMMON.UPDATE.SUCCEED)
                prepareData();
            } else {
                message.error(MSG.COMMON.VALIDATE.FAILED)
            }
        } catch (err) {
            message.error(MSG.COMMON.VALIDATE.FAILED)
            setLoading(false)
        }
    }

    const reCalculate = async () => {
        try {
            setLoading(true)
            const send = await ActionService.reCalculate({ lotto_dates_fk: lottoParam.lottoDate.selected.id })
            setLoading(false)
            if (send === `OK`) {
                message.success(MSG.COMMON.UPDATE.SUCCEED)
                prepareData();
            } else {
                message.error(MSG.COMMON.VALIDATE.FAILED)
            }
        } catch (err) {
            message.error(MSG.COMMON.VALIDATE.FAILED)
            setLoading(false)
        }
    }


    useEffect(() => {
        prepareData();
    }, [lottoParam.lottoDate.selected.id])

    useEffect(() => {
        forms.add.setFieldsValue(modalData.add)
    }, [forms.add, modalData.add])

    useEffect(() => {
        forms.edit.setFieldsValue(modalData.edit)
    }, [forms.edit, modalData.edit])

    const prepareData = async () => {
        if (lottoParam.lottoDate.selected.id) {
            setLoading(true);
            const res = await AdminService.prepareLottoResultAdd(lottoParam.lottoDate.selected.id)
            setData(res)
            setUpdatedAt(dayjs().add(ADMIN_INTERVAL_UPDATE, 'minute'))
            setLoading(false);
        }
    }

    const onVisibilityChange = () => {
        if (document.visibilityState === 'visible' && dayjs().isAfter(updatedAt)) {
            console.log("Tab reopened, refetch the data!");
            prepareData()
        }
    };

    useLayoutEffect(() => {
        document.addEventListener("visibilitychange", onVisibilityChange);
        return () => document.removeEventListener("visibilitychange", onVisibilityChange);
    }, [updatedAt]);

    const renderMarketContent = (m) => {
        let status = {}
        if (m.status === DB.SYSTEM.LOTTO_DATE.STATUS.PENDING) {
            if (dayjs().isBefore(m.openAt)) {
                status.key = 'willActive';
            } else if (dayjs().isBetween(m.openAt, m.closeAt)) {
                status.key = 'active';
            } else if (dayjs().isBetween(m.closeAt, m.postAt)) {
                status.key = 'pending';
            } else {
                status.key = 'late'
            }
        } else if (m.status === DB.SYSTEM.LOTTO_DATE.STATUS.CANCELED) {
            status.key = 'canceled'
        } else {
            status.key = 'inactive'
        }

        return <div className='h-100' >
            <div className={`market-item market-${status.key}`} >
                <Row wrap={false}>
                    <Col flex={1}>
                        <Row justify='space-between' gutter={[4, 4]}>
                            <Col xs={12}><div className="name">{m.name}</div><div className="date">{DATE_FORMAT.dateonly(m.date)}</div></Col>
                            <Col className='text-right' xs={12}>
                                <div className='market-image-wrapper'>
                                    <img className='market-image' src={`../images/lotto/${m.lottos_fk}.png`} alt="" />
                                </div>
                            </Col>
                            <Col xs={12}>สถานะ</Col><Col className='text-right' xs={12}>{lotto_date_tag[m.status]}</Col>
                            <Col xs={12}>เวลาเปิด</Col><Col className='text-right' xs={12}>{DATE_FORMAT.thaiHalf(m.openAt)}</Col>
                            <Col xs={12}>เวลาปิด</Col><Col className='text-right' xs={12}>{DATE_FORMAT.thaiHalf(m.closeAt)}</Col>
                            <Col xs={12}>เวลาออกผล</Col><Col className='text-right' xs={12}>{DATE_FORMAT.thaiHalf(m.postAt)}</Col>
                            {status.key === 'willActive' && (
                                <Col xs={24} className="text-right"><Statistic.Countdown prefix="เปิดรับใน" title="" value={m.openAt} /></Col>
                            )}
                            {status.key === 'active' && (
                                <Col xs={24} className="text-right"><Statistic.Countdown prefix="ปิดรับใน" title="" value={m.closeAt} /></Col>
                            )}
                            {status.key === 'pending' && (
                                <Col xs={24} className="text-center"><Alert message="ตลาดปิดรับ กรุณาออกผล" type="warning" showIcon /></Col>
                            )}
                            {status.key === 'late' && (
                                <Col xs={24} className="text-center"><Alert message="เลยเวลาออกผล กรุณาออกผลทันที" type="error" showIcon /></Col>
                            )}
                        </Row>
                    </Col>
                </Row>
            </div>
        </div >
    }

    const renderLottoResult = (result) => {
        return <Row gutter={[8, 8]}>
            {Object.entries(result).map(([key, value]) => {
                return key !== "result" && (
                    [<Col xs={12} key={`${key}1`}>{key}</Col>,
                    <Col xs={12} key={`${key}2`}>{Array.isArray(value) ? value.join(', ') : value}</Col>]
                )
            })}
        </Row>
    }

    const renderDynamicForm = (name, fields, add, remove, errors, limit) => {
        return <>
            {fields.map((field, index) => (

                <Form.Item
                    {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                    label={index === 0 ? name : ''}
                    required={false}
                    key={field.key}
                >
                    <Form.Item
                        {...field}
                        noStyle
                        rules={[
                            {
                                len: limit,
                                message: 'รูปแบบไม่ถูกต้อง',
                            },
                        ]}
                    >
                        <Input placeholder={index === 0 ? '' : name} maxLength={limit} style={{ width: '60%' }} autoComplete="new-password" />
                    </Form.Item>
                    {fields.length > 1 ? (
                        <MinusCircleOutlined
                            style={{ margin: '0 0 0 8px' }}
                            className="dynamic-delete-button"
                            onClick={() => remove(field.name)}
                        />
                    ) : null}
                </Form.Item>
            ))}
            <Form.Item {...formItemLayoutWithOutLabel}>
                <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{ width: '60%' }}
                    icon={<PlusOutlined />}
                >
                    เพิ่มเลข {name}
                </Button>
                <Form.ErrorList errors={errors} />
            </Form.Item>
        </>
    }



    return (
        <div className='admin-page-group result-add-page'>
            <div>
                <Button onClick={() => {
                    // lotto param context select this
                    lottoParamAction.selectLottoAndLottoDate(
                        {
                            id: null,
                            date: null,
                            status: null,
                        },
                        {
                            id: null,
                            groupId: null,
                            name: null,
                            groupName: null,
                        }
                    )
                    // navigate to lotto result add page
                    navigate(MENU_ITEMS.lottoResult.path)
                }} type='link' icon={<LeftOutlined />} size="large">
                    เปลี่ยนตลาด
                </Button>
            </div>

            {lottoParam.lotto.selected.id && (
                <div className='text-secondary text-center font-sarabun cursor-pointer'>
                    <Tooltip placement='top' title={<div className='text-center'>คลิกเพื่ออัปเดต</div>}>
                        <div>
                            {loading ? <SyncOutlined spin />
                                : <span onClick={prepareData}>อัปเดตล่าสุด: <ClockCircleOutlined /> {DATE_FORMAT.timeonly(dayjs(updatedAt).subtract(ADMIN_INTERVAL_UPDATE, 'minute'))}</span>}
                        </div>
                    </Tooltip>
                </div>
            )}


            <div className='page-content-wrapper'>
                <div className='page-content'>
                    <Row gutter={[16, 16]}>
                        <Col xs={12}>
                            <div className='market-wrapper'>
                                <Card title={<><InfoCircleOutlined /> ข้อมูลงวด</>} size='small'>
                                    {lottoParam.lotto.selected.id ? renderMarketContent(data)
                                        : <Alert
                                            style={{ margin: "1rem" }}
                                            description={<div>ยังไม่เลือกกลุ่มหวย</div>}
                                            type="info"
                                            showIcon
                                        />}
                                </Card>
                            </div>
                        </Col>
                        <Col xs={12}>
                            <div>
                                <Card title={<><ThunderboltOutlined /> Actions</>} size='small' className='card-items-centered card-actions'>
                                    {lottoParam.lotto.selected.id ?
                                        <Row gutter={[8, 8]} justify='center'>
                                            <Col><Link to={MENU_ITEMS.lottoClosedNumber.path} disabled={!lottoParam.lotto.selected.id}><Button type='primary' icon={<LockOutlined />}>ดูเลขอั้น</Button></Link></Col>
                                            <Col>
                                                <Popconfirm
                                                    disabled={!lottoParam.lotto.selected.id || data?.status !== DB.SYSTEM.LOTTO_DATE.STATUS.COMPLETED}
                                                    key="reCalculate"
                                                    title={`คำนวณโพยใหม่สำหรับงวดนี้?`}
                                                    onConfirm={() => reCalculate()}
                                                    okText="ยืนยัน"
                                                    okType="danger"
                                                    cancelText="ยกเลิก"
                                                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}>
                                                    <Button disabled={!lottoParam.lotto.selected.id || data?.status !== DB.SYSTEM.LOTTO_DATE.STATUS.COMPLETED} icon={<ReloadOutlined />}>คำนวณโพยใหม่</Button>
                                                </Popconfirm>
                                            </Col>
                                            <Col>
                                                <Popconfirm
                                                    disabled={!lottoParam.lotto.selected.id || data.status === DB.SYSTEM.LOTTO_DATE.STATUS.REFUNDED}
                                                    key="refund"
                                                    title={`ยกเลิกงวด คืนโพยให้เมมเบอร์?`}
                                                    onConfirm={() => refund()}
                                                    okText="ยืนยัน"
                                                    okType="danger"
                                                    cancelText="ยกเลิก"
                                                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}>
                                                    <Button disabled={!lottoParam.lotto.selected.id || data.status === DB.SYSTEM.LOTTO_DATE.STATUS.REFUNDED} type="primary" danger icon={<MehOutlined />}>ยกเลิกงวดคืนโพย</Button>
                                                </Popconfirm>
                                            </Col>
                                        </Row>
                                        : <Empty description="ไม่มีข้อมูล" />}
                                </Card>
                            </div>
                        </Col>
                        <Col xs={12}>
                            <div className='content-wrapper'>
                                <Card title={
                                    <Row justify='space-between'>
                                        <Col><FileDoneOutlined /> ผลหวย</Col>
                                        <Col>
                                            <Space className='text-right'>
                                                {data.result ?
                                                    <Button disabled={!lottoParam.lottoDate.selected.id} type="primary" icon={<EditOutlined />} onClick={() => { setModalVisible({ ...modalVisible, edit: true }); setModalData({ ...modalData, edit: cloneDeep(data.result) }) }}>แก้ไขผลหวย</Button>
                                                    : <Button disabled={!lottoParam.lottoDate.selected.id} type="primary" icon={<PlusOutlined />} onClick={() => setModalVisible({ ...modalVisible, add: true })}>เพิ่มผลหวย</Button>}
                                            </Space>
                                        </Col>
                                    </Row>} size='small'>
                                    {data.result ? renderLottoResult(data.result) :
                                        <>
                                            <Empty description="ไม่มีผลหวย" />
                                        </>
                                    }
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </div >
            </div >


            <Modal title={<span><PlusOutlined /> เพิ่มผลหวย</span>}
                {...modalProps('add')}>
                <Alert
                    style={{ marginBottom: "1rem" }}
                    description={<div>เพิ่มข้อมูลผลหวย <strong>ถ้าไม่มีให้ว่างไว้</strong></div>}
                    type="info"
                    showIcon
                />
                {data?.status === DB.SYSTEM.LOTTO_DATE.STATUS.REFUNDED && (
                    <Alert
                        style={{ marginBottom: "1rem" }}
                        description={<div>เพิ่มผลหวย งวดที่คืนโพยไปแล้วส่งผลให้ <strong>ยกเลิกการคืนโพย</strong></div>}
                        type="warning"
                        showIcon
                    />
                )}
                <Spin  {...ELM_STANDARD.spin} spinning={modalLoading}>
                    <Form
                        {...formProps('add')}>
                        <Form.Item
                            label="3 ตัวบน"
                            name="3 ตัวบน"
                            rules={[
                                {
                                    len: 3,
                                    message: 'รูปแบบไม่ถูกต้อง',
                                },
                            ]}
                        >
                            <Input
                                maxLength={3}
                                style={{ width: '60%' }}
                                pattern="[0-9]*" autoComplete="new-password" onChange={(e) => numberInputHandler3(e, 'add')} />
                        </Form.Item>

                        <Form.Item
                            label="3 ตัวล่าง"
                            name="3 ตัวล่าง"
                            rules={[
                                {
                                    len: 3,
                                    message: 'รูปแบบไม่ถูกต้อง',
                                },
                            ]}
                        >
                            <Input
                                maxLength={3}
                                style={{ width: '60%' }}
                                pattern="[0-9]*" autoComplete="new-password" />
                        </Form.Item>

                        <Form.List
                            name="3 ตัวโต๊ด"
                        >
                            {(fields, { add, remove }, { errors }) => (
                                renderDynamicForm('3 ตัวโต๊ด', fields, add, remove, errors, 3)
                            )}
                        </Form.List>

                        <Form.Item
                            label="2 ตัวบน"
                            name="2 ตัวบน"
                            rules={[
                                {
                                    len: 2,
                                    message: 'รูปแบบไม่ถูกต้อง',
                                },
                            ]}
                        >
                            <Input
                                maxLength={2}
                                style={{ width: '60%' }}
                                pattern="[0-9]*" autoComplete="new-password" />
                        </Form.Item>

                        <Form.Item
                            label="2 ตัวล่าง"
                            name="2 ตัวล่าง"
                            rules={[
                                {
                                    len: 2,
                                    message: 'รูปแบบไม่ถูกต้อง',
                                },
                            ]}
                        >
                            <Input
                                maxLength={2}
                                style={{ width: '60%' }}
                                pattern="[0-9]*" autoComplete="new-password" />
                        </Form.Item>

                        <Form.List
                            name="วิ่งบน"
                        >
                            {(fields, { add, remove }, { errors }) => (
                                renderDynamicForm('วิ่งบน', fields, add, remove, errors, 1)
                            )}
                        </Form.List>
                        <Form.List
                            name="วิ่งล่าง"
                        >
                            {(fields, { add, remove }, { errors }) => (
                                renderDynamicForm('วิ่งล่าง', fields, add, remove, errors, 1)
                            )}
                        </Form.List>

                    </Form>
                </Spin>
            </Modal>


            <Modal title={<span><EditOutlined /> แก้ไขผลหวย</span>}
                {...modalProps('edit')}>
                <Alert
                    style={{ marginBottom: "1rem" }}
                    description={<div>แก้ไขผลหวย <strong>ถ้าไม่มีให้ว่างไว้</strong></div>}
                    type="info"
                    showIcon
                />
                <Spin  {...ELM_STANDARD.spin} spinning={modalLoading}>
                    <Form
                        {...formProps('edit')}>
                        <Form.Item
                            label="3 ตัวบน"
                            name="3 ตัวบน"
                            rules={[
                                {
                                    len: 3,
                                    message: 'รูปแบบไม่ถูกต้อง',
                                },
                            ]}
                        >
                            <Input
                                maxLength={3}
                                style={{ width: '60%' }}
                                pattern="[0-9]*" autoComplete="new-password" onChange={(e) => numberInputHandler3(e, 'edit')} />
                        </Form.Item>

                        <Form.Item
                            label="3 ตัวล่าง"
                            name="3 ตัวล่าง"
                            rules={[
                                {
                                    len: 3,
                                    message: 'รูปแบบไม่ถูกต้อง',
                                },
                            ]}
                        >
                            <Input
                                maxLength={3}
                                style={{ width: '60%' }}
                                pattern="[0-9]*" autoComplete="new-password" />
                        </Form.Item>

                        <Form.List
                            name="3 ตัวโต๊ด"
                        >
                            {(fields, { add, remove }, { errors }) => (
                                renderDynamicForm('3 ตัวโต๊ด', fields, add, remove, errors, 3)
                            )}
                        </Form.List>

                        <Form.Item
                            label="2 ตัวบน"
                            name="2 ตัวบน"
                            rules={[
                                {
                                    len: 2,
                                    message: 'รูปแบบไม่ถูกต้อง',
                                },
                            ]}
                        >
                            <Input
                                maxLength={2}
                                style={{ width: '60%' }}
                                pattern="[0-9]*" autoComplete="new-password" />
                        </Form.Item>

                        <Form.Item
                            label="2 ตัวล่าง"
                            name="2 ตัวล่าง"
                            rules={[
                                {
                                    len: 2,
                                    message: 'รูปแบบไม่ถูกต้อง',
                                },
                            ]}
                        >
                            <Input
                                maxLength={2}
                                style={{ width: '60%' }}
                                pattern="[0-9]*" autoComplete="new-password" />
                        </Form.Item>

                        <Form.List
                            name="วิ่งบน"
                        >
                            {(fields, { add, remove }, { errors }) => (
                                renderDynamicForm('วิ่งบน', fields, add, remove, errors, 1)
                            )}
                        </Form.List>
                        <Form.List
                            name="วิ่งล่าง"
                        >
                            {(fields, { add, remove }, { errors }) => (
                                renderDynamicForm('วิ่งล่าง', fields, add, remove, errors, 1)
                            )}
                        </Form.List>

                    </Form>
                </Spin>
            </Modal>

        </div >
    )
}

export default ResultAdd;