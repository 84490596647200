import React, { useState, useEffect } from 'react';
import { Spin, Typography, Table, Tabs, Form, message, Button, Breadcrumb, Checkbox, Modal, Select, Switch, Empty, Tag } from 'antd';
import { ELM_STANDARD, MENU_ITEMS, ROLE_RENDER } from '../../../util/options';
import AgentService from '../../../services/user/agent.service';
import { useUserContext } from '../../../contexts/UserContext';
import { DB, MSG, openNotification } from '../../../util/constant';
import { CheckOutlined, CloseOutlined, EditOutlined, SaveOutlined, SettingOutlined } from '@ant-design/icons';
import { groupBy } from 'lodash';


const ReceiveSetting = () => {
    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([])
    const [breadcrumbs, setBreadcrumbs] = useState([])
    const [ownRate, setOwnRate] = useState([])

    const { user } = useUserContext();

    const [modalData, setModalData] = useState(null)
    const [modalVisible, setModalVisible] = useState({ edit: false });
    const [isModalLoading, setIsModalLoading] = useState(false);
    const [editForm] = Form.useForm();

    const [nameIsShow, setNameIsShow] = useState(false)

    const [bulkEditValue, setBulkEditValue] = useState(0)
    const [bulkEditSelectedLotto, setBulkEditSelectedLotto] = useState([])
    const [checkedList, setCheckedList] = useState([])
    const [bulkEditUplineKeep, setBulkEditUplineKeep] = useState(false)
    const handleCheckboxChange = id => (e) => {
        setCheckedList(e.target.checked ? [...checkedList, id] : checkedList.filter(item => item !== id))
    }
    const resetSelection = () => {
        setCheckedList([])
        setBulkEditSelectedLotto([])
        setBulkEditValue(0)
        setBulkEditUplineKeep(false)
    }

    const submitBulkEdit = async () => {
        if (breadcrumbs.length > 1) {
            message.error(MSG.COMMON.VALIDATE.DIRECT_DOWNLINE_ONLY)
            return
        }
        try {
            setLoading(true)
            const send = await ActionsService['bulkEdit']({ hold: bulkEditValue, lotto_groups_fk: bulkEditSelectedLotto, uplineKeep: bulkEditUplineKeep, userId: checkedList })
            setLoading(false)
            if (send === `OK`) {
                resetSelection()
                message.success(MSG.COMMON.UPDATE.SUCCEED)
                setLoading(true)
                prepareData();
            } else if (send === `HAS_DOWNLINE_RATE_HIGHER_THAN_THIS`) {
                message.error(`อัปเดตข้อมูลไม่สำเร็จ มีลูกสายที่มีอัตราการแบ่งหุ้นมากกว่าที่คุณกำหนด`)
            } else {
                message.error(MSG.COMMON.VALIDATE.FAILED)
            }
            setLoading(false);
        } catch (err) {
            message.error(MSG.COMMON.VALIDATE.FAILED)
            setLoading(false);
        }
    }

    const forms = { edit: editForm };
    const ActionsService = {
        edit: AgentService.updateDownlineHold,
        bulkEdit: AgentService.bulkUpdateDownlineHold
    }

    const modalProps = (param) => {
        return {
            visible: modalVisible[param],
            footer: [
                <Button key="back" onClick={() => closeModal(param)} icon={<CloseOutlined />}>ยกเลิก</Button>,
                <Button type="primary" key="submit" onClick={() => submit(param)} icon={<SaveOutlined />}>บันทึก</Button>
            ],
            onOk: () => submit(param),
            onCancel: () => closeModal(param),
            forceRender: true,
        }
    }

    const formProps = (param) => {
        return {
            form: forms[param],
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
            autoComplete: "new-password",
            initialValues: modalData
        }
    }

    const openEditModal = (record) => {
        setModalData(record)
        setModalVisible({ edit: true })
    }

    const renderForm = () => {
        let options = []
        for (let j = 0; j <= Number(ownRate.find((r) => r.id === modalData?.lotto_groups_fk)?.userRate.hold); j += 0.5) {
            options.push(<Select.Option key={j} value={j}>{`${j}%`}</Select.Option>)
        }
        let label1 = 'แบ่งหุ้น (ให้เอเยนต์)'
        let label2 = 'ปันผล (เข้าตัวเรา) :'
        if (modalData?.role === DB.USER.ROLE.MEMBER) {
            label1 = 'เปิดสู้สมาชิก (เข้าตัวเรา)'
            label2 = 'สูงสุดที่ได้รับ'
        }
        return <>
            <Form.Item label={label1} name={'hold'} >
                <Select>
                    {options}
                </Select>
            </Form.Item>

            <Form.Item label={label2} shouldUpdate >
                {() => {
                    if (modalData?.role === DB.USER.ROLE.AGENT) {
                        return `${Number(ownRate.find((r) => r.id === modalData?.lotto_groups_fk)?.userRate.hold) - (forms.edit.getFieldValue('hold') ?? 0)}%`
                    }
                    return `${Number(ownRate.find((r) => r.id === modalData?.lotto_groups_fk)?.userRate.hold)}%`
                }}
            </Form.Item>
        </>
    }

    const submit = async (param) => {
        try {
            setIsModalLoading(true)
            const send = await ActionsService[param]({ ...forms[param].getFieldsValue(), userId: modalData.users_fk, lotto_groups_fk: modalData.lotto_groups_fk })
            setIsModalLoading(false)
            if (send === `OK`) {
                forms[param].resetFields()
                setModalVisible({ ...modalVisible, [param]: false })
                message.success(MSG.COMMON.UPDATE.SUCCEED)
                setLoading(true)
                prepareData();
            } else if (send === `HAS_DOWNLINE_RATE_HIGHER_THAN_THIS`) {
                message.error(`อัปเดตข้อมูลไม่สำเร็จ มีลูกสายที่มีอัตราการแบ่งหุ้นมากกว่าที่คุณกำหนด`)
            } else {
                message.error(MSG.COMMON.VALIDATE.FAILED)
            }


            setLoading(false);
        } catch (err) {
            message.error(MSG.COMMON.VALIDATE.FAILED)
            setLoading(false);
        }
    }


    const closeModal = (param) => {
        setModalVisible({ ...modalVisible, [param]: false });
        setModalData(null);
        forms[param].resetFields()
    }

    const changeUser = async (param) => {
        if (param.id === breadcrumbs[breadcrumbs.length - 1].id) {
            openNotification('info', `ระบบได้แสดงผลสมาชิก ${param.username} อยู่แล้ว`, '', 'bottomLeft')
            return
        }
        try {
            resetSelection()
            setLoading(true)
            const res = await AgentService.getDownlineRate(param.id)
            setList(await processData(res.list))
            setOwnRate(res.ownRate)
            let index = breadcrumbs.findIndex(b => b.id === param.id)

            if (index === -1) {
                const bc = [...breadcrumbs]
                bc.push(param)
                setBreadcrumbs([...bc])
            } else {
                let newArr = JSON.parse(JSON.stringify(breadcrumbs));
                newArr.splice(index + 1)
                setBreadcrumbs(newArr)
            }
            setLoading(false)
        } catch (err) {
            setLoading(false)
        }
    }

    useEffect(() => {
        prepareData();
    }, [])

    const prepareData = async () => {
        const res = await AgentService.getDownlineRate()
        setList(await processData(res.list))
        setOwnRate(res.ownRate)
        setBreadcrumbs([{ id: user.id, username: user.username }])
        setLoading(false)
    }

    useEffect(() => {
        forms.edit.setFieldsValue(modalData)
    }, [forms.edit, modalData])

    const processData = async (data) => {
        const groupedData = groupBy(data, 'lotto_groups_fk')
        return groupedData
    }





    return (
        <div className='agent-page-group my-setting-page'>
            <Spin {...ELM_STANDARD.spin} spinning={loading}>
                <div className='page-title'>
                    <Typography className='title'>{MENU_ITEMS.receiveSetting.label}</Typography>
                </div>

                <div className='page-content-wrapper'>
                    <div className='page-content'>
                        <div className='breadcrumb-styled'>
                            <Breadcrumb separator=">" className='unselectable'>
                                {breadcrumbs.map((item) =>
                                    <Breadcrumb.Item key={item.id}
                                        onClick={() => changeUser({ id: item.id, username: item.username })}>{item.username}</Breadcrumb.Item>
                                )}
                            </Breadcrumb>
                        </div>


                        <Tabs defaultActiveKey={Object.keys(ownRate)?.[0]} onChange={() => resetSelection()}>
                            {Object.entries(ownRate)?.map(([key, item]) => {
                                const tableData = list[item.id]
                                return <Tabs.TabPane tab={DB.SYSTEM.LOTTO.GROUP.find((g) => g.id == item.id)?.name} key={item.id}>
                                    <Table
                                        scroll={{ x: 'max-content' }}
                                        rowKey={(record) => record.id}
                                        pagination={ELM_STANDARD.pagination}
                                        dataSource={tableData}
                                        columns={[
                                            {
                                                title: 'ลำดับ',
                                                dataIndex: 'i',
                                                key: 'i',
                                                align: 'center',
                                                render: (text, record, index) => {
                                                    return index + 1
                                                },
                                            },
                                            {
                                                title: <div>
                                                    <div>ชื่อผู้ใช้</div>
                                                    <div><Checkbox  checked={nameIsShow} onChange={() => setNameIsShow(!nameIsShow)}>แสดงชื่อ</Checkbox></div>
                                                </div>,
                                                dataIndex: 'username',
                                                key: 'username',
                                                render (value, record) {
                                                    const name = nameIsShow && <span className='text-gray'>({record.name})</span>
                                                    return record.role === DB.USER.ROLE.MEMBER ?
                                                        <Typography>{value} {name}</Typography>
                                                        :
                                                        <Typography.Link onClick={() => changeUser({ id: record.users_fk, username: record.username })}>{value} {name}</Typography.Link>
                                                },
                                            },
                                            {
                                                title: 'ระดับ',
                                                dataIndex: 'role',
                                                key: 'role',
                                                align: 'center',
                                                render (value) {
                                                    return ROLE_RENDER[value]
                                                },
                                            },
                                            {
                                                title: 'ตัวเลือกการตั้งค่า',
                                                dataIndex: 'users_fk',
                                                key: 'users_fk',
                                                align: 'center',
                                                render: (value, record) => {
                                                    return <Checkbox disabled={breadcrumbs?.length > 1}  checked={checkedList.findIndex(item => item === record.users_fk) !== -1} onChange={handleCheckboxChange(record.users_fk)}></Checkbox>
                                                }
                                            },
                                            {
                                                title: 'แบ่งหุ้น (ให้เอเจนต์)',
                                                dataIndex: 'hold',
                                                key: 'hold',
                                                align: 'center',
                                            },
                                            {
                                                title: 'เก็บของ (เข้าตัวเรา)',
                                                dataIndex: 'keep',
                                                key: 'keep',
                                                align: 'center',
                                                render: (value, record) => {
                                                    return ownRate.find((r) => r.id === record.lotto_groups_fk)?.userRate.hold - record.hold
                                                }
                                            },
                                            {
                                                title: 'เก็บของเกิน',
                                                dataIndex: 'uplineKeep',
                                                key: 'uplineKeep',
                                                align: 'center',
                                                render: (value, record, i) => {
                                                    return value ? <CheckOutlined className='text-green' /> : <CloseOutlined className='text-red' />
                                                }
                                            },
                                            {
                                                title: 'Actions',
                                                dataIndex: 'name',
                                                key: 'name',
                                                align: 'center',
                                                render (value, record) {
                                                    return <Button disabled={breadcrumbs.length > 1} icon={<EditOutlined />} onClick={() => openEditModal(record)}>แก้ไข</Button>
                                                }
                                            },
                                        ]}
                                        sticky
                                        summary={() => {
                                            let options = []
                                            for (let j = 0; j <= Number(ownRate.find((r) => r.id === item.id)?.userRate.hold); j += 0.5) {
                                                options.push(<Select.Option key={j} value={j}>{`${j}%`}</Select.Option>)
                                            }
                                            options.push(<Select.Option key={100} value={100}>{`${100}%`}</Select.Option>)
                                            const remain = Number(ownRate.find((r) => r.id === item.id)?.userRate.hold) - bulkEditValue
                                            return (
                                                <Table.Summary fixed={'top'}>
                                                    <Table.Summary.Row className='bg-blue' style={{ fontWeight: 'bold' }}>
                                                        <Table.Summary.Cell colSpan={3}>
                                                            <Select
                                                                mode="multiple"
                                                                allowClear
                                                                className="w-100"
                                                                placeholder="เลือกหวย"
                                                                value={bulkEditSelectedLotto}
                                                                onChange={(value) => setBulkEditSelectedLotto(value)}
                                                                placement="topLeft"
                                                            >
                                                                {ownRate.map((item, i) => {
                                                                    return <Select.Option key={item.id} value={item.id}>{item.id}. {item.name}</Select.Option>
                                                                })}
                                                            </Select>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell className='text-center'>
                                                            <Checkbox disabled={breadcrumbs?.length > 1} 
                                                                onChange={(e) => e.target.checked ? setCheckedList(tableData.map(i => i.users_fk)) : setCheckedList([])}></Checkbox>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell><Select className="w-100 text-center" value={bulkEditValue} onChange={setBulkEditValue}>{options}</Select></Table.Summary.Cell>
                                                        <Table.Summary.Cell><Select disabled className="w-100 text-center" value={`${remain}%`}></Select></Table.Summary.Cell>
                                                        <Table.Summary.Cell className='text-center'> <Checkbox onChange={(e) => setBulkEditUplineKeep(e.target.checked)}></Checkbox></Table.Summary.Cell>
                                                        <Table.Summary.Cell className='text-center'><Button type='primary' icon={<EditOutlined />}
                                                            onClick={() => submitBulkEdit()}
                                                            disabled={!checkedList.length || !bulkEditSelectedLotto.length}
                                                        >บันทึก</Button></Table.Summary.Cell>
                                                    </Table.Summary.Row>
                                                </Table.Summary>
                                            );
                                        }}
                                    />
                                </Tabs.TabPane>
                            })}
                        </Tabs>
                        {Object.entries(list)?.length === 0 && <Empty />}

                    </div>
                </div>

                <Modal title={<span><SettingOutlined /> ตั้งค่า{MENU_ITEMS.receiveSetting.label}</span>}
                    {...modalProps('edit')}>
                    <Spin  {...ELM_STANDARD.spin} spinning={isModalLoading}>
                        <Tag className='my-1'>ตั้งค่าให้ผู้ใช้ {modalData?.username}</Tag>
                        <Form
                            {...formProps('edit')}>
                            {renderForm('edit')}

                            <Form.Item label={`เก็บของเกิน`} name={'uplineKeep'} valuePropName="checked" >
                                <Switch checkedChildren="เก็บ" unCheckedChildren="ไม่เก็บ" />
                            </Form.Item>
                        </Form>
                    </Spin>
                </Modal>
            </Spin >
        </div>
    )
}

export default ReceiveSetting;
