import React, { useState, useEffect } from 'react';
import { Spin, Typography, Table, Tabs, message, Breadcrumb, Checkbox, Switch, Empty } from 'antd';
import { ELM_STANDARD, MENU_ITEMS, ROLE_RENDER } from '../../../util/options';
import AgentService from '../../../services/user/agent.service';
import { useUserContext } from '../../../contexts/UserContext';
import { DB, MSG, openNotification } from '../../../util/constant';
import { groupBy } from 'lodash';


const LottoSetting = () => {
    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([])
    const [breadcrumbs, setBreadcrumbs] = useState([])
    const { user } = useUserContext();

    const [nameIsShow, setNameIsShow] = useState(false)

    const submit = async (status, record) => {
        try {
            setLoading(true)
            const send = await AgentService.updateDownlineRate({ status: status, userId: record.users_fk, lotto_groups_fk: record.lotto_groups_fk })
            if (send === `OK`) {
                message.success(MSG.COMMON.UPDATE.SUCCEED)
                setLoading(false)
            } else {
                message.error(MSG.COMMON.VALIDATE.FAILED)
            }

            setLoading(false);
        } catch (err) {
            message.error(MSG.COMMON.VALIDATE.FAILED)
            setLoading(false);
        }
    }


    const changeUser = async (param) => {
        if (param.id === breadcrumbs[breadcrumbs.length - 1].id) {
            openNotification('info', `ระบบได้แสดงผลสมาชิก ${param.username} อยู่แล้ว`, '', 'bottomLeft')
            return
        }

        try {
            setLoading(true)
            const res = await AgentService.getDownlineRate(param.id)
            setList(await processData(res.list))
            let index = breadcrumbs.findIndex(b => b.id === param.id)

            if (index === -1) {
                const bc = [...breadcrumbs]
                bc.push(param)
                setBreadcrumbs([...bc])
            } else {
                let newArr = JSON.parse(JSON.stringify(breadcrumbs));
                newArr.splice(index + 1)
                setBreadcrumbs(newArr)
            }
            setLoading(false)
        } catch (err) {
            setLoading(false)
        }
    }

    useEffect(() => {
        prepareData();
    }, [])

    const prepareData = async () => {
        const res = await AgentService.getDownlineRate()
        setList(await processData(res.list))
        setBreadcrumbs([{ id: user.id, username: user.username }])
        setLoading(false)
    }

    const processData = async (data) => {
        const groupedData = groupBy(data, 'lotto_groups_fk')
        return groupedData
    }


    return (
        <div className='agent-page-group my-setting-page'>
            <Spin {...ELM_STANDARD.spin} spinning={loading}>
                <div className='page-title'>
                    <Typography className='title'>{MENU_ITEMS.manageLotto.label}</Typography>
                </div>

                <div className='page-content-wrapper'>
                    <div className='page-content'>
                        <div className='breadcrumb-styled'>
                            <Breadcrumb separator=">" className='unselectable'>
                                {breadcrumbs.map((item) =>
                                    <Breadcrumb.Item key={item.id}
                                        onClick={() => changeUser({ id: item.id, username: item.username })}>{item.username}</Breadcrumb.Item>
                                )}
                            </Breadcrumb>
                        </div>


                        <Tabs defaultActiveKey={Object.keys(list)?.[0]}>
                            {Object.entries(list)?.map(([key, item]) => {
                                return <Tabs.TabPane tab={DB.SYSTEM.LOTTO.GROUP.find((g) => g.id == key)?.name} key={key}>
                                    <Table
                                        scroll={{ x: 'max-content' }}
                                        rowKey={(record) => record.id}
                                        pagination={ELM_STANDARD.pagination}
                                        dataSource={item}
                                        columns={[
                                            {
                                                title: 'ลำดับ',
                                                dataIndex: 'i',
                                                key: 'i',
                                                align: 'center',
                                                render: (text, record, index) => {
                                                    return index + 1
                                                },
                                            },
                                            {
                                                title: <div>
                                                    <div>ชื่อผู้ใช้</div>
                                                    <div><Checkbox checked={nameIsShow} onChange={() => setNameIsShow(!nameIsShow)}>แสดงชื่อ</Checkbox></div>
                                                </div>,
                                                dataIndex: 'username',
                                                key: 'username',
                                                render (value, record) {
                                                    const name = nameIsShow && <span className='text-gray'>({record.name})</span>
                                                    return record.role === DB.USER.ROLE.MEMBER ?
                                                        <Typography>{value} {name}</Typography>
                                                        :
                                                        <Typography.Link onClick={() => changeUser({ id: record.users_fk, username: record.username })}>{value} {name}</Typography.Link>
                                                },
                                            },
                                            {
                                                title: 'ระดับ',
                                                dataIndex: 'role',
                                                key: 'role',
                                                align: 'center',
                                                render (value) {
                                                    return ROLE_RENDER[value]
                                                },
                                            },
                                            {
                                                title: 'สถานะ',
                                                dataIndex: 'status',
                                                key: 'status',
                                                align: 'center',
                                                render (value, record) {
                                                    return <Switch defaultChecked={record.status} disabled={breadcrumbs.length > 1} onChange={(value) => submit(value, record)} checkedChildren="เปิด" unCheckedChildren="ปิด" />
                                                }
                                            },
                                        ]}
                                    />
                                </Tabs.TabPane>
                            })}
                        </Tabs>
                        {Object.entries(list)?.length === 0 && <Empty />}

                    </div>
                </div>
            </Spin >
        </div>
    )
}

export default LottoSetting;
