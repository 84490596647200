import React from 'react';

import { Space } from 'antd';
class Footer extends React.PureComponent {
  render() {
    return (
      <div className="custom-footer">
        <Space direction="vertical" size={'large'}>
          <div>
            <img
              className="shadow3-mixin"
              src={`/logo192.png`}
              alt=""
              style={{ width: '100%', maxWidth: '5rem' }}
            />
          </div>
          <div>
            <span>©2022 DUNCHESS LOTTO All Rights Reserved</span>
          </div>
        </Space>
      </div>
    );
  }
}

export default Footer;
