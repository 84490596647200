import React, { useState, useEffect } from 'react';
import { Spin, Typography, Row, Col, Table, Button, Modal, Form, Tag, InputNumber, Input, message, Checkbox } from 'antd';
import { ELM_STANDARD, MENU_ITEMS } from '../../util/options';
import { DB, MSG } from '../../util/constant';
import AgentService from '../../services/user/agent.service';
import { CloseOutlined, EditOutlined, SaveOutlined, SettingOutlined } from '@ant-design/icons';
const ShareByType = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([])

    const [modalData, setModalData] = useState(null)
    const [modalVisible, setModalVisible] = useState({ edit: false });
    const [isModalLoading, setIsModalLoading] = useState(false);
    const [editForm] = Form.useForm();

    const initialBulkEditValues = {
        [DB.SYSTEM.LOTTO.BET_MODE_TO_ID['3 ตัวบน']]: null,
        [DB.SYSTEM.LOTTO.BET_MODE_TO_ID['3 ตัวล่าง']]: null,
        [DB.SYSTEM.LOTTO.BET_MODE_TO_ID['3 ตัวโต๊ด']]: null,
        [DB.SYSTEM.LOTTO.BET_MODE_TO_ID['2 ตัวบน']]: null,
        [DB.SYSTEM.LOTTO.BET_MODE_TO_ID['2 ตัวล่าง']]: null,
        [DB.SYSTEM.LOTTO.BET_MODE_TO_ID['วิ่งบน']]: null,
        [DB.SYSTEM.LOTTO.BET_MODE_TO_ID['วิ่งล่าง']]: null,
    }

    const [bulkEditValues, setBulkEditValues] = useState({ ...initialBulkEditValues })

    const [checkedList, setCheckedList] = useState([])

    const handleCheckboxChange = id => (e) => {
        setCheckedList(e.target.checked ? [...checkedList, id] : checkedList.filter(item => item !== id))
    }

    const forms = { edit: editForm };
    const ActionsService = {
        edit: AgentService.updateSelfRate,
        bulkEdit: AgentService.bulkUpdateSelfRate
    }

    const modalProps = (param) => {
        return {
            visible: modalVisible[param],
            footer: [
                <Button key="back" onClick={() => closeModal(param)} icon={<CloseOutlined />}>ยกเลิก</Button>,
                <Button type="primary" key="submit" onClick={() => submit(param)} icon={<SaveOutlined />}>บันทึก</Button>
            ],
            onOk: () => submit(param),
            onCancel: () => closeModal(param),
            forceRender: true,
        }
    }

    const formProps = (param) => {
        return {
            form: forms[param],
            autoComplete: "new-password",
            initialValues: modalData
        }
    }

    const openEditModal = async (record) => {
        setModalData(record)
        setModalVisible({ edit: true })
    }


    const submit = async (param) => {
        try {

            setIsModalLoading(true)
            const send = await ActionsService[param]({ ...forms[param].getFieldsValue(), lotto_groups_fk: modalData.id })
            setIsModalLoading(false)
            if (send === `OK`) {
                forms[param].resetFields()
                setModalVisible({ ...modalVisible, [param]: false })
                message.success(MSG.COMMON.UPDATE.SUCCEED)
                setLoading(true)
                prepareData();
            } else {
                message.error(MSG.COMMON.VALIDATE.FAILED)
            }


            setLoading(false);
        } catch (err) {
            message.error(MSG.COMMON.VALIDATE.FAILED)
            setLoading(false);
        }
    }

    const submitBulkEdit = async () => {
        try {

            setIsModalLoading(true)
            const send = await ActionsService.bulkEdit({ bulkEditValues, lotto_groups_fk: checkedList })
            setIsModalLoading(false)
            if (send === `OK`) {
                setBulkEditValues({ ...initialBulkEditValues })
                message.success(MSG.COMMON.UPDATE.SUCCEED)
                setLoading(true)
                await prepareData();
            } else {
                message.error(MSG.COMMON.VALIDATE.FAILED)
            }
            setLoading(false);
        } catch (err) {
            message.error(MSG.COMMON.VALIDATE.FAILED)
            setLoading(false);
        }
    }

    const closeModal = (param) => {
        setModalVisible({ ...modalVisible, [param]: false });
        setModalData(null);
        forms[param].resetFields()
    }


    useEffect(() => {
        forms.edit.setFieldsValue(modalData)
    }, [forms.edit, modalData])


    useEffect(() => {
        prepareData()
    }, [])

    const prepareData = async () => {
        const res = await AgentService.getOwnRate()
        setData(res)
        setLoading(false)
    }


    return (
        <div className='agent-page-group my-setting-page'>
            <Spin {...ELM_STANDARD.spin} spinning={loading}>
                <div className='page-title'>
                    <Typography className='title'>{MENU_ITEMS.shareByType.label}</Typography>
                </div>

                <div className='page-content-wrapper'>
                    <div className='page-content'>
                        <Row gutter={[16, 16]}>
                            <Col xs={24}>
                                <div className='user-rate-wrapper'>
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24}>
                                            <Table
                                                scroll={{ x: 'max-content' }}
                                                rowKey={(record) => record.id}
                                                pagination={false}
                                                dataSource={data}
                                                columns={[
                                                    {
                                                        title: 'ชนิดหวย',
                                                        dataIndex: 'name',
                                                        key: 'name',
                                                        align: 'left',

                                                        render: (value, record, index) => {
                                                            return `[${(DB.SYSTEM.LOTTO.GROUP.find((g) => g.id === record.id))?.name}] - ${value}`
                                                        }
                                                    },
                                                    {
                                                        title: '',
                                                        dataIndex: 'id',
                                                        key: 'id',
                                                        align: 'center',
                                                        render: (value, record) => {
                                                            return <Checkbox checked={checkedList.findIndex(item => item === record.id) !== -1} onChange={handleCheckboxChange(record.id)}></Checkbox>
                                                        }
                                                    },
                                                    {
                                                        title: '3 ตัวบน',
                                                        dataIndex: 'threeUp',
                                                        key: 'threeUp',
                                                        align: 'center',
                                                        width: '150px',
                                                        render: (text, record, index) => {
                                                            const rate = record.rate.find((r) => r.id === DB.SYSTEM.LOTTO.BET_MODE_TO_ID['3 ตัวบน'])
                                                            return rate.holdAmount ?? '0'
                                                        }
                                                    },
                                                    {
                                                        title: '3 ตัวล่าง',
                                                        dataIndex: 'threeDown',
                                                        key: 'threeDown',
                                                        align: 'center',
                                                        width: '150px',
                                                        render: (text, record, index) => {
                                                            const rate = record.rate.find((r) => r.id === DB.SYSTEM.LOTTO.BET_MODE_TO_ID['3 ตัวล่าง'])
                                                            return rate.holdAmount ?? '0'
                                                        }
                                                    },
                                                    {
                                                        title: '3 ตัวโต๊ด',
                                                        dataIndex: 'threeOdd',
                                                        key: 'threeOdd',
                                                        align: 'center',
                                                        width: '150px',
                                                        render: (text, record, index) => {
                                                            const rate = record.rate.find((r) => r.id === DB.SYSTEM.LOTTO.BET_MODE_TO_ID['3 ตัวโต๊ด'])
                                                            return rate.holdAmount ?? '0'
                                                        }
                                                    },
                                                    {
                                                        title: '2 ตัวบน',
                                                        dataIndex: 'twoUp',
                                                        key: 'twoUp',
                                                        align: 'center',
                                                        width: '150px',
                                                        render: (text, record, index) => {
                                                            const rate = record.rate.find((r) => r.id === DB.SYSTEM.LOTTO.BET_MODE_TO_ID['2 ตัวบน'])
                                                            return rate.holdAmount ?? '0'
                                                        }
                                                    },
                                                    {
                                                        title: '2 ตัวล่าง',
                                                        dataIndex: 'twoDown',
                                                        key: 'twoDown',
                                                        align: 'center',
                                                        width: '150px',
                                                        render: (text, record, index) => {
                                                            const rate = record.rate.find((r) => r.id === DB.SYSTEM.LOTTO.BET_MODE_TO_ID['2 ตัวล่าง'])
                                                            return rate.holdAmount ?? '0'
                                                        }
                                                    },
                                                    {
                                                        title: 'วิ่งบน',
                                                        dataIndex: 'runUp',
                                                        key: 'runUp',
                                                        align: 'center',
                                                        width: '150px',
                                                        render: (text, record, index) => {
                                                            const rate = record.rate.find((r) => r.id === DB.SYSTEM.LOTTO.BET_MODE_TO_ID['วิ่งบน'])
                                                            return rate.holdAmount ?? '0'
                                                        }
                                                    },
                                                    {
                                                        title: 'วิ่งล่าง',
                                                        dataIndex: 'runDown',
                                                        key: 'runDown',
                                                        align: 'center',
                                                        width: '150px',
                                                        render: (text, record, index) => {
                                                            const rate = record.rate.find((r) => r.id === DB.SYSTEM.LOTTO.BET_MODE_TO_ID['วิ่งล่าง'])
                                                            return rate.holdAmount ?? '0'
                                                        }
                                                    },
                                                    {
                                                        title: 'Actions',
                                                        dataIndex: 'name',
                                                        key: 'name',
                                                        align: 'center',
                                                        width: '10%',
                                                        render (value, record) {
                                                            return <Button icon={<EditOutlined />}
                                                                onClick={() => openEditModal(record)}
                                                            >แก้ไข</Button>
                                                        }
                                                    },
                                                ]}
                                                bordered
                                                sticky
                                                summary={() => {
                                                    return (
                                                        <Table.Summary fixed={'top'}>
                                                            <Table.Summary.Row className='bg-blue' style={{ fontWeight: 'bold' }}>
                                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                                <Table.Summary.Cell className='text-center'>
                                                                    <Checkbox
                                                                        onChange={(e) => e.target.checked ? setCheckedList(data.map(i => i.id)) : setCheckedList([])}></Checkbox>
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell className='text-center'><InputNumber min={0} size={'small'} value={bulkEditValues[DB.SYSTEM.LOTTO.BET_MODE_TO_ID['3 ตัวบน']]} onChange={(value) => setBulkEditValues({ ...bulkEditValues, [DB.SYSTEM.LOTTO.BET_MODE_TO_ID['3 ตัวบน']]: value })} /></Table.Summary.Cell>
                                                                <Table.Summary.Cell className='text-center'><InputNumber min={0} size={'small'} value={bulkEditValues[DB.SYSTEM.LOTTO.BET_MODE_TO_ID['3 ตัวล่าง']]} onChange={(value) => setBulkEditValues({ ...bulkEditValues, [DB.SYSTEM.LOTTO.BET_MODE_TO_ID['3 ตัวล่าง']]: value })} /></Table.Summary.Cell>
                                                                <Table.Summary.Cell className='text-center'><InputNumber min={0} size={'small'} value={bulkEditValues[DB.SYSTEM.LOTTO.BET_MODE_TO_ID['3 ตัวโต๊ด']]} onChange={(value) => setBulkEditValues({ ...bulkEditValues, [DB.SYSTEM.LOTTO.BET_MODE_TO_ID['3 ตัวโต๊ด']]: value })} /></Table.Summary.Cell>
                                                                <Table.Summary.Cell className='text-center'><InputNumber min={0} size={'small'} value={bulkEditValues[DB.SYSTEM.LOTTO.BET_MODE_TO_ID['2 ตัวบน']]} onChange={(value) => setBulkEditValues({ ...bulkEditValues, [DB.SYSTEM.LOTTO.BET_MODE_TO_ID['2 ตัวบน']]: value })} /></Table.Summary.Cell>
                                                                <Table.Summary.Cell className='text-center'><InputNumber min={0} size={'small'} value={bulkEditValues[DB.SYSTEM.LOTTO.BET_MODE_TO_ID['2 ตัวล่าง']]} onChange={(value) => setBulkEditValues({ ...bulkEditValues, [DB.SYSTEM.LOTTO.BET_MODE_TO_ID['2 ตัวล่าง']]: value })} /></Table.Summary.Cell>
                                                                <Table.Summary.Cell className='text-center'><InputNumber min={0} size={'small'} value={bulkEditValues[DB.SYSTEM.LOTTO.BET_MODE_TO_ID['วิ่งบน']]} onChange={(value) => setBulkEditValues({ ...bulkEditValues, [DB.SYSTEM.LOTTO.BET_MODE_TO_ID['วิ่งบน']]: value })} /></Table.Summary.Cell>
                                                                <Table.Summary.Cell className='text-center'><InputNumber min={0} size={'small'} value={bulkEditValues[DB.SYSTEM.LOTTO.BET_MODE_TO_ID['วิ่งล่าง']]} onChange={(value) => setBulkEditValues({ ...bulkEditValues, [DB.SYSTEM.LOTTO.BET_MODE_TO_ID['วิ่งล่าง']]: value })} /></Table.Summary.Cell>
                                                                <Table.Summary.Cell className='text-center'><Button type='primary' icon={<EditOutlined />}
                                                                    onClick={() => submitBulkEdit()}
                                                                    disabled={!checkedList.length}
                                                                >บันทึก</Button></Table.Summary.Cell>
                                                            </Table.Summary.Row>
                                                        </Table.Summary>
                                                    );
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </Col>


                        </Row>
                    </div>
                </div>

                <Modal title={<span><SettingOutlined /> ตั้งค่า{MENU_ITEMS.shareByType.label}</span>}
                    {...modalProps('edit')}>
                    <Spin  {...ELM_STANDARD.spin} spinning={isModalLoading}>
                        <Tag className='my-1'>กลุ่มหวย {(DB.SYSTEM.LOTTO.GROUP.find((g) => g.id === modalData?.id))?.name}</Tag>
                        <Tag className='my-1'>ชนิดหวย {modalData?.name}</Tag>
                        <Form
                            {...formProps('edit')}>
                            <Table
                                scroll={{ x: 'max-content' }}
                                size={'small'}
                                rowKey={(record) => record.id}
                                pagination={false}
                                dataSource={modalData?.rate}
                                columns={[
                                    {
                                        title: 'ประเภท',
                                        dataIndex: 'name',
                                        key: 'name',
                                    },
                                    {
                                        title: 'จำนวนเงินที่ถือสู้ ต่อตัว',
                                        dataIndex: 'holdAmount',
                                        key: 'holdAmount',
                                        align: 'center',
                                        render: (value, record, i) => {
                                            return <> <Form.Item name={['rate', i, 'holdAmount']} style={{ marginBottom: 0 }}>
                                                <InputNumber min={0} size={'small'} />
                                            </Form.Item>
                                                <Form.Item name={['rate', i, 'discount']} style={{ display: 'none' }}>
                                                    <InputNumber min={0} max={100} size={'small'} />
                                                </Form.Item>
                                                <Form.Item name={['rate', i, 'id']} style={{ display: 'none' }}>
                                                    <Input type="hidden" />
                                                </Form.Item>
                                                <Form.Item name={['rate', i, 'min']} style={{ display: 'none' }}>
                                                    <InputNumber min={1} max={100000} />
                                                </Form.Item>
                                                <Form.Item name={['rate', i, 'max']} style={{ display: 'none' }}>
                                                    <InputNumber min={1} max={100000} />
                                                </Form.Item>
                                                <Form.Item name={['rate', i, 'rate']} style={{ display: 'none' }}>
                                                    <InputNumber min={0} size={'small'} max={1000} />
                                                </Form.Item>

                                            </>
                                        }
                                    },
                                ]}
                            />
                        </Form>
                    </Spin>
                </Modal>

            </Spin >
        </div >
    )
}

export default ShareByType;