import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Spin, Alert, Row, Col, Statistic, Divider, Tooltip } from 'antd';
import {
    useNavigate,
} from "react-router-dom";
import { ELM_STANDARD, lotto_date_tag, MENU_ITEMS } from '../../../util/options';
import { groupBy } from 'lodash'
import { ADMIN_INTERVAL_UPDATE, DATE_FORMAT, DB } from '../../../util/constant';
import dayjs from 'dayjs'
import 'dayjs/locale/th'
import isBetween from 'dayjs/plugin/isBetween'
import AdminService from '../../../services/user/admin.service';
import { useLottoParamContext } from '../../../contexts/LottoParamContext';
import { ClockCircleOutlined, SyncOutlined } from '@ant-design/icons';
dayjs.extend(isBetween)

const HomeAdmin = () => {
    const [updatedAt, setUpdatedAt] = useState(null)
    const [loading, setLoading] = useState(true)
    const [markets, setMarkets] = useState([])
    const navigate = useNavigate();
    const { lottoParam, lottoParamAction } = useLottoParamContext();

    useEffect(() => {
        if (lottoParam.lotto.selected.id && lottoParam.lottoDate.selected.id) {
            // navigate to lotto result add page
            navigate(MENU_ITEMS.lottoResultAdd.path)
        } else {
            prepareData()
        }
    }, [lottoParam.lotto.selected.id, lottoParam.lottoDate.selected.id])

    const prepareData = async () => {
        setLoading(true);
        const data = await AdminService.getMarkets()
        const groupedData = groupBy(data, 'groupId')

        // assign group name
        Object.keys(groupedData).forEach((key) => {
            groupedData[key].groupName = DB.SYSTEM.LOTTO.GROUP.find(x => x.id == key)?.name
        })

        setMarkets(Object.values(groupedData))
        setUpdatedAt(dayjs().add(ADMIN_INTERVAL_UPDATE, 'minute'))
        setLoading(false)
    }

    const onVisibilityChange = () => {
        if (document.visibilityState === 'visible' && dayjs().isAfter(updatedAt)) {
            console.log("Tab reopened, refetch the data!");
            prepareData()
        }
    };

    useLayoutEffect(() => {
        document.addEventListener("visibilitychange", onVisibilityChange);
        return () => document.removeEventListener("visibilitychange", onVisibilityChange);
    }, [updatedAt]);

    const selectLottoAndLottoDate = (lottoDate, lotto) => {
        // lotto param context select this
        lottoParamAction.selectLottoAndLottoDate(
            { ...lottoDate },
            { ...lotto }
        )
        // navigate to lotto result add page
        navigate(MENU_ITEMS.lottoResultAdd.path)
    }


    const renderMarkets = () => {
        return markets.map((market, i) => {
            return <div key={`market-${i}`} className='market-wrapper'>
                <div className='market-title'>{market.groupName}</div>
                <Row className='market-content' gutter={[8, 16]}>{renderMarketContent(market, market.groupName)}</Row>
                <Divider />
            </div>

        })
    }

    const renderMarketContent = (market, groupName) => {
        return market.map((m) => {
            let status = {}
            if (m.status === DB.SYSTEM.LOTTO_DATE.STATUS.PENDING) {
                if (dayjs().isBefore(m.openAt)) {
                    status.key = 'willActive';
                } else if (dayjs().isBetween(m.openAt, m.closeAt)) {
                    status.key = 'active';
                } else if (dayjs().isBetween(m.closeAt, m.postAt)) {
                    status.key = 'pending';
                } else {
                    status.key = 'late'
                }
            } else if (m.status === DB.SYSTEM.LOTTO_DATE.STATUS.CANCELED) {
                status.key = 'canceled'
            } else {
                status.key = 'inactive'
            }


            return <Col xs={6} key={m.lottoId}>
                <div className='cursor-pointer h-100' onClick={() => selectLottoAndLottoDate({ id: m.id, date: m.date, status: m.status }, { id: m.lottoId, groupId: m.groupId, name: m.name, groupName })}>
                    <div className={`market-item market-${status.key}`} >
                        <Row wrap={false}>
                            <Col xs={6} className="col-flex-vertical-center">
                                <div className='market-image-wrapper'>
                                    <img className='market-image' src={`../images/lotto/${m.lottoId}.png`} alt="" />
                                </div>
                            </Col>
                            <Col flex={1}>
                                <Row justify='space-between' gutter={[4, 4]}>
                                    <Col xs={24} className="name">{m.name}</Col>
                                    <Col xs={24} className="date">{DATE_FORMAT.dateonly(m.date)}</Col>
                                    {status.key === 'willActive' && (
                                        <>
                                            <Col xs={12}>สถานะ</Col><Col className='text-right' xs={12}>{lotto_date_tag[m.status]}</Col>
                                            <Col xs={12}>เปิด</Col><Col className='text-right' xs={12}>{DATE_FORMAT.default(m.openAt)}</Col>
                                            <Col xs={12}></Col><Col className='text-right' xs={12}><Statistic.Countdown prefix="เปิดรับใน" title="" value={m.openAt} /></Col>
                                        </>
                                    )}
                                    {status.key === 'active' && (
                                        <>
                                            <Col xs={12}>สถานะ</Col><Col className='text-right' xs={12}>{lotto_date_tag[m.status]}</Col>
                                            <Col xs={12}>ปิด</Col><Col className='text-right' xs={12}>{DATE_FORMAT.default(m.closeAt)}</Col>
                                            <Col xs={12}></Col><Col className='text-right' xs={12}><Statistic.Countdown prefix="ปิดรับใน" title="" value={m.closeAt} /></Col>
                                        </>
                                    )}
                                    {status.key === 'pending' && (
                                        <>
                                            <Col xs={12}>สถานะ</Col><Col className='text-right' xs={12}>{lotto_date_tag[m.status]}</Col>
                                            <Col xs={12}>ปิด</Col><Col className='text-right' xs={12}>{DATE_FORMAT.default(m.closeAt)}</Col>
                                            <Col xs={12}>ออกผล</Col><Col className='text-right' xs={12}>{DATE_FORMAT.default(m.postAt)}</Col>
                                            <Col xs={24}><Alert message="ตลาดปิดรับ กรุณาออกผล" type="warning" showIcon /></Col>
                                        </>
                                    )}
                                    {status.key === 'late' && (
                                        <>
                                            <Col xs={12}>สถานะ</Col><Col className='text-right' xs={12}>{lotto_date_tag[m.status]}</Col>
                                            <Col xs={12}>ปิด</Col><Col className='text-right' xs={12}>{DATE_FORMAT.default(m.closeAt)}</Col>
                                            <Col xs={12}>ออกผล</Col><Col className='text-right' xs={12}>{DATE_FORMAT.default(m.postAt)}</Col>
                                            <Col xs={24}><Alert message="เลยเวลาออกผล กรุณาออกผล" type="error" showIcon /></Col>
                                        </>
                                    )}
                                    {status.key === 'inactive' && (
                                        <>
                                            <Col xs={12}>สถานะ</Col><Col className='text-right' xs={12}>{lotto_date_tag[m.status]}</Col>
                                            <Col xs={12}>ปิด</Col><Col className='text-right' xs={12}>{DATE_FORMAT.default(m.closeAt)}</Col>
                                            <Col xs={12}>ออกผล</Col><Col className='text-right' xs={12}>{DATE_FORMAT.default(m.postAt)}</Col>
                                        </>

                                    )}
                                    {status.key === 'canceled' && (
                                        <> <Col xs={12}>สถานะ</Col><Col className='text-right' xs={12}>{lotto_date_tag[m.status]}</Col></>
                                    )}

                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div >
            </Col >
        })
    }

    return (
        <div className='admin-page-group home-admin-page'>
            <Spin {...ELM_STANDARD.spin} spinning={loading}>
                <div className='text-secondary font-sarabun cursor-pointer text-center'>
                    <Tooltip placement='top' title={<div className='text-center'>คลิกเพื่ออัปเดต</div>}>
                        <div>
                            {loading ? <SyncOutlined spin />
                                : <span onClick={prepareData}>อัปเดตล่าสุด: <ClockCircleOutlined /> {DATE_FORMAT.timeonly(dayjs(updatedAt).subtract(ADMIN_INTERVAL_UPDATE, 'minute'))}</span>}
                        </div>
                    </Tooltip>
                </div>
                <div className='page-content-wrapper'>
                    <div className='page-content'>
                        {renderMarkets()}
                    </div>
                </div>
            </Spin>
        </div>

    )
}

export default HomeAdmin;