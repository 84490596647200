import React, { useState, useEffect, useLayoutEffect } from 'react';
import {
    useNavigate,
} from "react-router-dom";
import { Alert, Button, Checkbox, Col, DatePicker, Divider, Dropdown, Form, Menu, message, Modal, Popconfirm, Row, Segmented, Space, Spin, Table, Tooltip, Typography } from 'antd';
import { ELM_STANDARD, lotto_date_tag, MENU_ITEMS } from '../../util/options';
import { ADMIN_INTERVAL_UPDATE, dateFormats, DATE_FORMAT, DB, MSG } from '../../util/constant';
import { CalculatorOutlined, ClearOutlined, ClockCircleOutlined, CloseOutlined, DeleteOutlined, DownOutlined, EditOutlined, FileDoneOutlined, LockOutlined, MehOutlined, PlusOutlined, PlusSquareOutlined, QuestionCircleOutlined, SaveOutlined, SearchOutlined, StopOutlined, SyncOutlined } from '@ant-design/icons';
import AdminService from '../../services/user/admin.service';
import { useLottoParamContext } from '../../contexts/LottoParamContext';

import dayjs from 'dayjs'
import 'dayjs/locale/th'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

import moment from 'moment';
import 'moment/locale/th';
import { cloneDeep } from 'lodash';

moment.updateLocale('th', {
    week: {
        dow: 1
    }
})
dayjs.extend(isSameOrBefore)

const configShowtime = {
    defaultValue: moment('00:00:00', 'HH:mm:ss')
}

const { RangePicker } = DatePicker;

const LottoDate = () => {
    const params = {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '50', '100', '250'],
        total: 0
    }
    const navigate = useNavigate();
    const [pagination, setPagination] = useState({ ...params })
    const { lottoParam, lottoParamAction } = useLottoParamContext();
    const [updatedAt, setUpdatedAt] = useState(null)

    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([])

    const [modalData, setModalData] = useState(null)
    const [modalVisible, setModalVisible] = useState({ edit: false, changePassword: false, deposit: false });
    const [isModalLoading, setIsModalLoading] = useState(false);

    const [addBulkMode, setAddBulkMode] = useState('วัน')
    const [addBulkDateValues, setAddBulkDateValues] = useState(null)
    const [addBulkData, setAddBulkData] = useState([])

    const [checkedDays, setCheckedDays] = useState([])
    const [dayModeOpenAt, setDayModeOpenAt] = useState(null)
    const [dayModeCloseAt, setDayModeCloseAt] = useState(null)
    const [dayModePostAt, setDayModePostAt] = useState(null)

    const [numDayModeOpenAt, setNumDayModeOpenAt] = useState(null)
    const [numDayModeCloseAt, setNumDayModeCloseAt] = useState(null)
    const [numDayModePostAt, setNumDayModePostAt] = useState(null)


    const [editForm] = Form.useForm();
    const [addOneForm] = Form.useForm();

    const forms = { edit: editForm, addOne: addOneForm };
    const ActionService = {
        edit: AdminService.updateLottoDate,
        refund: AdminService.lottoDateRefund,
        toggleCancel: AdminService.lottoDateToggleCancel,
        deleteDate: AdminService.lottoDateDelete,
        addOne: AdminService.lottoDateAdd,
        addBulk: AdminService.lottoDateAddBulk,
        reCalculate: AdminService.lottoDateReCalculate,
    }

    const pageChange = (pagination) => {
        setLoading(true)
        AdminService.listLottoDate({ lottos_fk: lottoParam.lotto.selected.id, ...pagination, })
            .then(async res => {
                setPagination({ ...pagination, total: res.total })
                setList(res.results)
                setUpdatedAt(dayjs().add(ADMIN_INTERVAL_UPDATE, 'minute'))
                setLoading(false)
            })
    }

    const submit = async (param) => {
        try {
            let payload = {}

            if (param !== 'addBulk') {
                await forms[param].validateFields()
                payload = {
                    lotto_dates_fk: modalData?.id ?? null,
                    lottos_fk: lottoParam.lotto.selected.id,
                    openAt: moment(forms[param].getFieldValue('openAt')).format(dateFormats.default),
                    closeAt: moment(forms[param].getFieldValue('closeAt')).format(dateFormats.default),
                    postAt: moment(forms[param].getFieldValue('postAt')).format(dateFormats.default),
                }
            }

            if (param === 'addOne') {
                payload = {
                    ...payload,
                    date: moment(forms[param].getFieldValue('date')).format(dateFormats.only_date),
                }
            }

            else if (param === 'addBulk') {
                if (addBulkData.length === 0) {
                    message.error('ไม่มีข้อมูลที่ต้องการบันทึก')
                    return
                }
                payload = {
                    lottos_fk: lottoParam.lotto.selected.id,
                    lotto_dates: addBulkData
                }
            }

            setIsModalLoading(true)
            const send = await ActionService[param](payload)
            setIsModalLoading(false)
            if (send === `OK`) {
                if (param !== 'addBulk') {
                    forms[param].resetFields()
                } else {
                    clearAddBulkData()
                }
                setModalVisible({ ...modalVisible, [param]: false })
                message.success(MSG.COMMON.UPDATE.SUCCEED)
                setLoading(true)
                prepareData();
            } else if (send === `LOTTO_DATE_NOT_PENDING`) {
                message.error(MSG.LOTTO_DATE.LOTTO_DATE_NOT_PENDING)
            }
            else if (send === `LOTTO_DATE_DUPLICATED`) {
                message.error(MSG.LOTTO_DATE.LOTTO_DATE_DUPLICATED)
            }
            else {
                message.error(MSG.COMMON.VALIDATE.FAILED)
            }
            setLoading(false);
        } catch (err) {
            message.error(MSG.COMMON.VALIDATE.FAILED)
            setLoading(false);
            setIsModalLoading(false)
        }
    }

    const refund = async (lotto_dates_fk) => {
        try {
            setLoading(true)
            const send = await ActionService.refund({ lotto_dates_fk })
            setLoading(false)
            if (send === `OK`) {
                message.success(MSG.COMMON.UPDATE.SUCCEED)
                prepareData();
            } else {
                message.error(MSG.COMMON.VALIDATE.FAILED)
            }
        } catch (err) {
            message.error(MSG.COMMON.VALIDATE.FAILED)
            setLoading(false)
        }
    }

    const toggleCancel = async (lotto_dates_fk) => {
        try {
            setLoading(true)
            const send = await ActionService.toggleCancel({ lotto_dates_fk })
            setLoading(false)
            if (send === `OK`) {
                message.success(MSG.COMMON.UPDATE.SUCCEED)
                prepareData();
            } else if (send === `LOTTO_DATE_HAS_PENDING_ORDER`) {
                message.error(MSG.LOTTO_DATE.TOGGLE_CANCEL.LOTTO_DATE_HAS_PENDING_ORDER)
            }
            else {
                message.error(MSG.COMMON.VALIDATE.FAILED)
            }
        } catch (err) {
            message.error(MSG.COMMON.VALIDATE.FAILED)
            setLoading(false)
        }
    }

    const reCalculate = async (lotto_dates_fk) => {
        try {
            setLoading(true)
            const send = await ActionService.reCalculate({ lotto_dates_fk })
            setLoading(false)
            if (send === `OK`) {
                message.success(MSG.COMMON.UPDATE.SUCCEED)
                prepareData();
            } else {
                message.error(MSG.COMMON.VALIDATE.FAILED)
            }
        } catch (err) {
            message.error(MSG.COMMON.VALIDATE.FAILED)
            setLoading(false)
        }
    }


    const deleteDate = async (lotto_dates_fk) => {
        try {
            setLoading(true)
            const send = await ActionService.deleteDate({ lotto_dates_fk })
            setLoading(false)
            if (send === `OK`) {
                message.success(MSG.COMMON.UPDATE.SUCCEED)
                prepareData();
            } else {
                message.error(MSG.COMMON.VALIDATE.FAILED)
            }
        } catch (err) {
            message.error(MSG.COMMON.VALIDATE.FAILED)
            setLoading(false)
        }
    }

    const closeModal = (param) => {
        if (param !== 'addBulk') {
            setModalData(null);
            forms[param].resetFields()
        }
        setModalVisible({ ...modalVisible, [param]: false });
    }


    useEffect(() => {
        prepareData();
        clearAddBulkData()
    }, [lottoParam.lotto.selected.id])

    useEffect(() => {
        if (modalData) {
            forms.edit.setFieldsValue({
                openAt: moment(modalData?.openAt ?? ''),
                closeAt: moment(modalData?.closeAt ?? ''),
                postAt: moment(modalData?.postAt ?? ''),
            })
        }

    }, [forms.edit, modalData])

    const clearAddBulkData = () => {
        setAddBulkMode('วัน')
        setAddBulkDateValues(null)
        setAddBulkData([])
        setCheckedDays([])

        setDayModeOpenAt(null)
        setDayModeCloseAt(null)
        setDayModePostAt(null)

        setNumDayModeOpenAt(null)
        setNumDayModeCloseAt(null)
        setNumDayModePostAt(null)
    }

    const addBulkRemove = (id) => {
        setAddBulkData(cloneDeep(addBulkData.filter((d) => d.id !== id)))
    }

    const calculateAddBulkData = () => {
        try {
            if (!addBulkDateValues) {
                message.warning('โปรดเลือก ช่วงวัน ที่ต้องการเพิ่มงวด')
                return
            }

            const days = []
            const start = moment(addBulkDateValues[0])
            const end = moment(addBulkDateValues[1])

            if (end.isBefore(start)) {
                message.warning('ช่วงวันไม่ถูกต้อง')
                return
            }
            if (addBulkMode === 'วัน') {
                if (checkedDays.length === 0) {
                    message.warning('โปรดเลือก วัน ที่ต้องการเพิ่มงวด')
                    return
                }

                if (dayModeOpenAt === null || dayModeCloseAt === null || dayModePostAt === null) {
                    message.warning('โปรดเลือก เวลาเปิดตลาด ปิดตลาด และ เวลาประกาศผล')
                    return
                }

                if (dayModeCloseAt.isSame(dayModeOpenAt || dayModeCloseAt.isAfter(dayModePostAt))) {
                    message.warning('โปรดเลือก เวลาเปิดตลาด ปิดตลาด และ เวลาประกาศผล ให้ถูกต้อง')
                    return
                }

                let isCloseAfterOpenOneDay = false
                if (dayModeCloseAt.isSameOrBefore(dayModeOpenAt)) {
                    isCloseAfterOpenOneDay = true
                }

                while (start.isSameOrBefore(end)) {
                    if (checkedDays.includes(String(start.isoWeekday()))) {
                        let closeAt = isCloseAfterOpenOneDay ? moment(start).add(1, 'days').hour(dayModeCloseAt.hour()).minute(dayModeCloseAt.minute()).second(0)
                            : moment(start).hour(dayModeCloseAt.hour()).minute(dayModeCloseAt.minute()).second(0)
                        let postAt = isCloseAfterOpenOneDay ? moment(start).add(1, 'days').hour(dayModePostAt.hour()).minute(dayModePostAt.minute()).second(0)
                            : moment(start).hour(dayModePostAt.hour()).minute(dayModePostAt.minute()).second(0)

                        days.push({
                            date: moment(closeAt).format(dateFormats.only_date),
                            openAt: moment(start).hour(dayModeOpenAt.hour()).minute(dayModeOpenAt.minute()).second(0).format(dateFormats.default),
                            closeAt: closeAt.format(dateFormats.default),
                            postAt: postAt.format(dateFormats.default),
                        })
                    }
                    start.add(1, 'days')

                }

            } else if (addBulkMode === 'วันที่') {
                if (numDayModeOpenAt === null || numDayModeCloseAt === null || numDayModePostAt === null) {
                    message.warning('โปรดเลือก เวลาเปิดตลาด ปิดตลาด และ เวลาประกาศผล')
                    return
                }

                let isCloseAfterOpenOneDay = false
                if (numDayModeCloseAt.isSameOrBefore(numDayModeOpenAt)) {
                    isCloseAfterOpenOneDay = true
                }

                const numDay = numDayModePostAt.date()
                while (start.isSameOrBefore(end)) {
                    if (numDay === start.date()) {
                        let openAt = isCloseAfterOpenOneDay ? moment(start).date(numDayModeOpenAt.date()).subtract(1, 'months').hour(numDayModeOpenAt.hour()).minute(numDayModeOpenAt.minute()).second(0)
                            : moment(start).date(numDayModeOpenAt.date()).hour(numDayModeOpenAt.hour()).minute(numDayModeOpenAt.minute()).second(0)

                        days.push({
                            date: moment(start).format(dateFormats.only_date),
                            openAt: openAt.format(dateFormats.default),
                            closeAt: moment(start).date(numDayModeCloseAt.date()).hour(numDayModeCloseAt.hour()).minute(numDayModeCloseAt.minute()).second(0).format(dateFormats.default),
                            postAt: moment(start).date(numDayModePostAt.date()).hour(numDayModePostAt.hour()).minute(numDayModePostAt.minute()).second(0).format(dateFormats.default),
                        })
                    }
                    start.add(1, 'days')
                }
            }

            const newAddBulkData = [
                ...days.map((value, i) => {
                    return {
                        id: i,
                        ...value
                    }
                })
            ]

            setAddBulkData(cloneDeep(newAddBulkData))
        }
        catch (e) {
            message.error(MSG.COMMON.VALIDATE.FAILED)
        }
    }

    const prepareData = async () => {
        if (lottoParam.lotto.selected.id) {
            const data = await AdminService.listLottoDate({ ...pagination, lottos_fk: lottoParam.lotto.selected.id })
            setList(data.results)
            setPagination({ ...pagination, total: data.total })
            setUpdatedAt(dayjs().add(ADMIN_INTERVAL_UPDATE, 'minute'))
        }
        setLoading(false)
    }


    const onVisibilityChange = () => {
        if (document.visibilityState === 'visible' && dayjs().isAfter(updatedAt)) {
            console.log("Tab reopened, refetch the data!");
            prepareData()
        }
    };

    useLayoutEffect(() => {
        document.addEventListener("visibilitychange", onVisibilityChange);
        return () => document.removeEventListener("visibilitychange", onVisibilityChange);
    }, [updatedAt]);


    const modalProps = (param) => {
        return {
            ...ELM_STANDARD.noTransitionModal,
            visible: modalVisible[param],
            footer: [
                <Button key="back" onClick={() => closeModal(param)} icon={<CloseOutlined />}>ยกเลิก</Button>,
                <Button type="primary" key="submit" onClick={() => submit(param)} icon={<SaveOutlined />}>ยืนยัน</Button>
            ],
            onOk: () => submit(param),
            onCancel: () => closeModal(param),
            forceRender: true,
        }
    }

    const formProps = (param) => {
        return {
            form: forms[param],
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
            autoComplete: "new-password",
        }
    }

    const selectLottoAndLottoDate = (lottoDate, lotto) => {
        lottoParamAction.selectLottoAndLottoDate(
            { ...lottoDate },
            { ...lotto }
        )
    }


    const menu = (record) => {
        return <Menu
            items={[
                {
                    label: 'แก้ไขเวลา',
                    key: 'edit',
                    icon: <EditOutlined />,
                    disabled: record.status !== DB.SYSTEM.LOTTO_DATE.STATUS.PENDING,
                    onClick: () => { setModalVisible({ ...modalVisible, edit: true }); setModalData(record) }
                },
                {
                    type: 'divider',
                },
                {
                    label: 'หน้าออกผล',
                    key: 'result',
                    icon: <SearchOutlined />,
                    onClick: () => {
                        selectLottoAndLottoDate({ id: record.id, date: record.date, status: record.status }, { ...lottoParam.lotto.selected })
                        navigate(MENU_ITEMS.lottoResultAdd.path)
                    }
                },
                {
                    label: 'ดูเลขอั้น',
                    key: 'closedNumbers',
                    icon: <LockOutlined />,
                    onClick: () => {
                        selectLottoAndLottoDate({ id: record.id, date: record.date, status: record.status }, { ...lottoParam.lotto.selected })
                        navigate(MENU_ITEMS.lottoClosedNumber.path)
                    }
                },
                {
                    label: 'ดูแพ้-ชนะ สมาชิก/ประเภท',
                    key: 'reportResultByType',
                    icon: <FileDoneOutlined />,
                    onClick: () => {
                        selectLottoAndLottoDate({ id: record.id, date: record.date, status: record.status }, { ...lottoParam.lotto.selected })
                        navigate(MENU_ITEMS.adminReportResultByType.path)
                    }
                },
                {
                    label: 'ดูแพ้-ชนะ สุทธิ',
                    key: 'reportResultByDate',
                    icon: <FileDoneOutlined />,
                    onClick: () => {
                        selectLottoAndLottoDate({ id: record.id, date: record.date, status: record.status }, { ...lottoParam.lotto.selected })
                        navigate(MENU_ITEMS.adminReportResultByDate.path)
                    }
                },
                {
                    type: 'divider',
                },
                {
                    label: 'คำนวณผลใหม่',
                    key: 'recalculate',
                    icon: <CalculatorOutlined />,
                    disabled: record.status !== DB.SYSTEM.LOTTO_DATE.STATUS.COMPLETED,
                    onClick: () => { reCalculate(record.id) }
                },
                {
                    type: 'divider',
                },
                {
                    label: 'ยกเลิกงวดคืนโพย',
                    key: 'refund',
                    icon: <MehOutlined />,
                    onClick: () => { refund(record.id) }
                },
                {
                    label: record.status === DB.SYSTEM.LOTTO_DATE.STATUS.CANCELED ? 'เปิดใช้งานงวด' : 'ยกเลิกงวด',
                    key: 'cancel',
                    icon: <StopOutlined />,
                    disabled: ![DB.SYSTEM.LOTTO_DATE.STATUS.PENDING, DB.SYSTEM.LOTTO_DATE.STATUS.CANCELED].includes(record.status),
                    onClick: () => { toggleCancel(record.id) }
                },
                {
                    label: <Popconfirm
                        key="submit"
                        title={`ลบงวด ${DATE_FORMAT.dateonly(record.date)} ?`}
                        onConfirm={() => deleteDate(record.id)}
                        okText="ยืนยัน"
                        okType="danger"
                        cancelText="ยกเลิก"
                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}>
                        <Space className='w-100'><DeleteOutlined />ลบงวด</Space>
                    </Popconfirm>,
                    key: 'delete',
                    disabled: ![DB.SYSTEM.LOTTO_DATE.STATUS.PENDING, DB.SYSTEM.LOTTO_DATE.STATUS.CANCELED].includes(record.status),
                },
            ]}
        />
    }
    return (
        <div className='admin-page-group lotto-date-page'>
            <Spin {...ELM_STANDARD.spin} spinning={loading}>
                <div className='page-title'>
                    <Typography className='title'>{MENU_ITEMS.lottoDate.label}</Typography>
                </div>

                <div>
                    {lottoParam.lotto.selected.id ?
                        <>
                            <Space className='text-big'>
                                <span className='text-blue'>[{lottoParam.lotto.selected.groupName}] - {lottoParam.lotto.selected.name}</span>
                                <span className='text-secondary'>(เปลี่ยนได้ที่แถบเมนูด้านบน)</span>
                                <Tooltip className='text-secondary text-center font-sarabun cursor-pointer' placement='top' title={<div className='text-center'>คลิกเพื่ออัปเดต</div>}>
                                    {loading ? <SyncOutlined spin />
                                        : <span onClick={prepareData}>อัปเดตล่าสุด: <ClockCircleOutlined /> {DATE_FORMAT.timeonly(dayjs(updatedAt).subtract(ADMIN_INTERVAL_UPDATE, 'minute'))}</span>}
                                </Tooltip>
                            </Space>
                        </>
                        : '*เลือกกลุ่มหวย'}
                </div>



                <div className='page-content-wrapper'>
                    <div className='page-content'>
                        {lottoParam.lotto.selected.id ?
                            <>
                                <Row gutter={[8, 8]} justify="end">
                                    <Col>
                                        <Button type='primary' key='addOne' icon={<PlusOutlined />} onClick={() => { setModalVisible({ ...modalVisible, addOne: true }) }}>เพิ่มงวดทีละวัน</Button>
                                    </Col>
                                    <Col>
                                        <Button type='primary' key='addBulk' icon={<PlusSquareOutlined />} onClick={() => { setModalVisible({ ...modalVisible, addBulk: true }) }}>เพิ่มงวดหลายวัน</Button>
                                    </Col>
                                </Row>
                                <Table
                                     scroll={{ x: 'max-content' }}
                                    className='my-2'
                                    rowKey={(record) => record.id}
                                    pagination={{ ...pagination, position: ['bottomRight', 'topRight'] }}
                                    onChange={pageChange}
                                    dataSource={list}
                                    columns={[
                                        {
                                            title: 'ลำดับ',
                                            dataIndex: 'id',
                                            key: 'id',
                                            align: 'center',
                                        },
                                        {
                                            title: 'งวด',
                                            dataIndex: 'date',
                                            key: 'date',
                                            align: 'center',
                                            render: (value) => {
                                                return `${DATE_FORMAT.dateonly(value)}`
                                            }
                                        },
                                        {
                                            title: 'เปิด',
                                            dataIndex: 'openAt',
                                            key: 'openAt',
                                            align: 'center',
                                            render (value) {
                                                return DATE_FORMAT.thaiHalf(value)
                                            }
                                        },
                                        {
                                            title: 'ปิด',
                                            dataIndex: 'closeAt',
                                            key: 'closeAt',
                                            align: 'center',
                                            render (value) {
                                                return DATE_FORMAT.thaiHalf(value)
                                            }
                                        },
                                        {
                                            title: 'ประกาศผล',
                                            dataIndex: 'postAt',
                                            key: 'postAt',
                                            align: 'center',
                                            render (value) {
                                                return DATE_FORMAT.thaiHalf(value)
                                            }
                                        },
                                        {
                                            title: 'สถานะ',
                                            dataIndex: 'status',
                                            key: 'status',
                                            align: 'center',
                                            render (value) {
                                                return lotto_date_tag[value]
                                            }
                                        },
                                        {
                                            title: 'อัปเดตเมื่อ',
                                            dataIndex: 'updatedAt',
                                            key: 'updatedAt',
                                            align: 'center',
                                            render (value) {
                                                return DATE_FORMAT.default(value)
                                            }
                                        },
                                        {
                                            title: 'Actions',
                                            dataIndex: 'name',
                                            key: 'name',
                                            align: 'center',
                                            render (value, record) {
                                                return <Dropdown overlay={() => menu(record)} trigger={['click']}>
                                                    <Button>
                                                        <Space>
                                                            จัดการ
                                                            <DownOutlined />
                                                        </Space>
                                                    </Button>
                                                </Dropdown>
                                            }
                                        },
                                    ]}
                                />
                            </>
                            : <Alert
                                style={{ margin: "1rem" }}
                                description={<div>ยังไม่เลือกกลุ่มหวย</div>}
                                type="info"
                                showIcon
                            />}

                    </div>
                </div >

                <Modal title={<span><EditOutlined /> แก้ไขเวลา</span>}
                    {...modalProps('edit')}>
                    <Spin  {...ELM_STANDARD.spin} spinning={isModalLoading}>
                        <Form
                            {...formProps('edit')}>
                            <Alert
                                style={{ margin: "1rem" }}
                                message={<div>ระบุเวลาให้ถูกต้อง</div>}
                                description={<ul>
                                    <li>โปรดระวังเวลาซ้อนทับกับงวดอื่น</li>
                                    <li>เวลาเปิดต้องอยู่ก่อนเวลาปิด</li>
                                    <li>เวลาปิดต้องอยู่ก่อนเวลาประกาศผล</li>
                                </ul>}
                                type="info"
                                showIcon
                            />
                            <Form.Item
                                name="openAt"
                                label="เวลาเปิด"
                                rules={[
                                    {
                                        required: true,
                                        message: 'กรุณาระบุ!',
                                    },
                                ]}
                            >
                                <DatePicker showSecond={false} showNow={false} showTime format={dateFormats.th_half} />
                            </Form.Item>
                            <Form.Item
                                name="closeAt"
                                label="เวลาปิด"
                                rules={[
                                    {
                                        required: true,
                                        message: 'กรุณาระบุ!',
                                    },
                                ]}
                            >
                                <DatePicker showSecond={false} showNow={false} showTime format={dateFormats.th_half} />
                            </Form.Item>
                            <Form.Item
                                name="postAt"
                                label="เวลาประกาศผล"
                                rules={[
                                    {
                                        required: true,
                                        message: 'กรุณาระบุ!',
                                    },
                                ]}
                            >
                                <DatePicker showSecond={false} showNow={false} showTime format={dateFormats.th_half} />
                            </Form.Item>


                        </Form>
                    </Spin>
                </Modal>


                <Modal title={<span><PlusOutlined /> เพิ่มงวด</span>}
                    {...modalProps('addOne')}>
                    <Spin  {...ELM_STANDARD.spin} spinning={isModalLoading}>
                        <Form
                            {...formProps('addOne')}>
                            <div className='text-big'>เพิ่มงวดให้ <span className='text-blue'>[{lottoParam.lotto.selected.groupName}] - {lottoParam.lotto.selected.name}</span></div>
                            <Alert
                                style={{ margin: "1rem" }}
                                message={<div>ระบุเวลาให้ถูกต้อง</div>}
                                description={<ul>
                                    <li>โปรดระวังเวลาซ้อนทับกับงวดอื่น</li>
                                    <li>เวลาเปิดต้องอยู่ก่อนเวลาปิด</li>
                                    <li>เวลาปิดต้องอยู่ก่อนเวลาประกาศผล</li>
                                </ul>}
                                type="info"
                                showIcon
                            />
                            <Form.Item
                                name="date"
                                label="งวดวันที่"
                                rules={[
                                    {
                                        required: true,
                                        message: 'กรุณาระบุ!',
                                    },
                                ]}
                            >
                                <DatePicker showSecond={false} showNow={false} showTime={false} format={dateFormats.th_date} />
                            </Form.Item>
                            <Form.Item
                                name="openAt"
                                label="เวลาเปิด"
                                rules={[
                                    {
                                        required: true,
                                        message: 'กรุณาระบุ!',
                                    },
                                ]}
                            >
                                <DatePicker showSecond={false} minuteStep={1} showNow={false} showTime={configShowtime} format={dateFormats.th_half} />
                            </Form.Item>
                            <Form.Item
                                name="closeAt"
                                label="เวลาปิด"
                                rules={[
                                    {
                                        required: true,
                                        message: 'กรุณาระบุ!',
                                    },
                                ]}
                            >
                                <DatePicker showSecond={false} minuteStep={1} showNow={false} showTime={configShowtime} format={dateFormats.th_half} />
                            </Form.Item>
                            <Form.Item
                                name="postAt"
                                label="เวลาประกาศผล"
                                rules={[
                                    {
                                        required: true,
                                        message: 'กรุณาระบุ!',
                                    },
                                ]}
                            >
                                <DatePicker showSecond={false} minuteStep={1} showNow={false} showTime={configShowtime} format={dateFormats.th_half} />
                            </Form.Item>


                        </Form>
                    </Spin>
                </Modal>

                <Modal title={<span><PlusSquareOutlined /> เพิ่มงวด หลายวัน</span>}
                    width={"70vw"}
                    {...modalProps('addBulk')}>
                    <div className='text-big'>เพิ่มงวดให้ <span className='text-blue'>[{lottoParam.lotto.selected.groupName}] - {lottoParam.lotto.selected.name}</span></div>
                    <Alert
                        style={{ margin: "1rem" }}
                        message={<div>ระบุเวลาให้ถูกต้อง</div>}
                        description={<ul>
                            <li>โปรดระวังเวลาซ้อนทับกับงวดอื่น</li>
                            <li>เวลาเปิดต้องอยู่ก่อนเวลาปิด</li>
                            <li>เวลาปิดต้องอยู่ก่อนเวลาประกาศผล</li>
                        </ul>}
                        type="info"
                        showIcon
                    />
                    <Spin  {...ELM_STANDARD.spin} spinning={isModalLoading}>
                        <Divider />
                        <Row gutter={[8, 8]} justify='center'>
                            <Col xs={8} className='text-right'>ช่วงวันที่ต้องการเพิ่ม: </Col>
                            <Col xs={16}>
                                <RangePicker
                                    ranges={{
                                        'เดือนนี้': [moment().startOf('month'), moment().endOf('month')],
                                        'เดือนหน้า': [moment().add(1, 'months').startOf('month'), moment().add(1, 'months').endOf('month')],
                                        'ปีนี้': [moment().startOf('year'), moment().endOf('year')],
                                        'ปีหน้า': [moment().add(1, 'years').startOf('year'), moment().add(1, 'years').endOf('year')],

                                    }}
                                    onChange={setAddBulkDateValues}
                                    value={addBulkDateValues}
                                    allowEmpty={[false, false]}
                                    allowClear={false} />
                            </Col>

                            <Col xs={8} className='text-right'>โหมด: </Col>
                            <Col xs={16}><Segmented onChange={setAddBulkMode} value={addBulkMode} options={['วัน', 'วันที่']} /></Col>
                            {addBulkMode === 'วัน' &&
                                <>
                                    <Col xs={8} className='text-right'>เลือกวัน: </Col>
                                    <Col xs={16}>
                                        <Checkbox.Group style={{ width: '100%' }} value={checkedDays} onChange={setCheckedDays}>
                                            <Row gutter={[8, 8]}>
                                                <Col> <Checkbox value="1">จันทร์</Checkbox></Col>
                                                <Col> <Checkbox value="2">อังคาร</Checkbox></Col>
                                                <Col> <Checkbox value="3">พุธ</Checkbox></Col>
                                                <Col> <Checkbox value="4">พฤหัสบดี</Checkbox></Col>
                                                <Col> <Checkbox value="5">ศุกร์</Checkbox></Col>
                                                <Col> <Checkbox value="6">เสาร์</Checkbox></Col>
                                                <Col> <Checkbox value="7">อาทิตย์</Checkbox></Col>
                                            </Row>
                                        </Checkbox.Group>
                                    </Col>

                                    <Col xs={8} className='text-right'>เปิดตลาด: </Col>
                                    <Col xs={16}>
                                        <DatePicker value={dayModeOpenAt} minuteStep={1} onChange={setDayModeOpenAt} showSecond={false} showNow={false} picker='time' showTime={configShowtime} format={dateFormats.add_bulk_only_time} />
                                    </Col>

                                    <Col xs={8} className='text-right'>ปิดตลาด: </Col>
                                    <Col xs={16}>
                                        <DatePicker value={dayModeCloseAt} minuteStep={1} onChange={setDayModeCloseAt} showSecond={false} showNow={false} picker='time' showTime={configShowtime} format={dateFormats.add_bulk_only_time} />
                                    </Col>

                                    <Col xs={8} className='text-right'>ประกาศผล: </Col>
                                    <Col xs={16}>
                                        <DatePicker value={dayModePostAt} minuteStep={1} onChange={setDayModePostAt} showSecond={false} showNow={false} picker='time' showTime={configShowtime} format={dateFormats.add_bulk_only_time} />
                                    </Col>
                                </>
                            }
                            {addBulkMode === 'วันที่' &&
                                <>
                                    <Col xs={8} className='text-right'>เปิดตลาด: </Col>
                                    <Col xs={16}>
                                        <DatePicker showSecond={false} value={numDayModeOpenAt} minuteStep={1} onChange={setNumDayModeOpenAt} howNow={false} showTime={configShowtime} format={dateFormats.add_bulk_style} />
                                    </Col>

                                    <Col xs={8} className='text-right'>ปิดตลาด: </Col>
                                    <Col xs={16}>
                                        <DatePicker showSecond={false} value={numDayModeCloseAt} minuteStep={1} onChange={setNumDayModeCloseAt} showNow={false} showTime={configShowtime} format={dateFormats.add_bulk_style} />
                                    </Col>

                                    <Col xs={8} className='text-right'>ประกาศผล: </Col>
                                    <Col xs={16}>
                                        <DatePicker showSecond={false} value={numDayModePostAt} minuteStep={1} onChange={setNumDayModePostAt} showNow={false} showTime={configShowtime} format={dateFormats.add_bulk_style} />
                                    </Col>
                                </>
                            }


                        </Row>
                        {/* todo: presets dates like หวยไทย */}
                        {/* <div className='text-center my-2'>
                            <Space>
                                <Button onClick={clearAddBulkData} icon={<ClearOutlined />}>ล้างข้อมูล</Button>
                            </Space>
                        </div> */}

                        <div className='text-center my-2'>
                            <Space>
                                <Button onClick={clearAddBulkData} icon={<ClearOutlined />}>ล้างข้อมูล</Button>
                                <Button type='primary' onClick={calculateAddBulkData} icon={<CalculatorOutlined />}>คำนวณวัน</Button>
                            </Space>
                        </div>

                        <Divider />
                        {addBulkData?.lotto_dates?.length > 0 && (
                            <Alert
                                style={{ margin: "1rem" }}
                                message={<div>โปรดแก้ไขเวลาอย่างระมัดระวัง</div>}
                                type="warning"
                                showIcon
                            />
                        )}
                        <Table
                             scroll={{ x: 'max-content' }}
                            rowKey={(record) => record.id}
                            pagination={false}
                            dataSource={addBulkData}
                            style={{ maxHeight: '50vh', overflowY: 'auto', height: '50vh' }}
                            size='small'
                            columns={[
                                {
                                    title: 'ชื่องวด',
                                    dataIndex: 'date',
                                    key: 'date',
                                    align: 'center',
                                    render: (value) => {
                                        return `${DATE_FORMAT.dateonly(value)}`
                                    }
                                },
                                {
                                    title: 'เวลาเปิด',
                                    dataIndex: 'openAt',
                                    key: 'openAt',
                                    align: 'center',
                                    render: (value) => {
                                        return `${DATE_FORMAT.thaiFull_yearBc(value)}`
                                    }
                                },
                                {
                                    title: 'เวลาปิด',
                                    dataIndex: 'closeAt',
                                    key: 'closeAt',
                                    align: 'center',
                                    render: (value) => {
                                        return `${DATE_FORMAT.thaiFull_yearBc(value)}`
                                    }
                                },
                                {
                                    title: 'เวลาประกาศผล',
                                    dataIndex: 'postAt',
                                    key: 'postAt',
                                    align: 'center',
                                    render: (value) => {
                                        return `${DATE_FORMAT.thaiFull_yearBc(value)}`
                                    }
                                },
                                {
                                    title: '',
                                    dataIndex: 'actionDelete',
                                    key: 'actionDelete',
                                    align: 'center',
                                    render (value, record) {
                                        return <Button danger icon={<DeleteOutlined />} onClick={() => addBulkRemove(record.id)}></Button>
                                    }
                                },
                            ]}
                        />
                    </Spin>
                </Modal>

            </Spin >
        </div >
    );
}

export default LottoDate;
