import React, { useState } from "react";
import { Routes, Route, Navigate } from 'react-router-dom'

import { MENU_ITEMS } from "../util/options"

import NotFound from "../components/common/404";
import Login from "../components/common/Login";
import { useUserContext } from "../contexts/UserContext";
import { default as CustomSider } from "../components/common/layout/Sider";
import CustomHeader from "../components/common/layout/CustomHeader";
import { default as CustomFooter } from "../components/common/layout/Footer";
import { Layout } from "antd";
import UnderConstruction from "../components/common/UnderConstruction";
import { LottoParamProvider } from '../contexts/LottoParamContext'
import ListUser from "../components/agent/ListUser";
import HomeMember from "../components/member/HomeMember";
import Bet from "../components/member/Bet";
import UserRateProvider from "../contexts/UserRateContext";
import CurrentRate from "../components/common/CurrentRate";
import Profile from "../components/common/Profile";
import Orders from "../components/member/Orders";
import OrdersByType from "../components/member/OrdersByType";
import ReportReward from "../components/common/ReportReward";
import Rules from "../components/common/Rules";
import Transactions from "../components/member/Transactions";
import WaitReport from "../components/member/WaitReport";
import TransactionsReport from "../components/member/TransactionsReport";
import CreateUserConfig from "../components/agent/CreateUserConfig";
import MySetting from "../components/agent/settings/MySetting";
import ReceiveSetting from "../components/agent/settings/ReceiveSetting";
import BoundarySetting from "../components/agent/settings/BoundarySetting";
import RateSetting from "../components/agent/settings/RateSetting";
import LottoSetting from "../components/agent/settings/LottoSetting";
import BetResultOrders from "../components/agent/BetResultOrders";
import BetResult from "../components/agent/BetResult";
import ShareByType from "../components/agent/ShareByType";
import BetResultByDate from "../components/agent/BetResultByDate";
import Landing from "../components/agent/Landing";
import ReceiveSettingHistory from "../components/agent/settings/ReceiveSettingHistory";
import ReceiveList from "../components/agent/settings/ReceiveList";
import TransferHistory from "../components/agent/report/TransferHistory";
import LoginHistory from "../components/agent/report/LoginHistory";
import WaitReward from "../components/agent/WaitReward";
import RewardHistoryByLotto from "../components/agent/report/RewardHistoryByLotto";
import RewardHistoryByDate from "../components/agent/report/RewardHistoryByDate";
import AdminAddAgent from "../components/admin/AddAgent";
import AdminListUser from "../components/admin/ListUser";
import HomeAdmin from "../components/admin/LottoResult/HomeAdmin";
import ResultAdd from "../components/admin/LottoResult/ResultAdd";
import LottoDate from "../components/admin/LottoDate";
import CloseNumber from "../components/admin/CloseNumber";
import CommonClosedNumbers from "../components/common/ClosedNumbers";
import AdminBetResult from "../components/admin/BetResult";
import AdminBetResultByDate from "../components/admin/BetResultByDate";
import AdminWaitReward from "../components/admin/WaitReward";
import HowToPlay from "../components/common/HotToPlay";
import ResultLinks from "../components/common/ResultLinks";
import AddAgent from "../components/agent/AddAgent";
import AddMember from "../components/agent/AddMember";
import ListLockedUser from "../components/agent/ListLockedUser";




const { Sider, Footer, Content, Header } = Layout;
const MainRoutes = () => {
    const { user, userAction } = useUserContext();
    const [isCollapsed, setIsCollapsed] = useState(false);
    return (
        <>
            {user.isPermit && (
                <UserRateProvider>
                    <LottoParamProvider>

                        <Layout hasSider style={{
                            minWidth: '100rem',
                            overflow: 'auto'
                        }}>
                            <Sider
                        
                                style={{
                                    overflowY: 'auto',
                                    overflowX: 'hidden',
                                    minHeight: '90vh',
                                    // maxHeight: 'calc(100vh - 48px)',
                                    // position: 'fixed',
                                    left: 0,
                                    top: 0,
                                    bottom: 0,
                                    zIndex: 99,
                                }}
                                // breakpoint="md"
                                width="256px"
                                onCollapse={(collapsed, type) => { setIsCollapsed(collapsed) }}
                            >
                                <CustomSider isCollapsed={isCollapsed} />

                                {/* routes displaying user rate */}
                                {userAction.isMember() && (
                                    <Routes>
                                        <Route path={MENU_ITEMS.bet.path} element={<CurrentRate isCollapsed={isCollapsed} />} />
                                        <Route path="*" element={<CurrentRate isCollapsed={isCollapsed} />} />
                                    </Routes>
                                )}

                            </Sider>

                            <Layout className={`content-layout ${isCollapsed ? `sider-is-collapsed` : ``}`}>
                                {!userAction.isMember() && (
                                    <Header><CustomHeader /></Header>
                                )}
                                <Content>
                                    <Routes>
                                        {userAction.isMember() && (
                                            <>
                                                <Route exact path={MENU_ITEMS.homeMember.path} element={<HomeMember />} />
                                                <Route exact path={MENU_ITEMS.bet.path} element={<Bet />} />
                                                <Route exact path={MENU_ITEMS.profile.path} element={<Profile />} />
                                                <Route exact path={MENU_ITEMS.orders.path} element={<Orders />} />
                                                <Route exact path={MENU_ITEMS.ordersByType.path} element={<OrdersByType />} />
                                                <Route exact path={MENU_ITEMS.memberTransactions.path} element={<Transactions />} />
                                                <Route exact path={MENU_ITEMS.memberWaitReport.path} element={<WaitReport />} />
                                                <Route exact path={MENU_ITEMS.memberTransactionsReport.path} element={<TransactionsReport />} />
                                                <Route exact path={MENU_ITEMS.reportReward.path} element={<ReportReward />} />
                                                <Route exact path={MENU_ITEMS.rules.path} element={<Rules />} />
                                                <Route exact path={MENU_ITEMS.howToPlay.path} element={<HowToPlay />} />
                                                <Route exact path={MENU_ITEMS.resultLink.path} element={<ResultLinks />} />
                                            </>
                                        )}

                                        {userAction.isAgent() && (
                                            <>
                                                <Route exact path={MENU_ITEMS.home.path} element={<Landing />} />
                                                <Route exact path={MENU_ITEMS.profile.path} element={<Profile />} />

                                                <Route exact path={MENU_ITEMS.waitReward.path} element={<WaitReward />} />
                                                <Route exact path={MENU_ITEMS.lockedNumber.path} element={<CommonClosedNumbers />} />
                                                <Route exact path={MENU_ITEMS.shareByType.path} element={<ShareByType />} />

                                                <Route exact path={MENU_ITEMS.betResult.path} element={<BetResult />} />
                                                <Route exact path={MENU_ITEMS.betResultByDate.path} element={<BetResultByDate />} />
                                                <Route exact path={MENU_ITEMS.betResultOrders.path} element={<BetResultOrders />} />
                                                <Route exact path={MENU_ITEMS.betResultGameResult.path} element={<ReportReward />} />

                                                <Route exact path={MENU_ITEMS.receiveSettingHistory.path} element={<ReceiveSettingHistory />} />
                                                <Route exact path={MENU_ITEMS.orderGraph.path} element={<UnderConstruction />} />

                                                <Route exact path={MENU_ITEMS.addMember.path} element={<AddMember />} />
                                                <Route exact path={MENU_ITEMS.addAgent.path} element={<AddAgent />} />
                                                <Route exact path={MENU_ITEMS.userList.path} element={<ListUser />} />
                                                <Route exact path={MENU_ITEMS.lockedUser.path} element={<ListLockedUser />} />
                                                <Route exact path={MENU_ITEMS.createUserConfig.path} element={<CreateUserConfig />} />

                                                <Route exact path={MENU_ITEMS.mySetting.path} element={<MySetting />} />
                                                <Route exact path={MENU_ITEMS.receiveSetting.path} element={<ReceiveSetting />} />
                                                <Route exact path={MENU_ITEMS.boundarySetting.path} element={<BoundarySetting />} />
                                                <Route exact path={MENU_ITEMS.rate.path} element={<RateSetting />} />
                                                <Route exact path={MENU_ITEMS.manageLotto.path} element={<LottoSetting />} />
                                                <Route exact path={MENU_ITEMS.userReceive.path} element={<ReceiveList />} />

                                                <Route exact path={MENU_ITEMS.memberTransfer.path} element={<TransferHistory />} />
                                                <Route exact path={MENU_ITEMS.loginHistory.path} element={<LoginHistory />} />

                                                <Route exact path={MENU_ITEMS.lottoHistoryByLotto.path} element={<RewardHistoryByLotto />} />
                                                <Route exact path={MENU_ITEMS.lottoHistoryByDate.path} element={<RewardHistoryByDate />} />

                                            </>
                                        )}

                                        {userAction.isAdmin() && (
                                            <>
                                                <Route exact path={MENU_ITEMS.profile.path} element={<Profile />} />

                                                <Route exact path={MENU_ITEMS.home.path} element={<Navigate to={MENU_ITEMS.lottoResult.path} replace />} />
                                                <Route exact path={MENU_ITEMS.lottoResult.path} element={<HomeAdmin />} />
                                                <Route exact path={MENU_ITEMS.lottoResultAdd.path} element={<ResultAdd />} />

                                                <Route exact path={MENU_ITEMS.lottoDate.path} element={<LottoDate />} />

                                                <Route exact path={MENU_ITEMS.lottoClosedNumber.path} element={<CloseNumber />} />

                                                <Route exact path={MENU_ITEMS.adminAddAgent.path} element={<AdminAddAgent />} />
                                                <Route exact path={MENU_ITEMS.adminUserList.path} element={<AdminListUser />} />

                                                <Route exact path={MENU_ITEMS.adminReportResultByType.path} element={<AdminBetResult />} />
                                                <Route exact path={MENU_ITEMS.adminReportResultByDate.path} element={<AdminBetResultByDate />} />

                                                <Route exact path={MENU_ITEMS.adminWaitReward.path} element={<AdminWaitReward />} />


                                                {/* common */}
                                                <Route exact path={MENU_ITEMS.betResultGameResult.path} element={<ReportReward />} />
                                                <Route exact path={MENU_ITEMS.rules.path} element={<Rules />} />
                                                <Route exact path={MENU_ITEMS.howToPlay.path} element={<HowToPlay />} />
                                                <Route exact path={MENU_ITEMS.resultLink.path} element={<ResultLinks />} />
                                            </>
                                        )}
                                        {/* should not go there */}
                                        {/* <Route exact path={MENU_ITEMS.register.path} element={<Navigate to={MENU_ITEMS.home.path} replace />} /> */}
                                        <Route path="*" element={<NotFound />} />
                                    </Routes>
                                </Content>
                                <Footer>
                                    <CustomFooter />
                                </Footer>
                            </Layout>
                        </Layout>
                    </LottoParamProvider>
                </UserRateProvider>
            )
            }
            {
                !user.isPermit && (
                    <Routes>
                        <Route exact path="/login" element={<Login />} />
                        <Route path="*" element={<Login />} />
                    </Routes>
                )
            }
        </>
    );
};


export default MainRoutes;
