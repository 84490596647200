import { notification } from 'antd';
import dayjs from 'dayjs';
import numeral from 'numeral';
import { SHARED_CONSTANT } from '../shared/shared_constant';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import 'moment/locale/th';

moment.updateLocale('th', {
  week: {
    dow: 1,
  },
});

export const API_URL = process.env.REACT_APP_API_URL;
export const COMPANY_NAME = `DUNCHESS LOTTO`;
export const ADMIN_INTERVAL_UPDATE =
  process.env.REACT_APP_ADMIN_INTERVAL_UPDATE;
export const MEMBER_INTERVAL_UPDATE =
  process.env.REACT_APP_MEMBER_INTERVAL_UPDATE;

export const MSG = {
  COMMON: {
    ADD: {
      SUCCEED: 'เพิ่มข้อมูลสำเร็จ',
      FAILED: 'เพิ่มข้อมูลไม่สำเร็จ',
    },
    UPDATE: {
      SUCCEED: 'อัปเดตข้อมูลสำเร็จ',
      FAILED: 'อัปเดตข้อมูลไม่สำเร็จ',
      FAILED_ALREADY_EXIST: 'ข้อมูลนี้มีอยู่แล้ว',
    },
    VALIDATE: {
      FAILED: 'กรุณาตรวจสอบข้อมูลอีกครั้ง',
      NOT_ENOUGH_BALANCE: 'ยอดเงินคงเหลือไม่พอ',
      DIRECT_DOWNLINE_ONLY: 'ปรับลูกสายโดยตรงเท่านั้น',
      UPLINE_LOCKED: 'อัปเดตข้อมูลไม่สำเร็จ สายบนถูกล็อคอยู่',
    },
    LOGIN: {
      SUCCEED: 'ยินดีต้อนรับคุณ ',
      FAILED: 'ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง',
    },
    LOGOUT: 'ออกจากระบบ สำเร็จ',
    SMT_WRONG: 'เกิดข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบหรือลองใหม่อีกครั้ง',
  },
  LOTTO_DATE: {
    NOT_FOUND: 'ไม่พบข้อมูล',
    LOTTO_DATE_CALCULATING: 'กำลังคำนวณผลล่าสุด',
    LOTTO_DATE_NOT_PENDING: 'ไม่อยู่ในสถานะรอผล',
    LOTTO_DATE_DUPLICATED: 'งวดซ้ำ กรุณาตรวจสอบ',
    TOGGLE_CANCEL: {
      LOTTO_DATE_HAS_PENDING_ORDER: 'มีโพยรอผล ไม่สามารถยกเลิกงวดได้',
    },
  },
};

export const dateFormats = {
  th_half: 'D MMMM YYYY, HH:mm:ss',
  th_date: 'D MMMM YYYY',
  only_date: 'YYYY-MM-DD',
  only_time: 'HH:mm:ss',
  default: 'YYYY-MM-DD HH:mm:ss',
  add_bulk_style: 'ทุกวันที่ D ของเดือน, เวลา HH:mm:ss',
  add_bulk_only_time: 'เวลา HH:mm',
};

export const ranges = {
  วันนี้: [moment(), moment()],
  เมื่อวาน: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
  '7 วันก่อน': [moment().subtract(7, 'days'), moment().subtract(1, 'days')],
  สัปดาห์นี้: [moment().startOf('week'), moment().endOf('week')],
  สัปดาห์ที่แล้ว: [
    moment().subtract(7, 'days').startOf('week'),
    moment().subtract(7, 'days').endOf('week'),
  ],
  เดือนนี้: [moment().startOf('month'), moment().endOf('month')],
  เดือนที่แล้ว: [
    moment().subtract(1, 'months').startOf('month'),
    moment().subtract(1, 'months').endOf('month'),
  ],
};

var relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);
export const DATE_FORMAT = {
  default: (value) => {
    return dayjs(value).format(`DD/MM/YYYY, HH:mm:ss`);
  },
  dateonly: (value) => {
    return dayjs(value).format(`DD/MM/YYYY`);
  },
  dateonlyDashed: (value) => {
    return dayjs(value).format(`DD-MM-YYYY`);
  },
  timeonly: (value) => {
    return dayjs(value).format(`HH:mm:ss`);
  },
  thaiFull_yearBc: (value) => {
    return dayjs(value).format('วันddd ที่ D เดือน MMMM ปี YYYY HH:mm:ss');
  },
  thaiFull: (value) => {
    return dayjs(value).format('วันddd ที่ D เดือน MMMM ปี YYYY HH:mm:ss');
  },
  thaiHalf: (value) => {
    return dayjs(value).format('D MMMM YYYY, HH:mm:ss');
  },
  thaiHalfNoSec: (value) => {
    return dayjs(value).format('D MMMM YYYY, HH:mm');
  },
  relativeTime: (value) => {
    return dayjs(value).fromNow();
  },
};

export const NUMBER_FORMAT = {
  default: (value) => {
    return numeral(value).format('0,0.00');
  },
  compact: (value) => {
    return numeral(value).format('0,0');
  },
};
export const DB = cloneDeep(SHARED_CONSTANT);

export const openNotification = (type, message, desc, placement) => {
  notification[type]({
    message: message,
    description: desc,
    placement,
  });
};

export const replaceRateByDefault = async (userRate) => {
  const defaultRegister = cloneDeep(DB.SYSTEM.LOTTO.DEFAULT_REGISTER);

  let filtered = [...defaultRegister].filter(
    (item) => item.id === userRate.lotto_groups_fk
  )[0];
  filtered.userRate = userRate.rate;

  let newRate = { ...filtered };
  newRate.status = userRate.status;
  newRate.lotto_groups_fk = userRate.lotto_groups_fk;
  newRate.users_fk = userRate.users_fk;
  newRate.username = userRate.username;

  newRate.userRate.map((r) => {
    let index = newRate.rate.findIndex((r2) => r2.id === r.id);
    if (index > -1) {
      newRate.rate[index] = { ...newRate.rate[index], ...r };
    }
  });

  return newRate;
};

export const TABLE_COL_ATTRIBUTE = {
  colGroup: { className: 'col-group-bordered' },
  memberExport: { className: 'col-first-group-cell' },
  agentHold: { className: 'col-first-group-cell' },
  companyHold: { className: 'col-first-group-cell' },
};
