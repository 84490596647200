import React, { useState } from 'react';
import { Button, Col, Dropdown, Menu, Modal, Row, Space, Table, Typography } from 'antd';
import { close_number_tag, ELM_STANDARD, order_tag } from '../../../util/options';
import { DATE_FORMAT, DB, NUMBER_FORMAT } from '../../../util/constant';
import { CheckOutlined, DownOutlined, SettingOutlined } from '@ant-design/icons';



const OrderDetail = (props) => {
    const [defaultVisible, setDefaultVisible] = useState(true)
    const columns = [
        {
            title: 'ประเภท @ หมายเลข',
            dataIndex: 'mode',
            key: 'mode',
            align: 'center',
            render: (text, record) => (
                <div style={{ textAlign: "center" }}><span>{record.mode}</span> @ <span className="text-blue">{record.betNumber}</span></div>
            )
        },
        {
            title: 'ยอด',
            dataIndex: 'betAmount',
            key: 'betAmount',
            align: 'right',
            render: value => (
                <div className='text-green'>{NUMBER_FORMAT.default(value)}</div>
            )
        },
        {
            title: 'ส่วนลด',
            dataIndex: 'discountAmount',
            key: 'discountAmount',
            align: 'right',
            render: value => (
                <div className='text-red'>{NUMBER_FORMAT.default(value)}</div>
            )
        },
        {
            title: 'รวม',
            dataIndex: 'summary',
            key: 'summary',
            align: 'right',
            render: (value, record) => (
                <div className='text-blue'>{NUMBER_FORMAT.default(record.betAmount - record.discountAmount)}</div>
            )
        },
        {
            title: () => <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>จ่าย</span>
                <span>รวม</span>
            </div>,
            dataIndex: 'rate',
            key: 'rate',
            align: 'center',
            render: (value, record) => (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>{value}</span>
                    <span className='text-blue'>{NUMBER_FORMAT.default(record.betAmount * (record.rate * 1))}</span>
                </div>
            )
        },
        {
            title: () => <><div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>จ่าย (อัตราเริ่มต้น)</span>
                <span>รวม</span>
            </div>
            </>,
            dataIndex: 'defaultRate',
            key: 'defaultRate',
            align: 'center',
            render: (value, record) => {
                const defaultRate = DB.SYSTEM.LOTTO.DEFAULT_REGISTER.find((g) => g.id === props.data.groupId).rate.find((g) => g.id === record.modes_fk).rate;

                return <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>{defaultRate}</span>
                    <span className='text-blue'>{NUMBER_FORMAT.default(record.betAmount * (defaultRate * 1))}</span>
                </div>
            }
        },
        {
            title: 'สถานะ',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            render: (value, record) => {
                if (props.data.status === DB.SYSTEM.ORDER.STATUS.COMPLETED) {
                    return Number(record.wonAmount) > 0 ?
                        <Row justify='space-between' className='text-blue text-bold'>
                            <Col>ถูกรางวัล</Col>
                            <Col >{defaultVisible ? NUMBER_FORMAT.default(record.wonAmount) : NUMBER_FORMAT.default(record.betAmount * (record.rate * 1))}</Col>
                        </Row>
                        : <span className='text-red'>ไม่ถูกรางวัล</span>
                }
                return ''
            }
        },
        {
            title: 'หมายเหตุ',
            dataIndex: 'flag',
            key: 'flag',
            align: 'center',
            render: value => {
                return close_number_tag[value]
            }
        },
    ]

    return <div className={`order-detail-component`}>
        <Modal style={{ minWidth: '50vw' }}
            {...ELM_STANDARD.noTransitionModal}
            footer={[
                <Button key="back" onClick={props.onClose}>
                    ปิด
                </Button>]}
            title={< span > [{(DB.SYSTEM.LOTTO.GROUP.find((g) => g.id === props.data.groupId))?.name}] {props.data.name} | งวด {DATE_FORMAT.dateonly(props.data.date)} {order_tag[props.data.status]}</span>}
            visible={props.visible} onCancel={props.onClose}>
            <div className='text-right'>
                <Dropdown overlay={<Menu
                    items={[
                        {
                            label: <div onClick={() => setDefaultVisible(true)}>{defaultVisible && <CheckOutlined />} แสดงอัตราจ่ายเริ่มต้น</div>,
                            key: '0',
                        },
                        {
                            label: <div onClick={() => setDefaultVisible(false)}>{!defaultVisible && <CheckOutlined />} แสดงอัตราจ่ายที่ปรับแล้ว</div>,
                            key: '1',
                        },
                    ]}
                />} trigger={['click']}>
                    <Typography.Link onClick={e => e.preventDefault()}>
                        <Space>
                            <SettingOutlined />
                            ตั้งค่า
                            <DownOutlined />
                        </Space>
                    </Typography.Link>
                </Dropdown>
            </div>

            <Table
                className='my-2'
                rowKey={(record) => record.key}
                scroll={{ x: 'max-content' }}
                dataSource={props.data?.details}
                rowClassName={(record) => {
                    if (Number(record.wonAmount) > 0) return 'highlighted-won-order'
                    return ''
                }}
                columns={defaultVisible ? columns : columns.filter(col => col.dataIndex !== 'defaultRate')}
                summary={pageData => {

                    let sum1 = 0;
                    let sum2 = 0;
                    let sum3 = 0;
                    let sum4 = 0;
                    let sum6 = 0;
                    pageData.forEach(({ betAmount, discountAmount, wonAmount, rate }) => {
                        sum1 += betAmount
                        sum2 += discountAmount
                        sum3 += betAmount - discountAmount
                        sum4 += wonAmount > 0 ? betAmount * rate : 0
                        sum6 += wonAmount ?? 0
                    });
                    return (
                        <Table.Summary.Row style={{ fontWeight: 'bold' }}>
                            <Table.Summary.Cell className='text-center'><div >รวม @ {props.data.count} รายการ</div></Table.Summary.Cell>
                            <Table.Summary.Cell className='text-right text-green'><div>{NUMBER_FORMAT.default(sum1)}</div> </Table.Summary.Cell>
                            <Table.Summary.Cell className='text-right text-red'><div>{NUMBER_FORMAT.default(sum2)}</div> </Table.Summary.Cell>
                            <Table.Summary.Cell className='text-right text-blue'><div>{NUMBER_FORMAT.default(sum3)}</div> </Table.Summary.Cell>
                            {defaultVisible ? (
                                [<Table.Summary.Cell key='0' className='text-center' colSpan={2}></Table.Summary.Cell>,
                                <Table.Summary.Cell key='1' className='text-right text-blue'><div style={defaultVisible ? {} : { display: 'none' }}>{sum6 > 0 && NUMBER_FORMAT.default(sum6)}</div> </Table.Summary.Cell>,
                                <Table.Summary.Cell key='2' className='text-center' colSpan={1}></Table.Summary.Cell>]
                            ) : (
                                [<Table.Summary.Cell key='0' className='text-center' colSpan={1}></Table.Summary.Cell>,
                                <Table.Summary.Cell key='1' className='text-right text-blue'><div>{NUMBER_FORMAT.default(sum4)}</div> </Table.Summary.Cell>,
                                <Table.Summary.Cell key='2' className='text-right text-blue'><div style={defaultVisible ? {} : { display: 'none' }}>{sum6 > 0 && NUMBER_FORMAT.default(sum6)}</div> </Table.Summary.Cell>]
                            )}

                        </Table.Summary.Row>
                    );
                }}
                bordered
                pagination={false}
            />
        </Modal>


    </div>
};

export default OrderDetail;
