import {
    AppstoreOutlined,
    AuditOutlined,
    BankOutlined,
    BarChartOutlined,
    CalendarOutlined,
    CheckCircleOutlined,
    ClockCircleOutlined,
    CloseCircleOutlined,
    DoubleRightOutlined,
    ExclamationCircleOutlined,
    FileDoneOutlined,
    FileTextOutlined,
    HistoryOutlined,
    HomeOutlined,
    InfoCircleOutlined,
    LinkOutlined,
    LoadingOutlined,
    LockOutlined,
    LogoutOutlined,
    OrderedListOutlined,
    RiseOutlined,
    ScissorOutlined,
    SettingOutlined,
    SyncOutlined,
    UserOutlined,
} from '@ant-design/icons';
import { Tag } from 'antd';



export const ELM_STANDARD = {
    pagination: {
        pageSizeOptions: ['10', '20', '50', '100'],
        position: ['bottomRight', 'topRight'],
        showSizeChanger: true
    },
    spin: {
        tip: "กำลังโหลด..",
        indicator: <LoadingOutlined spin />
    },
    noTransitionModal: {
        transitionName: "",
        maskTransitionName: ""
    }
}
export const ROLE_RENDER = {
    [process.env.REACT_APP_ROLE_ADMIN]: <Tag style={{ margin: 0 }} color="#f5222d" >Admin </Tag >,
    [process.env.REACT_APP_ROLE_AGENT]: <Tag style={{ margin: 0 }} color="#87d068" >Agent </Tag >,
    [process.env.REACT_APP_ROLE_MEMBER]: <Tag style={{ margin: 0 }} color="#108ee9" >Member </Tag >,
}

export const close_number_tag = {
    closed: <Tag icon={<LockOutlined />} color='#f5222d'>เลขอั้น</Tag>,
    half: <Tag icon={< ScissorOutlined />} color="warning" >จ่ายครึ่ง </Tag >,
}

export const order_tag = {
    PENDING: <Tag icon={< SyncOutlined spin />} color="processing" >รอผล </Tag >,
    CANCELED: <Tag icon={< ExclamationCircleOutlined />} color="default" >ยกเลิก </Tag >,
    CALCULATING: <Tag icon={< SyncOutlined spin />} color="processing" >คำนวณผล </Tag >,
    COMPLETED: <Tag icon={< CheckCircleOutlined />} color="success" >เสร็จสิ้น </Tag >,
    REFUNDED: <Tag icon={< ExclamationCircleOutlined />} color="default" >คืนเงินแล้ว </Tag >,
}

export const lotto_date_tag = {
    PENDING: <Tag icon={< SyncOutlined spin />} color="processing" >รอผล </Tag >,
    CANCELED: <Tag icon={< ExclamationCircleOutlined />} color="default" >ยกเลิกงวด</Tag >,
    CALCULATING: <Tag icon={< SyncOutlined spin />} color="processing" >คำนวณผล</Tag >,
    COMPLETED: <Tag icon={< CheckCircleOutlined />} color="success" >จ่ายเงินแล้ว</Tag >,
    REFUNDED: <Tag icon={< ExclamationCircleOutlined />} color="default" >คืนเงินแล้ว </Tag >,
}

export const default_tag = {
    ACTIVE: <Tag icon={< CheckCircleOutlined />} color="success" >ปกติ </Tag >,
    INACTIVE: <Tag icon={< ExclamationCircleOutlined />} color="default" >ระงับ </Tag >,
    PENDING: <Tag icon={< SyncOutlined spin />} color="processing" >รอตรวจสอบ </Tag >,
    LOCKED: <Tag icon={< LockOutlined />} color="default" >ถูกล็อค </Tag >,
    UPLINELOCKED: <Tag icon={< LockOutlined />} color="default" >สายงานถูกล็อค </Tag >,
    REJECTED: <Tag icon={< CloseCircleOutlined />} color="error" >ไม่อนุมัติ </Tag >,
    APPROVED: <Tag icon={< CheckCircleOutlined />} color="success" >อนุมัติ </Tag >,
    CANCELED: <Tag icon={< ExclamationCircleOutlined />} color="default" >ยกเลิก </Tag >,
    COMPLETED: <Tag icon={< CheckCircleOutlined />} color="success" >เสร็จสิ้น </Tag >,
    CALCULATING: <Tag icon={< SyncOutlined spin />} color="processing" >คำนวณผล </Tag >,
}

export const custom_tag = {
    ACTIVE: (text = "ปกติ", icon = < CheckCircleOutlined />) => <Tag icon={icon} color="success" >{text} </Tag >,
    INACTIVE: (text = "ระงับ", icon = < ExclamationCircleOutlined />) => <Tag icon={icon} color="default" >{text} </Tag >,
    PENDING: (text = "รอตรวจสอบ", icon = < SyncOutlined spin />) => <Tag icon={icon} color="processing" >{text} </Tag >,
    LOCKED: (text = "ถูกล็อค", icon = < LockOutlined />) => <Tag icon={icon} color="default" >{text} </Tag >,
    REJECTED: (text = "ไม่อนุมัติ", icon = < CloseCircleOutlined />) => <Tag icon={icon} color="error" >{text} </Tag >,
    APPROVED: (text = "อนุมัติ", icon = < CheckCircleOutlined />) => <Tag icon={icon} color="success" >{text} </Tag >,
    CANCELED: (text = "ยกเลิก", icon = < ExclamationCircleOutlined />) => <Tag icon={icon} color="default" >{text} </Tag >,
    COMPLETED: (text = "เสร็จสิ้น", icon = < CheckCircleOutlined />) => <Tag icon={icon} color="success" >{text} </Tag >,
    CALCULATING: (text = "คำนวณผล", icon = < SyncOutlined spin />) => <Tag icon={icon} color="processing" >{text} </Tag >,

}

export const MENU_ITEMS = {
    home: { key: 'home', path: '/', label: 'หน้าแรก', icon: <HomeOutlined /> },
    profile: { key: 'profile', path: '/profile', label: 'ข้อมูลผู้ใช้', icon: <UserOutlined /> },
    reportReward: { key: 'reportReward', path: '/report/reward', label: 'ตรวจผลรางวัล', icon: <BarChartOutlined /> },
    rules: { key: 'rules', path: '/rules', label: 'กฎ และ กติกา', icon: <AuditOutlined /> },
    howToPlay: { key: 'howToPlay', path: '/how-to', label: 'วิธีเล่นหวย', icon: <InfoCircleOutlined /> },
    resultLink: { key: 'resultLink', path: '/links', label: 'ลิงก์ดูผล', icon: <LinkOutlined /> },



    // member
    homeMember: { key: 'homeMember', path: '/', label: 'แทงหวย', icon: <AppstoreOutlined />, roleFlag: 1 },
    bet: { key: 'bet', path: '/bet/:lottoDateId', rawPath: '/bet', label: 'แทง', icon: <AppstoreOutlined /> },
    orders: { key: 'orders', path: '/orders', label: 'รายการแทง/ยกเลิกโพย', icon: <OrderedListOutlined /> },
    ordersByType: { key: 'ordersByType', path: '/orders/type', label: 'รายการแทง (ตามชนิดหวย)', icon: <OrderedListOutlined /> },
    memberAccount: {
        key: 'memberAccount',
        label: 'บัญชีการเงิน',
        icon: <BankOutlined />,
    },
    memberTransactions: { key: 'memberTransactions', path: '/transactions', label: 'งบดุล' },
    memberWaitReport: { key: 'memberWaitReport', path: '/transactions/wait-report', label: 'รอผลเดิมพัน' },
    memberTransactionsReport: { key: 'memberTransactionsReport', path: '/transactions/report', label: 'รายงานสรุป' },

    // agent
    betReport: {
        key: 'betReport',
        label: 'รายงานการแทง',
        icon: <OrderedListOutlined />,
    },
    waitReward: { key: 'waitReward', path: '/bet-report/wait-reward', label: 'รอผลเดิมพัน', icon: <DoubleRightOutlined /> },
    lockedNumber: { key: 'lockedNumber', path: '/bet-report/locked-number', label: 'เลขอั้น', icon: <DoubleRightOutlined /> },
    shareByType: { key: 'shareByType', path: '/bet-report/share/by-type', label: 'ตั้งค่ารับของ', icon: <DoubleRightOutlined /> },
    betResultReport: {
        key: 'betResultReport',
        label: 'รายงานแพ้ชนะ',
        icon: <FileDoneOutlined />
    },
    betResult: { key: 'betResult', path: '/bet-result-report', label: 'แพ้-ชนะ สมาชิก/ประเภท', icon: <DoubleRightOutlined /> },
    betResultByDate: { key: 'betResultByDate', path: '/bet-result-report/by-date', label: 'แพ้-ชนะ สุทธิ', icon: <DoubleRightOutlined /> },
    betResultOrders: { key: 'betResultOrders', path: '/bet-result-report/orders', label: 'รายการที่ถูกรางวัล', icon: <DoubleRightOutlined /> },
    betResultGameResult: { key: 'betResultGameResult', path: '/bet-result-report/game-results', label: 'ผลการออกรางวัล', icon: <DoubleRightOutlined /> },
    lottoReport: {
        key: 'lottoReport',
        label: 'รายงานสรุป',
        icon: <FileTextOutlined />,
    },
    receiveSettingHistory: { key: 'receiveSettingHistory', path: '/lotto-report/receive-setting/history', label: 'ประวัติการตั้งอั้น', icon: <DoubleRightOutlined /> },
    orderGraph: { key: 'orderGraph', path: '/lotto-report/order-graph', label: 'กราฟโพย/ช่วงเวลา', icon: <DoubleRightOutlined /> },
    manageUser: {
        key: 'manageUser',
        label: 'จัดการสมาชิก',
        icon: <UserOutlined />,
    },
    addMember: { key: 'addMember', path: '/manage-user/create/member', label: 'เพิ่มเมมเบอร์', icon: <DoubleRightOutlined /> },
    addAgent: { key: 'addAgent', path: '/manage-user/create/agent', label: 'เพิ่มเอเย่นต์', icon: <DoubleRightOutlined /> },
    userList: { key: 'userList', path: '/manage-user/user-list', label: 'รายชื่อสมาชิก', icon: <DoubleRightOutlined /> },
    lockedUser: { key: 'lockedUser', path: '/manage-user/user-locked', label: 'รายชื่อที่ถูกระงับ', icon: <DoubleRightOutlined /> },
    createUserConfig: { key: 'createUserConfig', path: '/manage-user/register', label: 'ตั้งค่าสมัครสมาชิก', icon: <DoubleRightOutlined /> },
    userSetting: {
        key: 'userSetting',
        label: 'ตั้งค่าสมาชิก',
        icon: <SettingOutlined />,
    },
    mySetting: { key: 'mySetting', path: '/user-setting/my-setting', label: 'ส่วนแบ่งที่ได้รับ', icon: <DoubleRightOutlined /> },
    receiveSetting: { key: 'receiveSetting', path: '/user-setting/receive-setting', label: 'แบ่งหุ้น/เก็บของ', icon: <DoubleRightOutlined /> },
    boundarySetting: { key: 'boundarySetting', path: '/user-setting/boundary', label: 'ขั้นต่ำ/สูงสุด', icon: <DoubleRightOutlined /> },
    rate: { key: 'rate', path: '/user-setting/rate', label: 'อัตราจ่าย/คอมมิชชัน', icon: <DoubleRightOutlined /> },
    manageLotto: { key: 'manageLotto', path: '/user-setting/lotto', label: 'เปิด/ปิด หวย', icon: <DoubleRightOutlined /> },
    userReceive: { key: 'userReceive', path: '/user-setting/receive', label: 'รายงานเก็บของ/อั้น', icon: <DoubleRightOutlined /> },
    report: {
        key: 'report',
        label: 'รายงาน',
        icon: <RiseOutlined />,
    },
    memberTransfer: { key: 'memberTransfer', path: '/report/member-transfer', label: 'รายงานการเงิน', icon: <DoubleRightOutlined /> },
    loginHistory: { key: 'loginHistory', path: '/report/member-login', label: 'ประวัติการเข้าใช้งาน', icon: <DoubleRightOutlined /> },
    lottoHistory: {
        key: 'lotto-history',
        label: 'งวดหวย',
        icon: <HistoryOutlined />,
    },
    lottoHistoryByLotto: { key: 'lottoHistoryByLotto', path: '/lotto-history/by-lotto', label: 'งวดหวย ตามประเภท', icon: <DoubleRightOutlined /> },
    lottoHistoryByDate: { key: 'lottoHistoryByDate', path: '/lotto-history/by-date', label: 'งวดหวย ตามวัน', icon: <DoubleRightOutlined /> },
    // end agent

    // admin
    adminManageLotto: {
        key: 'adminManageLotto',
        label: 'จัดการหวย',
        icon: <AppstoreOutlined />,
    },
    lottoDate: { key: 'lottoDate', path: '/lotto-date', label: 'งวดหวย', icon: <CalendarOutlined /> },
    lottoResult: { key: 'lottoResult', path: '/result', label: 'ออกผล', icon: <AppstoreOutlined /> },
    lottoResultAdd: { key: 'lottoResultAdd', path: '/result/add', label: 'ออกผล', icon: <AppstoreOutlined /> },
    lottoClosedNumber: { key: 'lottoClosedNumber', path: '/lotto/closed-number', label: 'จัดการเลขอั้น', icon: <LockOutlined /> },


    adminManageUser: {
        key: 'adminManageUser',
        label: 'จัดการสมาชิก',
        icon: <UserOutlined />,
    },
    adminAddAgent: { key: 'adminAddAgent', path: '/admin/manage/user/create/agent', label: 'เพิ่มเอเย่นต์', icon: <DoubleRightOutlined /> },
    adminUserList: { key: 'adminUserList', path: '/admin/manage/user/user-list', label: 'รายชื่อสมาชิก', icon: <DoubleRightOutlined /> },

    adminReportResult: {
        key: 'adminReportResult',
        label: 'รายงานแพ้ชนะ',
        icon: <FileDoneOutlined />,
    },
    adminReportResultByType: { key: 'adminReportResultByType', path: '/admin/report/result', label: 'แพ้-ชนะ สมาชิก/ประเภท', icon: <DoubleRightOutlined /> },
    adminReportResultByDate: { key: 'adminReportResultByDate', path: '/admin/report/result/by-date', label: 'แพ้-ชนะสุทธิ', icon: <DoubleRightOutlined /> },

    adminWaitReward: { key: 'adminWaitReward', path: '/admin/wait-reward', label: 'รอผลเดิมพัน', icon: <ClockCircleOutlined /> },


    // end admin
    logout: { key: 'logout', path: '/logout', label: 'ออกจากระบบ', icon: <LogoutOutlined /> },
}
