import React, { } from "react";

import {
    Typography, Row, Col, Card
} from 'antd';
import { MENU_ITEMS } from "../../util/options";

const ResultLinks = () => {
    const renderResultLinkCard = ({ title, closed, post, link }) => {
        return <Col  xs={4} key={title}>
            <Card title={title} size='small' className="shadow1-mixin" style={{ minHeight: "12rem" }}>
                {closed.map((item, i) => <div key={i}>{item}</div>)}
                {post.map((item, i) => <div key={i}>{item}</div>)}
                {link.map((item, i) => <div key={i}>📌 ลิงก์ดูผล <a href={item} about="_blank">{item}</a></div>)}
            </Card>
        </Col>
    }

    const items = [
        { title: `ลิงก์ดูผลลาวTV 📺 (ใช้ผล 5 ตัว)`, closed: [`❌ ปิดรับ 10:20 น.`], post: [`✅ ผลออก 10:30 น.`], link: [`https://lao-tv.com`] },
        { title: `ฮานอยHD`, closed: [], post: [`✅ ผลออก 11.30 น.`], link: [`https://xosohd.com`] },
        { title: `ลิงก์ดูผลฮานอยสตาร์⭐️`, closed: [`❌ ปิดรับ 12:00 น.`], post: [`✅ ผลออก 12:30 น.`], link: [`https://minhngocstar.com`] },
        { title: `ลิงก์ดูผลฮานอย TV📺`, closed: [`❌ ปิดรับ 14:10 น.`], post: [`✅ ผลออก 14:30 น.`], link: [`https://minhngoctv.com`] },
        { title: `ลิงก์ดูผลลาวสตาร์⭐️ (ใช้ผล 5 ตัว)`, closed: [`❌ ปิดรับ 15:30 น.`], post: [`✅ ผลออก 15:45 น.`], link: [`www.laostars.com`] },
        { title: `ลิงก์ดูผลลาวสามัคคีวีไอพี`, closed: [`❌ ปิดรับ 21:25 น.`], post: [`✅ ผลออก 21:30 น.`], link: [`https://laounionvip.com`] },
        { title: `ลิงก์ดูผลฮานอยกาชาด`, closed: [`❌ ปิดรับ 16:00 น.`], post: [`✅ ผลออก 16:30 น.`], link: [`https://xosoredcross.com/`] },
        { title: `🇻🇳 ลิงก์ดูผลฮานอย (พิเศษ)`, closed: [`❌ ปิดรับ 17:00 น.`], post: [`✅ ผลออก 17:30 น.`], link: [`http://www.xsthm.com/`] },
        { title: `🇻🇳 _ดูผลฮานอย (ปกติ)`, closed: [`❌ ปิดรับ 18:00 น.`], post: [`✅ ผลออก 18:30 น.`], link: [`https://www.minhngoc.net.vn/xo-so-truc-tiep/mien-bac.html`] },
        { title: `🇻🇳 ลิงก์ดูผลฮานอย (VIP)`, closed: [`❌ ปิดรับ 18:50 น.`], post: [`✅ ผลออก 19:30 น.`], link: [`http://www.mlnhngoc.net/`] },
        { title: `🇱🇦 ลิงก์ดูผลลาวสามัคคี (ใช้ผล4ตัว)`, closed: [`❌  ปิดรับ 20:00 น.`, `❌ ยกเว้นวันจันทร์ พฤหัส และพุธที่3 ของเดือน`], post: [`✅ ผลออก 20:30 น.`], link: [`www.laounion.com`] },
        { title: `ลิงก์ดูผลลาวสตาร์VIP⭐️ (ใช้ผล 5 ตัว)`, closed: [`❌  ปิดรับ 21:45 น.`], post: [`✅ ผลออก 22:00 น.`], link: [`www.laostarsvip.com`] },
        { title: `ลาว Extra`, closed: [`❌  ปิดรับ 08:25 น.`], post: [`✅ ผลออก 08:30 น.`], link: [`https://laoextra.com/`] },
        { title: `🎐 อังกฤษVIP ⏰‼️`, closed: [], post: [`✅ ผลออก 21.45 - 21.50 น.`, `📌 ลิงก์ดูผล3รัส VIP 👇🏻👇🏻`], link: [`https://lottosuperrich.com/`] },
        { title: `🎐 เยอรมัน  ⏰‼️`, closed: [], post: [`✅ ผลออก 22.45 - 22.50 น.`, `📌 ลิงก์ดูผล3รัส VIP 👇🏻👇🏻`], link: [`https://lottosuperrich.com/`] },
        { title: `🎐 รัสเซีย   ⏰‼️`, closed: [], post: [`✅ ผลออก 23.45 - 23.50 น.`, `📌 ลิงก์ดูผล3รัส VIP 👇🏻👇🏻`], link: [`https://lottosuperrich.com/`] },
        { title: `🇱🇦 ลิงก์ดูผลลาวกาชาด (ใช้ผล 5 ตัว)`, closed: [`❌  ปิดรับ 23:00 น.`], post: [`✅ ผลออก 23:30 น.`], link: [`https://lao-redcross.com`] },
        { title: `ดาวโจนส์วีไอพี⭐️⭐️`, closed: [`❌  ปิดรับ 00:00 น.`], post: [`✅ ผลออก 00:30 น.`], link: [`https://stocks-vip.com`, `https://dowjonespowerball.com`] },
        { title: `ดาวโจนส์star ⭐️`, closed: [`❌ ปิดรับ 01:05 น.`], post: [`✅ ผลออก 01:30 น.`, `✅ ออกผลทุกวัน`], link: [`https://dowjonestar.com/`] },
        { title: `ฮานอย extra`, closed: [`❌ ปิดรับ 22:10 น.`], post: [`✅ ผลออก 22:30 น.`], link: [`www.xosoextra.com`] },
        { title: `🇱🇦 ลิงก์ดูผลลาวHD (ใช้ผล 5 ตัว)`, closed: [], post: [`✅ ผลออก 13:30 น.`], link: [`https://laoshd.com/`] },
        { title: `🇯🇵 หุ้นนิเคอิเช้า VIP`, closed: [`❌ ปิดรับ 09.00 ออกผล 09.05`], post: [], link: [`nikkeivipstock.com`] },
        { title: `🇨🇳 หุ้นจีนเช้า VIP`, closed: [`❌ ปิดรับ 10.00 ออกผล 10.05`], post: [], link: [`https://shenzhenindex.com`] },
        { title: `🇸🇬 หุ้นฮั่งเส็งเช้า VIP`, closed: [`❌ ปิดรับ 10.30 ออกผล 10.35`], post: [], link: [`hangsengvip.com`] },
        { title: `🇹🇼 หุ้นไต้หวัน VIP`, closed: [`❌ ปิดรับ 11.30 ออกผล 11.35`], post: [], link: [`https://tsecvipindex.com`] },
        { title: `🇰🇷 หุ้นเกาหลี VIP`, closed: [`❌ ปิดรับ 12.30 ออกผล 12.35`], post: [], link: [`ktopvipindex.com`] },
        { title: `🇯🇵 หุ้นนิเคอิบ่าย VIP`, closed: [`❌ ปิดรับ 13.20 ออกผล 13.25`], post: [], link: [`nikkeivipstock.com`] },
        { title: `🇨🇳 หุ้นจีนบ่าย VIP`, closed: [`❌ ปิดรับ 14.20 ออกผล 14.25`], post: [], link: [`https://shenzhenindex.com`] },
        { title: `🇸🇬 หุ้นฮั่งเส็งบ่าย VIP`, closed: [`❌ ปิดรับ 15.20 ออกผล 15.25`], post: [], link: [`hangsengvip.com`] },
        { title: `ลิงก์รวมดูหวยหุ้นVIP`, closed: [], post: [], link: [`https://stocks-vip.com/`] },
        { title: `ฮานอยสามัคคี`, closed: [`❌ ปิดรับ 17.10 เริ่มออกผล 17.15`], post: [], link: [`https://xosounion.com`] },
        { title: `ฮานอยพัฒนา`, closed: [`❌ ปิดรับ 19.10 เริ่มออกผล 19.15`], post: [], link: [`https://xosodevelop.com`] },
        { title: `สิงก์โปร์ VIP`, closed: [`❌ ปิดรับ 17.00 เริ่มออกผล 17.05`], post: [], link: [`https://sgxvip.com/`] },
        { title: `🇻🇳ฮานอยอาเซียน🇻🇳`, closed: [`❌ ปิดรับ 09:10`], post: [`ออกทุกวันเวลา 09.30`], link: [`https://hanoiasean.com`] },
        { title: `🇱🇦 ลาวอาเซียน 🇱🇦`, closed: [`❌ ปิดรับ 20:55`], post: [`ออกทุกวันเวลา 21.00`], link: [`https://lotterylaosasean.com/`] },

    ]
    return (
        <div className="common-page-group results-link-page">
            <div className='page-title'>
                <Typography className='title'>{MENU_ITEMS.resultLink.label}</Typography>
            </div>
            <Row gutter={[16, 16]}>
                {items.map((item) => renderResultLinkCard(item))}
            </Row>
        </div>
    );
};

export default ResultLinks;
