import React, { useState, useEffect } from 'react';
import { Typography, Row, Col, message, DatePicker, Table, Card, Button, Modal, Checkbox, Divider } from 'antd';
import { close_number_tag, ELM_STANDARD, MENU_ITEMS } from '../../util/options';
import { dateFormats, DATE_FORMAT, DB, MSG, NUMBER_FORMAT, ranges } from '../../util/constant';

import dayjs from 'dayjs'
import 'dayjs/locale/th'

import moment from 'moment';
import 'moment/locale/th';
import MemberService from '../../services/user/member.service';
import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined, MinusOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { useLottoParamContext } from '../../contexts/LottoParamContext';
import { groupBy } from 'lodash';
moment.updateLocale('th', {
    week: {
        dow: 1
    }
})

const { RangePicker } = DatePicker;
const CheckboxGroup = Checkbox.Group;
const TransactionsReport = () => {
    const { lottoParam, lottoParamAction } = useLottoParamContext();
    const [loadingResult, setLoadingResult] = useState(false)

    const [data, setData] = useState([])
    const params = {
        dateRange: { from: moment().format('YYYY-MM-DD'), to: moment().format('YYYY-MM-DD') }
    }
    const [dateRange, setDateRange] = useState({ ...params.dateRange })
    const [dateValues, setDateValues] = useState([moment(), moment()])

    const [displayInfo, setDisplayInfo] = useState({})

    const [isModalDetailVisible, setIsModalDetailVisible] = useState(false)
    const [modalData, setModalData] = useState({})

    const [options, setOptions] = useState([])
    const [checkedList, setCheckedList] = useState([]);


    const setCheckedListGroup = (param) => {
        if (param === 'all') {
            setCheckedList(lottoParam.lotto.list.map(item => item.id))
        } else {
            setCheckedList([...checkedList, ...lottoParam.lotto.list.filter(item => item.groupId == param).map(item => item.id)])
        }
    }

    const onRangeChange = (dates, dateStrings) => {
        if (!dates) return
        let dateObj = {
            "from": dateStrings[0],
            "to": dateStrings[1],
        }
        setDateValues(dates)
        setDateRange(dateObj)
    }

    const search = async (_dateRange = dateRange) => {
        setLoadingResult(true)
        setDisplayInfo({
            diff: dayjs(_dateRange.to).diff(_dateRange.from, 'day') + 1,
            from: DATE_FORMAT.dateonly(_dateRange.from),
            to: DATE_FORMAT.dateonly(_dateRange.to),
        })
        MemberService.getTransactionsReport({ lotto: checkedList, dateRange: _dateRange })
            .then(async res => {
                setData(res);
                setLoadingResult(false)
            })
    }

    useEffect(() => {
        try {
            const groupedData = groupBy(lottoParam.lotto.list, 'groupId')
            setOptions(groupedData)
            setCheckedList(lottoParam.lotto.list.map(item => item.id))
        }
        catch (err) {
            message.error(MSG.COMMON.SMT_WRONG)
        }
    }, [lottoParam.lotto.list])


    const openModal = async (record) => {
        setLoadingResult(true)
        const res = await MemberService.getTransactionsReportByLottoDatesFk({ lotto: checkedList, dateRange: dateRange, lotto_dates_fk: record.lotto_dates_fk })
        setModalData({ name: record.name, date: record.date, details: res })
        setIsModalDetailVisible(true)
        setLoadingResult(false)
    }


    const renderExpandRow = (expandData) => {
        return <Table
            size='small'
            scroll={{ x: 'max-content' }}
            dataSource={expandData.details}
            columns={[
                {
                    title: 'ประเภท @ หมายเลข',
                    dataIndex: 'mode',
                    key: 'mode',
                    align: 'center',
                    render: (text, record, index) => (
                        <div style={{ textAlign: "center" }}><span className="color-red">{record.mode}</span> @ <span className="color-blue">{record.betNumber}</span></div>
                    )
                },
                {
                    title: 'ยอด',
                    dataIndex: 'betAmount',
                    key: 'betAmount',
                    align: 'right',
                    render: value => (
                        <div className='text-green'>{NUMBER_FORMAT.default(value)}</div>
                    )
                },
                {
                    title: 'ส่วนลด',
                    dataIndex: 'discountAmount',
                    key: 'discountAmount',
                    align: 'right',
                    render: value => (
                        <div className='text-red'>{NUMBER_FORMAT.default(value)}</div>
                    )
                },
                {
                    title: 'รวม',
                    dataIndex: 'summary',
                    key: 'summary',
                    align: 'right',
                    render: (value, record) => (
                        <div className='text-blue'>{NUMBER_FORMAT.default(record.betAmount - record.discountAmount)}</div>
                    )
                },
                {
                    title: () => <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>จ่าย</span>
                        <span>รวม</span>
                    </div>,
                    dataIndex: 'rate',
                    key: 'rate',
                    align: 'center',
                    render: (value, record) => (
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>{value}</span>
                            <span className='text-blue'>{NUMBER_FORMAT.default(record.betAmount * (record.rate * 1))}</span>
                        </div>
                    )
                },
                {
                    title: () => <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>จ่าย (อัตราเริ่มต้น)</span>
                        <span>รวม</span>
                    </div>,
                    dataIndex: 'defaultRate',
                    key: 'defaultRate',
                    align: 'center',
                    render: (value, record) => {
                        const defaultRate = DB.SYSTEM.LOTTO.DEFAULT_REGISTER.find((g) => g.id === expandData.groupId).rate.find((g) => g.id === record.modes_fk).rate;

                        return <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>{defaultRate}</span>
                            <span className='text-blue'>{NUMBER_FORMAT.default(record.betAmount * (defaultRate * 1))}</span>
                        </div>
                    }
                },
                {
                    title: 'สถานะ',
                    dataIndex: 'status',
                    key: 'status',
                    align: 'center',
                    render: (value, record) => {
                        return Number(record.wonAmount) > 0 ?
                            <Row justify='space-between' className='text-green text-bold'><Col>ถูกรางวัล</Col><Col >{NUMBER_FORMAT.default(record.wonAmount)}</Col></Row>
                            : <span className='text-red'>ไม่ถูกรางวัล</span>
                    }
                },
                {
                    title: 'หมายเหตุ',
                    dataIndex: 'flag',
                    key: 'flag',
                    align: 'center',
                    render: value => {
                        return close_number_tag[value]
                    }
                },

            ]}
            summary={pageData => {
                let sum1 = 0;
                let sum2 = 0;
                let sum3 = 0;
                let sum4 = 0;

                pageData.forEach(({ betAmount, discountAmount, wonAmount }) => {

                    sum1 += betAmount
                    sum2 += discountAmount
                    sum3 += betAmount - discountAmount
                    sum4 += wonAmount
                });
                return (
                    <Table.Summary.Row style={{ fontWeight: 'bold' }}>
                        <Table.Summary.Cell className='text-center'><div >รวม @ {expandData.count} รายการ</div></Table.Summary.Cell>
                        <Table.Summary.Cell className='text-right text-green'><div>{NUMBER_FORMAT.default(sum1)}</div> </Table.Summary.Cell>
                        <Table.Summary.Cell className='text-right text-red'><div>{NUMBER_FORMAT.default(sum2)}</div> </Table.Summary.Cell>
                        <Table.Summary.Cell className='text-right text-blue'><div>{NUMBER_FORMAT.default(sum3)}</div> </Table.Summary.Cell>
                        <Table.Summary.Cell className='text-left' colSpan={2}></Table.Summary.Cell>
                        <Table.Summary.Cell className='text-right text-green'><div>{NUMBER_FORMAT.default(sum4)}</div> </Table.Summary.Cell>

                    </Table.Summary.Row>
                );
            }}
            bordered
            pagination={false}
        />
    }


    return (
        <div className='member-page-group transactions-report-page'>
            <div className='page-title'>
                <Typography className='title'>{MENU_ITEMS.memberTransactionsReport.label}</Typography>
            </div>


            <div className='page-content-wrapper'>
                <div className='page-content'>

                    <div className="search-section">
                        <Card title="ตัวเลือกการค้นหา" size="small">
                            <Row gutter={[16, 16]} justify='start'>
                                <Col xs={12}>
                                    <Row gutter={[16, 16]}>
                                        <Col>
                                            <RangePicker
                                                ranges={{ ...ranges }}
                                                onChange={onRangeChange}
                                                value={dateValues}
                                                allowEmpty={[false, false]}
                                                allowClear={false}
                                            />
                                        </Col>
                                        <Col><Button disabled={checkedList.length === 0} loading={loadingResult} type="primary" onClick={() => search()}><SearchOutlined /> ค้นหา</Button></Col>
                                    </Row>
                                    <Row gutter={[16, 16]} className='my-2'>
                                        {Object.entries(ranges)?.map(([key, value]) => {
                                            return <Col key={key}><Button onClick={() => {
                                                let dateObj = {
                                                    "from": moment(value[0]).format(dateFormats.only_date),
                                                    "to": moment(value[1]).format(dateFormats.only_date),
                                                }
                                                onRangeChange([...value], [dateObj.from, dateObj.to])
                                                search(dateObj)
                                            }
                                            }>{key}</Button></Col>
                                        })}
                                    </Row>
                                </Col>
                                <Col xs={12}>
                                    <div className="checkboxGroup">
                                        <CheckboxGroup className={`checkbox w-100`} value={checkedList} onChange={(list) => setCheckedList(list)} >
                                            {Object.values(options)?.map((g, i) => {
                                                return <Row className="group-wrapper" key={`Row-${i}`}>
                                                    {g.map((g, i) => {
                                                        return <Col span={6} key={`${g.id}`}><Checkbox value={g.id}>{g.name}</Checkbox></Col>
                                                    })}
                                                </Row>
                                            })
                                            }
                                        </CheckboxGroup>
                                    </div>
                                    <Divider />
                                    <Row gutter={[8, 16]}>
                                        <Col>
                                            <Button type="primary" onClick={() => setCheckedListGroup('all')}>
                                                เลือกทั้งหมด
                                            </Button>
                                        </Col>
                                        <Col>
                                            <Button type="primary" danger onClick={() => setCheckedList([])}>
                                                เอาออกทั้งหมด
                                            </Button>
                                        </Col>
                                        {Object.keys(options)?.map((g) => {
                                            return <Col key={g}>
                                                <Button onClick={() => setCheckedListGroup(g)}>
                                                    {(DB.SYSTEM.LOTTO.GROUP.find((gr) => gr.id == g))?.name}
                                                </Button>
                                            </Col>
                                        })}
                                    </Row>
                                </Col>
                            </Row>
                        </Card>
                    </div>

                    <div className='displayInfo-section'>
                        {displayInfo.diff && (
                            <div>ข้อมูลรวม <span className='text-blue'>{displayInfo.diff}</span> วัน
                                ตั้งแต่วันที่ <span className='text-blue'>{displayInfo.from}</span> ถึง <span className='text-blue'>{displayInfo.to}</span></div>

                        )}
                        <div><ClockCircleOutlined /> ยังไม่ออกรางวัล,  <CheckCircleOutlined style={{ color: 'green' }} /> ออกรางวัล, <CloseCircleOutlined style={{ color: 'red' }} /> ยกเลิก</div>
                    </div>

                    <div className="table-wrapper">
                        <Table
                            scroll={{ x: 'max-content' }}
                            loading={loadingResult}
                            rowKey={(record) => `${record.id}${record.date}`}
                            pagination={false}
                            dataSource={data}
                            columns={[
                                {
                                    title: 'ชนิดหวย',
                                    dataIndex: 'name',
                                    key: 'name',
                                    render: (value, record) => {
                                        let status = <span className='text-red'><CloseCircleOutlined /> </span>
                                        if (record.status === DB.SYSTEM.LOTTO_DATE.STATUS.CALCULATING || record.status === DB.SYSTEM.LOTTO_DATE.STATUS.PENDING) {
                                            status = <ClockCircleOutlined />
                                        } else if (record.status === DB.SYSTEM.LOTTO_DATE.STATUS.COMPLETED) {
                                            status = <span className='text-green'><CheckCircleOutlined /> </span>
                                        }
                                        return <div>{status} [{(DB.SYSTEM.LOTTO.GROUP.find((g) => g.id === record.groupId))?.name}] {record.name}</div>
                                    }
                                },
                                {
                                    title: 'งวด',
                                    dataIndex: 'date',
                                    key: 'date',
                                    align: 'center',
                                    render: (value, record) => {
                                        return <Typography.Link onClick={() => openModal(record)}>{DATE_FORMAT.dateonly(value)}</Typography.Link>
                                    }
                                },
                                {
                                    title: 'ยอด',
                                    dataIndex: 'sum',
                                    key: 'sum',
                                    align: 'right',
                                    render: (value, record) => {
                                        return <span className='text-green'>{NUMBER_FORMAT.default(value)}</span>
                                    }
                                },
                                {
                                    title: 'ส่วนลด',
                                    dataIndex: 'discount',
                                    key: 'discount',
                                    align: 'right',
                                    render: (value, record) => {
                                        return <span className='text-red'>{NUMBER_FORMAT.default(value)}</span>
                                    }
                                },
                                {
                                    title: 'ถูกรางวัล',
                                    dataIndex: 'wonAmount',
                                    key: 'wonAmount',
                                    align: 'right',
                                    render: (value, record) => {
                                        return <span className={value > 0 ? 'text-green' : ''}>{NUMBER_FORMAT.default(value)}</span>
                                    }
                                },
                                {
                                    title: 'แพ้/ชนะ',
                                    dataIndex: 'summary',
                                    key: 'summary',
                                    align: 'right',
                                    render: (value, record) => {
                                        let summary = Number(record.discount) + Number(record.wonAmount) - Number(record.sum)
                                        return <span className='text-blue'>{NUMBER_FORMAT.default(summary)}</span>
                                    }
                                },
                            ]}
                            summary={pageData => {
                                let sum1 = 0;
                                let sum2 = 0;
                                let sum3 = 0;
                                let sum4 = 0;
                                pageData.forEach(({ sum, discount, wonAmount }) => {
                                    sum1 += Number(sum)
                                    sum2 += Number(discount)
                                    sum3 += Number(wonAmount)
                                    sum4 += Number(discount) + Number(wonAmount) - Number(sum)
                                });
                                return (
                                    <Table.Summary.Row style={{ fontWeight: 'bold' }}>
                                        <Table.Summary.Cell className='text-left' colSpan={2}><div >รวม</div></Table.Summary.Cell>
                                        <Table.Summary.Cell className='text-right text-green'><div>{NUMBER_FORMAT.default(sum1)}</div> </Table.Summary.Cell>
                                        <Table.Summary.Cell className='text-right text-red'><div>{NUMBER_FORMAT.default(sum2)}</div> </Table.Summary.Cell>
                                        <Table.Summary.Cell className='text-right text-green'><div>{NUMBER_FORMAT.default(sum3)}</div> </Table.Summary.Cell>
                                        <Table.Summary.Cell className='text-right text-blue'><div>{NUMBER_FORMAT.default(sum4)}</div> </Table.Summary.Cell>
                                    </Table.Summary.Row>
                                );
                            }}
                        />
                    </div>

                    <Modal style={{ minWidth: '50vw' }}
                        {...ELM_STANDARD.noTransitionModal}
                        footer={[
                            <Button key="back" onClick={() => setIsModalDetailVisible(false)}>
                                ปิด
                            </Button>]}
                        title={< span > {modalData.name} | {DATE_FORMAT.dateonly(modalData.date)}</span>}
                        visible={isModalDetailVisible} onCancel={() => setIsModalDetailVisible(false)}>
                        <Table
                            loading={loadingResult}
                            rowKey={(record) => record.orderId}
                            scroll={{ x: 'max-content' }}
                            dataSource={modalData?.details}
                            columns={[
                                {
                                    title: '#',
                                    dataIndex: 'orderId',
                                    key: 'orderId',
                                    align: 'center',
                                },
                                {
                                    title: 'หมายเหตุ',
                                    dataIndex: 'remark',
                                    key: 'remark',
                                    align: 'left',
                                },
                                {
                                    title: 'ยอด',
                                    dataIndex: 'sum',
                                    key: 'sum',
                                    align: 'right',
                                    render: (value, record) => {
                                        return <span className='text-green'>{NUMBER_FORMAT.default(value)}</span>
                                    }
                                },
                                {
                                    title: 'ส่วนลด',
                                    dataIndex: 'discount',
                                    key: 'discount',
                                    align: 'right',
                                    render: (value, record) => {
                                        return <span className='text-red'>{NUMBER_FORMAT.default(value)}</span>
                                    }
                                },
                                {
                                    title: 'ถูกรางวัล',
                                    dataIndex: 'wonAmount',
                                    key: 'wonAmount',
                                    align: 'right',
                                    render: (value, record) => {
                                        return <span className={value > 0 ? 'text-green' : ''}>{NUMBER_FORMAT.default(value)}</span>
                                    }
                                },
                                {
                                    title: 'แพ้/ชนะ',
                                    dataIndex: 'summary',
                                    key: 'summary',
                                    align: 'right',
                                    render: (value, record) => {
                                        return <span className='text-blue'>{NUMBER_FORMAT.default(Number(record.discount) + Number(record.wonAmount) - Number(record.sum))}</span>
                                    }
                                },
                            ]}
                            summary={pageData => {
                                let sum1 = 0;
                                let sum2 = 0;
                                let sum3 = 0;
                                let sum4 = 0;
                                pageData.forEach(({ sum, discount, wonAmount }) => {
                                    sum1 += Number(sum)
                                    sum2 += Number(discount)
                                    sum3 += Number(wonAmount)
                                    sum4 += Number(discount) + Number(wonAmount) - Number(sum)
                                });
                                return (
                                    <Table.Summary.Row style={{ fontWeight: 'bold' }}>
                                        <Table.Summary.Cell className='text-left' colSpan={3}><div >รวม</div></Table.Summary.Cell>
                                        <Table.Summary.Cell className='text-right text-green'><div>{NUMBER_FORMAT.default(sum1)}</div> </Table.Summary.Cell>
                                        <Table.Summary.Cell className='text-right text-red'><div>{NUMBER_FORMAT.default(sum2)}</div> </Table.Summary.Cell>
                                        <Table.Summary.Cell className='text-right text-green'><div>{NUMBER_FORMAT.default(sum3)}</div> </Table.Summary.Cell>
                                        <Table.Summary.Cell className='text-right text-blue'><div>{NUMBER_FORMAT.default(sum4)}</div> </Table.Summary.Cell>
                                    </Table.Summary.Row>
                                );
                            }}
                            expandable={{
                                expandedRowRender: record => renderExpandRow(record),
                                expandIcon: ({ expanded, onExpand, record }) => {
                                    return expanded ? (
                                        <Button type='link' onClick={e => onExpand(record, e)} icon={<MinusOutlined />}>ปิด</Button >
                                    ) : (
                                        <Button type='link' onClick={e => onExpand(record, e)} icon={<PlusOutlined />}>ดูรายละเอียด</Button>
                                    )
                                },
                                fixed: 'left'
                            }}
                            EXPAND_COLUMN_INDEX={0}
                            bordered
                            pagination={false}
                        />
                    </Modal>
                </div>
            </div >
        </div >

    )
}

export default TransactionsReport;