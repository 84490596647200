import React, { useState, useEffect } from 'react';
import { Button, Spin, Typography, Table, Modal } from 'antd';
import { close_number_tag, ELM_STANDARD, MENU_ITEMS } from '../../util/options';

import MemberService from '../../services/user/member.service';
import { DATE_FORMAT, DB, NUMBER_FORMAT } from '../../util/constant';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

const WaitReport = () => {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [isModalDetailVisible, setIsModalDetailVisible] = useState(false)
    const [modalData, setModalData] = useState({})

    useEffect(() => {
        prepareData()
    }, [])

    const prepareData = async () => {
        const data = await MemberService.getWaitReport()
        setData(data)
        setLoading(false)
    }

    const openModal = async (record) => {
        setLoading(true)
        const res = await MemberService.getWaitReportByLottoDatesFk(record.lotto_dates_fk)
        setModalData({ name: record.name, date: record.date, details: res })
        setIsModalDetailVisible(true)
        setLoading(false)
    }


    const renderExpandRow = (expandData) => {
        return <Table
            size='small'
            scroll={{ x: 'max-content' }}
            dataSource={expandData.details}
            columns={[
                {
                    title: 'ประเภท @ หมายเลข',
                    dataIndex: 'mode',
                    key: 'mode',
                    align: 'center',
                    render: (text, record, index) => (
                        <div style={{ textAlign: "center" }}><span className="color-red">{record.mode}</span> @ <span className="color-blue">{record.betNumber}</span></div>
                    )
                },
                {
                    title: 'ยอด',
                    dataIndex: 'betAmount',
                    key: 'betAmount',
                    align: 'right',
                    render: value => (
                        <div className='text-green'>{NUMBER_FORMAT.default(value)}</div>
                    )
                },
                {
                    title: 'ส่วนลด',
                    dataIndex: 'discountAmount',
                    key: 'discountAmount',
                    align: 'right',
                    render: value => (
                        <div className='text-red'>{NUMBER_FORMAT.default(value)}</div>
                    )
                },
                {
                    title: 'รวม',
                    dataIndex: 'summary',
                    key: 'summary',
                    align: 'right',
                    render: (value, record) => (
                        <div className='text-blue'>{NUMBER_FORMAT.default(record.betAmount - record.discountAmount)}</div>
                    )
                },
                {
                    title: () => <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>จ่าย</span>
                        <span>รวม</span>
                    </div>,
                    dataIndex: 'rate',
                    key: 'rate',
                    align: 'center',
                    render: (value, record) => (
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>{value}</span>
                            <span className='text-blue'>{NUMBER_FORMAT.default(record.betAmount * (record.rate * 1))}</span>
                        </div>
                    )
                },
                {
                    title: () => <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>จ่าย (อัตราเริ่มต้น)</span>
                        <span>รวม</span>
                    </div>,
                    dataIndex: 'defaultRate',
                    key: 'defaultRate',
                    align: 'center',
                    render: (value, record) => {
                        const defaultRate = DB.SYSTEM.LOTTO.DEFAULT_REGISTER.find((g) => g.id === expandData.groupId).rate.find((g) => g.id === record.modes_fk).rate;

                        return <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>{defaultRate}</span>
                            <span className='text-blue'>{NUMBER_FORMAT.default(record.betAmount * (defaultRate * 1))}</span>
                        </div>
                    }
                },
                {
                    title: 'หมายเหตุ',
                    dataIndex: 'flag',
                    key: 'flag',
                    align: 'center',
                    render: value => {
                        return close_number_tag[value]
                    }
                },

            ]}
            summary={pageData => {
                let sum1 = 0;
                let sum2 = 0;
                let sum3 = 0;

                pageData.forEach(({ betAmount, discountAmount }) => {

                    sum1 += betAmount
                    sum2 += discountAmount
                    sum3 += betAmount - discountAmount

                });
                return (
                    <Table.Summary.Row style={{ fontWeight: 'bold' }}>
                        <Table.Summary.Cell className='text-center'><div >รวม @ {expandData.count} รายการ</div></Table.Summary.Cell>
                        <Table.Summary.Cell className='text-right text-green'><div>{NUMBER_FORMAT.default(sum1)}</div> </Table.Summary.Cell>
                        <Table.Summary.Cell className='text-right text-red'><div>{NUMBER_FORMAT.default(sum2)}</div> </Table.Summary.Cell>
                        <Table.Summary.Cell className='text-right text-blue'><div>{NUMBER_FORMAT.default(sum3)}</div> </Table.Summary.Cell>
                        <Table.Summary.Cell className='text-center' colSpan={2}></Table.Summary.Cell>
                    </Table.Summary.Row>
                );
            }}
            bordered
            pagination={false}
        />
    }

    return (
        <div className='member-page-group member-wait-report-page'>
            <Spin {...ELM_STANDARD.spin} spinning={loading}>
                <div className='page-title'>
                    <Typography className='title'>{MENU_ITEMS.memberWaitReport.label}</Typography>
                </div>

                <div className='page-content-wrapper'>
                    <div className='page-content'>
                        <Table
                            scroll={{ x: 'max-content' }}
                            rowKey={(record) => `${record.id}${record.date}`}
                            pagination={false}
                            dataSource={data}
                            columns={[
                                {
                                    title: 'ชนิดหวย',
                                    dataIndex: 'name',
                                    key: 'name',
                                    render: (value, record) => {
                                        return <div>[{(DB.SYSTEM.LOTTO.GROUP.find((g) => g.id === record.groupId))?.name}] {record.name}</div>
                                    }
                                },
                                {
                                    title: 'งวด',
                                    dataIndex: 'date',
                                    key: 'date',
                                    align: 'center',
                                    render: (value, record) => {
                                        return <Typography.Link onClick={() => openModal(record)}>{DATE_FORMAT.dateonly(value)}</Typography.Link>
                                    }
                                },
                                {
                                    title: 'ยอด',
                                    dataIndex: 'sum',
                                    key: 'sum',
                                    align: 'right',
                                    render: (value, record) => {
                                        return <span className='text-green'>{NUMBER_FORMAT.default(value)}</span>
                                    }
                                },
                                {
                                    title: 'ส่วนลด',
                                    dataIndex: 'discount',
                                    key: 'discount',
                                    align: 'right',
                                    render: (value, record) => {
                                        return <span className='text-red'>{NUMBER_FORMAT.default(value)}</span>
                                    }
                                },
                                {
                                    title: 'แพ้/ชนะ',
                                    dataIndex: 'summary',
                                    key: 'summary',
                                    align: 'right',
                                    render: (value, record) => {
                                        return <span className='text-blue'>{NUMBER_FORMAT.default(Number(record.discount) - Number(record.sum))}</span>
                                    }
                                },
                            ]}
                            summary={pageData => {
                                let sum1 = 0;
                                let sum2 = 0;
                                let sum3 = 0;
                                pageData.forEach(({ sum, discount }) => {

                                    sum1 += Number(sum)
                                    sum2 += Number(discount)
                                    sum3 += discount - sum
                                });
                                return (
                                    <Table.Summary.Row style={{ fontWeight: 'bold' }}>
                                        <Table.Summary.Cell className='text-left' colSpan={2}><div >รวม</div></Table.Summary.Cell>
                                        <Table.Summary.Cell className='text-right text-green'><div>{NUMBER_FORMAT.default(sum1)}</div> </Table.Summary.Cell>
                                        <Table.Summary.Cell className='text-right text-red'><div>{NUMBER_FORMAT.default(sum2)}</div> </Table.Summary.Cell>
                                        <Table.Summary.Cell className='text-right text-blue'><div>{NUMBER_FORMAT.default(sum3)}</div> </Table.Summary.Cell>
                                    </Table.Summary.Row>
                                );
                            }}
                        />
                    </div>
                </div>

                <Modal style={{ minWidth: '50vw' }}
                    {...ELM_STANDARD.noTransitionModal}
                    footer={[
                        <Button key="back" onClick={() => setIsModalDetailVisible(false)}>
                            ปิด
                        </Button>]}
                    title={< span > [{modalData.name} | {DATE_FORMAT.dateonly(modalData.date)}</span>}
                    visible={isModalDetailVisible} onCancel={() => setIsModalDetailVisible(false)}>
                    <Table
                        rowKey={(record) => record.orderId}
                        scroll={{ x: 'max-content' }}
                        dataSource={modalData?.details}
                        columns={[
                            {
                                title: '#',
                                dataIndex: 'orderId',
                                key: 'orderId',
                                align: 'center',
                            },
                            {
                                title: 'หมายเหตุ',
                                dataIndex: 'remark',
                                key: 'remark',
                                align: 'left',
                            },
                            {
                                title: 'ยอด',
                                dataIndex: 'sum',
                                key: 'sum',
                                align: 'right',
                                render: (value, record) => {
                                    return <span className='text-green'>{NUMBER_FORMAT.default(value)}</span>
                                }
                            },
                            {
                                title: 'ส่วนลด',
                                dataIndex: 'discount',
                                key: 'discount',
                                align: 'right',
                                render: (value, record) => {
                                    return <span className='text-red'>{NUMBER_FORMAT.default(value)}</span>
                                }
                            },
                            {
                                title: 'แพ้/ชนะ',
                                dataIndex: 'summary',
                                key: 'summary',
                                align: 'right',
                                render: (value, record) => {
                                    return <span className='text-blue'>{NUMBER_FORMAT.default(Number(record.discount) - Number(record.sum))}</span>
                                }
                            },
                        ]}
                        summary={pageData => {
                            let sum1 = 0;
                            let sum2 = 0;
                            let sum3 = 0;
                            pageData.forEach(({ sum, discount }) => {

                                sum1 += Number(sum)
                                sum2 += Number(discount)
                                sum3 += discount - sum
                            });
                            return (
                                <Table.Summary.Row style={{ fontWeight: 'bold' }}>
                                    <Table.Summary.Cell className='text-left' colSpan={3}><div >รวม</div></Table.Summary.Cell>
                                    <Table.Summary.Cell className='text-right text-green'><div>{NUMBER_FORMAT.default(sum1)}</div> </Table.Summary.Cell>
                                    <Table.Summary.Cell className='text-right text-red'><div>{NUMBER_FORMAT.default(sum2)}</div> </Table.Summary.Cell>
                                    <Table.Summary.Cell className='text-right text-blue'><div>{NUMBER_FORMAT.default(sum3)}</div> </Table.Summary.Cell>
                                </Table.Summary.Row>
                            );
                        }}
                        expandable={{
                            expandedRowRender: record => renderExpandRow(record),
                            expandIcon: ({ expanded, onExpand, record }) => {
                                return expanded ? (
                                    <Button type='link' onClick={e => onExpand(record, e)} icon={<MinusOutlined />}>ปิด</Button >
                                ) : (
                                    <Button type='link' onClick={e => onExpand(record, e)} icon={<PlusOutlined />}>ดูรายละเอียด</Button>
                                )
                            },
                            fixed: 'left'
                        }}
                        EXPAND_COLUMN_INDEX={0}
                        bordered
                        pagination={false}
                    />
                </Modal>
            </Spin>
        </div>

    )
}

export default WaitReport;