import { cloneDeep } from "lodash"
import React, { createContext, useContext, useState, useMemo, useEffect } from "react"
import LottoService from "../services/system/lotto.service"
import { useUserContext } from "./UserContext"


const LottoParamContext = createContext({})

export const useLottoParamContext = () => {
    return useContext(LottoParamContext)
}

const initialLottoParam = {
    lotto: {
        list: [],
        selected: {
            id: null,
            groupId: null,
            name: null,
            groupName: null,
        }
    },
    lottoDate: {
        list: [],
        selected: {
            id: null,
            date: null,
            status: null,
        },
    }
}


export const LottoParamProvider = ({ children }) => {
    const [lottoParam, setLottoParam] = useState(initialLottoParam)
    const { user, userAction } = useUserContext();

    const Services = {
        getLottoParamDateLatest: userAction.isAdmin() ? LottoService.getLottoParamDateLatestAdmin : LottoService.getLottoParamDateLatest,
        getLottoParamDateData: userAction.isAdmin() ? LottoService.getLottoParamDateDataAdmin : LottoService.getLottoParamDateData,
    }

    const getLottoParamData = async () => {
        const data = await LottoService.getLottoParamData()
        const newLottoParam = { ...lottoParam }
        newLottoParam.lotto.list = data?.lotto

        setLottoParam(cloneDeep(newLottoParam))
    }

    const selectLotto = async (lotto) => {
        const newLottoParam = { ...lottoParam }
        const data = await Services.getLottoParamDateLatest(lotto.id)
        newLottoParam.lotto.selected = lotto
        newLottoParam.lottoDate.selected = { id: data?.lottoDate?.id, date: data?.lottoDate?.date, status: data?.lottoDate?.status }
        setLottoParam(cloneDeep(newLottoParam))
    }

    const selectLottoDate = (lottoDate) => {
        const newLottoParam = { ...lottoParam }
        newLottoParam.lottoDate.selected = lottoDate
        setLottoParam(cloneDeep(newLottoParam))
    }

    const selectLottoAndLottoDate = (lottoDate, lotto) => {
        const newLottoParam = { ...lottoParam }
        newLottoParam.lotto.selected = lotto
        newLottoParam.lottoDate.selected = lottoDate
        setLottoParam(cloneDeep(newLottoParam))
    }


    const updateLottoDateData = async () => {
        const data = await Services.getLottoParamDateData(lottoParam.lotto.selected.id)
        const newLottoParam = { ...lottoParam }
        newLottoParam.lottoDate.list = data?.lottoDate
        setLottoParam(cloneDeep(newLottoParam))
    }

    useEffect(() => {
        getLottoParamData()
    }, [])

    const lottoParamStore = useMemo(() => ({
        lottoParam,
        lottoParamAction: {
            setLottoParam,
            selectLotto,
            selectLottoDate,
            selectLottoAndLottoDate,
            updateLottoDateData,
        }
    }), [lottoParam])


    return (
        <LottoParamContext.Provider value={lottoParamStore}>
            {children}
        </LottoParamContext.Provider>
    )
}

export default LottoParamProvider