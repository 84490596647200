import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";
import { DB, NUMBER_FORMAT } from "../../util/constant";
import { useUserRateContext } from "../../contexts/UserRateContext";
import { Col, Divider, Row, Table } from 'antd';

const CurrentRate = (props) => {
    const { userRate, userRateAction } = useUserRateContext();
    const { lottoDateId } = useParams();

    useEffect(() => {
        if (lottoDateId) {
            userRateAction.getUserRateData(lottoDateId)
        }
    }, [lottoDateId]);

    return <div className={`current-rate-component ${props.isCollapsed ? 'display-none' : ''}`}>
        <Divider style={{ backgroundColor: '#0d263b', margin: '0 0 12px 0' }} />
        <Row justify='space-between'>
            <Col className='text-bold'>อัตราจ่าย</Col>
            <Col>{userRate?.rate?.length > 0 ? DB.SYSTEM.LOTTO.GROUP.find((d) => d.id === userRate.groupId)?.name : '-'}</Col>
        </Row>
        {userRate?.rate?.length > 0 && (
            <Table
            scroll={{ x: 'max-content' }}
                bordered
                pagination={false}
                size={'small'}
                rowKey={(record) => record.id}
                dataSource={userRate?.rate}
                columns={[
                    {
                        title: `${userRate.name}`,
                        dataIndex: 'id',
                        key: 'id',
                        render: (value,) => {
                            return DB.SYSTEM.DESC.RATE.find((d) => d.id === value)?.name
                        }
                    },
                    {
                        title: 'จ่าย',
                        dataIndex: 'rate',
                        key: 'rate',
                        align: 'center',
                    },
                    {
                        title: 'ลด',
                        dataIndex: 'discount',
                        key: 'discount',
                        align: 'center',
                    },
                    {
                        title: 'ขั้นต่ำ',
                        dataIndex: 'min',
                        key: 'min',
                        align: 'center',
                        render: (value, record) => {
                            return <div>{NUMBER_FORMAT.compact(value)} - {NUMBER_FORMAT.compact(record.max)}</div>
                        }
                    },
                ]}
            />
        )}


    </div>
};

export default CurrentRate;
