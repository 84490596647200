import React, { useState, useEffect } from 'react';
import { Button, Typography, Row, Col, message, DatePicker, Table, Card, Empty } from 'antd';
import { MENU_ITEMS } from '../../util/options';
import { dateFormats, DATE_FORMAT, DB, MSG, ranges } from '../../util/constant';

import dayjs from 'dayjs'
import 'dayjs/locale/th'

import moment from 'moment';
import 'moment/locale/th';
import LottoService from '../../services/system/lotto.service';
import { groupBy } from 'lodash';
moment.updateLocale('th', {
    week: {
        dow: 1
    }
})

const { RangePicker } = DatePicker;

const ReportReward = () => {
    const [loadingResult, setLoadingResult] = useState(false)

    const [data, setData] = useState([])
    const params = { keyword: '', dateRange: { from: moment().format('YYYY-MM-DD'), to: moment().format('YYYY-MM-DD') } }

    const [dateRange, setDateRange] = useState({ ...params.dateRange })
    const [displayInfo, setDisplayInfo] = useState({
        diff: dayjs().diff(dayjs(), 'day') + 1,
        from: DATE_FORMAT.dateonly(),
        to: DATE_FORMAT.dateonly(),
    })
    const [dateValues, setDateValues] = useState([moment(), moment()])

    const onRangeChange = (dates, dateStrings) => {
        if (!dates) return

        let dateObj = {
            "from": dateStrings[0],
            "to": dateStrings[1],
        }
        setDateValues(dates)
        setDateRange(dateObj)

        setLoadingResult(true)
        setDisplayInfo({
            diff: dayjs(dateStrings[1]).diff(dateStrings[0], 'day') + 1,
            from: DATE_FORMAT.dateonly(dateStrings[0]),
            to: DATE_FORMAT.dateonly(dateStrings[1]),
        })
    }

    useEffect(() => {
        try {
            setLoadingResult(true)
            LottoService.getReportReward({ dateRange: dateRange })
                .then(async res => {
                    setData(await processData(res.results))
                    setLoadingResult(false)
                })
        }
        catch (err) {
            message.error(MSG.COMMON.SMT_WRONG)
        }
    }, [dateRange])


    const processData = async (data) => {
        const groupedData = groupBy(data, 'groupId')
        return groupedData
    }

    const renderTables = () => {
        if (Object.entries(data).length > 0) {
            return Object.entries(data).map(([key, value]) => {
                return <div key={key} className="table-wrapper">
                    <div className='text-bold text-big'>< span >{(DB.SYSTEM.LOTTO.GROUP.find((g) => g.id === value[0].groupId))?.name}</span></div>
                    <Table
                        size='small'
                        rowKey={record => record.id}
                        dataSource={value}
                        scroll={{ x: 'max-content' }}
                        columns={[
                            {
                                title: '',
                                dataIndex: 'lottos_fk',
                                key: 'lottos_fk',
                                align: 'center',
                                width: '100px',
                                render: (value) => {
                                    return <img className='market-image-in-td' src={`/images/lotto/${value}.png`} alt="" />
                                }
                            },
                            {
                                title: 'ประเภทหวย',
                                dataIndex: 'name',
                                key: 'name',
                                align: 'center',
                                width: '250px',
                            },
                            {
                                title: 'งวด',
                                dataIndex: 'date',
                                key: 'date',
                                align: 'center',
                                width: '250px',
                                render: (value) => {
                                    return `${DATE_FORMAT.dateonly(value)}`
                                }
                            },
                            {
                                title: '3 ตัวบน',
                                dataIndex: 'threeUp',
                                key: 'threeUp',
                                align: 'center',
                                render: (text, record, index) => (
                                    record.result?.["3 ตัวบน"] ?? '-'
                                )
                            },
                            {
                                title: '2 ตัวบน',
                                dataIndex: 'twoUp',
                                key: 'twoUp',
                                align: 'center',
                                render: (text, record, index) => (
                                    record.result?.["2 ตัวบน"] ?? '-'
                                )
                            },
                            {
                                title: '2 ตัวล่าง',
                                dataIndex: 'twoBelow',
                                key: 'twoBelow',
                                align: 'center',
                                render: (text, record, index) => (
                                    record.result?.["2 ตัวล่าง"] ?? '-'
                                )
                            },
                        ]}
                        className="table-elm shadowed"
                        pagination={false}
                        loading={loadingResult}
                    />
                </div>
            })
        } else {
            return <Empty />
        }
    }



    return (
        <div className='common-page-group report-reward-page'>
            <div className='page-title'>
                <Typography className='title'>{MENU_ITEMS.reportReward.label}</Typography>
            </div>


            <div className='page-content-wrapper'>
                <div className='page-content'>

                    <div className="search-section">
                        <Card title="ตัวเลือกการค้นหา" size="small">
                            <Row gutter={[16, 16]} justify='start'>
                                <Col xs={24}>
                                    <RangePicker
                                        ranges={{ ...ranges }}
                                        onChange={onRangeChange}
                                        value={dateValues}
                                        allowEmpty={[false, false]}
                                        allowClear={false}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={[16, 16]} className='my-2'>
                                {Object.entries(ranges)?.map(([key, value]) => {
                                    return <Col key={key}><Button onClick={() => {
                                        let dateObj = {
                                            "from": moment(value[0]).format(dateFormats.only_date),
                                            "to": moment(value[1]).format(dateFormats.only_date),
                                        }
                                        setDateValues([...value])
                                        setDateRange(dateObj)
                                    }
                                    }>{key}</Button></Col>
                                })}
                            </Row>
                        </Card>
                    </div>

                    <div className='displayInfo-section'>
                        <div>ข้อมูลรวม <span className='text-blue'>{displayInfo.diff}</span> วัน
                            ตั้งแต่วันที่ <span className='text-blue'>{displayInfo.from}</span> ถึง <span className='text-blue'>{displayInfo.to}</span></div>
                    </div>

                    <div className='tables-wrapper'>
                        {renderTables()}
                    </div>
                </div>
            </div>
        </div >

    )
}

export default ReportReward;