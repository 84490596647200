import React, { useEffect } from 'react';
import './App.less';
import { useLocation } from 'react-router-dom';

import { message } from 'antd';
import MainRoutes from './routes/MainRoutes.js';

import './App.less';

function App() {
  const location = useLocation();

  const versionCheck = () => {
    let name = 'DUNCHESS LOTTO';
    let version = '1.1.5';
    console.log(`Welcome to ${name} v${version} 😎`);

    const last_version = localStorage.getItem(`${name}-Version`);
    if (last_version !== version) {
      console.log('New Version Available ! 😍');
      message.loading('กำลังอัปเดตซอฟต์แวร์..');
      localStorage.setItem(`${name}-Version`, version);
      window.location.reload(true);
    }
  };

  useEffect(() => {
    versionCheck();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="App">
      <MainRoutes />
    </div>
  );
}

export default App;
