import React, { useState, useEffect } from 'react';
import ListUser from './ListUser';

const ListLockedUser = () => {

    return (
        <div className='agent-page-group list-locked-user-page'>
            <ListUser custom='locked' />
        </div >
    );
}

export default ListLockedUser;
