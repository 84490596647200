import React, { useState, useEffect } from 'react';
import { Spin, Typography, Row, Col, Space, Table, Tabs } from 'antd';
import { ELM_STANDARD, MENU_ITEMS } from '../../../util/options';
import { DB, NUMBER_FORMAT } from '../../../util/constant';
import AgentService from '../../../services/user/agent.service';
const MySetting = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([])

    useEffect(() => {
        prepareData()
    }, [])

    const prepareData = async () => {
        const res = await AgentService.getOwnRate()
        setData(res)
        setLoading(false)
    }


    return (
        <div className='agent-page-group my-setting-page'>
            <Spin {...ELM_STANDARD.spin} spinning={loading}>
                <div className='page-title'>
                    <Typography className='title'>{MENU_ITEMS.mySetting.label}</Typography>
                </div>

                <div className='page-content-wrapper'>
                    <div className='page-content'>
                        <Row gutter={[16, 16]}>
                            <Col xs={24}>
                                <div className='user-rate-wrapper'>
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24}>
                                            <Tabs defaultActiveKey={data?.[0]?.id}>
                                                {data?.map((item, index) => (
                                                    <Tabs.TabPane tab={item.name} key={item.id}>
                                                        <Space direction='vertical' size={'large'} className='w-100'>
                                                            <div className='text-bold text-big'>แบ่งหุ้น เก็บของ <span className="text-blue">{item.name}</span>: แบ่งหุ้น {item.userRate?.hold}%</div>
                                                            <Table
                                                                scroll={{ x: 'max-content' }}
                                                                bordered
                                                                pagination={false}
                                                                rowKey={(record) => record.id}
                                                                dataSource={item?.rate}
                                                                columns={[
                                                                    {
                                                                        title: ``,
                                                                        dataIndex: 'id',
                                                                        key: 'id',
                                                                        render: (value,) => {
                                                                            return DB.SYSTEM.DESC.RATE.find((d) => d.id === value)?.name
                                                                        }
                                                                    },
                                                                    {
                                                                        title: 'จ่าย',
                                                                        dataIndex: 'rate',
                                                                        key: 'rate',
                                                                        align: 'center',
                                                                    },
                                                                    {
                                                                        title: 'ลด',
                                                                        dataIndex: 'discount',
                                                                        key: 'discount',
                                                                        align: 'center',
                                                                    },
                                                                    {
                                                                        title: 'ขั้นต่ำ',
                                                                        dataIndex: 'min',
                                                                        key: 'min',
                                                                        align: 'center',
                                                                        render: (value, record) => {
                                                                            return <div>{NUMBER_FORMAT.compact(value)} - {NUMBER_FORMAT.compact(record.max)}</div>
                                                                        }
                                                                    },
                                                                ]}
                                                            />
                                                        </Space>
                                                    </Tabs.TabPane>



                                                ))}
                                            </Tabs>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>


                        </Row>
                    </div>
                </div>

            </Spin>
        </div>
    )
}

export default MySetting;